import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCircleCheck, faXmarkCircle, faGlassCheers } from "@fortawesome/free-solid-svg-icons";
import triangle from "../../assets/svg/triangle.svg";
import dangerline from "../../assets/svg/dangerline.svg";
import styleModal from "../../styles/Modal.module.css";
import { useSelector } from "react-redux";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";

import { useSearchParams, useNavigate } from "react-router-dom";
import PaginationBar from "../../Components/PaginationBar";
import { useEffect, useState, useRef } from "react";

import PopUpModal from "../../Components/Modal";
import LoaderPage from "../loaders/loaderPage";
import { useReactToPrint } from "react-to-print";
import { useCallback } from "react";
import Csv from "../../Components/csv/Csv";
import axios from "axios";
import NoResult from "../../Components/NoResult";
import styles from "../../styles/Refund.module.css";

import { requestDateTime, TIMEOUT } from "../../Features/dateTime";
import ViewImei from "../../Components/IMEI/ViewImei";
import ReceivePayments from "../../Pages/ReceivePayments";
import InvoicePaymentSales from "../../Pages/SalesHistory/InvoicePaymentSales/InvoicePaymentSales";
import ThermalInvoicePaymentSales from "../../Pages/SalesHistory/InvoicePaymentSales/ThermalInvoicePaymentSales";
import AddRefund from "../../Pages/SalesHistory/Refund/AddRefund";

const History = ({ payment, search, setSettingsModal, columnNotToShow, submitted, toDate, fromDate, locationFields, sizeOfPages, onlyWalkInCustomer }) => {
  const { user } = useSelector((state) => state.auth);
  const user_type = user.user_type;
  const shopName = user.shop_name;
  const [orderBy, setOrderBy] = useState({ "sales_order.created_at": "DESC" });

  const componentRef = useRef(null);
  const thermalcomponentRef = useRef(null);
  const shop_id = user.shop_id;
  const userId = user.user_id;
  const logo = user.logo_path;
  const { printerType } = useSelector((state) => state.printerType);
  const [sales, setSales] = useState([]);
  const [totalSales, setTotalSales] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [dummyModal, setDummyModal] = useState(false);
  const [searchParams] = useSearchParams();
  const [modalPaymentShow, setPaymentModalShow] = useState(false);
  const [customerForPaymentModal, setCustomerForPaymentModal] = useState(null);
  let [is_remaining, setRemaining] = useState([]);
  const [emailSuccess, setEmailSuccess] = useState();
  const [emailError, setEmailError] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [printId, setPrintId] = useState();
  let [received_amount, setReceivedAmount] = useState();
  const [updatePaymentId, setupdatePaymentId] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const [toggle, refresh] = useState(false);
  const [locationId, setLocationId] = useState(-1);
  const [emailModalShow, setEmailModalShow] = useState(false);
  const [emailPaymentModalShow, setEmailPaymentModalShow] = useState(false);
  const [isEmailClicked, setIsEmailClicked] = useState(false);
  const [emailStatus, setEmailStatus] = useState("");
  const [refundModal, setRefundModal] = useState(false);
  const [salesOrderId, setSalesOrderId] = useState();
  const [showImeiModal, setShowImeiModal] = useState(false);
  const [ImeiData, setImeiData] = useState();

  const [openingBalances, setOpeningBalances] = useState([]);
  const [location, setLocation] = useState(-1);
  const [salesNumber, setSalesNumber] = useState(null);

  // useEffect(() => {
  //   if (searchParams.get("page")) {
  //     setCurrentPage(Number(searchParams.get("page")));
  //   }
  // }, [currentPage, searchParams]);

  const changePage = (page) => {
    setCurrentPage(page);
    navigate("/sales/sales-history?page=" + page);
  };

  // My Sales Order
  const getSalesHistory = async () => {
    try {
      let quantityObj = {};
      if (payment) {
        quantityObj["quantity"] = payment.value;
      }
      let searchobj = {};

      if (search.length > 0) {
        searchobj = { ...searchobj, search: search };
      } else {
        searchobj = { ...searchobj, search: "" };
      }
      if (Object.keys(locationFields).length > 0) {
        searchobj = { ...searchobj, location: locationFields.value };
      }
      let dates = {
        from: requestDateTime(fromDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
        to: requestDateTime(toDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
      };
      searchobj = { ...searchobj, ...quantityObj, dates, orderBy, onlyWalkInCustomer };
      const res = await axios.post(process.env.REACT_APP_API_URL + `/sales/sales-history/get/${userId}?page=${currentPage}&size=${sizeOfPages}`, searchobj, { withCredentials: true });
      const data = res.data;
      if (data) {
        setSales(
          data.data.map((item) => {
            if (item.customer_name === "nil") {
              return {
                ...item,
                customer_name: "Walk-in Customer",
                customer_number: "",
                customer_email: "",
              };
            } else {
              return { ...item, customer_email: item.customer_email ?? "" };
            }
          }),
        );
        setTotalSales(data.count.no_of_items);
        setNoOfPages(data.count.no_of_pages);
        if (data.count.no_of_pages !== 0) {
          if (currentPage === 0) {
            navigate("/sales/sales-history?page=1");
            setCurrentPage(1);
          }
        }
        if (currentPage > data.count.no_of_pages) {
          if (data.count.no_of_pages === 0) {
            setCurrentPage(1);
            navigate("/sales/sales-history?page=1");
          } else {
            setCurrentPage(data.count.no_of_pages);
            navigate("/sales/sales-history?page=" + data.count.no_of_pages);
          }
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    let timeOut;
    if (userId) {
      if (search?.length > 0) {
        setIsLoading(true);
        getSalesHistory();
      } else {
        setIsLoading(true);
        getSalesHistory();
      }
    }
    return () => clearTimeout(timeOut);
  }, [shop_id, search, submitted, currentPage, payment, toDate, locationFields, sizeOfPages, toggle, userId, orderBy, onlyWalkInCustomer]);
  useEffect(() => {
    if (currentPage === 1) {
      // refresh((prev) => !prev);
    } else {
      // changePage(1);
    }
  }, [orderBy, payment, locationFields, fromDate, toDate]);
  // My Sales Order For CSV
  function requestAllHistory() {
    let quantityObj = {};
    if (payment) {
      quantityObj["quantity"] = payment.value;
    }
    let searchobj = {};

    if (search.length > 0) {
      searchobj = { ...searchobj, search: search };
    } else {
      searchobj = { ...searchobj, search: "" };
    }
    if (Object.keys(locationFields).length > 0) {
      searchobj = { ...searchobj, location: locationFields.value };
    }
    let dates = {
      from: requestDateTime(fromDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
      to: requestDateTime(toDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
    };
    searchobj = { ...searchobj, ...quantityObj, dates, orderBy, onlyWalkInCustomer };
    return axios.post(process.env.REACT_APP_API_URL + `/sales/sales-history/get/${userId}`, searchobj, { withCredentials: true });
  }

  const handleReceivePayment = (sales) => {
    setRemaining(sales.remaining);
    setReceivedAmount(sales.received_amount);
    setupdatePaymentId(sales.id);
    setLocation(sales.location);
    setSalesNumber(sales.sales_number);
    setCustomerForPaymentModal({ customer_email: sales.customer_email, customer_name: sales.customer_name, customer_number: sales.customer_number });
  };

  const InvoicePayments = async (id) => {
    window.open(`/invoice-payments-sales/` + id, "_blank", "noopener,noreferrer");
  };

  // Send Email functionality
  const handleSendEmail = async (id) => {
    try {
      setIsEmailClicked(true);
      let invoiceData;
      const res = await axios.post(process.env.REACT_APP_API_URL + `/sales/invoice-payments-sales/${id}`, { id: shop_id }, { withCredentials: true });
      if (res) {
        let arr = [];
        const products = res.data.items.map((i) => ({
          title: i.title,
          cartQuantity: i.qty_ordered,
          price: i.sale_price,
          discount: i.discount,
        }));

        const miscProducts = res.data.miscItems.map((i) => ({
          title: i.name,
          cartQuantity: i.mis_qty,
          price: i.price,
          discount: i.discount,
          description: i.description,
        }));

        arr.push(...products);
        arr.push(...miscProducts);
        const data = {
          ...invoiceData,
          invoice: res.data.data[0],
          items: arr,
          payments: res.data.payments,
          logo: logo,
        };
        const res2 = await axios.post(process.env.REACT_APP_API_URL + "/sales/send-sales-history-email", data, { withCredentials: true });
        if (res2.data.status === 404) {
          setEmailSuccess("");
          setEmailError(res2.data.message);
          setModalShow(true);
          setTimeout(() => setModalShow(false), 2500);
          setIsEmailClicked(false);
        } else if (res2.data.status === 200) {
          setEmailError("");
          setEmailSuccess(res2.data.success.message);
          setModalShow(true);
          setTimeout(() => setModalShow(false), 2500);
          setIsEmailClicked(false);
        }
      }
    } catch (error) {
      setEmailSuccess("");
      setIsEmailClicked(true);
      setEmailError(error.response.data.error.message);
      setModalShow(true);
      setTimeout(() => setModalShow(false), 1500);
      setIsEmailClicked(false);
    }
  };
  const [isPrinting, setIsPrinting] = useState(false);
  const handleOnBeforeGetContent = () => {
    setIsPrinting(true);
  };
  const handleBeforePrint = () => {
    setIsPrinting(true);
  };

  const handleAfterPrint = () => {
    setIsPrinting(false);
  };
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Payment Invoice",
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
  });
  const reactToPrintThermalContent = useCallback(() => {
    return thermalcomponentRef.current;
  }, []);
  const handleThermalPrint = useReactToPrint({
    content: reactToPrintThermalContent,
    documentTitle: "Payment Invoice",
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
  });

  const hanldeId = (id) => {
    setPrintId(id);
  };

  const closeUpdatePaymentModal = () => {
    setCustomerForPaymentModal(null);
    setTimeout(() => setPaymentModalShow(false), 1500);

    refresh((prev) => !prev);
  };
  const handlePaymentEmailModal = () => {
    setEmailPaymentModalShow(true);
    setTimeout(() => setEmailPaymentModalShow(false), 1500);
  };

  const handleEmailStatus = (data) => {
    setEmailStatus(data);
  };

  // Refund
  const handleRefund = (id) => {
    setRefundModal(true);
    setSalesOrderId(id);
  };

  const viewImei = (data) => {
    setImeiData(data?.imei);
    setShowImeiModal(true);
  };
  const handleDummy = (data) => {
    setDummyModal(true);
    setSalesOrderId(data["id"]);
  };

  const archiveConfirm = async () => {
    try {
      const data = await axios.post(process.env.REACT_APP_API_URL + "/sales/add-dummy-sale", { sales_order_id: salesOrderId }, { withCredentials: true });
      if (data) {
        setDummyModal(false);
        refresh((prev) => !prev);
      }
    } catch {}
  };
  const restoreInventory = async () => {
    try {
      const data = await axios.post(process.env.REACT_APP_API_URL + "/sales/dummy-sale/restore-inventory-stock", { sales_order_id: salesOrderId }, { withCredentials: true });
      if (data) {
        setDummyModal(false);
        refresh((prev) => !prev);
      }
    } catch {}
  };
  const settingsModal = () => {
    setSettingsModal(true);
  };

  // Opening Balances For checks
  async function getOpeningBalances(id) {
    try {
      const openingBalanceRespponse = await axios.get(process.env.REACT_APP_API_URL + "/location/opening-balances/" + id, { withCredentials: true });
      if (openingBalanceRespponse.data.code === 200) {
        setOpeningBalances(openingBalanceRespponse.data.success.data);
      }
    } catch (err) {}
  }
  useEffect(() => {
    if (userId) {
      getOpeningBalances(userId);
    }
  }, [toggle, userId]);

  return (
    <>
      {
        <PopUpModal
          title={emailSuccess ? "Email Sent" : "Email Not sent."}
          icon={<FontAwesomeIcon icon={faCircleCheck} />}
          text={emailSuccess ? emailSuccess : emailError}
          type={emailSuccess ? styleModal.successBg : styleModal.errorBg}
          iconModal={emailSuccess ? styleModal.successIcon : styleModal.errorIcon}
          show={emailModalShow}
          onHide={() => setEmailModalShow(false)}
        />
      }

      <div className="d-none">
        <InvoicePaymentSales printId={printId} ref={componentRef} />
        <ThermalInvoicePaymentSales printId={printId} ref={thermalcomponentRef} />
      </div>

      <Container fluid className="p-0">
        {
          <PopUpModal
            title={emailSuccess ? "Email Sent" : "Email Not sent."}
            icon={<FontAwesomeIcon icon={emailSuccess ? faCircleCheck : faXmarkCircle} />}
            text={emailSuccess ? emailSuccess : emailError}
            type={emailSuccess ? styleModal.successBg : styleModal.errorBg}
            iconModal={emailSuccess ? styleModal.successIcon : styleModal.errorIcon}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        }
        {isLoading ? (
          <div
            style={{
              height: "50vh",
              display: "flex",
              justifyContent: "center",
            }}
            className={`order-3`}
          >
            <LoaderPage />
          </div>
        ) : sales.length > 0 ? (
          <>
            <CustomizeTableview
              settingsModal={settingsModal}
              dummy={handleDummy}
              className=""
              data={sales}
              columnNotShow={["id", "imei", "location"].concat(columnNotToShow)}
              dateColumn={columnNotToShow.includes("created_at") ? "" : ["created_at"]}
              sortColumn={["customer_name", "created_at", "base_grand_total", "received_amount", "remaining"]}
              print={{
                isShow: (value) => {
                  return !value["sales_number"].startsWith("D4U");
                },
                action: (id) => {
                  printerType?.value === "thermal" ? setTimeout(() => handleThermalPrint(), 500) : setTimeout(() => handlePrint(), 500);
                  hanldeId(id);
                },
              }}
              view={{
                action: InvoicePayments,
                isShow: (value) => {
                  return !value["sales_number"].startsWith("D4U");
                },
              }}
              email={isEmailClicked === false ? handleSendEmail : true}
              refund={{
                onClick: (id) => {
                  handleRefund(id);
                },
                isShow: (value, row) => {
                  if (row?.["sales_number"].startsWith("D4U")) {
                    return false;
                  }
                  if (value > 0) {
                    return false;
                  } else {
                    return true;
                  }
                },
              }}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              status={
                !columnNotToShow.includes("status")
                  ? {
                      column: "remaining",
                      style: (value) => {
                        if (value > 0) {
                          return "text-danger";
                        } else {
                          return "text-success";
                        }
                      },
                      format: (value) => {
                        if (value > 0) {
                          return "Pending";
                        } else {
                          return "Paid";
                        }
                      },
                    }
                  : ""
              }
              actionButton={{
                label: "Receive Payment",
                onClick: (id, sales) => {
                  setPaymentModalShow(true);
                  handleReceivePayment(sales);
                },
                variant: "outline-primary",
                style: { padding: "8px", fontSize: "12px" },
                column: "remaining",
                isShow: (value) => {
                  if (value > 0) {
                    return true;
                  } else {
                    return false;
                  }
                },
              }}
              viewImei={viewImei}
            />
            <Row className="mt-5 align-items-center">
              <Col className="d-none d-md-block" md="3" lg="3">
                <span className="text-secondary fs-6"> {totalSales} result(s)</span>
              </Col>
              <Col xs="12" md="6" lg="6">
                <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
              </Col>
              <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                <span className="text-secondary fs-6"> {totalSales} result(s)</span>
              </Col>
              <Col xs="6" md="3" lg="3" className="text-end">
                <Csv
                  filename={`${
                    "Sale History " +
                    shopName +
                    " " +
                    new Date().toLocaleDateString("en-US", {
                      timeZone: "Asia/Karachi",
                    })
                  }`}
                  api={requestAllHistory}
                  responseObject={(data) => {
                    return data.data.data;
                  }}
                  format={(data) => {
                    const { id, created_at, customer_number, ...temp } = data;
                    const newObj = {
                      ...temp,
                      customer_number: customer_number.replaceAll("+", "").replaceAll("-", " "),
                      status: data.remaining > 0 ? "Pending" : "Paid",
                      created_at: created_at.split("T")[0],
                    };

                    return newObj;
                  }}
                  error={(err) => {
                    const tempErr = err.response?.data.error;
                    if (tempErr?.status === 404 && tempErr.message === "No Record Found") {
                      return true;
                    } else {
                      return false;
                    }
                  }}
                />
              </Col>
            </Row>
          </>
        ) : (
          <NoResult path={"/sales"} name="Sales" />
        )}
      </Container>

      {<PopUpModal icon={<FontAwesomeIcon icon={faCircleCheck} />} text={emailStatus} type={styleModal.successBg} iconModal={styleModal.successIcon} show={emailPaymentModalShow} onHide={() => setEmailPaymentModalShow(false)} />}
      <PopUpModal title="Payment" show={modalPaymentShow} onHide={() => setPaymentModalShow(false)}>
        <ReceivePayments
          close={closeUpdatePaymentModal}
          is_remaining={is_remaining}
          received_amount={received_amount}
          updatePaymentId={updatePaymentId}
          emailClose={handlePaymentEmailModal}
          emailStatus={handleEmailStatus}
          paymentCheck={openingBalances.filter((ob) => ob.location_id === location)[0]}
          salesNumber={salesNumber}
          customerInfo={customerForPaymentModal}
        />
      </PopUpModal>
      <PopUpModal size="xl" title="Refund" show={refundModal} onHide={() => setRefundModal(false)} className={styles.refundModal}>
        <AddRefund close={() => setRefundModal(false)} openingBalances={openingBalances} id={salesOrderId} />
      </PopUpModal>
      <PopUpModal size="md" title=" IMEI Numbers" show={showImeiModal} onHide={() => setShowImeiModal(false)} className={styles.refundModal}>
        <ViewImei ImeiData={ImeiData} close={() => setShowImeiModal(false)} id={salesOrderId} />
      </PopUpModal>
      {/* Archive Model */}

      <Modal
        show={dummyModal}
        onHide={() => {
          setDummyModal(false);
        }}
        animation={true}
        centered
      >
        <Modal.Header>
          <Modal.Title>Archive</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center pb-4">
            <img src={triangle} alt="triangle" />
            <img src={dangerline} alt="dangerline" style={{ position: "absolute", right: "auto", top: "2.2rem" }} />
          </div>
          <div>
            <span className="d-flex justify-content-center pb-4">
              {/* Do you really want to Archive? */}
              Do you want to restore inventory stock?
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            <Button className="w-100 rounded-3" onClick={() => restoreInventory()}>
              Yes
              {/* Archive */}
            </Button>
            <Button className="w-100 rounded-3" onClick={() => archiveConfirm()}>
              No
              {/* Archive */}
            </Button>
            <span
              className="w-100 d-flex justify-content-center align-items-center text-secondary p-2 border-2 border-secondary"
              style={{
                cursor: "pointer",
                border: "2px solid rgba(151, 156, 162, 1)",
                borderRadius: "6px",
              }}
              onClick={() => {
                setDummyModal(false);
              }}
            >
              Cancel
            </span>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default History;
