import { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faWarning } from "@fortawesome/free-solid-svg-icons";
import SearchInput, { SearchMobleInput } from "./../Components/SearchInput";
import PopUpModal from "../Components/Modal";
import filterImg from "../assets/svg/filter.svg";
import { Link } from "react-router-dom";
import SearchableSelect from "../Components/SearchableSelect";
import CustomizeTableview from "../Components/TableViews/CustomizeTableview";
import { useSelector } from "react-redux";
import PaginationBar from "./../Components/PaginationBar";
import { useSearchParams, useNavigate } from "react-router-dom";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import styleModal from "../styles/Modal.module.css";
import axios from "axios";
import NoResult from "./../Components/NoResult";
import EditLocation from "./EditLocation";
import Csv from "./../Components/csv/Csv";
import SearchMobile from "./../Components/SearchMobile";
import MobileSearchAcoordian from "./../Components/MobileSearchAcoordian";
import customloader from "./../assets/images/RollingLoader.gif";
import { TIMEOUT } from "../Features/dateTime";
import LoaderPage from "../Components/loaders/loaderPage";

const LocationList = () => {
  const { user } = useSelector((state) => state.auth);

  const shop_id = user.shop_id;
  const user_type = user.user_type;
  const userId = user.user_id;
  const shopName = user.shop_name;

  const [modalEditLocationShow, setEditLocationModalShow] = useState(false);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [locationlist, setLocationlist] = useState([]);
  const [locationId, setLocationId] = useState([]);
  const [totalLocations, setTotalLocations] = useState();
  const [locationlistData, setLocationlistData] = useState([]);
  const [cityName, setCityName] = useState([]);
  const [provinceName, setProvinceName] = useState([]);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);
  const [locationNameSearch, setlocationNameSearch] = useState([]);
  const [orderBy, setOrderBy] = useState({ "location.created_at": "DESC" });
  const [searchParams] = useSearchParams();
  const [citySearch, setCitySearch] = useState(null);
  const [provinceSearch, setProvinceSearch] = useState(null);
  const [data_refresh, setToggle] = useState(false);
  const [isLocationLimitCompleted, SetIsLocationLimitCompleted] = useState(false);
  const [limitModalShow, setLimitModalShow] = useState(false);

  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [provinceMobile, setProvinceMobile] = useState(null);
  const [cityMobile, setCityMobile] = useState(null);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get("page")) {
      setCurrentPage(Number(searchParams.get("page")));
    }
  }, [currentPage, searchParams]);

  const changePage = (page) => {
    setCurrentPage(page);
    navigate("/settings/locations?page=" + page);
  };

  // Csv
  function requestAllHistory() {
    let searchobj = {};

    if (locationNameSearch && Object.keys(locationNameSearch).length > 0) {
      searchobj = { ...searchobj, location_nick: locationNameSearch };
    }
    if ((citySearch && Object.keys(citySearch).length > 0) || (cityMobile && Object.keys(cityMobile).length > 0)) {
      searchobj = {
        ...searchobj,
        city: citySearch?.value ? citySearch?.value : cityMobile?.value,
      };
    }
    if ((provinceSearch && Object.keys(provinceSearch).length > 0) || (provinceMobile && Object.keys(provinceMobile).length > 0)) {
      searchobj = {
        ...searchobj,
        province: provinceSearch?.value ? provinceSearch?.value : provinceMobile?.value,
      };
    }
    searchobj = { ...searchobj, orderBy, user_type, shop_id };
    return axios.post(process.env.REACT_APP_API_URL + `/location/get-location/${userId}`, searchobj, { withCredentials: true });
  }

  async function getLocations() {
    try {
      let searchobj = {};

      if (locationNameSearch && Object.keys(locationNameSearch).length > 0) {
        searchobj = { ...searchobj, location_nick: locationNameSearch };
      }
      if ((citySearch && Object.keys(citySearch).length > 0) || (cityMobile && Object.keys(cityMobile).length > 0)) {
        searchobj = {
          ...searchobj,
          city: citySearch?.value ? citySearch?.value : cityMobile?.value,
        };
      }
      if ((provinceSearch && Object.keys(provinceSearch).length > 0) || (provinceMobile && Object.keys(provinceMobile).length > 0)) {
        searchobj = {
          ...searchobj,
          province: provinceSearch?.value ? provinceSearch?.value : provinceMobile?.value,
        };
      }
      searchobj = { ...searchobj, orderBy, user_type, shop_id };
      const res = await axios.post(process.env.REACT_APP_API_URL + `/location/get-location/${userId}?page=${currentPage}&size=${sizeOfPages}`, searchobj, { withCredentials: true });
      const data = res.data;
      if (data) {
        setLocationlist(data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      throw new Error(error.response.data);
    }
  }

  async function getLocationsNoOfPages() {
    try {
      let searchobj = {};

      if (locationNameSearch && Object.keys(locationNameSearch).length > 0) {
        searchobj = { ...searchobj, location_nick: locationNameSearch };
      }
      if ((citySearch && Object.keys(citySearch).length > 0) || (cityMobile && Object.keys(cityMobile).length > 0)) {
        searchobj = {
          ...searchobj,
          city: citySearch?.value ? citySearch?.value : cityMobile?.value,
        };
      }
      if ((provinceSearch && Object.keys(provinceSearch).length > 0) || (provinceMobile && Object.keys(provinceMobile).length > 0)) {
        searchobj = {
          ...searchobj,
          province: provinceSearch?.value ? provinceSearch?.value : provinceMobile?.value,
        };
      }
      searchobj = { ...searchobj, orderBy, user_type, shop_id };
      const res = await axios.post(process.env.REACT_APP_API_URL + `/location/no-of-pages/get-location/${userId}?size=${sizeOfPages}`, searchobj, { withCredentials: true });
      if (res) {
        setTotalLocations(res.data.no_of_items);
        setNoOfPages(res.data.no_of_pages);
        if (res.data.no_of_pages !== 0) {
          if (currentPage === 0) {
            navigate("/settings/locations?page=1");
            setCurrentPage(1);
          }
        }
        if (currentPage > res.data.no_of_pages) {
          setCurrentPage(res.data.no_of_pages);
          navigate("/settings/locations?page=" + res.data.no_of_pages);
        }
      }
    } catch (error) {
      throw new Error(error.response.data);
    }
  }

  useEffect(() => {
    let timeOut;
    if (locationNameSearch?.length > 0) {
      timeOut = setTimeout(() => {
        setLoading(true);
        getLocations();
        getLocationsNoOfPages();
      }, TIMEOUT);
    } else {
      setLoading(true);
      getLocations();
      getLocationsNoOfPages();
    }
    return () => clearTimeout(timeOut);
  }, [locationNameSearch, currentPage, userId, user_type, shop_id, sizeOfPages, data_refresh, orderBy]);

  useEffect(() => {
    if (currentPage === 1) {
      setToggle((prev) => !prev);
    } else {
      changePage(1);
    }
  }, [orderBy, citySearch, cityMobile, provinceSearch, provinceMobile]);

  useEffect(() => {
    const getProvince = async (id) => {
      try {
        const res = await axios.post(process.env.REACT_APP_API_URL + `/location/get-province-location/${id}`, {}, { withCredentials: true });
        const getData = res.data.map((loc) => ({
          value: loc.province_id,
          label: loc.province_name,
        }));
        setProvinceName(getData);
      } catch (error) {
        throw new Error(error.response.data);
      }
    };
    getProvince(userId);
  }, [userId]);

  useEffect(() => {
    const getCity = async () => {
      try {
        const provinceId = provinceSearch?.value ? provinceSearch.value : provinceMobile?.value;
        const res = await axios.post(process.env.REACT_APP_API_URL + `/location/get-city-location/${shop_id}`, { province: provinceId }, { withCredentials: true });
        const getData = res.data.map((loc) => ({
          value: loc.city_id,
          label: loc.city_name,
        }));
        setCityName(getData);
      } catch (error) {
        throw new Error(error.response.data);
      }
    };
    if (provinceMobile?.value || provinceSearch?.value) {
      getCity();
    }
  }, [shop_id, provinceSearch, provinceMobile]);

  const filterCity = (e) => {
    if (e.label === "none") {
      setCitySearch({});
    } else {
      setCitySearch({ value: e.value, label: e.label });
      setCityMobile({ value: e.target?.id, label: e.target?.value });
    }
  };
  const filterProvince = (e) => {
    // if (e.label === "none") {
    //   setProvinceSearch({});
    // } else {
    setProvinceSearch({ value: e.value, label: e.label });
    setProvinceMobile({ value: e.target?.id, label: e.target?.value });
    setCitySearch(null);

    // }
  };

  const searchLocationName = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setlocationNameSearch("");
    } else {
      setlocationNameSearch(e.target.value);
    }
  };
  const resetFilterHandle = (e) => {
    setCitySearch(null);
    setProvinceSearch(null);
    setCityMobile(null);
    setProvinceMobile(null);
  };

  const handleEditLocation = (id) => {
    setLocationId(id);
    setEditLocationModalShow(true);
    setError("");
  };

  const closeEditLocationModal = () => {
    setEditLocationModalShow(false);
    setModalShow(true);
    setToggle((prev) => !prev);
    setTimeout(() => {
      setModalShow(false);
    }, 1000);
  };

  const [toogleMobileSearch, setToogleMobileSearch] = useState(false);
  const searchResult = () => {
    setToogleMobileSearch(false);
    setToogleMobileSearch(false);
  };
  const handleAcoordianCLose = () => {
    setToogleMobileSearch(false);
    setlocationNameSearch("");
    // setExpenseTypeFilter({ label: "all" });
  };
  const handleSearchChange = (e) => {
    setlocationNameSearch(e.target.value);
  };

  const handleAcoordianOpen = (value) => {
    setToogleMobileSearch(value);
  };
  const filter = () => {
    setToogleMobileSearch(false);
  };
  const SearchData = [
    {
      name: "Select Province",
      handleChange: filterProvince,
      value: provinceMobile,
      filter: provinceName,
    },
    {
      name: "Select City",
      handleChange: filterCity,
      value: cityMobile,
      filter: cityName,
    },
  ];

  const getSubscriptionDetails = async () => {
    axios
      .get(process.env.REACT_APP_API_URL + `/subscriptions/subscription-details/${shop_id}`, { withCredentials: true })
      .then((res) => {
        if (res.data?.subscription[0]) {
          SetIsLocationLimitCompleted(res.data?.subscription[0]?.locations_limit === res.data?.subscription[0]?.consumed_locations);
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  useEffect(() => {
    getSubscriptionDetails();
  }, [shop_id, data_refresh]);

  return (
    <>
      {<PopUpModal title="" icon={<FontAwesomeIcon icon={faCircleCheck} />} text={"Location updated succesfully."} type={styleModal.successBg} iconModal={styleModal.successIcon} show={modalShow} onHide={() => setModalShow(false)} />}
      <PopUpModal size="lg" title="Edit Location" show={modalEditLocationShow} onHide={() => setEditLocationModalShow(false)}>
        <div className="text-center">{error && <span className="text-danger p-2 text-center my-2 rounded-1">*{error}</span>}</div>
        <EditLocation locationId={locationId} close={closeEditLocationModal} shop_id={shop_id} setError={setError} setSuccess={setSuccess} />
      </PopUpModal>
      <PopUpModal icon={<FontAwesomeIcon icon={faWarning} />} footer={"true"} text={"Do you really want to delete this Location?"} show={modalDeleteShow} onHide={() => setModalDeleteShow(false)} />
      {toogleMobileSearch === "select" ? (
        <MobileSearchAcoordian SearchData={SearchData} toggleSearchclose={handleAcoordianCLose} filter={filter} />
      ) : (
        <>
          <Container fluid>
            {toogleMobileSearch === "search" ? (
              <div className="d-flex  mb-2 justify-content-center align-items-center">
                <div className="">
                  {" "}
                  <SearchMobleInput searchlogo value={locationNameSearch} handleChange={searchLocationName} />
                </div>
                <span className="ms-4" onClick={handleAcoordianCLose}>
                  X
                </span>
              </div>
            ) : (
              <Row className="d-flex align-items-center ">
                <Col xs="2" lg="3" xl="2" className="d-none d-lg-inline mb-3">
                  <SearchInput searchlogo handleChange={searchLocationName} />
                </Col>
                <Col xs="2" lg="4" xl="4" className="d-none d-lg-inline mb-3"></Col>
                <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3">
                  <SearchableSelect placeholder="By Province" searchable={false} value={provinceSearch} options={provinceName} handleChange={filterProvince} />
                </Col>
                <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3">
                  <SearchableSelect placeholder="By City" searchable={false} disabled={provinceSearch ? false : true} value={citySearch} options={cityName} handleChange={filterCity} />
                </Col>

                <Col xs="2" lg="3" onClick={() => handleAcoordianOpen("search")} className="d-lg-none d-inline mb-3">
                  <SearchInput searchlogo bordered />
                </Col>
                <Col className="d-lg-none d-flex mb-3" xs="2" lg="2">
                  {/* <Link to={"/settings/add-location"}> */}
                  <Button variant="outline-primary" onClick={() => handleAcoordianOpen("select")}>
                    <img src={filterImg} alt="filter" />
                    <span className=" ms-2 d-none d-sm-inline">Filter</span>{" "}
                  </Button>
                  {/* </Link> */}
                </Col>
                <Col className="d-none d-sm-inline d-lg-none d-md-inline " sm={2}></Col>
                {user_type === "primary" ? (
                  <Col className="mb-3  p-1" xs="2" sm="3" lg="2" xl="2" xxl="2">
                    {isLocationLimitCompleted ? (
                      <Button
                        className="h-100 w-100"
                        type="submit"
                        style={{ fontSize: "14px" }}
                        onClick={() => {
                          setLimitModalShow(true);
                        }}
                      >
                        <span className="d-none d-sm-inline d-lg-inline">Add New Location</span> <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    ) : (
                      <Link to={"/settings/add-location"}>
                        <Button className="h-100 w-100" type="submit" style={{ fontSize: "14px" }}>
                          <span className="d-none d-sm-inline d-lg-inline">Add New Location</span> <FontAwesomeIcon icon={faPlus} />
                        </Button>
                      </Link>
                    )}
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            )}
            <div className="pt-2">
              <Button size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
                <span className="d-lg-inline">Reset Filters</span>{" "}
              </Button>
            </div>

            {loading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                <LoaderPage />
              </div>
            ) : locationlist.length > 0 ? (
              <>
                <CustomizeTableview className={`mt-3`} data={locationlist} columnNotShow={["id"]} dateColumn={["created_at"]} sortColumn={["location_name", "created_at"]} orderBy={orderBy} setOrderBy={setOrderBy} edit={handleEditLocation} actions={user_type === "primary"} />
                <Row className="mt-5 align-items-center">
                  <Col className="d-none d-md-block" md="3" lg="3">
                    <span className="text-secondary fs-6">{totalLocations} result(s)</span>
                  </Col>
                  <Col xs="12" md="6" lg="6">
                    <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
                  </Col>
                  <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                    <span className="text-secondary fs-6">{totalLocations} result(s)</span>
                  </Col>
                  <Col xs="6" md="3" lg="3" className="text-end mt-3">
                    <Csv
                      filename={`${
                        "Locations " +
                        shopName +
                        " " +
                        new Date().toLocaleDateString("en-US", {
                          timeZone: "Asia/Karachi",
                        })
                      }`}
                      api={requestAllHistory}
                      format={(data) => {
                        const { id, created_at, ...temp } = data;
                        const newObj = {
                          ...temp,
                          created_at: created_at.split("T")[0],
                        };
                        return newObj;
                      }}
                      error={(err) => {
                        const tempErr = err.response.data.error;
                        if (tempErr.status === 404 && tempErr.message === "No Record Found") {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <NoResult name="Location" />
            )}
          </Container>
        </>
      )}
      <PopUpModal title={"Location Limit Reached."} icon={<FontAwesomeIcon icon={faWarning} />} text={"Location Limit Reached. You can not add more locations."} type={styleModal.errorBg} iconModal={styleModal.errorIcon} show={limitModalShow} onHide={() => setLimitModalShow(false)} />
    </>
  );
};

export default LocationList;
