import React from "react";
import { useState, useEffect } from "react";
import { Container, Row, Col, Button, Tabs, Tab } from "react-bootstrap";
import axios from "axios";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import SearchInput, { SearchMobleInput } from "../../Components/SearchInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import ReactSelect from "../../Components/ReactSelect";
import MobileSearchAcoordian from "../../Components/MobileSearchAcoordian";
import filterr from "../../assets/svg/filter.svg";
import filterStyle from "../../styles/filtersButtons.module.css";
import SearchableSelect from "../../Components/SearchableSelect";
import AddTradeInProduct from "./AddTradeInProduct";
import PopUpModal from "../../Components/Modal";
import moment from "moment";
import { requestDateTime, TIMEOUT } from "../../Features/dateTime";
import DatePicker from "../../Components/DatePicker/DatePicker";
import Csv from "../../Components/csv/Csv";
import PaginationBar from "../../Components/PaginationBar";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import NoResult from "../../Components/NoResult";
import LoaderPage from "../../Components/loaders/loaderPage";
const TradeInProducts = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { user } = useSelector((state) => state.auth);
  const location = useSelector((state) => state.location?.location);
  const shop_id = user.shop_id;
  const userId = user.user_id;
  const user_type = user.user_type;
  const shopName = user.shop_name;
  const [category, setCategory] = useState(null);
  const [locationSearch, setLocationSearch] = useState({});
  const [searchInput, setSearchInput] = useState("");
  const [conditionSearch, setConditionSearch] = useState(null);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [toogleMobileSearch, setToogleMobileSearch] = useState(false);
  const [techcategori, setTechCategori] = useState();
  const [getTechLocations, setGetTechLocations] = useState();
  const [modalAdd, setAddModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState();
  const [toggle, refresh] = useState(false);
  const [orderBy, setOrderBy] = useState({ created_at: "DESC" });
  const [fromDate, setFromDate] = useState(
    moment().subtract(29, "days").startOf("day")._d
  );
  const [toDate, setToDate] = useState(moment().endOf("day")._d);
  const [isLoading, setIsLoading] = useState(true);
  const [openingBalances, setOpeningBalances] = useState([]);

  async function getOpeningBalances(id) {
    try {
      const openingBalanceRespponse = await axios.get(
        process.env.REACT_APP_API_URL + "/location/opening-balances/" + id,
        { withCredentials: true }
      );
      if (openingBalanceRespponse.data.code === 200) {
        setOpeningBalances(openingBalanceRespponse.data.success.data);
      }
    } catch (err) {}
  }

  useEffect(() => {
    if (userId) {
      getOpeningBalances(userId);
    }
  }, [toggle, userId]);

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  // Start Customer with pagination
  const changePage = (page) => {
    setCurrentPage(page);
    navigate("/trade-in/products?page=" + page);
  };

  //searchParams
  useEffect(() => {
    if (searchParams.get("page")) {
      setCurrentPage(Number(searchParams.get("page")));
    }
  }, [currentPage, toggle, searchParams]);

  const filters = {
    search: searchInput,
    dates: {
      from: requestDateTime(fromDate)
        .toISOString()
        .replace("T", " ")
        .replace("Z", "")
        .split(".")[0],
      to: requestDateTime(toDate)
        .toISOString()
        .replace("T", " ")
        .replace("Z", "")
        .split(".")[0],
    },
    // location_id: locationSearch?.value,
    location_id: location?.value,
    category_id: category?.value,
    orderBy,
  };
  const headers = {
    filters: JSON.stringify(filters),
  };
  function getTradeInProducts() {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          `/trade-in/items/${shop_id}?page=` +
          currentPage +
          `&size=` +
          sizeOfPages,
        { headers, withCredentials: true }
      )
      .then((data) => {
        setIsLoading(false);
        setTotalProducts(data?.data?.pagination?.no_of_items);
        setProducts(data?.data?.data);

        if (data?.data?.pagination?.no_of_pages === 0) {
          if (searchParams.get("page")) {
            setNoOfPages(0);
            navigate("/trade-in?page=0");
          }
        } else {
          setNoOfPages(data?.data?.pagination?.no_of_pages);
          if (currentPage > data?.data?.pagination?.no_of_pages) {
            setCurrentPage(data?.data?.pagination?.no_of_pages);
            navigate("/trade-in?page=" + data?.data?.pagination?.no_of_pages);
          }
          if (searchParams.get("page") === "0") {
            setCurrentPage(1);
            navigate("/trade-in?page=1");
          }
        }
      })
      .catch((err) => {
        const tempErr = err.response.data.error;

        if (tempErr.status === 404 && tempErr.message === "No Record Found") {
          setProducts([]);
        }
      });
  }

  useEffect(() => {
    let timeOut;
    if (searchInput?.length > 0) {
      timeOut = setTimeout(() => {
        setIsLoading(true);
        getTradeInProducts();
      }, TIMEOUT);
    } else {
      setIsLoading(true);
      getTradeInProducts();
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, [
    currentPage,
    searchInput,
    noOfPages,
    sizeOfPages,
    shop_id,
    toggle,
    orderBy,
    category,
    location,
    // locationSearch,
    toDate,
    fromDate,
  ]);

  useEffect(() => {
    if (currentPage === 1) {
      refresh((prev) => !prev);
    } else {
      changePage(1);
    }
  }, [fromDate, toDate, orderBy]);

  function requestAllHistory() {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + `/trade-in/items/${shop_id}`, {
          headers,
          withCredentials: true,
        })
        .then((response) => {
          resolve(response?.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  const handleAcoordianCLose = () => {
    setToogleMobileSearch(false);
    setSearchInput("");
    setCategory(null);
    setConditionSearch(null);
    setLocationSearch({});
  };
  const filter = () => {
    setToogleMobileSearch(false);
  };

  const handleAcoordianOpen = (value) => {
    setToogleMobileSearch(value);
  };
  const getCat = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/categories/get", {
          withCredentials: true,
        })
        .then((res) => {
          const getData = res.data
            .filter((cat) => cat.id !== 7)
            .map((cat) => ({
              value: cat.id,
              label: cat.category,
            }));
          getData.splice(0, 0, { value: null, label: "All" });
          resolve(getData);
          setTechCategori(getData);
        })
        .catch((err) => {
          reject("err" + err);
        });
    });
  };
  const filterCategories = (e) => {
    if (e.value) {
      setCategory({ value: e.value, label: e.label });
    } else {
      setCategory(null);
    }
  };
  // const filterLocation = (e) => {
  //   if (e.value) {
  //     setLocationSearch({ value: e.value, label: e.label });
  //   } else {
  //     setLocationSearch({});
  //   }
  // };
  const filterCategoriesMobile = (e) => {
    if (e.target.value === "All") {
      setCategory({ value: null, label: e.target.value });
    } else {
      setCategory({ value: e.target.id, label: e.target.value });
    }
  };
  // const filterLocationMoble = (e) => {
  //   if (e.target.value === "All") {
  //     setLocationSearch({ value: null, label: e.target.value });
  //   } else {
  //     setLocationSearch({ value: e.target.value, label: e.target.value });
  //   }
  // };
  // Search Products
  const searchInventory = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearchInput("");
    } else {
      setSearchInput(e.target.value);
    }
  };

  // const getLocations = async () => {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post(
  //         process.env.REACT_APP_API_URL +
  //           `/location/get-location-by-user/${userId}`,
  //         { shop_id: shop_id, user_type: user_type },
  //         { withCredentials: true }
  //       )
  //       .then((res) => {
  //         const getData = res.data.map((loc) => ({
  //           value: loc.location_id,
  //           label: loc.location,
  //           isDisabled:
  //             new Date().toISOString() >= loc.active_till ? true : false,
  //         }));
  //         getData.splice(0, 0, {
  //           value: null,
  //           label: "All",
  //           isDisabled: false,
  //         });
  //         resolve(getData);
  //         setGetTechLocations(getData);
  //       })
  //       .catch((err) => {
  //         reject("err" + err);
  //       });
  //   });
  // };
  const resetFilterHandle = (e) => {
    setCategory(null);
    // setLocationSearch({});
    setFromDate(moment().subtract(29, "days").startOf("day")._d);
    setToDate(moment().endOf("day")._d);
    setSizeOfPages(10);
  };

  const SearchData = [
    {
      name: "Category type",
      handleChange: filterCategoriesMobile,
      value: category,
      filter: techcategori,
    },
    // {
    //   name: "Select Location",
    //   handleChange: filterLocationMoble,
    //   value: locationSearch,
    //   filter: getTechLocations,
    // },
  ];
  const closeModalAdd = () => {
    setAddModal(false);
    refresh((prev) => !prev);
  };
  const handleRefresh = () => {
    refresh((prev) => !prev);
  };

  return (
    <>
      {toogleMobileSearch === "select" ? (
        <MobileSearchAcoordian
          SearchData={SearchData}
          toggleSearchclose={handleAcoordianCLose}
          filter={filter}
          dateName={"Select Date"}
          fromDate={fromDate}
          toDate={toDate}
          setFromDate={setFromDate}
          setToDate={setToDate}
        />
      ) : (
        <Container fluid>
          {toogleMobileSearch === "search" ? (
            <div className="d-flex  mb-2 justify-content-center align-items-center">
              <div className="">
                {" "}
                <SearchMobleInput
                  searchlogo
                  value={searchInput}
                  handleChange={searchInventory}
                />
              </div>
              <span className="ms-4" onClick={handleAcoordianCLose}>
                X
              </span>
            </div>
          ) : (
            <Row className="d-flex align-items-center">
              <Col xs="2" lg="3" xl="2" className="d-none d-lg-inline mb-3">
                <SearchInput searchlogo handleChange={searchInventory} />
              </Col>
              <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3">
                {/* <ReactSelect
                  placeholder="Filter Location"
                  value={
                    Object.keys(locationSearch).length > 0
                      ? locationSearch
                      : null
                  }
                  searchable={false}
                  options={getLocations}
                  handleChange={filterLocation}
                /> */}
              </Col>
              <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3">
                <ReactSelect
                  placeholder="Filter Category"
                  value={category}
                  options={getCat}
                  handleChange={(e) => filterCategories(e)}
                />
              </Col>

              <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3">
                <DatePicker
                  important={true}
                  fromDate={fromDate}
                  toDate={toDate}
                  setFromDate={setFromDate}
                  setToDate={setToDate}
                />
              </Col>
              {/* For Mobile screen Start */}
              <Col
                onClick={() => handleAcoordianOpen("search")}
                xs="2"
                sm="2"
                lg="3"
                className="d-lg-none d-inline mb-3"
              >
                <SearchInput searchlogo bordered />
              </Col>
              <Col
                className={`d-lg-none d-inline mb-3 ${filterStyle.filter}`}
                xs="2"
                lg="2"
              >
                <Link to="" className={`${filterStyle.filterToggleButtonLink}`}>
                  <Button
                    onClick={() => handleAcoordianOpen("select")}
                    variant="outline-primary"
                    className={`${filterStyle.filterToggleButton} d-flex align-items-center`}
                  >
                    <img className="" src={filterr} alt="filter" />
                    <span className=" ms-2 d-none d-sm-inline">Filter</span>
                  </Button>
                </Link>
              </Col>
              <Col xs="6" lg="3" xl="2" sm="3" className="mb-3">
                <SearchableSelect
                  selectClassName={"bg-white"}
                  placeholder={`Showing ${sizeOfPages}`}
                  defaultValue={`Showing ${sizeOfPages}`}
                  value={`Showing ${sizeOfPages}`}
                  searchable={false}
                  options={[
                    { value: "10", label: "10" },
                    { value: "20", label: "20" },
                    { value: "30", label: "30" },
                  ]}
                  handleChange={(e) => setSizeOfPages(e.value)}
                />
              </Col>
              <Col
                xs="2"
                sm="3"
                lg="2"
                xl="2"
                xxl="2"
                className={`mb-3 p-0 ${filterStyle.addbtn}`}
              >
                <Button
                  className="h-100 w-100"
                  style={{ fontSize: "1rem" }}
                  size="sm"
                  type="submit"
                  onClick={() => setAddModal(true)}
                >
                  <span className="d-none d-sm-inline d-lg-inline">
                    Add Trade-in Product
                  </span>{" "}
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </Col>

              {/* For Mobile screen End */}
            </Row>
          )}
          {/* <> */}
          <div className="pt-0 d-lg-flex justify-content-between">
            <Button
              size="sm"
              type="submit"
              style={{ fontSize: "14px", marginTop: "-8px" }}
              onClick={resetFilterHandle}
            >
              <span className=" d-lg-inline">Reset Filters</span>{" "}
            </Button>
          </div>
          {isLoading ? (
            <div
              style={{
                height: "50vh",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <LoaderPage />
            </div>
          ) : (
            <>
              {products.length === 0 ? (
                <NoResult
                  onClick={() => setAddModal(true)}
                  name="Trade-in Product"
                />
              ) : (
                <>
                  <CustomizeTableview
                    className={"mt-5"}
                    data={products}
                    dateColumn={["created_at"]}
                    sortColumn={[
                      "title",
                      "created_at",
                      "quantity",
                      "cost_price",
                      "sold_price",
                    ]}
                    columnNotShow={["id", "updated_at"]}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                    actions={null}
                  />
                  <Row className="mt-5 align-items-center">
                    <Col className="d-none d-md-block" md="3" lg="3">
                      {totalProducts > 1 ? (
                        <span className="text-secondary fs-6">
                          {totalProducts} results
                        </span>
                      ) : (
                        <span className="text-secondary fs-6">
                          {totalProducts} result
                        </span>
                      )}
                    </Col>
                    <Col xs="12" md="6" lg="6">
                      <PaginationBar
                        currentPage={currentPage}
                        noOfPages={noOfPages}
                        changePage={changePage}
                      />
                    </Col>
                    <Col
                      className="d-md-none d-flex align-items-center mt-3"
                      xs="6"
                      lg="3"
                    >
                      {totalProducts > 1 ? (
                        <span className="text-secondary fs-6">
                          {totalProducts} results
                        </span>
                      ) : (
                        <span className="text-secondary fs-6">
                          {totalProducts} result
                        </span>
                      )}
                    </Col>
                    <Col xs="6" md="3" lg="3" className="text-end ">
                      <Csv
                        filename={`${
                          "Trade-in Products " +
                          shopName +
                          " " +
                          new Date().toLocaleDateString("en-US", {
                            timeZone: "Asia/Karachi",
                          })
                        }`}
                        api={requestAllHistory}
                        format={(data) => {
                          const { id, created_at, ...temp } = data;
                          const newObj = {
                            ...temp,
                            created_at: created_at.split("T")[0],
                            // updated_at:updated_at.split("T")[0],
                          };
                          return newObj;
                        }}
                        error={(err) => {
                          const tempErr = err.response.data.error;
                          if (
                            tempErr.status === 404 &&
                            tempErr.message === "No Record Found"
                          ) {
                            return true;
                          } else {
                            return false;
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </Container>
      )}
      <PopUpModal
        size="xl"
        title="Add Trade-in Product"
        show={modalAdd}
        onHide={closeModalAdd}
      >
        <AddTradeInProduct
          close={closeModalAdd}
          openingBalances={openingBalances}
          refresh={handleRefresh}
          locations={location}
        />
      </PopUpModal>
    </>
  );
};

export default TradeInProducts;
