import axios from "axios";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import SearchableSelect from "../SearchableSelect";

const LoginInput = ({ userInput, setUserInput, setLoading, setErrors, setInputType, setOtpMessage, setOtpError, shopList, setShopList, setUsername, username }) => {
  const [validated, setValidated] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [shop, setShop] = useState(null);
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setUserInput(inputValue);
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue)) {
      setIsValid(true);
      setValidated(false);
    } else if (/^(?:\+92-?)?0?[1-9][0-9]{2}-?[0-9]{7}$/.test(inputValue)) {
      setIsValid(true);
      setValidated(false);
    } else {
      setValidated(true);
      setIsValid(false);
    }
  };
  const onSubmitHandler = async (e) => {
    try {
      e.preventDefault();
      if (userInput.length > 0 && isValid && shopList.length > 0 ? username.length > 0 : true) {
        setLoading(true);
        setErrors("");
        const res = await axios.post(process.env.REACT_APP_API_URL + "/user/verify-user", { loginId: userInput, ...(shopList.length > 0 ? { username } : {}) });
        if (res.data) {
          setInputType(res.data.type);
          setUserInput((prev) => (res.data.phoneNumber ? res.data.phoneNumber : prev));
          setShopList(res.data.shops);
          setOtpMessage(res.data.message);
          setLoading(false);
          setValidated(false);
        }
      }
    } catch (error) {
      if (error.response.data.error.message === "OTP limit exceeded") {
        setOtpError(error.response.data.error.message);
      } else {
        setErrors(error.response.data.error.message);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={onSubmitHandler}>
        <Form.Group className="mb-4" md="4" controlId="validationCustomEmail">
          <Form.Control type="email" name="userInput" placeholder="Enter Email or Phone Number" className="p-2" aria-describedby="inputGroupPrepend" value={userInput} required maxLength={50} onChange={handleInputChange} isInvalid={validated && !(userInput.length > 0 && isValid)} />
          <Form.Control.Feedback type="invalid">* Please enter valid email address or phone number</Form.Control.Feedback>
        </Form.Group>
        {shopList.length > 0 && (
          <Form.Group className="mb-4" md="4" controlId="validationCustomUsername">
            <SearchableSelect
              placeholder="Select Shop"
              options={shopList.map((shop) => {
                return {
                  value: shop.username,
                  label: (
                    <>
                      Shop: <span style={{ fontWeight: "bold" }}>{shop.shop_name}</span>
                      <br />
                      <span style={{ fontSize: "14px" }}>
                        Username: <span style={{ textTransform: "lowercase" }}>{shop.username}</span>
                      </span>
                    </>
                  ),
                  name: shop.username,
                };
              })}
              value={shop}
              handleChange={(e) => {
                setShop({ value: e?.value, label: e.name });
                setUsername(e?.value);
              }}
            />

            <Form.Control hidden type="text" name="shopn" value={shop?.name} onChange={() => {}} />
          </Form.Group>
        )}
        <div className="d-flex justify-content-end mb-4">
          <Link className="link" to="/forget-password" state={{ email: userInput }}>
            Forget Password?
          </Link>
        </div>
        <div className="mb-4">
          <Button className="w-100" disabled={!(userInput.length > 0 && shopList.length > 0 ? username.length > 0 : true) && isValid} type="submit">
            Continue
          </Button>
        </div>
      </Form>
    </>
  );
};

export default LoginInput;
