import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import style from "./activationModal.module.css";
import { toast } from "react-toastify";
import LoaderPage from "../../loaders/loaderPage";
import axios from "axios";
import ActivationForm from "./ActivationForm";

const ListingActivationForm = forwardRef(({ value = null, setLoading, setIsSubmitting, sendChangeSignal }, ref) => {
  const intitalState = {
    listing: {
      listing_title: null,
      brand: null,
      online_price: null,
      online_discount: null,
      online_discount_unit: { value: "fixed", label: "Rs" },
      listed_quantity: null,
      stock_quantity: null,
      sale_price: null,
    },
    validated: false,
    isChecked: false,
  };
  const [inputState, setInputState] = useState(intitalState);

  const onHideHandle = (actionToCall) => {
    setInputState(intitalState);
    if (actionToCall) {
      actionToCall();
    }
  };

  useEffect(() => {
    if (value) {
      setInputState((prev) => {
        return {
          ...prev,
          validated: false,
          isChecked: prev.isChecked,
          listing: {
            ...prev.listing,
            listing_title: value?.listing?.title,
            brand: value?.listing?.brand,
            stock_quantity: value?.listing?.stock_quantity,
            sale_price: value?.listing?.sale_price,
            listed_quantity: value?.listing?.stock_quantity,
            online_price: value?.listing?.sale_price,
            online_discount: "0",
          },
        };
      });
    } else {
      setInputState(intitalState);
    }
  }, [value?.listing]);
  const checkValue = (value, type) => {
    if (value) {
      if (value === "") {
        return false;
      } else {
        if (type === "text") {
          return value.length > 0;
        } else {
          return +value !== 0;
        }
      }
    } else {
      return false;
    }
  };

  useImperativeHandle(ref, () => ({
    handleSave: async (actionToCall) => {
      try {
        if (value) {
          if (!inputState.validated) {
            setInputState((prev) => ({ ...prev, validated: true }));
          }

          if (checkValue(inputState?.listing?.online_price, "number") && inputState?.listing?.online_price >= inputState?.listing?.sale_price && checkValue(inputState?.listing?.listed_quantity, "number") && checkValue(inputState?.listing?.online_discount_unit?.value, "text")) {
            setIsSubmitting(true);
            const listing = {
              listing_title: inputState?.listing?.listing_title,
              online_price: !inputState.isChecked && inputState?.listing?.online_price ? +inputState?.listing?.online_price : 0,
              online_discount: inputState?.listing?.online_discount && !inputState.isChecked ? +inputState?.listing?.online_discount : 0,
              listed_quantity: +inputState?.listing?.listed_quantity,
              online_discount_unit: inputState?.listing?.online_discount_unit?.value,
            };
            const validateCallReponse = await axios.post(process.env.REACT_APP_API_URL + `/listings/activate/${value?.listing?.id}`, listing, { withCredentials: true });
            if (validateCallReponse) {
              setIsSubmitting(false);
              sendChangeSignal();
              if (actionToCall) {
                onHideHandle(actionToCall);
              }
            }
          }
        } else {
          toast.error(`Error on loading Listing!`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1500,
            pauseOnHover: false,
            className: "toastMsg",
          });
          if (actionToCall) {
            actionToCall();
          }
        }
      } catch (error) {
        toast.error(`Error While validating this listing!`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1500,
          pauseOnHover: false,
          className: "toastMsg",
        });
        setIsSubmitting(false);
      }
    },
    reset: () => {
      if (value) {
        setInputState((prev) => {
          return {
            ...prev,
            validated: false,
            isChecked: prev.isChecked,
            listing: {
              ...prev.listing,
              listing_title: value?.listing?.title,
              brand: value?.listing?.brand,
              stock_quantity: value?.listing?.stock_quantity,
              sale_price: value?.listing?.sale_price,
              listed_quantity: value?.listing?.stock_quantity,
              online_price: value?.listing?.sale_price,
              online_discount: "0",
            },
          };
        });
      } else {
        setInputState(intitalState);
      }
    },
  }));

  let DisplayForm = value // add loading condition here
    ? ActivationForm
    : LoaderPage;
  return (
    <div className={`${style.tableContainer} overflow-auto`}>
      <DisplayForm state={inputState} setState={setInputState} />
    </div>
  );
});

export default ListingActivationForm;
