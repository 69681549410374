import { useState } from "react";
import { useLocation } from "react-router-dom";
import MenuItem from "./MenuItem";

const Menu = ({ setIsSandwichOpen, isSandwichOpen, navData, userType }) => {
  const location = useLocation();
  const [active, setActive] = useState(location.pathname);
  const [activeParent, setActiveParent] = useState(""); // we need to set initaial state .. we do it later after observing behavior
  const [activeCaret, setActiveCaret] = useState(""); // we need to set initaial state .. we do it later after observing behavior
  return (
    <>
      {navData.map((item, index) => {
        return (
          <MenuItem
            subnavigation={item.subnavigation}
            setIsSandwichOpen={setIsSandwichOpen}
            item={item}
            key={index}
            isSandwichOpen={isSandwichOpen}
            active={active}
            setActive={setActive}
            activeParent={activeParent}
            setActiveParent={setActiveParent}
            activeCaret={activeCaret}
            setActiveCaret={setActiveCaret}
            Included={item.Included}
            navigation={item?.hasOwnProperty("navigation") ? item?.navigation : item?.hasOwnProperty("onlyForPrimary") ? item?.onlyForPrimary && userType === "primary" : true}
            alwaysGenerateRoute={item.alwaysGenerateRoute}
            isSubNavExists={item.isSubNavExists}
            isComing={item.isComing}
            userType={userType}
          />
        );
      })}
    </>
  );
};

export default Menu;
