import React, { useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import customloader from "../../assets/images/RollingLoader.gif";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import NoResult from "../../Components/NoResult";
import axios from "axios";
import PaginationBar from "../../Components/PaginationBar";
import Csv from "../../Components/csv/Csv";
import { requestDateTime, TIMEOUT } from "../../Features/dateTime";
import AddPurchaseOrder from "./AddPurchaseOrder";
import PopUpModal from "../../Components/Modal";
import PurchaseForm from "./PurchaseForm";
import { faCircleCheck, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styleModal from "./../../styles/Modal.module.css";
import VendorPurchaseInvoice from "./Invoices/VendorPurchaseInvoice";
import PaymentForm from "./PaymentForm";
import LoaderPage from "../../Components/loaders/loaderPage";

const PurchaseOrderTable = ({ search, fromDate, toDate, location, status, sizeOfPages, toggle, refresh, openingBalances, setOpeningBalances }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [edit, setEdit] = useState(false);
  const [vedndorId, setVendorId] = useState();
  const [purchaseId, setPurchaseId] = useState();
  const { user } = useSelector((state) => state.auth);
  const shopId = user.shop_id;
  const shopName = user.shop_name;
  const logo = user.logo_path;
  const user_id = user.user_id;
  const [emailSuccess, setEmailSuccess] = useState();
  const [emailError, setEmailError] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [emailmodalShow, setEmailModalShow] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [purchaseModalShow, setPurchaseModalShow] = useState(false);
  const [isEmailClicked, setIsEmailClicked] = useState(false);
  const [noOfItems, setNoOfItems] = useState();
  //data toggle
  // const [toggle, refresh] = useState(true);
  //pagination
  const [noOfPages, setNoOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  //navigate states
  const [navigateState, setNavigateState] = useState({});
  useEffect(() => {
    if (noOfPages !== 0) {
      setNavigateState({
        shop_id: shopId,
        user_id: user_id,
        fromDate: fromDate,
        toDate: toDate,
        location: location.value,
        status: status,
        currentPage: currentPage,
        nextPage: currentPage + 1,
      });
    }
  }, [shopId, user_id, currentPage, fromDate, toDate, status, noOfPages]);
  const changePage = (page) => {
    setCurrentPage(page);
  };

  //searchParams

  //filters
  const currentSearch = useRef();
  currentSearch.current = search;

  //orderBy
  const [orderBy, setOrderBy] = useState({ order_date: "DESC" });

  //Vendors
  const [vendors, setVendors] = useState([]);

  const editpurchase = (id) => {
    setVendorId(id);
    setModalShow(true);
    setEdit(true);
    setPurchaseId(id);
  };

  function requestAllHistory() {
    const filters = {
      search: search,
      dates: {
        from: fromDate.toISOString().replace("T", " ").replace("Z", "").split(".")[0],
        to: toDate.toISOString().replace("T", " ").replace("Z", "").split(".")[0],
      },
      orderBy: orderBy,
      location: location?.value,
      status: status?.value,
    };
    return axios.get(
      process.env.REACT_APP_API_URL + "/purchase-order/purchase-details/" + shopId + `?size=${sizeOfPages}&page=${currentPage}`,

      {
        withCredentials: true,
        headers: { filters: JSON.stringify(filters) },
      }
    );
  }
  async function getPurchaseDetails() {
    setIsLoading(true);
    const filters = {
      search: search,
      dates: {
        from: requestDateTime(fromDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
        to: requestDateTime(toDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
      },
      orderBy: orderBy,
      location: location?.value,
      status: status?.value,
    };

    axios
      .get(
        process.env.REACT_APP_API_URL + "/purchase-order/purchase-details/" + shopId + `?size=${sizeOfPages}&page=${currentPage}`,

        {
          withCredentials: true,
          headers: { filters: JSON.stringify(filters) },
        }
      )
      .then((data) => {
        const res = data.data.success.data;
        const updatedItems = res?.purchase_orders?.map((item) => {
          return {
            vendor_name: item.vendor_name,
            order: item.purchase_number,
            order_date: item.purchase_date,
            location: item.location,
            invoice_number: item.invoice_number === "nil" ? "" : item.invoice_number,
            due_amount: item.due_amount,
            vendor_email: item.vendor_email,
            vendor_id: item.vendor_id,
            id: item.vendor_purchase_id,
            po_type: item.po_type,
          };
        });
        setVendors(updatedItems);
        setNoOfItems(res?.counts?.no_of_items);
        if (res?.purchase_orders?.length === 0) {
          setCurrentPage(1);
        }
        setIsLoading(false);
        setNoOfPages(res?.counts?.no_of_pages);
      })
      .catch((err) => {
        setIsLoading(false);
        const tempErr = err.response.data.error;
        setVendors([]);

        if (tempErr.status === 404 && tempErr.message === "No Record Found") {
        }
      });
  }

  useEffect(() => {
    setIsLoading(true);
    getPurchaseDetails();
  }, [shopId, toggle, search, sizeOfPages, status, sizeOfPages, orderBy, currentPage, searchParams, currentPage, location]);

  useEffect(() => {
    if (currentPage === 1) {
      refresh((prev) => !prev);
    } else {
      changePage(1);
    }
  }, [status, fromDate, toDate]);

  //loading
  const [isLoading, setIsLoading] = useState(true);

  const [vendorData, setVendorData] = useState({});
  const [vendorName, setVendorName] = useState();
  const caret = async (id) => {
    const data = await axios.post(process.env.REACT_APP_API_URL + "/vendors/get/" + id, { shopId: shopId }, { withCredentials: true });
    const { created_at, is_deleted, location, shop_id, terms, vendor_id, vendor_name, ...vendorObj } = data.data;
    setVendorName(vendor_name);
    setVendorData(vendorObj);
  };
  const handleAddPaymentButton = (purchase_id, vendorid) => {
    setVendorId(vendorid);
    setPurchaseId(purchase_id);
    setPurchaseModalShow(true);
  };
  const handleEmail = async (id) => {
    try {
      setIsEmailClicked(true);
      const res = await axios.post(process.env.REACT_APP_API_URL + `/purchase-order/email/${id}`, { logo: logo, shopId: shopId }, { withCredentials: true });
      if (res.data.status === 401) {
        setEmailError(res.data.message);
        setEmailModalShow(true);
        setTimeout(() => setEmailModalShow(false), 1500);
        setIsEmailClicked(false);
      } else if (res.data.status === 200) {
        setEmailSuccess(res.data.success.message);
        setEmailModalShow(true);
        setTimeout(() => setEmailModalShow(false), 2500);
        setIsEmailClicked(false);
      }
    } catch (err) {
      setIsEmailClicked(true);
      setEmailError(err.response.data.error.message);
      setEmailModalShow(true);
      setTimeout(() => setEmailModalShow(false), 1500);
      setIsEmailClicked(false);
      throw new Error(err);
    }
  };
  const payment = (data) => {
    navigate("/purchase-order/" + data["id"] + "/payment", {
      state: navigateState,
    });
  };
  const generateInvoice = (id) => {
    setPurchaseId(id);
    setShowInvoiceModal(true);
  };
  const closeInviceModal = () => {
    setShowInvoiceModal(false);
    setPurchaseId(-1);
  };

  const viewPO = (id) => {
    setPurchaseId(id);
    setEdit(false);
    setModalShow(true);
  };

  // Opening Balances For checks
  async function getOpeningBalances(id) {
    try {
      const openingBalanceRespponse = await axios.get(process.env.REACT_APP_API_URL + "/location/opening-balances/" + id, { withCredentials: true });
      if (openingBalanceRespponse.data.code === 200) {
        setOpeningBalances(openingBalanceRespponse.data.success.data);
      }
    } catch (err) {}
  }

  useEffect(() => {
    if (user_id) {
      getOpeningBalances(user_id);
    }
  }, [toggle, user_id]);
  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <LoaderPage />
        </div>
      ) : vendors.length === 0 ? (
        <NoResult name="Purchase Order" />
      ) : (
        <>
          <CustomizeTableview
            className={"mt-5"}
            data={vendors}
            payment={payment}
            view={{
              action: viewPO,
              isShow: (row) => {
                return row.po_type === "N";
              },
            }}
            edit={{
              action: editpurchase,
              isShow: (row) => {
                return row.po_type === "N";
              },
            }}
            // remove={remove}
            sortColumn={["due_amount", "vendor_email", "location", "invoice_number", "order_date", "vendor_name", "order"]}
            print={{
              action: generateInvoice,
              isShow: (row) => {
                return row.po_type === "N";
              },
            }}
            // email={isEmailClicked === false ? handleEmail : true}
            email={
              isEmailClicked === false
                ? {
                    action: handleEmail,
                    isShow: (row) => {
                      return row.po_type === "N";
                    },
                  }
                : true
            }
            actionButton={{
              label: "Add Payment",
              onClick: (id, data) => {
                handleAddPaymentButton(id, data["vendor_id"]);
              },
              variant: "outline-primary",
              style: { padding: "8px", fontSize: "12px" },
              column: "due_amount",
              isShow: (value) => {
                if (value > 0) {
                  return true;
                } else {
                  return false;
                }
              },
            }}
            columnNotShow={["status", "id", "purchaseNumber", "dealer_location", "vendor_id", "vendor_email", "po_type"]}
            dateColumn={["order_date"]}
            status={{
              column: "due_amount",
              style: (value) => {
                if (value === 0) {
                  return "text-success";
                } else if (value > 0) {
                  return "text-danger";
                } else {
                  return "text-danger";
                }
              },
              format: (value) => {
                if (value === 0) {
                  return "Paid";
                } else if (value > 0) {
                  return "Pending";
                }
              },
            }}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          />

          <Row className="mt-5 align-items-baseline">
            <Col className="d-none d-sm-inline   d-md-block" md="3" lg="3">
              <span className="text-secondary fs-6">{noOfItems} result(s)</span>
            </Col>
            <Col xs="12" md="6" lg="6">
              <PaginationBar currentPage={currentPage} noOfPages={noOfPages} changePage={changePage} />
            </Col>
            <Col className="d-md-none d-sm-none d-flex align-items-center mt-3" xs="6" lg="3">
              <span className="text-secondary fs-6">{noOfItems} result(s)</span>
            </Col>
            <Col xs="6" md="3" lg="3" sm="4" className="text-end">
              <Csv
                filename={`${
                  "Vendors " +
                  shopName +
                  " " +
                  new Date().toLocaleDateString("en-US", {
                    timeZone: "Asia/Karachi",
                  })
                }.csv`}
                api={requestAllHistory}
                format={(data) => {
                  return {
                    order: data.purchase_number,
                    vendor_name: data.vendor_name,
                    order_date: data.purchase_date.split("T")[0],
                    location: data.location,
                    invoice_number: data.invoice_number,
                    due_amount: data.due_amount,
                    status: data.due_amount > 0 ? "Pending" : "Paid",
                  };
                }}
                responseObject={(data) => data.data.success.data.purchase_orders}
                error={(err) => {
                  const tempErr = err.response.data.error;
                  if (tempErr.status === 404 && tempErr.message === "No Record Found") {
                    return true;
                  } else {
                    return false;
                  }
                }}
              />
            </Col>
          </Row>
        </>
      )}
      <PopUpModal
        title={edit ? "Edit Purchase Order" : "View Purchase Order"}
        size={"lg"}
        marginright={true}
        mdSize={true}
        show={modalShow}
        onHide={() => {
          refresh((prev) => !prev);
          setModalShow(false);
        }}
      >
        <AddPurchaseOrder
          purchaseId={purchaseId}
          edit={edit}
          close={() => {
            setModalShow(false);
          }}
          refresh={refresh}
        />
      </PopUpModal>
      <PopUpModal title={"Add Payment"} size={"md"} marginright={true} mdSize={true} show={purchaseModalShow} onHide={() => setPurchaseModalShow(false)}>
        <PaymentForm
          close={() => {
            setPurchaseModalShow(false);
          }}
          id={vedndorId}
          openingBalances={openingBalances}
          purchaseId={purchaseId}
          refresh={refresh}
        />
      </PopUpModal>
      <PopUpModal
        title={emailSuccess ? "Email Sent" : "Email Not sent."}
        icon={<FontAwesomeIcon icon={emailSuccess ? faCircleCheck : faXmarkCircle} />}
        text={emailSuccess ? emailSuccess : emailError}
        type={emailSuccess ? styleModal.successBg : styleModal.errorBg}
        iconModal={emailSuccess ? styleModal.successIcon : styleModal.errorIcon}
        show={emailmodalShow}
        onHide={() => setEmailModalShow(false)}
      />
      <PopUpModal title="Purchase Order" size="xl" show={showInvoiceModal} onHide={closeInviceModal}>
        <VendorPurchaseInvoice close={closeInviceModal} id={purchaseId} />
      </PopUpModal>
    </>
  );
};

export default PurchaseOrderTable;
