import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import DatePicker from "../DatePicker/DatePicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { Col, Row, Button, Table } from "react-bootstrap";
import SearchableSelect from "../SearchableSelect";
import style from "../../styles/Reports.module.css";
import styles from "../../styles/Setting.module.css";
import MobileSubReportsTabs from "./MobileSubReportsTab";
import MobilrReportsFilter from "./MobileReportsFilter";
import LoaderPage from "../loaders/loaderPage";
import moment from "moment";
import tableStyles from "../../styles/JournalTable.module.css";
import PaginationBar from "../PaginationBar";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-regular-svg-icons";

const Journal = ({
  fromDate = moment().subtract(29, "days").startOf("day")._d,
  toDate = moment().endOf("day")._d,
  setToDate,
  setFromDate,
  close,
  show,
  MainactiveTab,
}) => {
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [location, setLocation] = useState({});
  const [locationFields, setLocationFields] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [activeTAb, setActiveTAb] = useState("Combined-Report");
  const [tableData, settableData] = useState([]);
  const [showMobileTabs, setShowMobileTabs] = useState(false);
  const [noOfPages, setNoOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);

  const BASE_URI = `${process.env.REACT_APP_API_URL}/reports/journal`;

  // Other than Combined-Report, all other reports have a different URI
  const reportURIs = {
    "Combined-Report": `${BASE_URI}`,
    "Purchases-Report": `purchases`,
    "Sales-Report": `sales`,
    "Trades-Report": `trades`,
    "Expenses-Report": `expenses`,
    "Refunds-Report": `refunds`,
  };

  const constructURI = (reportType, shopId, locationId) => {
    if (reportType === "Combined-Report") {
      return `${BASE_URI}/${shopId}/${locationId}`;
    } else {
      return `${BASE_URI}/${shopId}/${locationId}/${reportURIs[reportType]}`;
    }
  };

  const { user } = useSelector((state) => state.auth);
  const userId = user.user_id;
  const user_type = user.user_type;
  const shop_id = user.shop_id;

  const changePage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [userId, shop_id, locationFields, fromDate, toDate]);

  useEffect(() => {
    setIsFetchingData(true);
    axios
      .post(
        process.env.REACT_APP_API_URL +
          `/location/get-location-by-user/${userId}`,
        { shop_id: shop_id, user_type: user_type },
        { withCredentials: true }
      )
      .then((res) => {
        const locationsArr = res.data.map((loc) => ({
          value: loc.location_id,
          label: loc.location,
          isDisabled:
            new Date().toISOString() >= loc.active_till ? true : false,
        }));

        setLocation(locationsArr);
        setLocationFields({
          value: locationsArr[0]?.value,
          label: locationsArr[0]?.label,
          isDisabled: locationsArr[0]?.isDisabled,
        });
        setIsFetchingData(false);
      })
      .catch((err) => {
        setIsFetchingData(false);
        throw new Error(err);
      });
  }, [userId, shop_id, user_type]);

  const fetchReportData = (reportType, locationId) => {
    const from = moment(new Date(fromDate)).utcOffset(300).format("YYYY-MM-DD");
    const to = moment(new Date(toDate)).utcOffset(300).format("YYYY-MM-DD");
    const uri = constructURI(reportType, shop_id, locationId);
    setIsFetchingData(true);
    axios
      .get(
        `${uri}?date[from]=${from}&date[to]=${to}&page=${currentPage}&size=20`,
        { withCredentials: true }
      )
      .then((res) => {
        if (!res.data?.error && res.data?.data?.length > 0) {
          const result = res.data.data.map((row) => {
            row["date"] = moment.utc(row["date"]).format("DD-MM-YYYY");
            row["ts"] = moment.utc(row["ts"]).format("lll");
            return row;
          });
          settableData(result);
          const { pagination } = res.data;
          setNoOfPages(pagination.totalPages);
          setCount(pagination.totalRecords);
          setIsLoading(false);
        } else {
          settableData([]);
          setNoOfPages(1);
          setCount(0);
          setIsLoading(false);
        }
        setIsFetchingData(false);
      })
      .catch((err) => {
        throw new Error(err.response?.data);
      });
  };

  useEffect(() => {
    if (Object.keys(locationFields).length > 0) {
      fetchReportData(activeTAb, locationFields.value);
    }
  }, [
    userId,
    shop_id,
    locationFields,
    fromDate,
    toDate,
    currentPage,
    activeTAb,
  ]);

  const handleTabChange = (tab) => {
    setIsLoading(true);
    setActiveTAb(tab);
  };
  const handleClose = () => {
    setShowMobileTabs(false);
  };
  const handleShow = () => {
    setShowMobileTabs(true);
  };
  const handleLocation = (e) => {
    if (e.value) {
      setLocationFields(e);
    } else {
      setLocationFields({});
    }
  };

  /**
   *
   * Format the date as "Jul 11, 2023"
   *
   */
  const convertDate = (date) => {
    const inputDate = new Date(date);

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const formattedDate =
      months[inputDate.getMonth()] +
      " " +
      inputDate.getDate() +
      ", " +
      inputDate.getFullYear();
    return formattedDate;
  };

  return (
    <>
      <Row className="align-items-center justify-content-start px-2 py-2 bg-white mb-3 mt-1 ">
        <Col
          md="4"
          sm="4"
          lg="3"
          xl="2"
          className="position-relative mb-md-0 mb-2 d-lg-block d-md-block d-sm-block d-none"
        >
          <DatePicker
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={setFromDate}
            setToDate={setToDate}
          />
        </Col>
        <Col
          className="mb-2 mb-md-0 d-lg-block d-md-block d-sm-block d-none"
          md="4"
          sm="4"
          lg="3"
          xl="2"
        >
          <SearchableSelect
            placeholder="Select Location"
            searchable={false}
            options={location}
            handleChange={handleLocation}
            value={
              Object.keys(locationFields).length > 0 ? locationFields : null
            }
            style={{ width: "100px" }}
            disabled={isFetchingData}
          />
        </Col>
        <Col className="mb-2 mb-md-0 d-lg-block d-md-block d-sm-block d-none">
          <p className="fw-bold fs-16 ms-2 float-end p-0 my-0">
            {`${convertDate(fromDate)}` === `${convertDate(toDate)}`
              ? convertDate(toDate)
              : `${convertDate(fromDate)} - ${convertDate(toDate)}`}{" "}
          </p>{" "}
        </Col>
      </Row>
      <Row className="bg-white p-1 mb-3 overflow-auto text-nowrap ob ">
        <Col xl="11" lg="11" md="12">
          <Button
            onClick={() => {
              handleTabChange("Combined-Report");
            }}
            className={`${styles.btn} ${
              activeTAb === "Combined-Report" ? styles.activebtn : ""
            }  my-2 mx-1 rounded-5 `}
            variant="outline-primary"
          >
            Combined
          </Button>
          <Button
            onClick={() => {
              handleTabChange("Purchases-Report");
            }}
            className={`${styles.btns} ${
              activeTAb === "Purchases-Report" ? styles.activebtn : ""
            }  my-2 mx-1 rounded-5`}
            variant="outline-primary"
          >
            Purchases
          </Button>
          <Button
            onClick={() => {
              handleTabChange("Sales-Report");
            }}
            className={`${styles.btns} ${
              activeTAb === "Sales-Report" ? styles.activebtn : ""
            }  my-2 mx-1 rounded-5`}
            variant="outline-primary"
          >
            Sales
          </Button>
          <Button
            onClick={() => {
              handleTabChange("Trades-Report");
            }}
            className={`${styles.btns} ${
              activeTAb === "Trades-Report" ? styles.activebtn : ""
            } my-2 mx-1 rounded-5`}
            variant="outline-primary"
          >
            Trades
          </Button>
          <Button
            onClick={() => {
              handleTabChange("Expenses-Report");
            }}
            className={`${styles.btns} ${
              activeTAb === "Expenses-Report" ? styles.activebtn : ""
            } my-2 mx-1 rounded-5`}
            variant="outline-primary"
          >
            Expenses
          </Button>
          <Button
            onClick={() => {
              handleTabChange("Refunds-Report");
            }}
            className={`${styles.btns} ${
              activeTAb === "Refunds-Report" ? styles.activebtn : ""
            } my-2 mx-1 rounded-5`}
            variant="outline-primary"
          >
            Refunds
          </Button>
        </Col>
      </Row>
      {isLoading ? (
        <div style={{ height: "50vh" }} className="">
          <LoaderPage />
        </div>
      ) : tableData.length > 0 ? (
        <>
          <div className="bg-white p-3 w-100 my-1">
            <p className="fs-17 fw-bold text-center m-0 p-0">
              {activeTAb.replace("-", " ")}
            </p>
            <p className={`${tableStyles.report_subtitle} text-center m-0 p-0`}>
              {activeTAb === "Combined-Report"
                ? "The report provides a complete overview by outlining all transactions related to Sales, Stock Purchases, Trade-Ins, Expenses, and Refunds."
                : activeTAb === "Purchases-Report"
                ? "The report provides a complete overview of all transactions related to Stock Purchases."
                : activeTAb === "Sales-Report"
                ? "The report provides a complete overview of all transactions related to Sales."
                : activeTAb === "Trades-Report"
                ? "The report provides a complete overview of all transactions related to Trade-Ins."
                : activeTAb === "Expenses-Report"
                ? "The report provides a complete overview of all transactions related to Expenses."
                : "The report provides a complete overview of all transactions related to Refunds."}
            </p>
          </div>

          <div className={`${tableStyles.brdrd} mb-1`}>
            <Table
              responsive
              striped
              className={`rounded-3 border-1 bg-white border-secondary overflow-auto ${tableStyles.table_main}`}
            >
              <thead
                className="text-secondary mt-1"
                style={{
                  fontSize: "13px",
                  fontWeight: "400",
                  lineHeight: "18px",
                }}
              >
                <tr className="border-bottom">
                  <th>Date</th>
                  <th>Account / Description</th>
                  <th>Debit</th>
                  <th>Credit</th>
                  <th>Reference</th>
                  {
                    activeTAb === "Combined-Report" && (
                      <th>Source</th>
                    )
                  }
                  <th>Timestamp</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr className="border-bottom" key={index}>
                    <td className="fw-bold">{row.date}</td>
                    <td>{row.description}</td>
                    <td>{row.debit}</td>
                    <td>{row.credit}</td>
                    <td>
                      {row.reference_details.startsWith("SA") && row.source === 'Sales' ? (
                        <a
                          href={`/invoice-payments-sales/${row.ref_id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {row.reference_details}
                        </a>
                      ) : row.reference_details.startsWith("INV") && row.source === 'Sales' ? (
                        <a
                          href={`/invoice-view/${row.ref_id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {row.reference_details}
                        </a>
                      ) : (
                        row.reference_details
                      )}
                    </td>
                    {
                      activeTAb === "Combined-Report" && (
                        <td>{row.source}</td>
                      )
                    }
                    <td>{row.ts}</td>
                  </tr>
                ))}
              </tbody>
              <thead
                className="text-secondary mt-1"
                style={{
                  fontSize: "13px",
                  fontWeight: "400",
                  lineHeight: "18px",
                }}
              >
                <tr className="border-bottom">
                  <th>Date</th>
                  <th>Account / Description</th>
                  <th>Debit</th>
                  <th>Credit</th>
                  <th>Reference</th>
                  {
                    activeTAb === "Combined-Report" && (
                      <th>Source</th>
                    )
                  }
                  <th>Timestamp</th>
                </tr>
              </thead>
            </Table>
          </div>

          <Row className="align-items-center mb-1">
            <Col className="d-none d-sm-block" sm="3" md="3" lg="3">
              <span className="text-secondary fs-13 px-2">{`${
                (currentPage - 1) * 20 + 1
              } - ${Math.min(
                currentPage * 20,
                count
              )} of ${count} entries`}</span>
            </Col>
            <Col xs="12" sm="6" md="6" lg="6">
              <PaginationBar
                currentPage={currentPage}
                noOfPages={noOfPages}
                changePage={changePage}
              />
            </Col>
            <Col
              className="d-sm-none d-flex align-items-center justify-content-center py-1"
              xs="12"
              lg="3"
            >
              <span className="text-secondary fs-13">{`${
                (currentPage - 1) * 20 + 1
              } - ${Math.min(
                currentPage * 20,
                count
              )} of ${count} entries`}</span>
            </Col>
          </Row>

          <Row className={`align-items-center mb-2`}>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header><span className="legend-header-font fw-bold"><FontAwesomeIcon icon={faLightbulb} className="me-1"/> Helping Material</span></Accordion.Header>
                <Accordion.Body className="legend-body-font">
                <p className="p-0 m-0">
                <span className="fw-bold">Cash A/C</span> - Cash A/C is the
                account where all cash transactions are recorded.
              </p>
              <p className="p-0 m-0">
                <span className="fw-bold">Bank A/C</span> - Bank A/C is the
                account where all bank transactions such as Card Payments,
                Transfers or Cheque Payments are recorded.
              </p>
              <p className="p-0 m-0">
                <span className="fw-bold">Sales A/C</span> - Sales A/C is the
                account where all sales transactions are recorded.
              </p>
              <p className="p-0 m-0">
                <span className="fw-bold">Purchase A/C</span> - Purchase A/C is
                the account where all stock purchases made are recorded.
              </p>
              <p className="p-0 m-0">
                <span className="fw-bold">Expense A/C</span> - Expense A/C is
                the account where all expenses made are recorded such as utility
                bills, rent, salaries etc.
              </p>
              <p className="p-0 m-0">
                <span className="fw-bold">Payable A/C</span> - Payable A/C is
                the account where all the amounts payable are recorded i.e. the
                amount that the business owes to its suppliers.
              </p>
              <p className="p-0 m-0">
                <span className="fw-bold">Receivable A/C</span> - Receivable A/C
                is the account where all the amounts receivable are recorded
                i.e. the amount that the business is owed by its customers.
              </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Row>
        </>
      ) : (
        <Row
          className={`${style.noDataAccordian} fw-bold fs-16 d-flex justify-content-center py-3`}
          style={{ marginBottom: "2rem" }}
        >
          There are no transactions against the selected Date Range / Location
        </Row>
      )}
      <MobileSubReportsTabs
        tabs={[
          "Combined",
          "Purchases",
          "Sales",
          "Trades",
          "Expenses",
          "Refunds",
        ]}
        activeTAb={activeTAb}
        handleTabChange={handleTabChange}
        show={showMobileTabs}
        handleClose={handleClose}
      />
      <MobilrReportsFilter
        activeTab={MainactiveTab}
        handleLocation={handleLocation}
        locationFields={locationFields}
        location={location}
        show={show}
        handleClose={close}
        toDate={toDate}
        fromDate={fromDate}
        setToDate={setToDate}
        setFromDate={setFromDate}
      />
    </>
  );
};

export default Journal;
