import PropTypes from "prop-types";
import { Col, Form, Row } from "react-bootstrap";
import { useRef } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import ReactSelect from "../ReactSelect";

const ItemForm = ({ mode = "add", validated = false, state, setState }) => {
  const { user } = useSelector((state) => state.auth);
  const location = useSelector((state) => state.location.location);
  const user_type = user.user_type;
  const shop_id = user.shop_id;
  const userId = user.user_id;

  const textareaRef = useRef(null);
  const hiddenDivRef = useRef(null);

  const handleTitleChange = (event) => {
    // Remove newlines from the input value
    const newValue = event.target.value.replace(/(\r\n|\n|\r)/gm, "");
    setState((oldValue) => ({ ...oldValue, title: newValue }));
    adjustTextareaHeight();
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    const hiddenDiv = hiddenDivRef.current;
    hiddenDiv.innerText = textarea.value || " "; // Add a space if the input is empty to prevent height collapse
    const lineHeight = parseInt(
      window.getComputedStyle(textarea, null).getPropertyValue("line-height")
    );
    textarea.style.height = "38px"; // Set the default height to 38px
    textarea.style.height = `${Math.max(
      38,
      Math.min(textarea.scrollHeight, 60)
    )}px`; // Adjust height based on content, but not less than 38px
  };

  // On page load, adjust the textarea height once after the component has mounted
  useEffect(() => {
    adjustTextareaHeight();
  }, []);

  return (
    <>
      <Form noValidate validated={validated}>
        <Row>
          <Col className="py-2" md={12}>
            <div
              ref={hiddenDivRef}
              style={{
                position: "absolute",
                top: "-9999px",
                left: "-9999px",
                visibility: "hidden",
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                overflowWrap: "break-word",
                width: textareaRef.current
                  ? textareaRef.current.offsetWidth + "px"
                  : "auto",
              }}
            />

            <Form.Group>
              <Form.Label>
                Title<span style={{ color: "red" }}>*</span>
              </Form.Label>

              <Form.Control
                placeholder="e.g. dell"
                as="textarea"
                ref={textareaRef}
                value={state?.title}
                onChange={handleTitleChange}
                style={{
                  height: "auto",
                  overflow: "hidden",
                  resize: "none",
                  minHeight: "38px",
                  // Set a minimum height here to prevent it from going below the default size
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
                name="model"
                required
              />
              <Form.Control.Feedback type="invalid">
                * Please Enter Title
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                name="location"
                label={"Location"}
                important
                placeholder="Select Location"
                searchable={false}
                value={mode === "edit" ? state.location : location}
                disabled={true}
              />
              <Form.Control
                value={state?.location ? state?.location : ""}
                hidden
                // onChange={() => {}}
                name="Location"
                required
              />
              <Form.Control.Feedback type="invalid">
                * Please Select Location
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col className="py-3" sm="6" md={6}>
            <Form.Group className="mb-3" controlId="Condition">
              <ReactSelect
                name="condition"
                placeholder="Select Condition"
                label="Condition"
                important
                searchable={false}
                options={() => {
                  return new Promise((resolve, reject) => {
                    axios
                      .get(process.env.REACT_APP_API_URL + `/condition/get`, {
                        withCredentials: true,
                      })
                      .then((res) => {
                        const condition = res.data.map((c) => ({
                          value: c.id,
                          label: c.label,
                        }));
                        resolve(condition);
                      })
                      .catch((error) => {
                        reject(new Error(error));
                      });
                  });
                }}
                handleChange={(e) =>
                  setState((oldValue) => ({ ...oldValue, condition: e }))
                }
                value={state?.condition}
              />
              <Form.Control
                value={state?.condition ? state?.condition : ""}
                hidden
                onChange={() => {}}
                name="condition"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please Select Condition{" "}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          {mode === "edit" ? (
            ""
          ) : (
            <>
              <Col className="py-2" sm="6" md={6}>
                <Form.Group className="mb-3" controlId="quatity">
                  <Form.Label className="mb-0">Quantity</Form.Label>
                  <Form.Control
                    min={0}
                    type="number"
                    placeholder="e.g. 12 Items"
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-", "."].includes(e.key) &&
                      e.preventDefault()
                    }
                    value={state?.quantity ? state?.quantity : ""}
                    onChange={(e) => {
                      if (+e.target.value < 1) {
                        setState((oldValue) => ({
                          ...oldValue,
                          quantity: e.target.value,
                          costPrice: 0,
                        }));
                      } else {
                        setState((oldValue) => ({
                          ...oldValue,
                          quantity: e.target.value,
                        }));
                      }
                    }}
                    name="quantity"
                  />
                </Form.Group>
              </Col>
              <Col className="py-2" sm="6" md={6}>
                <Form.Group className="mb-3" controlId="price">
                  <Form.Label className="mb-0">
                    Cost price<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    min={0}
                    type="number"
                    placeholder="e.g. 500"
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-", "."].includes(e.key) &&
                      e.preventDefault()
                    }
                    value={state?.costPrice ? state?.costPrice : ""}
                    isInvalid={
                      Number(state?.quantity) !== 0 &&
                      parseInt(state?.costPrice) === 0
                    }
                    onChange={(e) =>
                      e.target.value.length < 10
                        ? setState((oldValue) => ({
                            ...oldValue,
                            costPrice: e.target.value,
                          }))
                        : setState((oldValue) => ({
                            ...oldValue,
                            costPrice: 0,
                          }))
                    }
                    disabled={
                      state?.quantity ? Number(state?.quantity) === 0 : true
                    }
                    name="costPrice"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    * Please Enter Cost price
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </>
          )}
          <Col className="py-2" sm="6" md={6}>
            <Form.Group className="mb-3" controlId="price">
              <Form.Label className="mb-0">
                Sale price<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                min={1}
                type="number"
                placeholder="e.g. 500"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                value={state?.salePrice ? state?.salePrice : ""}
                isInvalid={
                  !(parseInt(state?.salePrice) > 0) && state?.salePrice?.length
                }
                onChange={(e) =>
                  e.target.value.length < 10
                    ? setState((oldValue) => ({
                        ...oldValue,
                        salePrice: e.target.value,
                      }))
                    : setState((oldValue) => ({ ...oldValue, salePrice: 0 }))
                }
                name="salePrice"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please Enter Sale Price{" "}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
};

ItemForm.propTypes = {
  validated: PropTypes.bool,
  state: PropTypes.exact({
    title: PropTypes.string,
    quantity: PropTypes.number,
    costPrice: PropTypes.number,
    salePrice: PropTypes.number,
    condition: PropTypes.object,
    location: PropTypes.object,
  }),
  setState: PropTypes.func,
};

export default ItemForm;
