import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { Button, Form } from "react-bootstrap";
import inputStyle from "./imageUploader.module.css";
import { faClose, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDropzone } from "react-dropzone";

import arrayMove from "array-move";

import { Draggable } from "react-drag-reorder";
import axios from "axios";
import icon from "./../../assets/svg/empty-state-icon.svg";
import UploadViaMobile from "./UploadViaMobile";

const Image = ({ src, onDelete, primary, customize, imageUploadedViaMobile }) => {
  return (
    <>
      <div className={`${customize ? inputStyle.givenStyles : inputStyle.image} shadow`} style={{ overflow: "hidden" }}>
        {primary && (
          <span className="tag">
            <FontAwesomeIcon icon={faStar} />
          </span>
        )}
        {!imageUploadedViaMobile && <FontAwesomeIcon className="icon" icon={faClose} onClick={() => onDelete(src)} />}
        <div className="img">
          <img src={src.preview} style={customize ? { height: "30px" } : {}} alt="" width="100%" height="auto" />
        </div>
      </div>
    </>
  );
};

const RenderPhotos = ({ source = [], setSource, onDelete, customize, imageUploadedViaMobile }) => {
  const [dragKey, setDragKey] = useState(1);
  useEffect(() => {
    setDragKey((prev) => prev + 1);
  }, [source]);
  const handlePosChange = (currentPos, newPos) => {
    setSource(arrayMove(source, currentPos, newPos));
  };
  return (
    <div style={{ padding: "0 10%" }}>
      <div className="row justify-content-center">
        <Draggable key={dragKey} onPosChange={handlePosChange} className={`${inputStyle.drag} `}>
          {source?.length > 0 &&
            source?.map((photo, index) => {
              return (
                <div key={index} className={`${inputStyle.minWidth} col-lg-3 col-md-6 col-sm-12`}>
                  <Image customize={customize} src={photo} onDelete={onDelete} primary={index === 0} imageUploadedViaMobile={imageUploadedViaMobile} />
                </div>
              );
            })}
        </Draggable>
      </div>
    </div>
  );
};

const ImageUploader = forwardRef(({ selectedFiles = [], setSelectedFiles, numberOfImages = 10, cdnDirectory = "images", isInvalid, disabled = false, customize, mobileUploadAllow = true }, ref) => {
  const [isUploadViaMobile, setIsUploadViaMobile] = useState(false);
  const [imageUploadedViaMobile, setImageUploadedViaMobile] = useState(false);

  const [noOfImageError, setNoOfImageError] = useState(false);
  const [sizeOfImageError, setSizeOfImageError] = useState(false);

  const [deletedImages, setDeletedImages] = useState([]);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/heic": [".heic", ".heif"],
    },
    onDrop: (acceptedFiles, fileRejections) => {
      setSizeOfImageError(false);
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setSizeOfImageError(true);
          }
        });
      });
      const filesArray = acceptedFiles?.map((file) => ({
        preview: URL.createObjectURL(file),
        data: file,
        type: "file",
      }));
      if (selectedFiles.length + filesArray.length < numberOfImages + 1) {
        setSelectedFiles((prevImage) => [...prevImage, ...filesArray]);
        setNoOfImageError(false);
      } else {
        setNoOfImageError(true);
      }
      acceptedFiles?.map((file) => URL.revokeObjectURL(file));
    },
    // maxFiles:10,
    // maxSize: 2621440, //2.4mb
    maxSize: 10485760, //10mb
  });

  const onDelete = (photo) => {
    setSelectedFiles((prevImage) => prevImage.filter((img) => img !== photo));

    setNoOfImageError(false);
    setSizeOfImageError(false);
  };

  useImperativeHandle(ref, () => ({
    uploadingImages: () => {
      return new Promise((resolve, reject) => {
        if (selectedFiles.length > 0) {
          if (imageUploadedViaMobile) {
            const files = selectedFiles?.map((file) => file.data);
            const destination = cdnDirectory === "images" ? "images/inventoryImages" : "images/" + cdnDirectory;
            axios
              .patch(
                `${process.env.REACT_APP_URL_IMAGE_CDN}/temp/moving`,
                {
                  files,
                  destination,
                },
                { withCredentials: true }
              )
              .then(({ data }) => {
                resolve(data?.files);
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            if (selectedFiles.filter((img) => img.type === "file").length > 0) {
              const formData = new FormData();
              selectedFiles?.map((file) => {
                if (file.type === "file") {
                  formData.append("files", file.data, file.data.name);
                }
              });

              axios
                .post(
                  `${process.env.REACT_APP_URL_IMAGE_CDN}/images`,
                  formData,
                  { withCredentials: true },
                  {
                    headers: {
                      Accept: "application/json",
                      "content-type": "multipart/form-data",
                    },
                  }
                )
                .then(({ data }) => {
                  var filesArray = [];
                  var counter = 0;
                  selectedFiles?.map((file) => {
                    if (file.type === "url") {
                      filesArray.push(file.data);
                    } else {
                      filesArray.push(data.files[counter]);
                      counter = counter + 1;
                    }
                  });
                  resolve(filesArray);
                })
                .catch((err) => {
                  reject(err);
                });
            } else {
              resolve(selectedFiles);
            }
          }
        } else {
          resolve([]);
        }
      });
    },
    uploadImagesOnUpdate: () => {
      return new Promise((resolve, reject) => {
        if (selectedFiles.length > 0) {
          if (imageUploadedViaMobile) {
            const files = selectedFiles?.map((file) => file.data);
            const destination = cdnDirectory === "images" ? "images/inventoryImages" : "images/" + cdnDirectory;
            axios
              .patch(
                `${process.env.REACT_APP_URL_IMAGE_CDN}/temp/moving`,
                {
                  files,
                  destination,
                },
                { withCredentials: true }
              )
              .then(({ data }) => {
                resolve(data?.files.map((file) => ({ data: file, type: "tempUrl" })));
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            if (selectedFiles.filter((img) => img.type === "file").length > 0) {
              const formData = new FormData();
              selectedFiles?.map((file) => {
                if (file.type === "file") {
                  formData.append("files", file.data, file.data.name);
                }
              });

              axios
                .post(
                  `${process.env.REACT_APP_URL_IMAGE_CDN}/${cdnDirectory}`,
                  formData,
                  { withCredentials: true },
                  {
                    headers: {
                      Accept: "application/json",
                      "content-type": "multipart/form-data",
                    },
                  }
                )
                .then(({ data }) => {
                  var filesArray = [];
                  var counter = 0;
                  selectedFiles?.map((file) => {
                    if (file.type === "url") {
                      filesArray.push(file);
                    } else {
                      filesArray.push({
                        preview: data.files[counter],
                        data: data.files[counter],
                        type: "file",
                      });
                      counter = counter + 1;
                    }
                  });

                  resolve(filesArray);
                })
                .catch((err) => {
                  reject(err);
                });
            } else {
              resolve(selectedFiles);
            }
          }
        } else {
          resolve([]);
        }
      });
    },
    deleteImages: () => {
      return new Promise((resolve, reject) => {
        if (deletedImages.length > 0) {
          deletedImages.forEach((photo) => {
            axios
              .delete(photo.preview)
              .then((res) => {})
              .catch((err) => {
                reject(err);
              });
          });
        }
        resolve(true);
      });
    },
  }));

  return (
    <>
      {isUploadViaMobile ? (
        <UploadViaMobile close={() => setIsUploadViaMobile(false)} setSelectedFiles={setSelectedFiles} directory={cdnDirectory} maxLimit={numberOfImages} setImageUploadedViaMobile={setImageUploadedViaMobile} />
      ) : (
        <Form.Group
          className={inputStyle.section}
          style={{
            ...(isInvalid ? { border: "1px solid red", borderRadius: "8px" } : {}),
            ...(disabled ? { pointerEvents: "none", background: "#e9ecef", opacity: "0.7" } : {}),
          }}
        >
          <RenderPhotos customize={customize} source={selectedFiles} setSource={setSelectedFiles} onDelete={onDelete} getInputProps={getInputProps} imageUploadedViaMobile={imageUploadedViaMobile} />
          <Form.Label
            {...getRootProps({
              onClick: (e) => {
                e.preventDefault();
              },
            })}
            style={selectedFiles?.length > 0 ? { display: "none" } : {}}
            className={`${selectedFiles?.length > 0 ? "d-none" : ""} px-3 py-4 border-1 d-flex justify-content-center align-items-center gap-2`}
          >
            <img
              style={{
                pointerEvents: "none",
              }}
              alt="icon"
              src={icon}
            />
            <div className="text-center">
              <p className={`m-0 ${inputStyle.txt}`}>
                Drop your file here or <span className={inputStyle.browseTxt}>browse</span>
              </p>
              {!customize && (
                <>
                  {" "}
                  <span className={`text-secondary ${inputStyle.txtSize}`}>Allowed files: (jpeg, jpg, png)</span>
                  <span className={`text-secondary ${inputStyle.txtSize}`}>
                    (maximum <span className={inputStyle.browseTxt}>2.5 MB</span> file size)
                  </span>
                  <span className={`text-secondary ${inputStyle.txtSize}`}>
                    Max <span className={inputStyle.browseTxt}>{numberOfImages}</span> images can be added.
                  </span>
                </>
              )}
            </div>
            <Form.Control {...getInputProps()} />
          </Form.Label>
          <hr />
          {sizeOfImageError && <span style={{ color: "red" }}>You Try to Add Image with size more then 2.5 MBs.</span>}
          <br />
          {noOfImageError ? <span style={{ color: "red" }}>You Try to Add more then {numberOfImages} Images</span> : <span>{selectedFiles?.length} Images Added</span>}

          <br />
          {!imageUploadedViaMobile && selectedFiles?.length < numberOfImages && <Button onClick={open}>Add Images +</Button>}
          <br />
          {!imageUploadedViaMobile && mobileUploadAllow && selectedFiles?.length === 0 && (
            <Button
              variant="secondary"
              onClick={() => {
                setIsUploadViaMobile(true);
              }}
              className="mt-2 d-lg-block d-none mx-auto"
            >
              Upload via Mobile
            </Button>
          )}
        </Form.Group>
      )}
    </>
  );
});

export default ImageUploader;
