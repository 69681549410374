import React from "react";
import { Container, Row, Col, Button, Dropdown } from "react-bootstrap";
import SearchInput, { SearchMobleInput } from "../../Components/SearchInput";
import { useState } from "react";
import SearchableSelect from "../../Components/SearchableSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import filter from "../../assets/svg/filter.svg";
import { Link } from "react-router-dom";
import { useRef } from "react";
import DatePicker from "../../Components/DatePicker/DatePicker";
import moment from "moment";
import MobileSearchAcoordian from "../../Components/MobileSearchAcoordian";
import filterStyle from "./../../styles/filtersButtons.module.css";
import PurchaseOrderTable from "./PurchaseOrderTable";
import PopUpModal from "../../Components/Modal";
import AddPurchaseOrder from "./AddPurchaseOrder";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const PurchaseOrder = () => {
  const [openingBalances, setOpeningBalances] = useState([]);

  //data toggle
  const [toggle, refresh] = useState(true);

  //pagination
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [toogleMobileSearch, setToogleMobileSearch] = useState(false);
  const [vendorType, setVendorType] = useState("primary");

  const [modalShow, setModalShow] = useState(false);
  // const register = useSelector((state) => state.location?.register);
  // const registerID = register?.registerId;
  const location = useSelector((state) => state.location?.location);

  const handleAcoordianCLose = () => {
    setToogleMobileSearch(false);
    setSearch("");
    setStatus(null);
    setFromDate(moment().subtract(29, "days").startOf("day")._d);
    setToDate(moment().endOf("day")._d);
  };
  const handleAcoordianOpen = (value) => {
    setToogleMobileSearch(value);
  };

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  //filters
  const [search, setSearch] = useState("");
  const currentSearch = useRef();
  currentSearch.current = search;

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const [status, setStatus] = useState(null);
  const handleStatusChange = (e) => {
    if (e.value === "All") {
      setStatus(null);
    } else {
      setStatus(e);
    }
  };

  const [fromDate, setFromDate] = useState(
    moment().subtract(29, "days").startOf("day")._d
  );
  const [toDate, setToDate] = useState(moment().endOf("day")._d);

  const resetFilterHandle = (e) => {
    setStatus(null);
    setFromDate(moment().subtract(29, "days").startOf("day")._d);
    setToDate(moment().endOf("day")._d);
  };
  const Filter = () => {
    setToogleMobileSearch(false);
  };

  const handleChangeStatusMobile = (e) => {
    if (e.target.value === "All") {
      setStatus(null);
    } else {
      setStatus({ value: e.target.id, label: e.target.value });
    }
  };
  const SearchData = [
    {
      name: "Select Status",
      handleChange: handleChangeStatusMobile,
      value: status,
      filter: [
        { value: "All", label: "All" },

        { value: "paid", label: "Paid" },

        { value: "pending", label: "Pending" },
      ],
    },
  ];

  return (
    <>
      {toogleMobileSearch === "select" ? (
        <MobileSearchAcoordian
          SearchData={SearchData}
          toggleSearchclose={handleAcoordianCLose}
          dateName={"Select Date"}
          fromDate={fromDate}
          toDate={toDate}
          setFromDate={setFromDate}
          setToDate={setToDate}
          filter={Filter}
        />
      ) : (
        <Container fluid className="mb-5">
          {toogleMobileSearch === "search" ? (
            <div className="d-flex  mb-2 justify-content-center align-items-center">
              <div className="">
                {" "}
                <SearchMobleInput
                  searchlogo
                  value={search}
                  handleChange={handleSearchChange}
                />
              </div>
              <span className="ms-4" onClick={handleAcoordianCLose}>
                X
              </span>
            </div>
          ) : (
            <Row className="d-flex align-items-center">
              <Col xs="2" lg="3" xl="2" className="d-none d-lg-inline">
                <SearchInput
                  searchlogo
                  value={search}
                  handleChange={handleSearchChange}
                />
              </Col>
              <Col lg={2} className="d-none d-lg-inline"></Col>
              {vendorType === "other" && (
                <Col lg={2} className="d-none d-lg-inline"></Col>
              )}
              <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3">
                <DatePicker
                  important={true}
                  fromDate={fromDate}
                  toDate={toDate}
                  setFromDate={setFromDate}
                  setToDate={setToDate}
                />
              </Col>
              {vendorType === "primary" && (
                <Col xs="3" lg="3" xl="2" className="d-none d-lg-inline mb-3">
                  <SearchableSelect
                    selectClassName={"bg-white"}
                    placeholder="By Status"
                    value={status}
                    searchable={false}
                    options={[
                      { value: "All", label: "All" },
                      { value: "paid", label: "Paid" },
                      { value: "pending", label: "Pending" },
                    ]}
                    handleChange={handleStatusChange}
                  />
                </Col>
              )}

              <Col
                className="d-none d-sm-inline d-lg-none d-md-inline px-0"
                sm={2}
              ></Col>
              <Col xs="6" lg="3" xl="2" sm="3" className="mb-3">
                <SearchableSelect
                  selectClassName={"bg-white"}
                  placeholder={`Showing ${sizeOfPages}`}
                  defaultValue={`Showing ${sizeOfPages}`}
                  value={`Showing ${sizeOfPages}`}
                  searchable={false}
                  options={[
                    { value: "10", label: "10" },
                    { value: "20", label: "20" },
                    { value: "30", label: "30" },
                  ]}
                  handleChange={handlePageSize}
                />
              </Col>
              <Col
                onClick={() => handleAcoordianOpen("search")}
                xs="2"
                sm="2"
                lg="3"
                className="d-lg-none d-inline mb-3"
              >
                <SearchInput searchlogo bordered />
              </Col>
              <Col
                className={`d-lg-none d-inline mb-3 ${filterStyle.filter}`}
                xs="2"
                lg="2"
              >
                <Link to="" className={`${filterStyle.filterToggleButtonLink}`}>
                  <Button
                    onClick={() => handleAcoordianOpen("select")}
                    variant="outline-primary"
                    className={`${filterStyle.filterToggleButton} d-flex align-items-center`}
                  >
                    <img className="" src={filter} alt="filter" />
                    <span className=" ms-2 d-none d-sm-inline">Filter</span>
                  </Button>
                </Link>
              </Col>
              <Col
                xs="2"
                sm="3"
                lg="2"
                xl="2"
                xxl="2"
                className={`mb-3 p-0 ${filterStyle.addbtn}`}
              >
                <Button
                  onClick={() => {
                    setModalShow(true);
                  }}
                  type="submit"
                  style={{
                    fontSize: "1rem",
                    height: "100%",
                  }}
                  className={` ${filterStyle.addButton}`}
                >
                  <span className="d-none d-sm-inline  d-lg-inline">
                    Add Purchase Order
                  </span>{" "}
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </Col>
            </Row>
          )}
          <div className="pt-2">
            <Button
              size="sm"
              type="submit"
              style={{ fontSize: "14px" }}
              onClick={resetFilterHandle}
            >
              <span className="d-lg-inline">Reset Filters</span>{" "}
            </Button>
          </div>
          {/* <div className="d-flex justify-content-end">
            <Dropdown
              className={`d-flex d-lg-inline d-md-inline justify-content-end ${style.toggle}`}
              onSelect={(e) => setVendorType(e)}
            >
              <Dropdown.Toggle
                variant="light"
                className={`border-0 p-0 text-secondary `}
                id="dropdown-basic"
                style={{ background: "transparent" }}
              >
                <span style={{ color: "#9537FF" }}>
                  (
                  {vendorType === "primary"
                    ? "Vendors with Purchase Orders"
                    : "Vendors without Purchase Orders"}
                  )
                </span>{" "}
                <img src={icon} alt="ellipsis" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="rounded-0 border-0 shadow">
                <Dropdown.Item
                  as={Col}
                  className="purple cursor-pointer"
                  href=""
                  eventKey="primary"
                >
                  Vendors with Purchase Orders
                </Dropdown.Item>
                <Dropdown.Item
                  as={Col}
                  className="purple cursor-pointer"
                  href=""
                  eventKey="other"
                >
                  Vendors without Purchase Orders
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div> */}
          <PurchaseOrderTable
            search={search}
            fromDate={fromDate}
            toDate={toDate}
            location={location}
            status={status}
            setModalShow={setModalShow}
            sizeOfPages={sizeOfPages}
            toggle={toggle}
            refresh={refresh}
            setOpeningBalances={setOpeningBalances}
            openingBalances={openingBalances}
          />
        </Container>
      )}
      <PopUpModal
        title={"Add New Purchase Order"}
        size={"lg"}
        marginright={true}
        mdSize={true}
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <AddPurchaseOrder
          refresh={refresh}
          openingBalances={openingBalances}
          close={() => setModalShow(false)}
        />
      </PopUpModal>
    </>
  );
};

export default PurchaseOrder;
