import { useState } from "react";
import { Container, Row, Col, Button, Tabs, Tab, Form } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import SearchInput, { SearchMobleInput } from "../Components/SearchInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import style from "../styles/InventoryList.module.css";
import { useSelector } from "react-redux";
import SearchableSelect from "../Components/SearchableSelect";
import ReactSelect from "../Components/ReactSelect";
import filterStyle from "../styles/filtersButtons.module.css";
import filterr from "../assets/svg/filter.svg";

import MobileSearchAcoordian from "../Components/MobileSearchAcoordian";
import ArchiveList from "../Components/inventory/archivelist";
import Inventory from "../Components/inventory/Inventory";
import PopUpModal from "../Components/Modal";
import TableSettings from "../Components/TableSettings";
import { useEffect } from "react";

const InventoryList = () => {
  const [category, setCategory] = useState(null);
  const [search_input, setSearchInput] = useState("");
  const [conditionSearch, setConditionSearch] = useState(null);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [toogleMobileSearch, setToogleMobileSearch] = useState(false);
  const [techcategori, setTechCategori] = useState();
  const [archiveCount, setArchiveCount] = useState(0);
  const [settingsModal, setSettingsModal] = useState(false);
  const [archiveActionTrigger, setArchiveActionTrigger] = useState(true);
  const [actionTrigger, setActionTrigger] = useState(true);
  const [columnNotToShow, setColumnNotToShow] = useState([]);
  const [reset, setReset] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const [columns, setColumns] = useState([
    { label: "condition_item", value: 1 },
    { label: "in_stock", value: 1 },
    { label: "location", value: 1 },
    { label: "sale_price", value: 1 },
    { label: "stock_no", value: 1 },
    { label: "location", value: 1 },
    { label: "type", value: 1 },
    { label: "batches", value: 1 },
    { label: "barcode", value: 1 },
    { label: "status", value: 1 },
  ]);
  const [currentTab, setCurrentTab] = useState("Inventory");
  const [accessoryType, setAccessoryType] = useState(null);
  const [accessoryTypeOptions, setAccessoryTypeOptions] = useState([]);
  const handleAcoordianCLose = () => {
    setToogleMobileSearch(false);
    setSearchInput("");
    setCategory(null);
    setConditionSearch(null);
  };
  const filter = () => {
    setToogleMobileSearch(false);
  };

  const handleAcoordianOpen = (value) => {
    setToogleMobileSearch(value);
  };
  const { user } = useSelector((state) => state.auth);
  const location = useSelector((state) => state.location.location);
  const shop_id = user.shop_id;
  const userId = user.user_id;
  const user_type = user.user_type;
  const getCat = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/categories/get", {
          withCredentials: true,
        })
        .then((res) => {
          const getData = res.data
            .filter((cat) => cat.id !== 7)
            .map((cat) => ({
              value: cat.id,
              label: cat.category,
            }));
          getData.splice(0, 0, { value: null, label: "All" });
          resolve(getData);
          setTechCategori(getData);
        })
        .catch((err) => {
          reject("err" + err);
        });
    });
  };

  const getAccessoryTypes = (e) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + `/component/accessory_type`, {
          withCredentials: true,
          headers: { dropdown: true },
        })
        .then((res) => {
          const value = res.data.success.data.map((loc) => ({
            value: loc.id,
            label: loc.label,
          }));
          value.splice(0, 0, { value: null, label: "All" });
          if (e?.length > 0) {
            const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
            resolve(filteredValue);
            setAccessoryTypeOptions(value);
          } else {
            resolve(value);
            setAccessoryTypeOptions(value);
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    });
  };
  const filterCategories = (e) => {
    if (e.value) {
      setCategory({ value: e.value, label: e.label });
    } else {
      setCategory(null);
    }
  };
  const searchCondition = (e) => {
    if (e.value) {
      setConditionSearch({ value: e.value, label: e.label });
    } else {
      setConditionSearch(null);
    }
  };
  const filterCategoriesMobile = (e) => {
    if (e.target.value === "All") {
      setCategory({ value: null, label: e.target.value });
    } else {
      setCategory({ value: e.target.id, label: e.target.value });
    }
  };
  const filterAccessoryMobile = (e) => {
    if (e.target.value === "All") {
      setAccessoryType({ value: null, label: e.target.value });
    } else {
      setAccessoryType({ value: e.target.id, label: e.target.value });
    }
  };
  const searchConditionMobile = (e) => {
    if (e.target.value === "All") {
      setConditionSearch({ value: null, label: e.target.value });
    } else {
      setConditionSearch({ value: e.target.id, label: e.target.value });
    }
  };

  // Search Products
  const searchInventory = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearchInput("");
    } else {
      setSearchInput(e.target.value);
    }
  };
  const handleTabs = (e) => {
    setCurrentTab(e);
    setConditionSearch(null);
    setCategory(null);
    setAccessoryType(null);
  };
  const resetFilterHandle = (e) => {
    setCategory(null);
    setConditionSearch(null);
    setAccessoryType(null);
  };
  const SearchData = [
    {
      name: "Condition",
      handleChange: searchConditionMobile,
      value: conditionSearch,
      filter: [
        { value: "none", label: "All" },
        { value: "1", label: "New" },
        { value: "2", label: "Used" },
        { value: "3", label: "Refurbished" },
      ],
    },
    {
      name: "Category type",
      handleChange: filterCategoriesMobile,
      value: category,
      filter: techcategori,
    },
    {
      name: "Select Accessory",
      handleChange: filterAccessoryMobile,
      value: accessoryType,
      filter: accessoryTypeOptions,
      isShowOn: (value) => {
        if (value?.value?.value == 6) {
          if (accessoryTypeOptions?.length === 0) {
            getAccessoryTypes();
          }
          return true;
        } else {
          return false;
        }
      },
      isShowOnValue: "Category type",
    },
  ];
  // useEffect(() => {
  //   getAccessoryTypes();
  // }, []);

  useEffect(() => {
    const fetchTableColumns = async () => {
      try {
        const data = await axios.get(process.env.REACT_APP_API_URL + `/settings/get-inventory-settings/${userId}`, {
          withCredentials: true,
        });
        const res = data?.data?.inventory_table;
        const desiredArray = [];
        for (const key in res) {
          if (res.hasOwnProperty(key)) {
            desiredArray.push({ label: key, value: res[key] });
          }
        }
        if (desiredArray.length > 0) {
          setColumns(desiredArray);
        } else {
          setColumns([...columns]);
        }
        setColumnNotToShow(desiredArray.filter((obj) => obj.value === 0).map((obj) => obj.label));
      } catch {}
    };
    fetchTableColumns();
  }, [reset, settingsModal]);
  const handleSettings = (e, label, i) => {
    setIsValidate(false);

    let values = [...columns];
    values[i].value = e.target.checked === false ? 0 : 1;
    setColumns(values);
  };
  useEffect(() => {}, [columns]);
  const selectAll = () => {
    setColumns([
      { label: "condition_item", value: 1 },
      { label: "in_stock", value: 1 },
      { label: "location", value: 1 },
      { label: "sale_price", value: 1 },
      { label: "stock_no", value: 1 },
      { label: "type", value: 1 },
      { label: "batches", value: 1 },
      { label: "barcode", value: 1 },
      { label: "status", value: 1 },
    ]);
  };

  const handlesubmitSettings = async (e) => {
    e.preventDefault();

    const desiredObject = {};

    columns.forEach((obj) => {
      desiredObject[obj.label] = obj.value;
    });
    if (!Object.values(desiredObject).every((item) => item === 0)) {
      try {
        const data = await axios.post(
          process.env.REACT_APP_API_URL + `/settings/add-inventory-settings`,
          { user_id: userId, inventory_table: desiredObject },
          {
            withCredentials: true,
          },
        );
        if (data) {
          setSubmitted((prev) => !prev);
          setSettingsModal(false);
        }
      } catch {}
    } else {
      setIsValidate(true);
    }
  };
  return (
    <>
      {toogleMobileSearch === "select" ? (
        <MobileSearchAcoordian SearchData={SearchData} toggleSearchclose={handleAcoordianCLose} filter={filter} />
      ) : (
        <Container fluid>
          {toogleMobileSearch === "search" ? (
            <div className="d-flex  mb-2 justify-content-center align-items-center">
              <div className="">
                {" "}
                <SearchMobleInput searchlogo value={search_input} handleChange={searchInventory} />
              </div>
              <span className="ms-4" onClick={handleAcoordianCLose}>
                X
              </span>
            </div>
          ) : (
            <Row className="d-flex align-items-center">
              <Col xs="2" lg="3" xl="2" className="d-none d-lg-inline mb-3">
                <SearchInput searchlogo handleChange={searchInventory} />
              </Col>
              {!category || category?.value !== 6 ? <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3"></Col> : ""}
              <Col xs lg="3" xl="2" className={`d-none d-lg-inline mb-3 ${style.condition}`}>
                <SearchableSelect
                  placeholder="Filter condition"
                  searchable={false}
                  value={conditionSearch}
                  options={[
                    { value: null, label: "All" },
                    { value: "1", label: "New" },
                    { value: "2", label: "Used" },
                    { value: "3", label: "Refurbished" },
                  ]}
                  handleChange={searchCondition}
                />
              </Col>
              <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3">
                <ReactSelect placeholder="Filter Category" value={category} options={getCat} handleChange={(e) => filterCategories(e)} />
              </Col>
              {category && category?.value === 6 ? (
                <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3">
                  <ReactSelect
                    placeholder="Filter Accessory"
                    searchable={true}
                    cache={false}
                    options={getAccessoryTypes}
                    handleChange={(e) => {
                      if (e.value) {
                        setAccessoryType({ value: e.value, label: e.label });
                      } else {
                        setAccessoryType(null);
                      }
                    }}
                    value={accessoryType}
                    name="Accessory Type"
                  />
                </Col>
              ) : (
                ""
              )}

              {/* For Mobile screen Start */}

              <Col className="d-none d-sm-inline d-lg-none d-md-inline px-0" sm={2}></Col>
              <Col xs="6" lg="3" xl="2" sm="3" className="mb-3">
                <SearchableSelect
                  selectClassName={"bg-white"}
                  placeholder={`Showing ${sizeOfPages}`}
                  defaultValue={`Showing ${sizeOfPages}`}
                  value={`Showing ${sizeOfPages}`}
                  searchable={false}
                  options={[
                    { value: "10", label: "10" },
                    { value: "20", label: "20" },
                    { value: "30", label: "30" },
                  ]}
                  handleChange={(e) => setSizeOfPages(e.value)}
                />
              </Col>
              <Col onClick={() => handleAcoordianOpen("search")} xs="2" sm="2" lg="3" className="d-lg-none d-inline mb-3">
                <SearchInput searchlogo bordered />
              </Col>
              <Col className={`d-lg-none d-inline mb-3 ${filterStyle.filter}`} xs="2" lg="2">
                <Link to="" className={`${filterStyle.filterToggleButtonLink}`}>
                  <Button onClick={() => handleAcoordianOpen("select")} variant="outline-primary" className={`${filterStyle.filterToggleButton} d-flex align-items-center`}>
                    <img className="" src={filterr} alt="filter" />
                    <span className=" ms-2 d-none d-sm-inline">Filter</span>
                  </Button>
                </Link>
              </Col>
              <Col xs="2" sm="3" lg="2" xl="2" xxl="2" className={`mb-3 p-0 ${filterStyle.addbtn}`}>
                <Link to={"/inventory/add"} className={` ${filterStyle.addButtonLink}`}>
                  <Button
                    type="submit"
                    style={{
                      fontSize: "1rem",
                      height: "100%",
                    }}
                    className={` ${filterStyle.addButton}`}
                  >
                    <span className="d-none d-sm-inline  d-lg-inline">Add Inventory</span> <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </Link>
              </Col>

              {/* For Mobile screen End */}
            </Row>
          )}
          <>
            <div className="pt-2   d-lg-flex justify-content-between">
              <Button size="sm" type="submit" style={{ fontSize: "14px", marginTop: "7px" }} onClick={resetFilterHandle}>
                <span className=" d-lg-inline">Reset Filters</span>{" "}
              </Button>
            </div>
            <Row style={{ marginBottom: "-3rem" }} className="mt-3">
              <Col>
                <Tabs defaultActiveKey="Inventory" className={`${style.navtabs} `} onSelect={handleTabs}>
                  <Tab eventKey="Inventory" title="My Inventory" className="text-secondary mb-5 ">
                    <Inventory
                      isActive={currentTab === "Inventory"}
                      columnNotToShow={columnNotToShow}
                      submitted={submitted}
                      setSettingsModal={setSettingsModal}
                      search={search_input}
                      category={category}
                      accessoryType={category && category?.value === 6 ? (accessoryType ? accessoryType : null) : null}
                      location={location}
                      condition={conditionSearch}
                      sizeOfPages={sizeOfPages}
                      setArchiveActionTrigger={setArchiveActionTrigger}
                      refresh={actionTrigger}
                      toggle={setActionTrigger}
                    />
                  </Tab>
                  <Tab eventKey="Archived Inventory" title={`Archive (${archiveCount ? archiveCount : 0})`} className="text-secondary mb-5 ">
                    <ArchiveList
                      isActive={currentTab === "Archived Inventory"}
                      columnNotToShow={columnNotToShow}
                      submitted={submitted}
                      setSettingsModal={setSettingsModal}
                      search={search_input}
                      category={category}
                      accessoryType={category && category?.value === 6 ? (accessoryType ? accessoryType : null) : null}
                      location={location}
                      condition={conditionSearch}
                      sizeOfPages={sizeOfPages}
                      setArchiveCount={setArchiveCount}
                      recallAllQueries={archiveActionTrigger}
                      unarchiveActionToggle={() => setActionTrigger((prev) => !prev)}
                    />
                  </Tab>
                </Tabs>
              </Col>
            </Row>
            <PopUpModal
              title="Customize table"
              show={settingsModal}
              onHide={() => {
                setSettingsModal(false);
              }}
            >
              <TableSettings setReset={setReset} isValidate={isValidate} handlesubmitSettings={handlesubmitSettings} selectAll={selectAll} handleSettings={handleSettings} columns={columns} show={settingsModal} close={() => setSettingsModal(false)} />
            </PopUpModal>
          </>
        </Container>
      )}
    </>
  );
};
export default InventoryList;
