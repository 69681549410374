import { useState } from "react";
import { Form, Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import SearchableSelect from "../../Components/SearchableSelect";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import style from "./../../styles/ResetPassword.module.css";
import { faEye, faEyeSlash, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PhoneInput from "../../Components/PhoneInput/PhoneInput";

const AddUser = ({ close }) => {
  const modulesNotShow = ["Dashboard", "Employees", "GeneralReport"];
  const modulesAlwaysChecked = ["Dashboard", "GeneralReport"];
  const user = useSelector((state) => state.auth.user);
  const location = useSelector((state) => state.location.location);
  const shopId = user.shop_id;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [number, setNumber] = useState();
  const [alternativenumber, setAlternativeNumber] = useState();
  const [role, setRole] = useState({});
  const [commission, setCommission] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [validated, setValidated] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [modules, setModules] = useState([]);
  const [p_id, setP_id] = useState();

  const [notificationsCheckBoxes, setNotificationsCheckBoxes] = useState({
    has_global: false,
    has_new_reservation: false,
    has_delivery_order: false,
  });

  const handleRoleChange = (e) => {
    if (e.value) {
      setRole(e);
    } else {
      setRole({});
    }
  };

  const handleCommisionChange = (e) => {
    let value = e.target.value.toString();
    const regex = /^[0-9]+(\.[0-9]{0,2})?$/; // Regular expression to validate numbers or floating points up to 2 decimal places
    if (value.includes(".")) {
      const decimalIndex = value.indexOf(".");
      const decimalPart = value.substring(decimalIndex + 1);
      value = value.substring(0, decimalIndex + 1) + decimalPart.substring(0, 2); // Truncate decimal part to 2 places
    }
    if (regex.test(value) && parseFloat(value) <= 100) {
      setCommission(value);
    } else if (value === "") {
      setCommission("");
    } else {
      setCommission(100);
    }
  };

  const extractIds = (data) => {
    const ids = [];
    data.forEach((mainObj) => {
      if (mainObj.isChecked || modulesAlwaysChecked.includes(mainObj.name)) {
        ids.push(mainObj.id);
      }
      mainObj.subModules.forEach((submodule) => {
        if (submodule.isChecked || modulesAlwaysChecked.includes(submodule.name)) {
          ids.push(submodule.id);
        }
      });
    });
    return ids;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setValidated(true);

    try {
      setError("");
      const user = {
        user_full_name: name,
        user_email: email,
        password: password,
        whatsapp_number: number,
        backup_phone_number: alternativenumber,
        shop_id: shopId,
        user_type: "secondary",
        role: role?.label,
        commission: commission ? commission : 0,
      };

      const locationUser = {
        location_id: location.value,
      };

      if (name && email && location.value && password && number && role?.label) {
        setIsClicked(true);
        const res = await axios.post(
          process.env.REACT_APP_API_URL + `/user/add-secondary-user`,
          {
            user,
            notificationPermissions: notificationsCheckBoxes,
            location: locationUser,
            modules: extractIds(modules),
          },
          { withCredentials: true },
        );
        if (res.data) {
          setSuccess("User Added Successfully.");
          setTimeout(() => close(), 1000);
        }
      }
    } catch (error) {
      setIsClicked(true);
      setError(error.response?.data.error.message);
      setIsClicked(false);
    }
  };

  const handleNotificationsChange = (e, name) => {
    if (name === "has_global" && !e.target.checked) {
      setNotificationsCheckBoxes((prev) => {
        const obj = {};
        for (const key in prev) {
          obj[key] = false;
        }
        return obj;
      });
    } else {
      setNotificationsCheckBoxes({
        ...notificationsCheckBoxes,
        [name]: e.target.checked,
      });
    }
  };

  function fetchModules() {
    setModules(user.modules);
  }

  useEffect(() => {
    fetchModules();
  }, []);

  const fetchSubModulesData = async (name) => {
    setP_id(name);
    // setSubModulesOptions(modulesOptions[id].subModules);
  };

  const handleCheckboxChange = (e, name) => {
    setP_id(name);
    if (e.target.checked) {
      setModules((prev) => {
        return prev.map((item, i) => {
          if (item.name === name) {
            return { ...item, isChecked: e.target.checked };
          }
          return item;
        });
      });
    } else {
      setModules((prev) => {
        return prev.map((item, i) => {
          if (item.name === name) {
            item.isChecked = false;
            item.subModules.map((item, i) => {
              if ("isChecked" in item) {
                item.isChecked = false;
              }
            });
          }
          return item;
        });
      });
    }
  };

  const handleSubmoduleCheckboxChange = (e, name, pName) => {
    const parentModule = modules.find((item) => item.name === pName);
    if (parentModule?.isChecked ? parentModule?.isChecked : false) {
      setModules((prevModulesOptions) => {
        return prevModulesOptions.map((module, i) => {
          if (module.name === pName) {
            return {
              ...module,
              subModules: module.subModules.map((item, subIndex) => {
                if (item.name === name) {
                  return { ...item, isChecked: e.target.checked };
                }
                return item;
              }),
            };
          }
          return module;
        });
      });
    } else {
      alert("Please select Parent Module first");
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      {error && <p className="text-danger p-2 text-center my-1 rounded-1">*{error}</p>}
      {success && <p className="text-success p-2 text-center my-1 rounded-1">*{success}</p>}
      <Row>
        <Col lg="6">
          <Form.Group className="mb-3">
            <Form.Label className="mb-1">
              Full Name <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control className="p-2" type="text" name="name" placeholder="Full Name" value={name} onChange={(e) => setName(e.target.value)} required />
            <Form.Control.Feedback type="invalid">* Please Enter User Name</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg="6">
          <Form.Group className="mb-3">
            <Form.Label className="mb-1">
              Email <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control className="p-2" type="text" name="name" autocomplete="new-password" placeholder="example@mail.com" pattern="[a-z0-9._+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" value={email} onChange={(e) => setEmail(e.target.value)} required />
            <Form.Control.Feedback type="invalid">* Invalid Email</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg="6">
          <Form.Group className={`mb-4 ${style.passwordInput}`}>
            <Form.Label>
              Password <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control className={`p-2 ${style.passwordValidate}`} name="password" type={showPassword ? "text" : "password"} placeholder="******" required value={password} onChange={(e) => setPassword(e.target.value)} />
            <div className={`${style.passwordField}`}>
              <FontAwesomeIcon className="text-secondary" icon={showPassword ? faEye : faEyeSlash} onClick={() => setShowPassword((a) => !a)} />
            </div>
            <Form.Control.Feedback type="invalid">* Please Enter Password</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg="6">
          <div className="mb-3">
            <SearchableSelect important label={"Location"} placeholder="Select Location" disabled={true} value={location} />
            <Form.Control hidden type="text" name="email" value={Object.values(location).length > 0 ? location : ""} onChange={() => {}} required />
            <Form.Control.Feedback type="invalid">* Please Select Location</Form.Control.Feedback>
          </div>
        </Col>
        <Col lg="6">
          <div className="mb-3">
            <SearchableSelect
              important
              label={"Role"}
              value={Object.values(role).length > 0 ? role : null}
              placeholder="Select Role"
              options={[
                { value: "Cashier", label: "Cashier" },
                { value: "sales Person", label: "Sales Person" },
              ]}
              handleChange={handleRoleChange}
              required
            />
            <Form.Control hidden type="text" name="role" value={Object.values(role).length > 0 ? role : null} onChange={() => {}} required />
            <Form.Control.Feedback type="invalid">* Please Select Role</Form.Control.Feedback>
          </div>
        </Col>
        <Col lg="6">
          {role?.label === "Sales Person" && (
            <Form.Group className="mb-3">
              <Form.Label className="mb-1">Commision(%)</Form.Label>
              <Form.Control
                className="p-2"
                type="number"
                name="commission"
                placeholder="Commission"
                value={commission}
                onChange={handleCommisionChange}
                onKeyDown={(e) => {
                  if (["e", "E", "+", "-"].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Group>
          )}
        </Col>
        <Col lg="6">
          <Form.Group className="mb-3">
            <Form.Label className="mb-1">
              Phone No. <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <PhoneInput className="p-2" placeholder="+92-3XX-XXXXXXX" value={number} onChange={(e) => setNumber(e.target.value)} required />
            <Form.Control.Feedback type="invalid">* Please Select Phone Number</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg="6">
          <Form.Group className="mb-3">
            <Form.Label className="mb-1">Alternative Phone No.</Form.Label>
            <PhoneInput className="p-2" placeholder="+92-3XX-XXXXXXX" value={alternativenumber} onChange={(e) => setAlternativeNumber(e.target.value)} />
          </Form.Group>
        </Col>
      </Row>

      <Row className="py-2 mb-2">
        <Col lg="12 " className={`mb-2 `}>
          <Form.Group className="w-100" controlId="formBasicShopName">
            <Form.Label> Associated Modules</Form.Label>

            <div className="d-flex gap-3 flex-wrap">
              {modules
                .filter((item) => !modulesNotShow.includes(item.name))
                .map(
                  (item, index) =>
                    item.isIncluded && (
                      <div className="d-flex flex-column  ">
                        <div style={{ maxHeight: "50px" }} onClick={() => fetchSubModulesData(item?.name)} className=" mb-2 d-flex gap-3 p-2 bg-light align-items-center ">
                          <Form.Check
                            key={index}
                            className={""}
                            onChange={(e) => {
                              handleCheckboxChange(e, item?.name);
                            }}
                            name={item?.name}
                            checked={item.isChecked}
                            type="checkbox"
                            id={`${index}`}
                          />
                          <label
                            className=""
                            style={{ fontSize: "14px" }}
                            //  htmlFor={`${index}`}
                          >
                            {item.name}
                          </label>
                        </div>
                        {item?.subModules.length > 0 ? (
                          <Form.Group className="w-100" controlId="formBasicShopName">
                            <div className="d-grid ms-2 ">
                              {item?.subModules
                                ?.filter((item) => !modulesNotShow.includes(item.name))
                                ?.map(
                                  (sItem, i) =>
                                    sItem.isIncluded && (
                                      <Form.Check
                                        key={i}
                                        className={`${style.Checkbox}`}
                                        onChange={(e) => {
                                          handleSubmoduleCheckboxChange(e, sItem.name, item.name);
                                        }}
                                        name={sItem.name}
                                        checked={sItem.isChecked ? sItem.isChecked : false}
                                        type="checkbox"
                                        id={`${index}-${p_id}`}
                                        label={`${sItem.name}`}
                                      />
                                    ),
                                )}
                            </div>
                          </Form.Group>
                        ) : (
                          ""
                        )}
                      </div>
                    ),
                )}
            </div>
          </Form.Group>
        </Col>
      </Row>
      <>
        <hr className="mt-1 mb-1" />
        <Row className="py-2 mb-2">
          <label className="fs-5 fw-bold mb-1">Notifications</label>
          <Col>
            <Form.Group>
              <Form.Check type="checkbox" label="Notifications" checked={notificationsCheckBoxes.has_global} onChange={(e) => handleNotificationsChange(e, "has_global")} />
            </Form.Group>
          </Col>
          {notificationsCheckBoxes.has_global && (
            <>
              <Col>
                <Form.Group>
                  <Form.Check type="checkbox" label="Reservation" checked={notificationsCheckBoxes.has_new_reservation} onChange={(e) => handleNotificationsChange(e, "has_new_reservation")} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Check type="checkbox" label="Delivery Orders" checked={notificationsCheckBoxes.has_delivery_order} onChange={(e) => handleNotificationsChange(e, "has_delivery_order")} />
                </Form.Group>
              </Col>
            </>
          )}
        </Row>
      </>

      <Button disabled={isClicked} type="submit" className="w-100">
        Save
      </Button>
    </Form>
  );
};

export default AddUser;
