import React, { useLayoutEffect } from "react";
import { Card, Col, Row, Form, Table, Button, Tabs, Tab, Dropdown } from "react-bootstrap";
import style from "../../../styles/Reports.module.css";
import TabStyle from "../../../styles/Sales.module.css";

import { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import axios from "axios";
import SearchableSelect from "../../SearchableSelect";
import DatePicker from "../../DatePicker/DatePicker";
import { useSpring, animated } from "react-spring";
import ReactSelect from "../../ReactSelect";
import { getcomponent } from "../../InventoryForms/utils";
import SalesReportTab from "./SalesReportTab";
import SalesTrendReportTab from "./SalesTrendReportTab";
import BarChart from "../../Charts/BarChart";
import { moneyToMillions } from "../InventoryReports/numberToWords";
import styles from "../../../styles/Setting.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTrendDown, faArrowTrendUp, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import filterStyle from "../../../styles/filtersButtons.module.css";
import filterr from "../../../assets/svg/filter.svg";
import MobileSubReportsTabs from "../MobileSubReportsTab";
import LineChart from "../../Charts/LineChart";
import MobilrReportsFilter from "../MobileReportsFilter";
import LoaderPage from "../../loaders/loaderPage";
import { requestDateTime } from "../../../Features/dateTime";
const CurrencyAnimation = (props) => {
  const value = useSpring({
    from: { number: 0 },
    to: { number: props.number },
    delay: 200,
    config: { mass: 1, tension: 200, friction: 10 },
  });
  return (
    <animated.div>
      {value.number.to((n) => {
        const tempN = Number(n.toFixed(0));
        return tempN.toLocaleString("en-IN");
      })}
    </animated.div>
  );
};

const SalesReports = ({ MainactiveTab, close, show }) => {
  const [data, setData] = useState();

  const [fromDate, setFromDate] = useState(moment().subtract(29, "days").startOf("day")._d);
  const [toDate, setToDate] = useState(moment().endOf("day")._d);

  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  const user_type = user.user_type;
  const userId = user.user_id;
  const shopName = user.shop_name;

  const [filterInput, setFilterInput] = useState("");
  const [location, setLocation] = useState({});
  const [locationFields, setLocationFields] = useState({});
  const [brand, setBrand] = useState({});
  const [category, setCategory] = useState({});
  const [customer, setCustomer] = useState({});
  const [salesperson, setSalesperson] = useState({});
  const [product, setProcuct] = useState({});
  const [paymentType, setPaymentType] = useState({});
  const [productOptions, setProcuctOptions] = useState([]);
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [showMobileTabs, setShowMobileTabs] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("By-Product");
  const [overView, setOverView] = useState(true);

  const convertDate = (date) => {
    const inputDate = new Date(date);

    // Format the date as "jul 11 2023"
    const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
    const formattedDate = months[inputDate.getMonth()] + " " + inputDate.getDate() + " " + inputDate.getFullYear();
    return formattedDate;
  };
  const getChartData = () => {
    const filter = {
      location: locationFields?.value,
      dates: {
        from: requestDateTime(fromDate),
        to: requestDateTime(toDate),
      },
    };
    axios
      .get(process.env.REACT_APP_API_URL + `/reports/sales-widgets-chart/${shop_id}`, {
        withCredentials: true,
        headers: { filters: JSON.stringify(filter) },
      })
      .then((res) => {
        setIsLoading(false);
        // Mean & data
        const discountLabel = res.data.discount.map((data) => data.label);
        const discountData = res.data.discount.map((data) => data.discount);
        const discountMean = res.data.discount.map((data) => data.mean);

        const numOfSalesData = res.data.numOfSales.map((data) => data.numOfSales);
        const numOfSalesDataMean = res.data.numOfSales.map((data) => data.mean);

        const soldProductsData = res.data.soldProducts.map((data) => data.soldProducts);
        const soldProductsDataMean = res.data.soldProducts.map((data) => data.mean);

        const totalSalesValueData = res.data.totalSalesValue.map((data) => data.totalSalesValue);
        const totalSalesValueDataMean = res.data.totalSalesValue.map((data) => data.mean);
        // Inc & dec
        const salesValueTrendInc = res.data.totalSalesValue.filter((data) => data.trend === "increase");
        const salesValueTrendDec = res.data.totalSalesValue.filter((data) => data.trend === "decrease");
        const soldProductsDataInc = res.data.soldProducts.filter((data) => data.trend === "increase");
        const soldProductsDataDec = res.data.soldProducts.filter((data) => data.trend === "decrease");
        const numOfSalesDataInc = res.data.numOfSales.filter((data) => data.trend === "increase");
        const numOfSalesDataDec = res.data.numOfSales.filter((data) => data.trend === "decrease");
        const discountDataInc = res.data.discount.filter((data) => data.trend === "increase");
        const discountDataDec = res.data.discount.filter((data) => data.trend === "decrease");

        // const Netloss = res.data.netProfit.map((data) => data.netProfit);
        setChartData({
          SalesValue: {
            labels: discountLabel,
            Trend: salesValueTrendInc.length > salesValueTrendDec.length ? "up" : "down",
            datasets: [
              {
                label: "Total Sales Value",
                data: totalSalesValueData,
                backgroundColor: "rgba(175, 69, 212, 1)",
                borderColor: "rgba(175, 69, 212, 1)",
              },
              {
                label: "Mean",
                data: totalSalesValueDataMean,
                backgroundColor: "rgb(75, 192, 192)",
                borderColor: "rgb(75, 192, 192)",
              },
            ],
          },
          TotalProductSold: {
            labels: discountLabel,
            Trend: soldProductsDataInc.length > soldProductsDataDec.length ? "up" : "down",
            datasets: [
              {
                label: "Total Sold Products",
                data: soldProductsData,
                backgroundColor: "rgba(89, 50, 234, 0.5)",
                borderColor: "rgba(16, 1, 70, 1)",
              },
              {
                label: "Mean",
                data: soldProductsDataMean,
                backgroundColor: "rgb(75, 192, 192)",
                borderColor: "rgb(75, 192, 192)",
              },
            ],
          },
          TotalDiscount: {
            labels: discountLabel,
            Trend: discountDataInc.length > discountDataDec.length ? "up" : "down",

            datasets: [
              {
                label: "Discount",
                data: discountData,
                backgroundColor: "rgba(89, 50, 234, 0.5)",
                borderColor: "rgba(225, 102, 255, 1)",
              },
              {
                label: "Mean",
                data: discountMean,
                backgroundColor: "rgb(75, 192, 192)",
                borderColor: "rgb(75, 192, 192)",
              },
            ],
          },
          NumberOfSales: {
            labels: discountLabel,
            Trend: numOfSalesDataInc.length > numOfSalesDataDec.length ? "up" : "down",

            datasets: [
              {
                label: "Number Of Sales",
                data: numOfSalesData,
                backgroundColor: "rgba(89, 50, 234, 1)",
                borderColor: "rgba(89, 50, 234, 1)",
              },
              {
                label: "Mean",
                data: numOfSalesDataMean,
                backgroundColor: "rgb(75, 192, 192)",
                borderColor: "rgb(75, 192, 192)",
              },
            ],
          },
        });
      })
      .catch((err) => {
        setIsLoading(false);
        // throw new Error(err);
      });
  };
  useEffect(() => {
    axios
      .post(process.env.REACT_APP_API_URL + `/location/get-location-by-user/${userId}`, { shop_id: shop_id, user_type: user_type }, { withCredentials: true })
      .then((res) => {
        const locationsArr = res.data.map((loc) => ({
          value: loc.location_id,
          label: loc.location,
          isDisabled: new Date().toISOString() >= loc.active_till ? true : false,
        }));

        setLocation(locationsArr);
        setLocationFields({
          value: locationsArr[0]?.value,
          label: locationsArr[0]?.label,
          isDisabled: locationsArr[0]?.isDisabled,
        });
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, [userId, shop_id, user_type]);
  useEffect(() => {
    setIsProductLoading(true);
    axios
      .post(
        process.env.REACT_APP_API_URL + `/inventory/get-inventory/${user.user_id}`,
        {
          location_nick: locationFields.label,
          category_name: category?.value ? category?.label : null,
          brand: brand?.value ? brand?.value : null,
          isAvailableOnly: true,
        },
        { withCredentials: true },
      )
      .then((res) => {
        const data = res.data.data
          .map((item) => {
            return {
              value: item.id,
              label: item.title,
            };
          })
          .filter((i) => {
            return i.label.toLowerCase().includes(filterInput.toLowerCase());
          });
        data.splice(0, 0, { label: "All", value: null });
        setProcuctOptions(data);
        setIsProductLoading(false);
      })
      .catch((err) => {
        setIsProductLoading(false);
        // throw new Error(err);
      });
  }, [userId, shop_id, filterInput, category, brand, locationFields]);

  useEffect(() => {
    const getReport = () => {
      const filter = {
        location: locationFields?.value,
        dates: { from: requestDateTime(fromDate), to: requestDateTime(toDate) },
      };
      axios
        .get(process.env.REACT_APP_API_URL + `/reports/sales-widgets/${shop_id}`, {
          withCredentials: true,
          headers: { filters: JSON.stringify(filter) },
        })
        .then((res) => {
          setData(res.data.success.data.salesWidgetsData);
        })
        .catch((err) => {
          throw new Error(err);
        });
    };
    if (locationFields?.value && shop_id) {
      setIsLoading(true);
      getChartData();
      getReport();
    }
  }, [shop_id, fromDate, toDate, locationFields]);

  const resetFilterHandle = (e) => {
    setFromDate(moment().subtract(29, "days").startOf("day")._d);
    setToDate(moment().endOf("day")._d);
    setBrand({});
    setProcuct({});
    setCategory({});
    setCustomer({});
    setSalesperson({});
    setPaymentType({});
    // setLocationFields(location[0]);
  };
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const scrollX = window.scrollX;
  const scrollY = window.scrollY;

  useLayoutEffect(() => {
    window.scrollTo(scrollX, scrollY);
  });
  const handleClose = () => {
    setShowMobileTabs(false);
  };
  const handleShow = () => {
    setShowMobileTabs(true);
  };

  const handleLocation = (e) => {
    if (e.value) {
      setLocationFields(e);
      setProcuct({});
    } else {
      setLocationFields({});
    }
  };
  return (
    <>
      <Row className="align-items-baseline  bg-white py-2 px-1 d-flex  mb-3 ">
        <div className="w-100 d-xl-flex d-lg-grid d-md-grid d-sm-grid d-none  ">
          <div className=" d-flex mb-2 ">
            <div style={{ minWidth: "174px" }} className="me-3 ">
              <SearchableSelect placeholder="Select Location" searchable={false} options={location} handleChange={handleLocation} value={Object.keys(locationFields).length > 0 ? locationFields : null} style={{ width: "100px" }} disabled={!location.length} />
            </div>
            <div style={{ minWidth: "174px" }} className="me-3 ">
              <DatePicker fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
            </div>

            {/* </div> */}
            <div style={{ minWidth: "100px" }} className="me-3 w-100">
              <Button size="sm" type="submit" className="" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
                <span className=" d-lg-inline">Reset Filters</span>{" "}
              </Button>
            </div>
          </div>
        </div>
      </Row>
      {isLoading ? (
        <div style={{ height: "50vh" }} className="">
          <LoaderPage />
        </div>
      ) : (
        <>
          {" "}
          <Row className="py-1 mb-5 bg-white">
            <div className="p-2 fw-bold fs-5 d-flex align-items-baseline">
              Sales Report <p className="text-muted fs-16 ms-2">{`${convertDate(fromDate)}` === `${convertDate(toDate)}` ? convertDate(toDate) : `${convertDate(fromDate)} - ${convertDate(toDate)}`} </p>{" "}
            </div>
            <Col xl="6" lg="6" md="6" className="bg-white">
              <div className=" d-grid bg-white">
                <Card className={`${style.cards} border-0 py-2 w-100  reports-card`}>
                  <span className="border-bottom text-start px-2">Total product sold</span>

                  <div className=" d-flex ms-4 flex-md-row flex-column">
                    <hr className={`${style.LegendsLinePsold} me-3 `} width="5" size="40" />

                    <div className="text-md-start text-center w-100 ">
                      <Card.Text className={` ${style.cardstxt} h-100  d-grid mt-3`}>
                        <div className="d-flex justify-content-between ">
                          <div className="d-grid">
                            <span className={`${style.cardstitle}`}>
                              {moneyToMillions(data?.sold_products) ? moneyToMillions(data?.sold_products) : "--"}
                              <span className="mx-1">Items</span>
                            </span>
                          </div>
                          {chartData?.TotalProductSold?.Trend === "up" ? <FontAwesomeIcon style={{ height: "25px", width: "25px" }} className="me-3" icon={faArrowTrendUp} /> : <FontAwesomeIcon style={{ height: "25px", width: "25px", color: "red" }} className="me-3" icon={faArrowTrendDown} />}
                        </div>
                      </Card.Text>
                    </div>
                  </div>
                  <div className={`${style.item}`}>
                    <LineChart data={chartData.TotalProductSold} />
                  </div>
                </Card>
              </div>
            </Col>
            <Col xl="6" lg="6" md="6" className="mb-2">
              <div className="h-100 d-grid">
                <Card className={`${style.cards} border-0 w-100 mx-2  reports-card`}>
                  <span className="border-bottom text-start px-2">Total sales value</span>

                  <div className=" d-flex  ms-4 flex-md-row flex-column">
                    <hr className={`${style.LegendsLineTsales} me-3 `} width="5" size="40" />
                    <div className="text-md-start  mb-3 text-start w-100">
                      <Card.Text className={` ${style.cardstxt} h-100  d-grid  mt-3`}>
                        <div className="d-flex justify-content-between ">
                          <div className="d-grid">
                            <span className={`${style.cardstitle}`}>Rs {moneyToMillions(data?.sales_value) ? moneyToMillions(data?.sales_value) : "--"}</span>
                            <span className="text-muted fs-14 ">Exact Rs {data?.sales_value ? data?.sales_value : "--"} </span>
                          </div>
                          {chartData?.SalesValue?.Trend === "up" ? <FontAwesomeIcon style={{ height: "25px", width: "25px" }} className="me-3" icon={faArrowTrendUp} /> : <FontAwesomeIcon style={{ height: "25px", width: "25px", color: "red" }} className="me-3" icon={faArrowTrendDown} />}
                        </div>
                      </Card.Text>
                    </div>
                  </div>
                  <div className={`${style.item}`}>
                    <LineChart data={chartData.SalesValue} />
                  </div>
                </Card>
              </div>
            </Col>
            <Col xl="6" lg="6" md="6" className="bg-white">
              <div className=" d-grid bg-white">
                <Card className={`${style.cards} border-0 py-2 w-100 reports-card`}>
                  <span className="border-bottom text-start px-2">Number of sales</span>

                  <div className=" d-flex ms-4 flex-md-row flex-column">
                    <hr className={`${style.LegendsLineNoSales} me-3 `} width="5" size="40" />

                    <div className="text-md-start text-center w-100">
                      <Card.Text className={` ${style.cardstxt} h-100 d-grid mt-3`}>
                        <div className="d-flex justify-content-between ">
                          <div className="d-grid">
                            <span className={`${style.cardstitle}`}>
                              {moneyToMillions(data?.number_of_sales) ? moneyToMillions(data?.number_of_sales) : "--"}
                              <span className="mx-1">Sales</span>
                            </span>
                          </div>
                          {chartData?.NumberOfSales?.Trend === "up" ? <FontAwesomeIcon style={{ height: "25px", width: "25px" }} className="me-3" icon={faArrowTrendUp} /> : <FontAwesomeIcon style={{ height: "25px", width: "25px", color: "red" }} className="me-3" icon={faArrowTrendDown} />}
                        </div>
                      </Card.Text>
                    </div>
                  </div>
                  <div className={`${style.item}`}>
                    <LineChart data={chartData.NumberOfSales} />
                  </div>
                </Card>
              </div>
            </Col>
            <Col xl="6" lg="6" md="6" className="bg-white">
              <div className=" d-grid bg-white">
                <Card className={`${style.cards} border-0 w-100 mx-2 py-2 pb-3 sreports-card`}>
                  <span className="border-bottom text-start px-2">Total Discount given</span>

                  <div className=" d-flex ms-4 flex-md-row flex-column">
                    <hr className={`${style.LegendsLineDiscount} me-3 `} width="5" size="40" />

                    <div className="text-md-start  w-100 text-center">
                      <Card.Text className={` ${style.cardstxt} h-100 mt-3 d-grid`}>
                        <div className="d-flex justify-content-between ">
                          <div className="d-grid">
                            <span className={`${style.cardstitle}`}>Rs {moneyToMillions(data?.discount) ? moneyToMillions(data?.discount) : "--"}</span>
                            <span className="text-muted fs-14 ">Exact Rs {data?.discount ? data?.discount : "--"} </span>
                          </div>
                          {chartData?.TotalDiscount?.Trend === "up" ? <FontAwesomeIcon style={{ height: "25px", width: "25px" }} className="me-3" icon={faArrowTrendUp} /> : <FontAwesomeIcon style={{ height: "25px", width: "25px", color: "red" }} className="me-3" icon={faArrowTrendDown} />}
                        </div>
                      </Card.Text>
                    </div>
                  </div>
                  <div className={`${style.item}`}>
                    <LineChart data={chartData.TotalDiscount} />
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center mt-1">{activeTab === "bySalesperson" ? <Col className="mb-2" md="4" sm="4" lg="3" xl="2"></Col> : ""}</Row>
          <Row className=" w-100 bg-white ms-1 p-2 mb-2 overflow-auto text-nowrap ob ">
            <Button onClick={handleShow} variant="outline-primary" className={`${filterStyle.filterToggleButton} d-flex d-md-none d-lg-none d-sm-none justify-content-between align-items-center w-100`}>
              <span className=" ms-2 d-lg-inline d-sm-inline d-inline ">{activeTab.replaceAll("-", " ")}</span>
              <img className="" src={filterr} alt="filter" />
            </Button>
            <Col xl="11" lg="11" md="12" className="d-none d-lg-flex d-md-flex d-sm-flex">
              <Button
                onClick={() => {
                  // settableData(salesPaymentData)
                  handleTabChange("By-Product");
                }}
                className={`${styles.btn} ${activeTab === "By-Product" ? styles.activebtn : ""}  mb-2 mx-2 rounded-5 `}
                variant="outline-primary"
              >
                By Product
              </Button>
              <Button
                // disabled={true}
                onClick={() => {
                  // settableData(vendorPaymentsData)
                  handleTabChange("By-Category");
                }}
                className={`${styles.btns} ${activeTab === "By-Category" ? styles.activebtn : ""}  mb-2 mx-2 rounded-5`}
                variant="outline-primary"
              >
                By Category
              </Button>
              <Button
                onClick={() => {
                  // settableData(Vendor Wise Stock ReportsData)
                  handleTabChange("By-Location");
                }}
                className={`${styles.btns} ${activeTab === "By-Location" ? styles.activebtn : ""}  mb-2 mx-2 rounded-5`}
                variant="outline-primary"
              >
                By Location
              </Button>
              <Button
                onClick={() => {
                  // settableData(refundsData)
                  handleTabChange("By-Customer");
                }}
                className={`${styles.btns} ${activeTab === "By-Customer" ? styles.activebtn : ""} mb-2 mx-2 rounded-5`}
                variant="outline-primary"
              >
                By Customer
              </Button>
              <Button
                onClick={() => {
                  // settableData(refundsData)
                  handleTabChange("By-Salesperson");
                }}
                className={`${styles.btns} ${activeTab === "By-Salesperson" ? styles.activebtn : ""} mb-2 mx-2 rounded-5`}
                variant="outline-primary"
              >
                By Salesperson
              </Button>{" "}
              <Button
                onClick={() => {
                  // settableData(refundsData)
                  handleTabChange("By-Bank/Cash/Credit");
                }}
                className={`${styles.btns} ${activeTab === "By-Bank/Cash/Credit" ? styles.activebtn : ""} mb-2 mx-2 rounded-5`}
                variant="outline-primary"
              >
                By Bank/Cash/Credit
              </Button>{" "}
              <Button
                onClick={() => {
                  // settableData(refundsData)
                  handleTabChange("Trend");
                }}
                className={`${styles.btns} ${activeTab === "Trend" ? styles.activebtn : ""} mb-2 mx-2 rounded-5`}
                variant="outline-primary"
              >
                Trend
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="bg-white mb-1 p-3 w-100 fs-14 d-flex fw-bold">
                <div className="w-100 fs-20"> {activeTab.replaceAll("-", " ")}</div>
                {activeTab === "By-Category" && (
                  <div style={{ minWidth: "270px" }} className="me-3 ">
                    <ReactSelect
                      placeholder="Select Category"
                      searchable={false}
                      value={Object.keys(category).length > 0 ? category : null}
                      options={() => {
                        return new Promise((resolve, reject) => {
                          axios
                            .get(process.env.REACT_APP_API_URL + "/categories/get", {
                              withCredentials: true,
                            })
                            .then((res) => {
                              const getData = res.data.map((cat) => ({
                                value: cat.id,
                                label: cat.category,
                              }));
                              const result = getData.filter((cat) => cat.value < 7);
                              result.splice(0, 0, { label: "All", value: null });
                              resolve(result);
                            })
                            .catch((err) => {
                              reject("err" + err);
                            });
                        });
                      }}
                      handleChange={(e) => {
                        if (e.value) {
                          setCategory(e);
                        } else {
                          setCategory({});
                        }
                      }}
                    />
                  </div>
                )}

                <div className=" d-flex">
                  {activeTab === "By-Customer" && (
                    <div style={{ minWidth: "274px" }} className="me-3">
                      <ReactSelect
                        placeholder="Select Customer"
                        searchable={true}
                        options={(e) => {
                          return new Promise((resolve, reject) => {
                            axios
                              .get(process.env.REACT_APP_API_URL + "/customer/shop/" + shop_id, { withCredentials: true })
                              .then((res) => {
                                const data = res.data
                                  .map((item) => {
                                    return {
                                      label: item.customer_name,
                                      value: item.customer_id,
                                    };
                                  })
                                  .filter((i) => {
                                    return i.label.toLowerCase().includes(e.toLowerCase());
                                  });
                                data.splice(0, 0, { label: "All", value: null });
                                resolve(data);
                              })
                              .catch((err) => {
                                reject("err" + err);
                              });
                          });
                        }}
                        handleChange={(e) => {
                          if (e.value) {
                            setCustomer(e);
                          } else {
                            setCustomer({});
                          }
                        }}
                        value={Object.keys(customer).length > 0 ? customer : null}
                        onInputChange={(newInput) => {
                          const inputValue = newInput.replace(/\s+/g, " ").trim();
                          return inputValue;
                        }}
                      />
                    </div>
                  )}
                  {activeTab === "By-Bank/Cash/Credit" && (
                    <div style={{ minWidth: "220px" }} className="me-3 w-100">
                      <SearchableSelect
                        placeholder="Select Bank/Cash/Credit"
                        options={[
                          { value: null, label: "All" },
                          { value: "Card", label: "Debit/Credit Card" },
                          { value: "Cash", label: "Cash" },
                          { value: "Transfer", label: "Transfer" },
                        ]}
                        handleChange={(e) => {
                          if (e.value) {
                            setPaymentType(e);
                          } else {
                            setPaymentType({});
                          }
                        }}
                        value={Object.keys(paymentType).length > 0 ? paymentType : null}
                        onInputChange={(newInput) => {
                          const inputValue = newInput.replace(/\s+/g, " ").trim();
                          setFilterInput(inputValue);
                          return inputValue;
                        }}
                      />
                    </div>
                  )}
                  {activeTab === "By-Product" && (
                    <div style={{ minWidth: "174px" }} className="me-3 ">
                      <SearchableSelect
                        placeholder="Select Product"
                        options={productOptions}
                        isLoading={isProductLoading}
                        handleChange={(e) => {
                          if (e.value) {
                            setProcuct(e);
                          } else {
                            setProcuct({});
                          }
                        }}
                        value={Object.keys(product).length > 0 ? product : null}
                        onInputChange={(newInput) => {
                          // const inputValue = newInput.replace(/\s+/g, " ").trim();
                          setFilterInput(newInput);
                          return newInput;
                        }}
                      />
                    </div>
                  )}
                  {activeTab === "By-Salesperson" && (
                    <div style={{ minWidth: "320px" }} className="me-3 w-100">
                      <ReactSelect
                        placeholder="Select Salesperson"
                        searchable={true}
                        options={(e) => {
                          return new Promise((resolve, reject) => {
                            axios
                              .get(process.env.REACT_APP_API_URL + "/user/shop/" + shop_id, { withCredentials: true })
                              .then((res) => {
                                const data = res.data
                                  .map((item) => {
                                    return {
                                      label: item.user_full_name,
                                      value: item.user_id,
                                    };
                                  })
                                  .filter((i) => {
                                    return i.label.toLowerCase().includes(e.toLowerCase());
                                  });
                                data.splice(0, 0, { label: "All", value: null });
                                resolve(data);
                              })
                              .catch((err) => {
                                reject("err" + err);
                              });
                          });
                        }}
                        handleChange={(e) => {
                          if (e.value) {
                            setSalesperson(e);
                          } else {
                            setSalesperson({});
                          }
                        }}
                        value={Object.keys(salesperson).length > 0 ? salesperson : null}
                        onInputChange={(newInput) => {
                          const inputValue = newInput.replace(/\s+/g, " ").trim();
                          return inputValue;
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              {activeTab === "By-Product" ? (
                <SalesReportTab filter={{ marginReport: true }} shopId={shop_id} fromDate={fromDate} toDate={toDate} location={locationFields} category={category} product={product} activeTab={activeTab} />
              ) : activeTab === "By-Category" ? (
                <SalesReportTab filter={{ categoryWise: true }} shopId={shop_id} fromDate={fromDate} toDate={toDate} location={locationFields} category={category} activeTab={activeTab} />
              ) : activeTab === "By-Location" ? (
                <SalesReportTab filter={{ locationWise: true }} shopId={shop_id} fromDate={fromDate} toDate={toDate} location={locationFields} activeTab={activeTab} />
              ) : activeTab === "By-Customer" ? (
                <SalesReportTab filter={{ customerWise: true }} shopId={shop_id} fromDate={fromDate} toDate={toDate} location={locationFields} customer={customer} activeTab={activeTab} />
              ) : activeTab === "By-Salesperson" ? (
                <SalesReportTab filter={{ salePersonWise: true }} shopId={shop_id} fromDate={fromDate} toDate={toDate} location={locationFields} salesperson={salesperson} activeTab={activeTab} />
              ) : activeTab === "By-Bank/Cash/Credit" ? (
                <SalesReportTab filter={{ paymentWise: true }} shopId={shop_id} fromDate={fromDate} toDate={toDate} location={locationFields} paymentType={paymentType} activeTab={activeTab} />
              ) : activeTab === "Trend" ? (
                <SalesTrendReportTab filter={{ trendWise: true }} shopId={shop_id} fromDate={fromDate} toDate={toDate} location={locationFields} category={category} brand={brand} activeTab={activeTab} />
              ) : (
                ""
              )}
            </Col>
          </Row>
        </>
      )}
      <MobileSubReportsTabs tabs={["By-Product", "By-Category", "By-Location", "By-Customer", "By-Salesperson", "By-Bank/Cash/Credit", "Trend"]} activeTAb={activeTab} handleTabChange={handleTabChange} show={showMobileTabs} handleClose={handleClose} />
      <MobilrReportsFilter
        setFilterInput={setFilterInput}
        product={product}
        setSalesperson={setSalesperson}
        setPaymentType={setPaymentType}
        paymentType={paymentType}
        salesperson={salesperson}
        setProcuct={setProcuct}
        customer={customer}
        setCustomer={setCustomer}
        productOptions={productOptions}
        category={category}
        setCategory={setCategory}
        shop_id={shop_id}
        secondaryActiveTab={activeTab}
        handleLocation={handleLocation}
        location={location}
        locationFields={locationFields}
        setLocationFields={setLocationFields}
        activeTab={MainactiveTab}
        show={show}
        handleClose={close}
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
      />
    </>
  );
};

export default SalesReports;
