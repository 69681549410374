import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Row, Table } from "react-bootstrap";
import ReactSelect from "../../Components/ReactSelect";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import SearchableSelect from "../../Components/SearchableSelect";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faPlus, faXmark, faCircleUser } from "@fortawesome/free-solid-svg-icons";
import styleModal from "../../styles/Modal.module.css";
import style from "../../styles/SearchInput.module.css";
import Loader from "../../Components/Loader";
import customloader from "./../../assets/images/RollingLoader.gif";
import PopUpModal from "../../Components/Modal";
import { getDefaultImage } from "../../Components/InventoryForms/utility";
import OpeningRegister from "../../Components/Register/OpeningRegister";
import { setLocation } from "../../Features/locationSlice";
import search from "../../assets/svg/search.svg";
const AddTradeInProduct = ({ close, openingBalances, refresh, locations }) => {
  const { user } = useSelector((state) => state.auth);
  const register = useSelector((state) => state.location?.register);
  const registerID = register?.registerId;
  const location = useSelector((state) => state.location?.location);
  const dispatch = useDispatch();
  const shop_id = user.shop_id;
  const [techcategory, setTechCategory] = useState();
  const [condition, setCondition] = useState({ label: "Used", value: "Used" });
  const [customer, setCustomer] = useState({});
  const [customerFields, setCustomerFields] = useState([]);
  const [entryDate, setEntryDate] = useState(new Date().toISOString().split("T")[0]);
  const [tradeInAmount, setTradeInAmount] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [note, setNote] = useState("");
  const [bank, setBank] = useState(null);
  const [cheque_number, setCheque_number] = useState(null);
  const [totalSalesAmount, setTotalSalesAmount] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [error, setError] = useState("");
  const [registerClosedAlert, setRegisterClosedAlert] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [openingBalance, setOpeningBalance] = useState();
  const [isValidate, setIsValidated] = useState(false);
  const [rows, setRows] = useState([
    {
      id: 1,
      category: null,
      title: "",
      quantity: "",
      costPrice: "",
      salePrice: "",
      image: null,
    },
  ]);
  const [customerSearch, setCustomerSearch] = useState(""); //setCustomerSearch
  const addRow = () => {
    const newRow = {
      id: rows.length + 1,
      category: null,
      title: "",
      quantity: "",
      costPrice: "",
      salePrice: "",
      image: null,
    };
    setRows([...rows, newRow]);
  };
  const deleteRow = (index) => {
    let items = [...rows];
    items.splice(index, 1);
    setRows(items);
  };
  const handleEntryDateChange = (e) => {
    setEntryDate(e.target.value);
  };
  const handlePaymentTypeChange = (e) => {
    if (e === null) {
      setPaymentType();
    } else {
      setPaymentType({ label: e.value, value: e.value });
    }
  };
  const handleChequeNumberChange = (e) => {
    if (e.target.value.length < 20) {
      setCheque_number(e.target.value);
    }
  };
  const getCat = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/categories/get", {
          withCredentials: true,
        })
        .then((res) => {
          const getData = res.data
            .filter((cat) => cat.id !== 7)
            .map((cat) => ({
              value: cat.id,
              label: cat.category,
            }));
          resolve(getData);
          setTechCategory(getData);
        })
        .catch((err) => {
          reject("err" + err);
        });
    });
  };
  const [cashInHand, setCashInHand] = useState();

  useEffect(() => {
    // setLoading(true);
    const cashInHand = () => {
      axios
        .post(
          process.env.REACT_APP_API_URL + `/reports/cash-in-hand/${user?.user_id}`,
          {
            dates: {
              from: register?.openingBalance?.opening_balance_time.split(".")[0].replace("T", " "),
              to: register?.openingBalance?.closing_balance_time ? register?.openingBalance?.closing_balance_time.split(".")[0].replace("T", " ") : null,
            },
            locationFields: location,
            openingBalance: register?.openingBalance?.opening_balance_id,
          },
          { withCredentials: true }
        )
        .then((res) => {
          let n = res?.data?.length;
          setCashInHand(res.data[n - 1]);
          // setIsLoading(false);
          // setLoading(false);
        })
        .catch((err) => {
          throw new Error(err.response.data);
        });
    };
    if (Object.keys(location).length > 0 && register?.openingBalance) cashInHand();
  }, [location, register?.openingBalance]);
  // useEffect(() => {
  //   const res = axios
  //     .get(process.env.REACT_APP_API_URL + `/trade-in/customers/${shop_id}`, {
  //       withCredentials: true,
  //     })
  //     .then((res) => {
  //       const getData = res?.data?.data?.map((customer) => ({
  //         value: customer?.id,
  //         label: customer?.customer_name,
  //         // label: (
  //         //   <>
  //         //     <span style={{ fontSize: "12px" }}>
  //         //       Customer Name: <strong className="fs-12">{customer?.customer_name}</strong>
  //         //     </span>
  //         //     <br />
  //         //     <span style={{ fontSize: "12px" }}>Customer CNIC: {customer?.CNIC}</span>
  //         //     <br />
  //         //     <span className="d-block" style={{ fontSize: "12px" }}>
  //         //       Customer Number:{customer?.customer_number}
  //         //     </span>
  //         //   </>
  //         // ),
  //         // name: customer.customer_name,
  //       }));
  //       setCustomerFields(getData);
  //     })
  //     .catch((err) => {
  //       throw new Error(err);
  //     });
  // }, [shop_id, customerSearch]);
  useEffect(() => {
    const getCustomer = async () => {
      // let searchObj = customerSearch;
      // const filters = {
      //   search: searchInput,
      // };
      const headers = {
        filters: JSON.stringify({ search: customerSearch }),
      };
      const res = await axios.get(process.env.REACT_APP_API_URL + `/trade-in/customers/${shop_id}`, { headers, withCredentials: true });
      setCustomerFields(res?.data?.data);
    };
    const timeout = setTimeout(() => {
      getCustomer();
    }, 300);

    return () => clearTimeout(timeout);
  }, [customerSearch, shop_id]);
  const loadBanksOptions = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + `/shops/banks/${shop_id}`, {
          withCredentials: true,
        })
        .then((banksRes) => {
          const banks = banksRes.data.map((bank) => ({
            value: bank,
            label: (
              <>
                <span style={{ fontSize: "12px" }}>
                  Bank Name: <strong className="fs-12">{bank?.bank_name}</strong>
                </span>
                <br />
                <span style={{ fontSize: "12px" }}>Account Title: {bank?.account_title}</span>
                <br />
                <span className="d-block" style={{ fontSize: "12px" }}>
                  Account Number:{bank?.account_number}
                </span>
              </>
            ),
            name: bank.bank_name,
          }));
          const defaultBank = banks.filter((bank) => bank.value.default_business_account === 1);
          if (defaultBank[0]) {
            setBank({ value: defaultBank[0].value, label: defaultBank[0].name });
          }
          resolve(banks);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const handleChangeBank = (e) => {
    setBank({ value: e.value, label: e.name });
  };
  useEffect(() => {
    const totalPrice = rows.reduce((totalPrice, row) => {
      const price = +row.costPrice;
      const quantity = +row.quantity;
      const itemPrice = price * quantity;
      return totalPrice + itemPrice;
    }, 0);
    setTradeInAmount(totalPrice);
  }, [rows]);
  useEffect(() => {
    const totalCostPrice = rows.reduce((totalCostPrice, row) => {
      const price = +row.salePrice;
      const quantity = +row.quantity;
      const itemPrice = price * quantity;
      return totalCostPrice + itemPrice;
    }, 0);
    setTotalSalesAmount(totalCostPrice);
  }, [rows]);

  const handleChangeSelect = (event, index, property) => {
    let items = [...rows];
    items[index][property] = event;
    setRows((prev) =>
      prev.map((value) => {
        if (value.index === index) {
          return {
            ...value,
            [property]: event,
          };
        } else {
          return value;
        }
      })
    );
  };
  const handleChangeProperty = (event, id, property) => {
    if (property === "quantity" || property === "costPrice" || property === "salePrice") {
      if (+event.target.value > 0) {
        let items = [...rows];
        items[id][property] = event.target.value;
        setRows(items);
      } else {
        let items = [...rows];
        items[id][property] = "";
        setRows(items);
      }
    } else {
      if (event.target.value.length < event.target.maxLength) {
        let items = [...rows];
        items[id][property] = event.target.value;
        setRows(items);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);
    if (paymentType?.label === "Cash" ? register?.isCashRegisterOpen : true) {
      setError("");
      if (paymentType?.label === "Cash" ? cashInHand?.cash_in_hand >= Number(tradeInAmount) : true) {
        setError("");
        const data = rows.map((item) => {
          return {
            ...item,
            image: getDefaultImage(item?.category?.label)[0],
          };
        });
        const products = {
          items: data,
          condition_id: 1,
          condition_item: condition?.label,
          location_id: locations?.value,
          customer_id: customer?.id,
          note,
          shop_id: shop_id,
        };
        const payment = {
          trade_in_amount: tradeInAmount ? tradeInAmount : "",
          totalSalesAmount: totalSalesAmount,
          payment_type: paymentType?.label ? paymentType?.label : "",
          bank_id: paymentType?.label === "Cash" ? -1 : bank?.value?.bank_id,
          cheque_number: cheque_number,
        };
        if (products?.items?.length > 0 && products?.condition_id && products?.condition_item && products?.location_id && products?.customer_id && payment?.trade_in_amount && payment?.payment_type && payment?.bank_id) {
          setIsClicked(true);
          const res = axios
            .post(process.env.REACT_APP_API_URL + `/trade-in/inventory`, { products, payment }, { withCredentials: true })
            .then(() => {
              setSuccessModal(true);
              setTimeout(() => {
                setSuccessModal(false);
                close();
              }, 2000);
              setIsClicked(false);
            })
            .catch((error) => {
              setisLoading(false);
              setIsClicked(false);
            });
        }
      } else {
        setRegisterClosedAlert(false);
        setError("Amount is more then the Cash in Hand!");
      }
    } else {
      setRegisterClosedAlert(true);
      setError("Register is not opened!");
    }
  };
  const handleCashOpeningBalance = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setIsValidated(true);
    setValidated(true);
    try {
      if (registerID === -1) {
        setError("Register is not Set!");
      } else {
        if (openingBalance >= 0) {
          const res = await axios.post(process.env.REACT_APP_API_URL + `/cash-registers/opening-balance`, { register_id: registerID, opening_balance: openingBalance }, { withCredentials: true });
          if (res) {
            dispatch(setLocation({ shop_id, location: location }));
            setIsValidated(false);
            setValidated(false);
            setRegisterClosedAlert(false);
            refresh();
            setError(false);
            setOpeningBalance();
            setTimeout(() => setOpenRegisterModal(false), 1000);
          }
        }
      }
    } catch (error) {
      setError(error);
    }
  };

  const currentMonth = new Date().toDateString().split(" ")[1];
  const currentDate = new Date().getDate() + " " + currentMonth + ", " + new Date().getFullYear();
  const today_sh = new Date();
  const currentTime = today_sh.getHours();
  let showtime;
  if (currentTime < 12) {
    showtime = "Good Morning";
  } else if (currentTime < 18) {
    showtime = "Good Afternoon";
  } else {
    showtime = "Good Evening";
  }
  const handleCustomerSearchInput = (e) => {
    e.preventDefault();
    setCustomerSearch(e.target.value);
  };
  const handleSelectCustomer = (data) => {
    setCustomer(data);
    setCustomerSearch("");
    setCustomerFields();
  };
  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "40vh" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : (
        <div className=" mx-3 mt-1">
          <Form onSubmit={handleSubmit} noValidate validated={validated}>
            <Row className="mb-5">
              {registerClosedAlert ? (
                <div className="alert alert-warning border-dotted" style={{ border: "2px dotted #8834ff" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    *{error}
                    <button
                      onClick={() => {
                        setOpenRegisterModal(true);
                      }}
                      className="btn btn-primary"
                    >
                      Open Register
                    </button>
                  </div>
                </div>
              ) : error ? (
                <div className="alert alert-warning border-dotted" style={{ border: "2px dotted #8834ff" }}>
                  *{error}
                </div>
              ) : (
                ""
              )}
              <Col className="py-3" sm="6" md={6}>
                <Form.Group className="mb-3" controlId="Condition">
                  <ReactSelect name="condition" placeholder="Select Condition" label="Condition" important searchable={false} value={condition} disabled={true} />
                  <Form.Control value={condition ? condition : ""} hidden onChange={() => {}} name="condition" required />
                  <Form.Control.Feedback type="invalid">Please Select Condition </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className="py-3" sm="6" md={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1">
                    Entry Date<span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control type="date" value={entryDate} onChange={handleEntryDateChange} placeholder="Select date" required />
                  <Form.Control.Feedback type="invalid">Please Select Entry Date.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className="py-3" sm="6" md={6}>
                <Form.Group>
                  <SearchableSelect important label={"Location"} placeholder="Select Location" value={location} disabled={true} required />
                  <Form.Control hidden type="text" name="location" value={location ? location : ""} onChange={() => {}} required />
                  <Form.Control.Feedback type="invalid">* Please Select Location</Form.Control.Feedback>
                </Form.Group>
              </Col>

              <div className="mt-3 position-relative col-md-6">
                <Form.Group className="mb-3" controlId="Customer">
                  <label className="mb-1">
                    Customer<span className="text-danger">*</span>
                  </label>
                  <Form.Control type="search" placeholder="Search" className={`${style.searchInput}`} aria-label="Search Customer" onChange={handleCustomerSearchInput} value={customerSearch} />
                  <Form.Control.Feedback type="invalid" style={validated ? (Object.keys(customer).length > 0 ? { display: "none" } : { display: "block" }) : { display: "none" }}>
                    * Please Select Customer
                  </Form.Control.Feedback>
                  <div className={`${style.searchIconTradeIn} d-flex`}>
                    <img src={search} alt="search" />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-6"></div>
              <div className="px-2  rounded-3 col-md-6">
                {customerSearch ? (
                  <Container
                    fluid
                    className="shadow p-2 mb-3"
                    style={{
                      maxHeight: "100px",
                      overflowX: "hidden",
                      overflowY: "scroll",
                    }}
                  >
                    {customerFields?.length > 0 ? (
                      <>
                        {customerFields.map((c, index) => (
                          <Row className="py-2 px-4" style={{ cursor: "pointer" }} onClick={() => handleSelectCustomer(c)} key={index}>
                            <Col className="border-bottom">
                              <span style={{ fontSize: "12px" }} className="my-1 d-block">
                                Name: <strong className="my-1">{c.customer_name}</strong>
                              </span>
                              <span style={{ fontSize: "12px" }} className="my-1 d-block">
                                CNIC: {c.CNIC}
                              </span>
                              <span style={{ fontSize: "12px" }} className="mb-1 d-block">
                                Number: {c.customer_number}
                              </span>
                              {c.customer_email !== "" && (
                                <span style={{ fontSize: "12px" }} className="my-1 d-block">
                                  Email: {c.customer_email}
                                </span>
                              )}
                            </Col>
                          </Row>
                        ))}
                      </>
                    ) : (
                      <Row className="text-center text-secondary p-2">
                        <span>No Customer.</span>
                      </Row>
                    )}
                  </Container>
                ) : (
                  ""
                )}
                <Card className="mb-4 border-0 mt-2 bg-white">
                  <Row style={{ background: "#F5F6FA" }} className="">
                    <Card.Body>
                      {Object.keys(customer).length > 0 ? (
                        <Row>
                          <div className="d-flex justify-content-between">
                            <div className="d-flex gap-2" lg="10" xs="11">
                              <div>
                                <FontAwesomeIcon
                                  className={`mb-2 rounded-circle text-white`}
                                  icon={faCircleUser}
                                  style={{
                                    width: "42px",
                                    height: "42px",
                                  }}
                                />
                              </div>
                              <div className="d-flex flex-column">
                                <Card.Title style={{ fontSize: "16px" }}>{customer?.customer_name}</Card.Title>
                                <span style={{ fontSize: "12px" }}>Phone: {customer?.customer_number}</span>
                                <span style={{ fontSize: "12px" }}>CNIC: {customer?.CNIC}</span>
                                {customer?.customer_email !== "" && (
                                  <span className="d-block" style={{ fontSize: "12px" }}>
                                    Email: {customer?.customer_email}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div>
                              <FontAwesomeIcon icon={faXmark} onClick={() => setCustomer({})} />
                            </div>
                          </div>
                        </Row>
                      ) : (
                        <Row className="text-center text-secondary p-1">
                          <span className="ms-1">No Customer Added.</span>
                        </Row>
                      )}
                    </Card.Body>
                  </Row>
                </Card>
              </div>
              <Col className="mt-3" md={12}>
                <Form.Group className="mb-3" controlId="price">
                  <Form.Label className="mb-0">Note</Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    rows={3}
                    placeholder="Write Note"
                    value={note}
                    onChange={(e) => {
                      setNote(e.target.value);
                    }}
                    name="note"
                    pattern="^([A-Za-z ]|[0-9])+$"
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="table-container" style={{ overflowX: "auto", height: "250px" }}>
              <Table style={{ minWidth: "800px", width: "100%" }}>
                <thead>
                  <tr>
                    <th>
                      <label className="text-muted fs-13">Category</label>
                    </th>
                    <th>
                      <label className="text-muted fs-13">Title</label>
                    </th>
                    <th>
                      <label className="text-muted fs-13">Quantity</label>
                    </th>
                    <th>
                      <label className="text-muted fs-13">Cost Price</label>
                    </th>
                    <th>
                      <label className="text-muted fs-13">Sale Price</label>
                    </th>
                    <th>
                      <label className="text-muted fs-13">Action</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, index) => (
                    <tr key={index}>
                      <td className={`${styleModal.bankDropdown}`}>
                        <Form.Group>
                          <ReactSelect
                            placeholder="Category"
                            value={row.category}
                            options={getCat}
                            handleChange={(e) => {
                              handleChangeSelect(e, index, "category");
                            }}
                          />
                          <Form.Control value={row.category ? row.category : ""} hidden onChange={() => {}} required />
                          <Form.Control.Feedback type="invalid">* Please Select Category</Form.Control.Feedback>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group className="mb-3 ">
                          <Form.Control
                            placeholder="Title"
                            type="text"
                            maxLength={30}
                            value={row.title}
                            onChange={(e) => {
                              handleChangeProperty(e, index, "title");
                            }}
                            name="title"
                            required
                          />
                          <Form.Control.Feedback type="invalid">* Enter Title</Form.Control.Feedback>
                        </Form.Group>
                      </td>
                      <td>
                        {" "}
                        <Form.Group className="mb-3 ">
                          <Form.Control
                            value={row.quantity}
                            maxLength={10}
                            isInvalid={row.quantity.length > 0 && Number(row?.quantity) === 0 ? true : false}
                            onChange={(e) => {
                              handleChangeProperty(e, index, "quantity");
                            }}
                            className=""
                            type="number"
                            required
                            placeholder="Quantity"
                            onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                          />
                          <Form.Control.Feedback type="invalid">* Enter Quantity</Form.Control.Feedback>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group className="mb-3 ">
                          <Form.Control
                            index={index}
                            value={row.costPrice}
                            maxLength={10}
                            isInvalid={row.costPrice.length > 0 && Number(row?.costPrice) === 0 ? true : false}
                            onChange={(e) => {
                              handleChangeProperty(e, index, "costPrice");
                            }}
                            className=""
                            type="number"
                            required
                            placeholder="Cost Price"
                            onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                          />
                          <Form.Control.Feedback type="invalid">* Enter Cost Price</Form.Control.Feedback>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group className="mb-3 ">
                          <Form.Control
                            value={row.salePrice}
                            maxLength={10}
                            isInvalid={row.salePrice.length > 0 && Number(row.salePrice) === 0 ? true : false}
                            onChange={(e) => {
                              handleChangeProperty(e, index, "salePrice");
                            }}
                            className=""
                            type="number"
                            required
                            placeholder="Sale Price"
                            onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                          />
                          <Form.Control.Feedback type="invalid">* Enter Sale Price</Form.Control.Feedback>
                        </Form.Group>
                      </td>
                      <td>
                        <div
                          style={
                            rows.length > 1
                              ? {}
                              : {
                                  pointerEvents: "none",
                                  opacity: "0.6",
                                  cursor: "pointer",
                                }
                          }
                        >
                          <FontAwesomeIcon
                            onClick={() => deleteRow(index)}
                            className="cursor-pointer"
                            style={{
                              color: "red",
                              height: "22px",
                              marginLeft: "0.6rem",
                              marginTop: "7px",
                            }}
                            icon={faXmark}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <Row className="text-end ">
              <Col>
                <Button onClick={addRow}>
                  <span>Add More</span>
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="py-3" sm="4" md={4}>
                <Form.Group className="mb-3">
                  <SearchableSelect
                    placeholder="Select"
                    label="Payment Type"
                    important
                    value={paymentType}
                    isClearable={true}
                    searchable={false}
                    options={[
                      { value: "Transfer", label: "Transfer" },
                      { value: "Cash", label: "Cash" },
                      { value: "Cheque", label: "Cheque" },
                    ]}
                    handleChange={handlePaymentTypeChange}
                  />
                  <Form.Control hidden value={paymentType} onChange={() => {}} required />
                  <Form.Control.Feedback type="invalid">* Enter Payment Type</Form.Control.Feedback>
                </Form.Group>
              </Col>
              {paymentType?.label === "Cash" || paymentType?.label === undefined || paymentType?.label === "" ? (
                ""
              ) : (
                <>
                  {paymentType?.label === "Cheque" ? (
                    <>
                      <Col className={`py-1 ${styleModal.bankDropdown}`}>
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-1 mt-2">
                            Select Bank <span className="text-danger">*</span>
                          </Form.Label>
                          <ReactSelect placeholder="Select" value={bank} options={loadBanksOptions} handleChange={handleChangeBank} searchable={false} />
                          <Form.Control value={bank} onChange={() => {}} hidden required={paymentType?.label === "Transfer" || paymentType?.label === "Cheque"} />
                          <Form.Control.Feedback type="invalid">* Please Select Bank.</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col className="py-2" md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-1 mt-1">Cheque Number</Form.Label>
                          <Form.Control type="text" className="p-2" value={cheque_number} onChange={handleChequeNumberChange} placeholder="Cheque Number" />
                        </Form.Group>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col className={`py-1 ${styleModal.bankDropdown}`} md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-1 mt-2">
                            Select Bank <span className="text-danger">*</span>
                          </Form.Label>
                          <ReactSelect placeholder="Select" value={bank} options={loadBanksOptions} handleChange={handleChangeBank} searchable={false} />
                          <Form.Control value={bank} onChange={() => {}} hidden required={paymentType.label === "Card" || paymentType.label === "Transfer"} />
                          <Form.Control.Feedback type="invalid">* Please Select Bank.</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </>
                  )}
                </>
              )}
            </Row>
            <Row>
              <Col className="py-3" sm="4" md={4}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">
                    Paid Amount
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control value={tradeInAmount} disabled={true} className="p-2" type="number" required placeholder="Rs. 500" />
                  <Form.Control.Feedback type="invalid">Please fill paid amount</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <div className="d-flex row mb-2 ">
              <Button disabled={isClicked} type="submit" className=" col-lg-2 col-6 me-3">
                {isLoading ? <Loader /> : "Add Trade-in"}
              </Button>
              <Button variant="outline-secondary border " disabled={isClicked} onClick={close} type="button" className="col-lg-2 col-5 ">
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      )}
      <PopUpModal title="Success" icon={<FontAwesomeIcon icon={faCircleCheck} />} text="Trade-in Product Added Succesfully" type={styleModal.successBg} iconModal="success" show={successModal} onHide={() => setSuccessModal(false)} />
      <OpeningRegister
        cashRegisterModal={openRegisterModal}
        setCashRegisterModal={setOpenRegisterModal}
        validated={isValidate}
        setIsValidate={setIsValidated}
        handleCashOpeningBalance={handleCashOpeningBalance}
        showtime={showtime}
        error={error}
        openingBalance={openingBalance}
        setOpeningBalance={setOpeningBalance}
      />
    </>
  );
};

export default AddTradeInProduct;
