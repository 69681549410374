import { Card, Form, Modal, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import style from "./card.module.css";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../../../Features/cart/cartSlice";
import noImage from "../../../../assets/svg/no-image.svg";
import { useState } from "react";
const ItemCard = ({ p, reserved }) => {
  const dispatch = useDispatch(false);
  const cart = useSelector((state) => state.cart);
  const [showModal, setShowModal] = useState(false);

  const handleAddToCart = (product, isModal = false) => {
    if (product.quantity > 0) {
      // const reservedItems = reserved.map((item) => item.item_id);
      // if (reservedItems.includes(p.id) && !isModal) {
      //   const reservedItem = reserved.find((item) => item.item_id === product.id);
      //   if (product.quantity === reservedItem.quantity) {
      //     setShowModal(true);
      //     return;
      //   }
      // }
      dispatch(addToCart(product));
      // setShowModal(false);
    }
  };
  return (
    <>
      <Card
        className={` ${style.cardbody} ${cart.cartItems.some((cartItem) => cartItem.id === p.id) ? style.disable : ""}`}
        onClick={() => {
          handleAddToCart(p);
        }}
      >
        {cart.cartItems.some((cartItem) => cartItem.id === p.id) && (
          <div className={`position-absolute text-end w-100 ${style.disbleCheck}`}>
            <Form.Check className="mt-1 me-2" type="checkbox" checked={cart.cartItems.some((cartItem) => cartItem.id === p.id) && true} />
          </div>
        )}
        <div className="h-100">
          <div className={`${style.imagediv}`} style={{ padding: "2px" }}>
            <Card.Img src={process.env.REACT_APP_URL_S3 + "/" + p.img0} alt="productImage" className={`mb-2  ${style.cardimg}`} onError={(e) => (e.target.src = noImage)} />
          </div>
          <div className={`d-flex flex-column justify-content-between ${style.cardContent}`}>
            <div className="d-none d-md-block">
              <OverlayTrigger placement="right" onClick={(e) => e.preventDefault()} overlay={<Tooltip id="tooltip-disabled">{p.title}</Tooltip>}>
                <Card.Title className={`mb-1  ${style.cardTitle}`}>
                  <p className={`${style.cardTitleWrap}`}>{p.title}</p>
                </Card.Title>
              </OverlayTrigger>
            </div>
            <div className="d-block d-md-none ">
              <Card.Title className={`mb-1  ${style.cardTitle}`}>
                <p className={`${style.cardTitleWrap}`}>{p.title}</p>
              </Card.Title>
            </div>
            <div>
              <Card.Text className={`${style.cardPrice} text-dark`}>
                <strong>Rs.{Number(p.sale_price).toLocaleString("en-IN")}</strong>
              </Card.Text>
              <Card.Title style={{ fontWeight: "100" }} className={`mb-2 text-dark ${style.cardAvailableItem} text-nowrap  ${Number(p.quantity) === 0 && "text-danger"}`}>
                {Number(p.quantity) === 0 ? "Sold Out" : `Qty: ${p.quantity}`}
              </Card.Title>
            </div>
          </div>
        </div>
      </Card>
      {/* Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} animation={true} centered>
        <Modal.Header>
          <Modal.Title>Reserved Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <span className="d-flex justify-content-center pb-4">{`${p.title} available quantity is reserved. Do you really want to sell it?`}</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            <Button
              className="w-100 rounded-3"
              onClick={() => {
                handleAddToCart(p, true);
              }}
            >
              Yes
            </Button>
            <span
              className="w-100 d-flex justify-content-center align-items-center text-secondary p-2 border-2 border-secondary"
              style={{
                cursor: "pointer",
                border: "2px solid rgba(151, 156, 162, 1)",
                borderRadius: "6px",
              }}
              onClick={() => {
                setShowModal(false);
              }}
            >
              No
            </span>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ItemCard;
