import styles from ".././../styles/SalesPage.module.css";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { addCustomer, removeCustomer, updateCartItems, updateDiscount } from "../../Features/cart/cartSlice";
import { emptyCart } from "./../../Features/cart/cartSlice";
import SalesPageItems from "../../Components/SalesPage/SalesPageItems";

const Sales = () => {
  const dispatch = useDispatch();

  const handleVisibilityChange = () => {
    // window.location.reload();
    const cart = localStorage.getItem("__cart") ? JSON.parse(localStorage.getItem("__cart")) : [];

    let valuees = cart?.cartItems;
    let colDisscount = cart?.collectiveDiscount;
    // let location = JSON.parse(localStorage.getItem("location"));
    let customer = cart?.customer;

    if (valuees) {
      dispatch(
        updateCartItems({
          cartItems: valuees,
        }),
      );
    } else {
      dispatch(emptyCart());
    }
    if (colDisscount) {
      dispatch(updateDiscount(colDisscount));
    }
    // if (location) {
    //   setLocationFields(location);
    // }
    if (customer) {
      dispatch(addCustomer(customer));
    } else {
      dispatch(removeCustomer());
    }
  };

  useEffect(() => {
    window.addEventListener("visibilitychange", handleVisibilityChange);
  }, []);

  return (
    <>
      <div className={`${styles.pageContainer} ms-3`}>
        <div className={styles.itemViewsSection}>
          <SalesPageItems />
        </div>
      </div>
    </>
  );
};
export default Sales;
