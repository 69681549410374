import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import style from './../styles/ResetPassword.module.css'
import { faEye, faEyeSlash, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios'
import Cookies from 'js-cookie';
import { authActions } from '../Features/authSlice';
import PopUpModal from '../Components/Modal';
import styleModal from './../styles/Modal.module.css';

const ResetPassword = () => {
    const { user } = useSelector(
        (state) => state.auth
    )
    const user_id = user.user_id;
    const [showOPassword, setShowOPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showcPassword, setShowcPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [modalShow, setModalShow] = useState(false);

    const logout = () => {
        dispatch(authActions.logout())
        localStorage.removeItem('refreshToken')
        Cookies.remove('accessToken')
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }
        setValidated(true);
        try {
            setIsClicked(true)
            const res = await axios.post(process.env.REACT_APP_API_URL + `/user/reset-password/${user_id}`, { password, newPassword, confirmPassword }, { withCredentials: true });
            setSuccess(res.data.success)
            setError('')
            setPassword("");
            setNewPassword("");
            setConfirmPassword("");
            setError("");
            // setSuccess("")
            setTimeout(()=> setSuccess(""), 1000)
            setModalShow(true)
            setTimeout(()=> setModalShow(false), 1000)
            setValidated(false);
            setTimeout(()=> setIsClicked(false), 1000)
            // if(res.data){
            //     setTimeout(logout(), 5000);
            // }
        } catch (error) {
            setIsClicked(true)
            setError(error.response.data.error.message)
            setSuccess('')
            // setTimeout(()=> setError(""), 1000)
            setTimeout(()=> setIsClicked(false), 1000)
        }
    }
    return (
        <div className={`d-flex justify-content-center align-items-center mb-4 ${style.addForm}`}>
            {<PopUpModal icon={<FontAwesomeIcon icon={faCircleCheck} />} text={success} type={styleModal.successBg} iconModal={styleModal.successIcon} show={modalShow} onHide={() => setModalShow(false)} />}
            <div className={`bg-white card border-0 py-4 ${style.form}`}>
                <div className={`${style.formContent}`}>
                    <div className="d-flex justify-content-between align-items-center px-5">
                        <h5>Reset Your Password</h5>
                    </div>
                    <hr />
                    <div className={`${style.formMain}`}>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            {error && <p className="text-danger my-2 text-center">{error}</p>}
                            {success && <p className="text-success my-2 text-center">{success}</p>}
                            <Form.Group className={`mb-4 ${style.passwordInput}`} controlId="price">
                                <Form.Label>Current Password</Form.Label>
                                <Form.Control type={showOPassword ? "text" : "password"}
                                    placeholder="******" value={password}
                                    name="currentPassword"
                                    onChange={(e) => setPassword(e.target.value)}
                                    style={{padding:'8px'}}
                                    required />
                                <div className={`${style.passwordField}`}>
                                    <FontAwesomeIcon className="text-secondary mt-1" icon={showOPassword ? faEye : faEyeSlash} onClick={() => setShowOPassword((a) => !a)} />
                                </div>
                                <Form.Control.Feedback type="invalid"> 
                                    * Please Enter Your Current password
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className={`mb-4 ${style.passwordInput}`}>
                                <Form.Label>New Password</Form.Label>
                                <Form.Control className="p-2"
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="******"
                                    required
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)} />
                                <div className={`${style.passwordField} `}>
                                    <FontAwesomeIcon className="text-secondary " icon={showPassword ? faEye : faEyeSlash} onClick={() => setShowPassword((a) => !a)} />
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    * Please enter new password
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className={`mb-4 ${style.passwordInput}`}>
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control className="p-2"
                                    name="cpassword"
                                    type={showcPassword ? "text" : "password"}
                                    placeholder="******"
                                    required
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)} />
                                <div className={`${style.passwordField}`}>
                                    <FontAwesomeIcon className="text-secondary" icon={showcPassword ? faEye : faEyeSlash} onClick={() => setShowcPassword((a) => !a)} />
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    * Please Confirm password
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Button disabled={isClicked} type='submit' className="w-100 my-2">Save</Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;