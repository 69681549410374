import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import Select from "../../Components/Select";
import { useSelector } from "react-redux";
import PhoneInput from "./../../Components/PhoneInput/PhoneInput";
import PopUpModal from "../../Components/Modal";
import styleModal from "./../../styles/Modal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

const AddCustomer = ({ close, getCustomer }) => {
  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  const [customer_name, setCustomerName] = useState("");
  const [customer_email, setCustomerEmail] = useState("");
  const [customer_number, setCustomerNumber] = useState("");
  const [customer_type, setCustomerType] = useState("");
  const [customer_comment, setCustomerComments] = useState("");
  const [success, setSucces] = useState("");
  const [error, setError] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const ourRequest = axios.CancelToken;
  var cancel;
  // Add Customer
  const handleCustomerSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setValidated(true);
    try {
      const emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      let emailValidate =
        customer_email.length > 0 && !customer_email.match(emailFormat)
          ? false
          : true;

      if (customer_name && customer_type && customer_number && emailValidate) {
        setIsClicked(true);
        const res = await axios.post(
          process.env.REACT_APP_API_URL + "/customer/add",
          {
            customer_name,
            customer_email,
            customer_type,
            customer_number,
            customer_comment,
            shop_id,
          },
          { withCredentials: true }
        );
        if (res) {
          setSucces("Customer Added Successfully");
          setTimeout(() => close(), 1000);
          setModalShow(true);
          setError("");
          getCustomer(res.data);
        }
      }
    } catch (error) {
      setIsClicked(true);
      setError(error.response?.data.error.message);
      setIsClicked(false);
    }
  };
  return (
    <>
      <PopUpModal
        title={success ? success : error}
        icon={
          <FontAwesomeIcon icon={success ? faCircleCheck : faCircleXmark} />
        }
        text={success ? success : error}
        type={success ? styleModal.successBg : styleModal.errorBg}
        iconModal={success ? styleModal.successIcon : styleModal.errorIcon}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <Form noValidate validated={validated} onSubmit={handleCustomerSubmit}>
        {error && (
          <p className="text-danger p-2 text-center my-2 rounded-1">*{error}</p>
        )}
        {success && (
          <p className="text-success p-2 text-center my-2 rounded-1">
            *{success}
          </p>
        )}
        <Form.Group className="mb-3">
          <Form.Label className="mb-1">
            Customer Full Name <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control
            className="p-2"
            type="text"
            name="customer_name"
            placeholder="Full Name"
            pattern="^^[A-Za-z ]+$"
            value={customer_name}
            onChange={(e) => setCustomerName(e.target.value)}
            required
          />
          <Form.Control.Feedback type="invalid">
            * Invalid Username
          </Form.Control.Feedback>
        </Form.Group>
        <div className="mb-3">
          <Select
            label={"Customer Type"}
            important
            placeholder="Select Customer Type"
            handleChange={(e) => setCustomerType(e.target.value)}
            required={true}
          >
            <option value="Individual">Individual</option>
            <option value="Business">Business</option>
          </Select>
        </div>
        <Form.Group className="mb-3">
          <Form.Label className="mb-1">Customer Email</Form.Label>
          <Form.Control
            className="p-2"
            type="email"
            name="customer_email"
            placeholder="example@mail.com"
            value={customer_email}
            onChange={(e) => setCustomerEmail(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            * Invalid Email
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="mb-1">
            Customer Contact <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <PhoneInput
            className="p-2"
            name="customer_number"
            placeholder="+92-3XX-XXXXXXX"
            value={customer_number}
            onChange={(e) => setCustomerNumber(e.target.value)}
            required
          />
          {/* <Form.Control className="p-2" type='text' name="customer_number" {...bindField("customer_number")} placeholder='xxx xxxxxxxx' value={customer_number} onChange={(e) => setCustomerNumber(e.target.value)} pattern="^((\\+92)?(0092)?(92)?(0)?)(3)([0-9]{2})(-||)([0-9]{7})$" required /> */}
          <Form.Control.Feedback type="invalid">
            * Invalid Phone Number
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label className="mb-1">Comments</Form.Label>
          <Form.Control
            placeholder="Comments..."
            as="textarea"
            rows={3}
            value={customer_comment}
            maxLength={100}
            onChange={(e) => setCustomerComments(e.target.value)}
          />
        </Form.Group>
        <Button disabled={isClicked} type="submit" className="w-100">
          Save
        </Button>
      </Form>
    </>
  );
};

export default AddCustomer;
