import React, { useState } from "react";
import style from "./CartItemList.module.css";
import SearchBar from "./SearchBar";
import { useSelector } from "react-redux";
import { Form, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import removeIcon from "../../../assets/svg/remove.svg";
import noImage from "../../../assets/svg/no-image.svg";

import { removeFromCart, updateCartItemDiscount, updateCartItemQuantity } from "../../../Features/cart/cartSlice";
import MiscItems from "./MiscItems";
import IMEIForm from "../../IMEI/IMEIForm";

function CartItemList() {
  const cart = useSelector((state) => state.cart);

  const [ImeiModal, setImeiModal] = useState(false);
  const [itemId, setItemId] = useState();
  // const [isInvalid, setIsInvalid] = useState(false);

  const dispatch = useDispatch();

  // Function to handle quantity change for a specific item
  const handleQuantityChange = (itemId, newQuantity) => {
    dispatch(updateCartItemQuantity({ itemId, newQuantity }));
  };

  const handleDiscountChange = (itemId, newDiscount, sale_price) => {
    const inputValue = newDiscount;
    const isValidInput = /^[0-9.%]*$/.test(inputValue) && !/-/.test(inputValue);

    if (isValidInput || inputValue === "") {
      dispatch(updateCartItemDiscount({ itemId, newDiscount, sale_price }));
    }
  };

  return (
    <div className={`border-end ${style.cartItemListContainer}`}>
      <div className="d-flex flex-row my-2">
        <SearchBar />
        <MiscItems />
      </div>
      <div className={`${style.itemListContainer} `}>
        <Table size="sm" className="">
          <thead>
            <tr>
              <th className={`border-end`}>Title</th>
              <th className={`border-end`}>Quantity</th>
              <th className={`border-end`}>Price</th>
              <th className={`border-end`}>Discount</th>
              <th className={``}>Action</th>
            </tr>
          </thead>
          <tbody className={``}>
            {cart.cartItems.map((item) => (
              <tr key={item.id}>
                <td className={`border-end w-50`} style={{ position: "relative" }}>
                  <div className="d-flex flex-row gap-1">
                    <div style={{ width: "32px", height: "32px" }}>
                      <img src={process.env.REACT_APP_URL_S3 + "/" + item.img0} style={{ width: "100%", objectFit: "cover" }} onError={(e) => (e.target.src = noImage)} />
                    </div>
                    <div style={{ fontSize: "14px" }}>
                      {item.title}

                      {item.label === "Mobile" || item.label === "Tablet" ? (
                        <div className="pt-2 ">
                          <p
                            className="mb-0"
                            style={{ color: "#9938FF", cursor: "pointer", fontSize: "12px" }}
                            onClick={() => {
                              setItemId(item.id);
                              setImeiModal(true);
                            }}
                          >
                            {" "}
                            Add IMEI +
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      {item.isMisc && (
                        <div
                          className=""
                          style={{
                            backgroundColor: "#bb86fc",
                            color: "white",
                            padding: "3px 6px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            borderRadius: "3px",
                          }}
                        >
                          Misc.
                        </div>
                      )}
                    </div>
                  </div>
                </td>

                <td className={`border-end`} style={{ position: "relative", padding: 0 }}>
                  <Form.Control
                    size="sm"
                    onFocus={(e) => e.target.select()}
                    placeholder="0"
                    min={1}
                    style={{
                      borderStyle: "none",
                      position: "absolute",
                      right: 0,
                      top: 0,
                      bottom: 0,
                      left: 0,
                      borderRadius: 0,
                      fontSize: "12px",
                    }}
                    type="number"
                    max={item.quantity}
                    value={item.cartQuantity}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleQuantityChange(item.id, e.target.value);
                      } else if (+e.target.value > -1) {
                        if (+e.target.value === 0) {
                          dispatch(removeFromCart({ itemId: item.id }));
                        } else {
                          handleQuantityChange(item.id, e.target.value);
                        }
                      }
                    }}
                    onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        dispatch(removeFromCart({ itemId: item.id }));
                      }
                    }}
                    isInvalid={item.cartQuantity > item.quantity}
                  />
                </td>
                <td className={`border-end align-middle`} style={{ fontSize: "12px" }}>
                  {Math.ceil(item.sale_price)}
                </td>
                <td className={`border-end `} style={{ position: "relative", padding: 0 }}>
                  <Form.Control
                    size="sm"
                    style={{
                      borderStyle: "none",
                      position: "absolute",
                      right: 0,
                      top: 0,
                      bottom: 0,
                      left: 0,
                      borderRadius: 0,
                      fontSize: "12px",
                    }}
                    value={item.discount?.length > 0 && +item.discount !== 0 ? item.discount : ""}
                    isInvalid={item.isValid === false}
                    placeholder="0"
                    disabled={item.isMisc || cart?.discount}
                    onChange={(e) => handleDiscountChange(item.id, e.target.value, item.sale_price)}
                  />
                </td>
                <td className={` align-middle`} style={{ width: "12% !important" }}>
                  <img src={removeIcon} style={{ cursor: "pointer", height: "12px" }} onClick={() => dispatch(removeFromCart({ itemId: item.id }))} />
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot className="">
            <tr>
              <th className={`border-end border-bottom-0 border-top text-nowrap`}>Total </th>
              <td className={` border-end border-bottom-0 border-top text-nowrap`}>{cart.totalCartQuantity}</td>
              <td className={`border-end border-bottom-0 border-top text-nowrap`}>Rs. {Number(cart.totalCartAmount).toLocaleString("en-IN")}</td>
              <td className={`border-end border-bottom-0 border-top text-nowrap`}>Rs. {Number(cart.totalCartDiscount).toLocaleString("en-IN")}</td>
              <td className={`border-bottom-0 border-top text-nowrap`}></td>
            </tr>
          </tfoot>
        </Table>
      </div>
      <IMEIForm show={ImeiModal} close={() => setImeiModal(false)} id={itemId} setId={() => setItemId(-1)} />
    </div>
  );
}

export default CartItemList;
