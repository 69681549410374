import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";
import ReactSelect from "../ReactSelect";
import search from "../../assets/svg/search.svg";
import { dateTime } from "../../Features/dateTime";
import CustomizeTableview from "../TableViews/CustomizeTableview";
import LoaderPage from "../loaders/loaderPage";

const ImeiReport = ({}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  const [isLoading, setIsLoading] = useState(false);

  const [selectedImei, setSelectedImei] = useState({});
  const [data, setData] = useState(null);

  const getImeis = (e) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + `/imei/shop/${shop_id}`, {
          withCredentials: true,
          headers: { search: e },
        })
        .then((res) => {
          const imei = res.data.success.data.map((imei) => ({
            value: imei.imei_no,
            label: imei.imei_no,
          }));
          resolve(imei);
          setIsLoading(false);
        })
        .catch((err) => {
          reject(new Error(err));
        });
    });
  };

  useEffect(() => {
    const getImeiData = async (imei) => {
      try {
        setIsLoading(true);
        const res = await axios.get(process.env.REACT_APP_API_URL + `/reports/imei-report/${shop_id}`, { withCredentials: true, headers: { imei } });
        if (res?.data?.code === 200) {
          const newData = res.data.success.data?.map((value) => {
            return {
              soldtoPurchaseForm: ` ${value.status}: ${value.name} ${value.number} `,
              price: value.price,
              device: value.product,
              shopLocation: value.location,
              salesOrder: value.invoice,
              Date: value.date,
              status: value.status,
              id: value.id,
            };
          });
          setData(newData);
        } else {
          setData(null);
        }
        setIsLoading(false);
      } catch (err) {
        setData(null);
        throw err;
      }
    };
    if (selectedImei?.value) {
      getImeiData(selectedImei?.value);
    } else {
      setData(null);
    }
  }, [selectedImei]);

  const link = (data) => {
    return data.status === "Sold to" ? `/invoice-payments-sales/${data.id}` : `/purchase-order/${data.id}/invoice`;
  };
  return (
    <>
      <Row className="align-items-center my-4">
        <Col sm="6" md="6" lg="6" xl="4">
          <ReactSelect
            placeholder="Select IMEI"
            searchable
            isClearable={true}
            options={getImeis}
            handleChange={(e, action) => {
              if (action.action === "clear") {
                setSelectedImei(null);
              } else {
                if (e.value) {
                  setSelectedImei(e);
                } else {
                  setSelectedImei(null);
                }
              }
            }}
            value={selectedImei && Object.keys(selectedImei).length > 0 ? selectedImei : null}
            style={{ width: "100px" }}
            onKeyDown={(e) => {
              if (e.keyCode !== 8) {
                if (e.target.value.length > 14) {
                  e.preventDefault();
                }
              }
            }}
          />
        </Col>
      </Row>
      {isLoading ? (
        <div style={{ height: "50vh" }} className="">
          <LoaderPage />
        </div>
      ) : data ? (
        <>
          <div className="bg-white mb-1 p-3 w-100 fs-20 fw-bold">Track history</div>
          <CustomizeTableview data={data} SimpleLink={{ link: link, label: "Invoice" }} columnNotShow={["id", "status", "link"]} dateColumn={["Date"]} />
        </>
      ) : (
        <Container fluid className="d-flex justify-content-center align-items-center" style={{ height: "75vh" }}>
          <div className="text-center">
            <img height="45px" width="45px" src={search} alt="search" className="mb-3" />
            <h5 className="text-dark">Search IMEI</h5>
          </div>
        </Container>
      )}
    </>
  );
};

export default ImeiReport;
