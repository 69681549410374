import { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import SearchInput, { SearchMobleInput } from "../../Components/SearchInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PopUpModal from "../../Components/Modal";
import filterImg from "../../assets/svg/filter.svg";
import AddUser from "./AddUser";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import EditUser from "./EditUser";
import { useSelector } from "react-redux";
import customloader from "../../assets/images/RollingLoader.gif";
import DatePicker from "../../Components/DatePicker/DatePicker";
import moment from "moment";
import PaginationBar from "../../Components/PaginationBar";
import SearchableSelect from "../../Components/SearchableSelect";
import axios from "axios";
import NoResult from "../../Components/NoResult";
import ResetUserPassword from "./ResetUserPassword";
import Csv from "./../../Components/csv/Csv";
import SearchMobile from "../../Components/SearchMobile";
import MobileSearchAcoordian from "../../Components/MobileSearchAcoordian";
import { useNavigate, useSearchParams } from "react-router-dom";
import { requestDateTime, TIMEOUT } from "../../Features/dateTime";
import LoaderPage from "../../Components/loaders/loaderPage";

const SecondaryUser = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { user } = useSelector((state) => state.auth);
  const location = useSelector((state) => state.location?.location);
  const shopId = user.shop_id;
  const shopName = user.shop_name;
  const [modalAddUserShow, setAddUserModalShow] = useState(false);
  const [modalEditUserShow, setEditUserModalShow] = useState(false);
  const [modalSettingShow, setModalSettingShow] = useState(false);

  const [toggle, refresh] = useState(false);
  const [orderBy, setOrderBy] = useState({ "user.created_at": "DESC" });

  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState();
  const [userId, setUserId] = useState();
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [fromDate, setFromDate] = useState(moment().subtract(29, "days").startOf("day")._d);
  const [toDate, setToDate] = useState(moment().endOf("day")._d);

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  // Start Customer with pagination
  const changePage = (page) => {
    navigate("/employees?page=" + page);
  };

  //searchParams
  useEffect(() => {
    if (searchParams.get("page")) {
      setCurrentPage(Number(searchParams.get("page")));
    }
  }, [currentPage, toggle, searchParams]);

  function getSecondaryUsers() {
    axios
      .post(
        process.env.REACT_APP_API_URL + `/user/get-secondary-user/${shopId}?page=` + currentPage + "&size=" + sizeOfPages,
        {
          search: search,
          location: location.value,
          orderBy: orderBy,
        },
        { withCredentials: true }
      )
      .then((data) => {
        const res = data?.data;
        setUsers(res?.data);
        if (res?.count?.no_of_pages === 0) {
          if (searchParams.get("page")) {
            setNoOfPages(0);
            navigate("/employees?page=0");
          }
        } else {
          setTotalUsers(res?.count?.no_of_items);
          setNoOfPages(res?.count?.no_of_pages);
          if (currentPage > res?.count?.no_of_pages) {
            setCurrentPage(res?.count?.no_of_pages);
            navigate("/employees?page=" + res?.count?.no_of_pages);
          }
          if (searchParams.get("page") === "0") {
            setCurrentPage(1);
            navigate("/employees?page=1");
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        const tempErr = err.response.data.error;
        if (tempErr.status === 404 && tempErr.message === "No Record Found") {
          setUsers([]);
        }
      });
  }
  useEffect(() => {
    let timeout;
    if (location) {
      if (search?.length > 0) {
        timeout = setTimeout(() => {
          setIsLoading(true);
          getSecondaryUsers();
        }, TIMEOUT);
      } else {
        setIsLoading(true);
        getSecondaryUsers();
      }
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [search, currentPage, noOfPages, sizeOfPages, shopId, toggle, orderBy, location]);

  useEffect(() => {
    if (currentPage === 1) {
      refresh((prev) => !prev);
    } else {
      changePage(1);
    }
  }, [orderBy, fromDate, toDate]);

  function requestAllHistory() {
    return axios.post(
      process.env.REACT_APP_API_URL + `/user/get-secondary-user/${shopId}`,
      {
        search: search,
        location: location.value,
        orderBy: orderBy,
      },
      { withCredentials: true }
    );
  }

  const resetFilterHandle = () => {
    setFromDate(moment().subtract(29, "days").startOf("day")._d);
    setToDate(moment().endOf("day")._d);
  };

  const closeModalAdd = () => {
    setAddUserModalShow(false);
    refresh((prev) => !prev);
  };

  // Reset Password
  const resetPassword = (id) => {
    setModalSettingShow(true);
    setUserId(id);
  };

  const closeResetPasswordModal = (id) => {
    setModalSettingShow(false);
    setUserId(-1);
  };

  // Edit User
  const edit = (id) => {
    setEditUserModalShow(true);
    setUserId(id);
  };

  const closeModalEdit = () => {
    setEditUserModalShow(false);
    setUserId(-1);
    refresh((prev) => !prev);
  };

  // Delete User
  const remove = (id) => {
    axios
      .delete(process.env.REACT_APP_API_URL + `/user/delete-secondary-user/${id}`, { withCredentials: true })
      .then(() => {
        refresh((prev) => !prev);
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const [toogleMobileSearch, setToogleMobileSearch] = useState(false);
  const searchResult = () => {
    setToogleMobileSearch(false);
    setToogleMobileSearch(false);
  };
  const handleAcoordianCLose = () => {
    setToogleMobileSearch(false);
    setSearch("");
    // setExpenseTypeFilter({ label: "all" });
  };
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleAcoordianOpen = (value) => {
    setToogleMobileSearch(value);
  };
  const filter = () => {
    setToogleMobileSearch(false);
  };
  const SearchData = [];

  return (
    <>
      {toogleMobileSearch === "select" ? (
        <MobileSearchAcoordian SearchData={SearchData} toggleSearchclose={handleAcoordianCLose} dateName={"Select Date"} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} filter={filter} />
      ) : (
        <>
          <Container fluid>
            {toogleMobileSearch === "search" ? (
              <div className="d-flex  mb-2 justify-content-center align-items-center">
                <div className="">
                  {" "}
                  <SearchMobleInput searchlogo value={search} handleChange={(e) => setSearch(e.target.value)} />
                </div>
                <span className="ms-4" onClick={handleAcoordianCLose}>
                  X
                </span>
              </div>
            ) : (
              <Row className="d-flex align-items-center mb-3">
                <Col xs="2" lg="3" className="d-none d-lg-inline">
                  <SearchInput searchlogo handleChange={(e) => setSearch(e.target.value)} value={search} />
                </Col>
                <Col xs lg="2" xxl="4" xl="3" className="d-none d-lg-inline"></Col>
                <Col xs lg="2" className="d-none d-lg-inline"></Col>

                <Col xs="2" lg="3" onClick={() => handleAcoordianOpen("search")} className="d-lg-none d-inline">
                  <SearchInput searchlogo bordered />
                </Col>
                <Col className="d-lg-none d-flex" xs="2" lg="2"></Col>
                <Col className="d-none d-sm-inline d-lg-none d-md-inline" sm={2}></Col>
                <Col xs="6" lg="3" xl="2" sm="3">
                  <SearchableSelect
                    placeholder={`Showing ${sizeOfPages}`}
                    defaultValue={`Showing ${sizeOfPages}`}
                    value={`Showing ${sizeOfPages}`}
                    searchable={false}
                    options={[
                      { value: "10", label: "10" },
                      { value: "20", label: "20" },
                      { value: "30", label: "30" },
                    ]}
                    handleChange={handlePageSize}
                  />
                </Col>
                <Col xs="2" sm="3" lg="2" xl="2" xxl="1" className="p-0">
                  <Button className="h-100 w-100" style={{ fontSize: "0.75rem" }} size="sm" type="submit" onClick={() => setAddUserModalShow(true)}>
                    <span className="d-none d-sm-inline d-lg-inline">Add Employee</span> <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </Col>
              </Row>
            )}
            <div className="pt-2   d-lg-inline">
              <Button size="sm" type="submit" style={{ fontSize: "14px", marginTop: "7px" }} onClick={resetFilterHandle}>
                <span className=" d-lg-inline">Reset Filters</span>{" "}
              </Button>
            </div>
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                <LoaderPage />
              </div>
            ) : !users[0] ? (
              <NoResult name="Employee" />
            ) : (
              <>
                <CustomizeTableview className={"mt-5"} data={users} settings={resetPassword} edit={edit} remove={remove} columnNotShow={["id"]} dateColumn={["created_at"]} sortColumn={["user_full_name", "created_at"]} orderBy={orderBy} setOrderBy={setOrderBy} />
                <Row className="mt-5 align-items-center">
                  <Col className="d-none d-md-block" md="3" lg="3">
                    <span className="text-secondary fs-6">{totalUsers} result(s)</span>
                  </Col>
                  <Col xs="12" md="6" lg="6">
                    <PaginationBar currentPage={currentPage} noOfPages={noOfPages} changePage={changePage} />
                  </Col>
                  <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                    <span className="text-secondary fs-6">{totalUsers} result(s)</span>
                  </Col>
                  <Col xs="6" md="3" lg="3" className="text-end mt-3">
                    <Csv
                      filename={`${
                        "Secondary Users " +
                        shopName +
                        " " +
                        new Date().toLocaleDateString("en-US", {
                          timeZone: "Asia/Karachi",
                        })
                      }`}
                      api={requestAllHistory}
                      format={(data) => {
                        const { id, created_at, ...temp } = data;
                        const newObj = {
                          ...temp,
                          created_at: created_at.split("T")[0],
                        };
                        return newObj;
                      }}
                      error={(err) => {
                        if (err.response) {
                          const tempErr = err.response.data.error;
                          if (tempErr.status === 404 && tempErr.message === "No Record Found") {
                            return true;
                          } else {
                            return false;
                          }
                        } else {
                          return false;
                        }
                      }}
                      responseObject={(data) => {
                        return data?.data?.data;
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </>
      )}

      <PopUpModal title="Reset Password" show={modalSettingShow} onHide={() => setModalSettingShow(false)}>
        <ResetUserPassword close={closeResetPasswordModal} id={userId} />
      </PopUpModal>
      <PopUpModal size="lg" title="Add New Employee" show={modalAddUserShow} onHide={() => setAddUserModalShow(false)}>
        <AddUser close={closeModalAdd} />
      </PopUpModal>
      <PopUpModal size="lg" title="Edit Employee" show={modalEditUserShow} onHide={closeModalEdit}>
        <EditUser id={userId} close={closeModalEdit} />
      </PopUpModal>
    </>
  );
};
export default SecondaryUser;
