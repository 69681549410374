import { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import SearchInput, { SearchMobleInput } from "../../Components/SearchInput";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import { useSelector } from "react-redux";
import PaginationBar from "../../Components/PaginationBar";
import SearchableSelect from "../../Components/SearchableSelect";
import axios from "axios";
import NoResult from "../../Components/NoResult";
import filterStyle from "../../styles/filtersButtons.module.css";
import filterr from "../../assets/svg/filter.svg";
import { Link } from "react-router-dom";
import Csv from "../../Components/csv/Csv";
import MobileSearchAcoordian from "../../Components/MobileSearchAcoordian";
import { useNavigate, useSearchParams } from "react-router-dom";
import { dateTime, dateTimeForInvoice, TIMEOUT } from "../../Features/dateTime";
import LoaderPage from "../../Components/loaders/loaderPage";
import DatePicker from "../../Components/DatePicker/DatePicker";
import moment from "moment/moment";
import PopUpModal from "../../Components/Modal";
import whatsappIcon from "../../assets/svg/whatsapp.svg";
import ReceivePayments from "../ReceivePayments";

const Quotations = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  const shopName = user.shop_name;
  const userId = user.user_id;
  const [searchParams] = useSearchParams();
  const location = useSelector((state) => state.location.location);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [toogleMobileSearch, setToogleMobileSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [toggle, refresh] = useState(false);
  const [statusSearch, setStatusSearch] = useState({ value: null, label: "All" });
  const [isLoading, setIsLoading] = useState(true);
  const [orderBy, setOrderBy] = useState({ created_at: "DESC" });
  const [quotations, setQuotations] = useState([]);
  const [ordersData, setOrdersData] = useState({});
  const [totalOrders, setTotalOrders] = useState();
  const [fromDate, setFromDate] = useState(moment().subtract(29, "days").startOf("day")._d);
  const [toDate, setToDate] = useState(moment().endOf("day")._d);
  const [quotationData, setQuotationData] = useState(null);

  let [is_remaining, setRemaining] = useState([]);
  let [received_amount, setReceivedAmount] = useState();
  const [updatePaymentId, setupdatePaymentId] = useState();
  const [modalPaymentShow, setPaymentModalShow] = useState(false);
  const [emailPaymentModalShow, setEmailPaymentModalShow] = useState(false);
  const [openingBalances, setOpeningBalances] = useState([]);
  const [salesNumber, setSalesNumber] = useState(null);
  const [emailStatus, setEmailStatus] = useState("");
  async function getOpeningBalances(id) {
    try {
      const openingBalanceRespponse = await axios.get(process.env.REACT_APP_API_URL + "/location/opening-balances/" + id, { withCredentials: true });
      if (openingBalanceRespponse.data.code === 200) {
        setOpeningBalances(openingBalanceRespponse.data.success.data);
      }
    } catch (err) {}
  }
  useEffect(() => {
    if (userId) {
      getOpeningBalances(userId);
    }
  }, [toggle, userId]);

  const handleAcoordianCLose = () => {
    setToogleMobileSearch(false);
    setSearch("");
    setStatusSearch(null);
    setFromDate(moment().subtract(29, "days").startOf("day")._d);
    setToDate(moment().endOf("day")._d);
  };
  const filter = () => {
    setToogleMobileSearch(false);
  };

  const handleAcoordianOpen = (value) => {
    setToogleMobileSearch(value);
  };

  // Search Products
  const searchHandle = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearch("");
    } else {
      setSearch(e.target.value);
    }
    setCurrentPage(1);
  };

  const searchStatus = (e) => {
    if (e.value) {
      setStatusSearch({ value: e.value, label: e.label });
    } else {
      setStatusSearch({ value: null, label: "All" });
    }
    setCurrentPage(1);
  };

  const searchStatusMobile = (e) => {
    if (e.target.value === null) {
      setStatusSearch({ value: null, label: "All" });
    } else {
      setStatusSearch({ value: e.target.id, label: e.target.value });
    }
    setCurrentPage(1);
  };

  const resetFilterHandle = (e) => {
    setStatusSearch({ value: "Pending", label: "Pending" });
    setFromDate(moment().subtract(29, "days").startOf("day")._d);
    setToDate(moment().endOf("day")._d);
    setSizeOfPages(10);
    setCurrentPage(1);
  };

  const SearchData = [
    {
      name: "Select Status",
      handleChange: searchStatusMobile,
      value: statusSearch,
      filter: [
        { value: null, label: "All" },
        { value: "Active", label: "Active" },
        { value: "Expired", label: "Expired" },
      ],
    },
  ];

  const changePage = (page) => {
    setCurrentPage(page);
  };

  //searchParams
  useEffect(() => {
    if (searchParams.get("page")) {
      setCurrentPage(Number(searchParams.get("page")));
    }
  }, [currentPage, toggle, searchParams]);

  function getDeliverForYouOrders() {
    const orderByFormatForQueryString = (obj) => {
      const [[key, value]] = Object.entries(obj);
      return `${key}:${value}`;
    };
    const orderByString = orderBy ? orderByFormatForQueryString(orderBy) : "";
    const searchParams = new URLSearchParams({
      page: currentPage,
      size: sizeOfPages,
      ...(search.length > 0 ? { search: search } : {}),
      ...(statusSearch?.value ? { status: statusSearch?.value } : {}),
      ...(orderBy ? { orderBy: orderByString } : {}),
      ...(location ? { location: location?.value } : {}),
      ...(fromDate && toDate ? { "date[from]": moment(fromDate).format("YYYY-MM-DD"), "date[to]": moment(toDate).format("YYYY-MM-DD") } : {}),
    });
    axios
      .get(process.env.REACT_APP_API_URL + `/quotation/${shop_id}?` + searchParams.toString(), { withCredentials: true })
      .then((data) => {
        setIsLoading(false);
        setTotalOrders(data?.data?.pagination?.no_of_items);
        setQuotations(
          data?.data?.data.map((item) => {
            return {
              id: item.quotation_id,
              ...item,
            };
          })
        );
        if (data?.data?.pagination?.no_of_pages === 0) {
          if (searchParams.get("page")) {
            setNoOfPages(0);
            navigate("/sales/quotations?page=1");
          }
        } else {
          setNoOfPages(data?.data?.pagination?.no_of_pages);
          if (currentPage > data?.data?.pagination?.no_of_pages) {
            setCurrentPage(data?.data?.pagination?.no_of_pages);
            navigate("/sales/quotations?page=" + data?.data?.pagination?.no_of_pages);
          }
          if (searchParams.get("page") === "0") {
            setCurrentPage(1);
            navigate("/sales/quotations?page=1");
          }
        }
      })
      .catch((err) => {
        const tempErr = err.response.data.error;
        if (tempErr.status === 404 && tempErr.message === "No Record Found") {
          setQuotations([]);
        }
        setIsLoading(false);
      });
  }

  useEffect(() => {
    let timeout;
    if (location) {
      if (search?.length > 0) {
        timeout = setTimeout(() => {
          setIsLoading(true);
          getDeliverForYouOrders();
        }, TIMEOUT);
      } else {
        setIsLoading(true);
        getDeliverForYouOrders();
      }
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [search, fromDate, toDate, location, currentPage, noOfPages, sizeOfPages, statusSearch, shop_id, toggle, orderBy]);

  useEffect(() => {
    if (currentPage === 1) {
      refresh((prev) => !prev);
    } else {
      changePage(1);
    }
  }, []);

  function requestAllHistory() {
    const orderByFormatForQueryString = (obj) => {
      const [[key, value]] = Object.entries(obj);
      return `${key}:${value}`;
    };
    const orderByString = orderBy ? orderByFormatForQueryString(orderBy) : "";
    const searchParams = new URLSearchParams({
      page: currentPage,
      size: sizeOfPages,
      ...(search.length > 0 ? { search: search } : {}),
      ...(statusSearch?.value ? { status: statusSearch?.value } : {}),
      ...(orderBy ? { orderBy: orderByString } : {}),
      ...(location ? { location: location?.value } : {}),
      ...(fromDate && toDate ? { "date[from]": moment(fromDate).format("YYYY-MM-DD"), "date[to]": moment(toDate).format("YYYY-MM-DD") } : {}),
    });
    return axios.get(process.env.REACT_APP_API_URL + `/quotation/${shop_id}?` + searchParams.toString(), { withCredentials: true });
  }

  const caret = async (quotationId) => {
    const data = quotations.filter((item) => item.id === quotationId);
    const { customer_name, customer_email, customer_number, notes, items, misc_items, ...ordersObj } = data[0];
    setOrdersData({
      customer_name,
      customer_number: (
        <>
          <span className={`d-inline-flex gap-1 align-item-center`}>
            <span>{customer_number}</span>
            <img
              src={whatsappIcon}
              height={20}
              width={20}
              alt="whatsapp"
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(`https://wa.me/${customer_number.replace("+92", 92).replaceAll("-", "")}`);
              }}
            />
          </span>
        </>
      ),
      ...(customer_email !== null && customer_email !== "" ? { customer_email } : {}),
      items: (
        <>
          <div>
            <ul>
              {items.map((item) => (
                <>
                  <li>
                    <strong>Name: </strong> {item.item_name} <strong>Price: </strong> {item.price} <strong>Quantity: </strong> {item.qty_ordered} <strong>Discount: </strong> {item.discount}
                  </li>
                </>
              ))}
            </ul>
          </div>
        </>
      ),
      misc_items: (
        <>
          <div>
            <ul>
              {misc_items.map((item) => (
                <>
                  <li>
                    <strong>Name: </strong> {item.name} <strong>Price: </strong> {item.price} <strong>Quantity: </strong> {item.qty_ordered}
                  </li>
                </>
              ))}
            </ul>
          </div>
        </>
      ),
    });
  };
  const closeUpdatePaymentModal = () => {
    setTimeout(() => setPaymentModalShow(false), 1500);
    refresh((prev) => !prev);
  };
  const InvoicePayments = async (id) => {
    window.open(`/qoutation-invoice/` + id, "_blank", "noopener,noreferrer");
  };
  const handleReceivePayment = (sales) => {
    setRemaining(sales.base_grand_total);
    setReceivedAmount(sales.received_amount);
    setupdatePaymentId(sales.id);
    setSalesNumber(sales.sales_number);
  };
  const handlePaymentEmailModal = () => {
    setEmailPaymentModalShow(true);
    setTimeout(() => setEmailPaymentModalShow(false), 1500);
  };
  const handleEmailStatus = (data) => {
    setEmailStatus(data);
  };
  return (
    <>
      {toogleMobileSearch === "select" ? (
        <MobileSearchAcoordian SearchData={SearchData} toggleSearchclose={handleAcoordianCLose} dateName={"Select Date"} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} filter={filter} />
      ) : (
        <>
          <Container fluid>
            {toogleMobileSearch === "search" ? (
              <div className="d-flex  mb-2 justify-content-center align-items-center">
                <div className="">
                  {" "}
                  <SearchMobleInput searchlogo value={search} handleChange={searchHandle} />
                </div>
                <span className="ms-4" onClick={handleAcoordianCLose}>
                  X
                </span>
              </div>
            ) : (
              <Row className="d-flex align-items-center">
                <Col xs="2" lg="3" xl="2" className="d-none d-lg-inline mb-3">
                  <SearchInput value={search} searchlogo handleChange={searchHandle} />
                </Col>
                <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3"></Col>
                <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3">
                  <DatePicker important={true} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
                </Col>
                <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3">
                  <SearchableSelect
                    placeholder="Filter Status"
                    searchable={false}
                    value={statusSearch}
                    options={[
                      { value: null, label: "All" },
                      { value: "Active", label: "Active" },
                      { value: "Expired", label: "Expired" },
                    ]}
                    handleChange={searchStatus}
                  />
                </Col>
                {/* For Mobile screen Start */}

                <Col className="d-none d-sm-inline d-lg-none d-md-inline px-0" sm={2}></Col>
                <Col xs="6" lg="3" xl="2" sm="3" className="mb-3">
                  <SearchableSelect
                    selectClassName={"bg-white"}
                    placeholder={`Showing ${sizeOfPages}`}
                    defaultValue={`Showing ${sizeOfPages}`}
                    value={`Showing ${sizeOfPages}`}
                    searchable={false}
                    options={[
                      { value: "10", label: "10" },
                      { value: "20", label: "20" },
                      { value: "30", label: "30" },
                    ]}
                    handleChange={(e) => setSizeOfPages(e.value)}
                  />
                </Col>
                <Col onClick={() => handleAcoordianOpen("search")} xs="2" sm="2" lg="3" className="d-lg-none d-inline mb-3">
                  <SearchInput searchlogo bordered />
                </Col>
                <Col className={`d-lg-none d-inline mb-3 ${filterStyle.filter}`} xs="2" lg="2">
                  <Link to="" className={`${filterStyle.filterToggleButtonLink}`}>
                    <Button onClick={() => handleAcoordianOpen("select")} variant="outline-primary" className={`${filterStyle.filterToggleButton} d-flex align-items-center`}>
                      <img className="" src={filterr} alt="filter" />
                      <span className=" ms-2 d-none d-sm-inline">Filter</span>
                    </Button>
                  </Link>
                </Col>
                <Col xs="2" sm="3" lg="2" xl="2" xxl="2" className={`mb-3 p-0 ${filterStyle.addbtn}`}></Col>

                {/* For Mobile screen End */}
              </Row>
            )}
            <div className="pt-2">
              <Button size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
                <span className="d-lg-inline">Reset Filters</span>{" "}
              </Button>
            </div>

            {isLoading ? (
              <div
                style={{
                  height: "50vh",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <LoaderPage />
              </div>
            ) : quotations.length === 0 ? (
              <NoResult />
            ) : (
              <>
                <CustomizeTableview
                  className={"mt-3"}
                  data={quotations}
                  caret={caret}
                  caretData={ordersData}
                  columnNotShow={["quotation_id", "id", "customer_id", "user_id", "sales_person_id", "items", "misc_items", "location_id", "shop_id", "customer_name", "customer_email", "customer_number", "sales_order_comment", "sales_order_id"]}
                  dateColumn={["created_at", "valid_till"]}
                  sortColumn={["created_at", "valid_till", "base_grand_total"]}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                  status={{
                    column: ["valid_till"],
                    style: (value) => {
                      const validTillDate = dateTimeForInvoice(value["valid_till"]).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }).replaceAll("/", "-");
                      const todaysDate = new Date().toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }).replaceAll("/", "-");
                      if (validTillDate >= todaysDate) {
                        return "text-success";
                      } else if (validTillDate < todaysDate) {
                        return "text-warning";
                      }
                    },
                    format: (value) => {
                      const validTillDate = dateTimeForInvoice(value["valid_till"]).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }).replaceAll("/", "-");
                      const todaysDate = new Date().toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }).replaceAll("/", "-");
                      if (validTillDate >= todaysDate) {
                        return "Active";
                      } else if (validTillDate < todaysDate) {
                        return "Expired";
                      }
                    },
                  }}
                  view={{
                    action: InvoicePayments,
                    isShow: (value) => {
                      return true;
                    },
                  }}
                  actionButton={{
                    column: ["valid_till", "sales_order_id"],
                    label: (value) => {
                      return "Sell";
                    },
                    onClick: (er, val) => {
                      if (val.sales_order_id === null) {
                        setPaymentModalShow(true);
                        handleReceivePayment(val);
                        setQuotationData(val);
                      }
                    },
                    variant: "primary",
                    disabled: (value) => (value["sales_order_id"] === null ? value["valid_till"].split("T")[0] < new Date().toISOString().split("T")[0] : true),
                    style: { padding: "6px 18px" },
                    isShow: (_, row) => {
                      return true;
                    },
                  }}
                />
                <Row className="mt-5 align-items-center">
                  <Col className="d-none d-md-block" md="3" lg="3">
                    <span className="text-secondary fs-6">{totalOrders} result(s)</span>
                  </Col>
                  <Col xs="12" md="6" lg="6">
                    <PaginationBar currentPage={currentPage} noOfPages={noOfPages} changePage={changePage} />
                  </Col>
                  <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                    <span className="text-secondary fs-6">{totalOrders} result(s)</span>
                  </Col>
                  <Col xs="6" md="3" lg="3" className="text-end mt-3">
                    <Csv
                      filename={`${
                        "Quotation " +
                        shopName +
                        " " +
                        new Date().toLocaleDateString("en-US", {
                          timeZone: "Asia/Karachi",
                        })
                      }`}
                      api={requestAllHistory}
                      responseObject={(response) => {
                        return response.data.data;
                      }}
                      format={(data) => {
                        const { quotation_id, id, customer_id, user_id, sales_person_id, location_id, shop_id, sales_order_id, created_at, ...temp } = data;
                        const newObj = {
                          ...temp,
                          items: temp.items
                            .map((item) => {
                              return `Name:${item?.item_name} - qty: ${item?.qty_ordered} - Rs. ${item?.price} - discount: ${item?.discount}`;
                            })
                            .join(";"),
                          misc_items: temp.misc_items
                            .map((item) => {
                              return `Name:${item?.item_name} - qty: ${item?.qty_ordered} - Rs. ${item?.price} - discount: ${item?.discount}`;
                            })
                            .join(";"),
                          created_at: created_at?.split("T")[0],
                        };
                        return newObj;
                      }}
                      error={(err) => {
                        const tempErr = err.response.data.error;
                        if (tempErr.status === 404 && tempErr.message === "No Record Found") {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </>
      )}
      <PopUpModal title="Payment" show={modalPaymentShow} onHide={() => setPaymentModalShow(false)}>
        <ReceivePayments
          quotationData={quotationData}
          close={closeUpdatePaymentModal}
          is_remaining={is_remaining}
          received_amount={received_amount}
          updatePaymentId={updatePaymentId}
          emailClose={handlePaymentEmailModal}
          emailStatus={handleEmailStatus}
          paymentCheck={openingBalances.filter((ob) => ob.location_id === location.value)[0]}
          salesNumber={salesNumber}
          customerInfo={{ customer_email: quotationData?.customer_email, customer_name: quotationData?.customer_name, customer_number: quotationData?.customer_number }}
        />
      </PopUpModal>
    </>
  );
};
export default Quotations;
