import { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import SearchInput from "../../Components/SearchInput";
import PaginationBar from "../../Components/PaginationBar";
import filterImg from "../../assets/svg/filter.svg";
import axios from "axios";
import NoResult from "../../Components/NoResult";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import DatePicker from "../../Components/DatePicker/DatePicker";
import moment from "moment";
import SearchableSelect from "../../Components/SearchableSelect";
import { useSelector } from "react-redux";
import customloader from "../../assets/images/RollingLoader.gif";
import Csv from "../../Components/csv/Csv";
import SearchMobile from "../../Components/SearchMobile";
import MobileSearchAcoordian from "../../Components/MobileSearchAcoordian";
import { useNavigate, useSearchParams } from "react-router-dom";
import { requestDateTime } from "../../Features/dateTime";
import LoaderPage from "../../Components/loaders/loaderPage";

const Refunds = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { user } = useSelector((state) => state.auth);
  const userId = user.user_id;
  const shop_id = user.shop_id;
  const user_type = user.user_type;
  const shopName = user.shop_name;
  const locationField = useSelector((state) => state.location?.location);
  // refund
  const [refund, setRefund] = useState([]);
  const [totalRefund, setTotalRefund] = useState();

  const [toggle, refresh] = useState(false);
  const [orderBy, setOrderBy] = useState({ created_at: "DESC" });

  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const DELAY_TIME = 100;

  const [fromDate, setFromDate] = useState(moment().subtract(29, "days").startOf("day")._d);
  const [toDate, setToDate] = useState(moment().endOf("day")._d);
  const [onlyWalkInCustomer, setOnlyWalkInCustomer] = useState(false);

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  // Start Refund with pagination
  const changePage = (page) => {
    setCurrentPage(page);
    navigate("/sales/refunds-history?page=" + page);
  };

  //searchParams
  useEffect(() => {
    if (searchParams.get("page")) {
      setCurrentPage(Number(searchParams.get("page")));
    }
  }, [currentPage, toggle, searchParams]);

  useEffect(() => {
    const pageTimeout = setTimeout(() => {
      let filters = {};
      if (searchInput.length > 0) {
        filters = { ...filters, search: searchInput };
      } else {
        filters = { ...filters, search: "" };
      }

      if (Object.keys(locationField).length > 0) {
        filters = { ...filters, location: locationField.value };
      }
      axios
        .post(
          process.env.REACT_APP_API_URL + `/refund/no-of-pages/${userId}?size=` + sizeOfPages,
          {
            ...filters,
            dates: {
              from: fromDate.toISOString().replace("T", " ").replace("Z", "").split(".")[0] + " " + fromDate.toTimeString().split(" ")[0],
              to: toDate.toISOString().replace("T", " ").replace("Z", "").split(".")[0] + " " + toDate.toTimeString().split(" ")[0],
            },
            orderBy: orderBy,
            onlyWalkInCustomer,
          },
          { withCredentials: true }
        )
        .then((data) => {
          if (data.data.no_of_pages === 0) {
            if (searchParams.get("page")) {
              setNoOfPages(0);
              navigate("/sales/refunds-history?page=0");
            }
          } else {
            setNoOfPages(data.data.no_of_pages);
            if (currentPage > data.data.no_of_pages) {
              setCurrentPage(data.data.no_of_pages);
              navigate("/sales/refunds-history?page=" + data.data.no_of_pages);
            }
            if (searchParams.get("page") === "0") {
              setCurrentPage(1);
              navigate("/sales/refunds-history?page=1");
            }
          }
        })
        .catch((err) => {
          const tempErr = err.response.data.error;
          if (tempErr.status === 404 && tempErr.message === "No Record Found") {
            setRefund([]);
          }
        });
    }, DELAY_TIME);
    return () => {
      clearTimeout(pageTimeout);
    };
  }, [sizeOfPages, searchParams, currentPage, navigate, userId, toggle, searchInput, locationField, orderBy, fromDate, toDate, onlyWalkInCustomer]);

  useEffect(() => {
    const pageTimeout = setTimeout(() => {
      let filters = {};
      if (searchInput.length > 0) {
        filters = { ...filters, search: searchInput };
      } else {
        filters = { ...filters, search: "" };
      }

      if (Object.keys(locationField).length > 0) {
        filters = { ...filters, location: locationField.value };
      }
      axios
        .post(
          process.env.REACT_APP_API_URL + `/refund/get/${userId}?page=` + currentPage + "&size=" + sizeOfPages,
          {
            ...filters,
            dates: {
              from: requestDateTime(fromDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0].replace("T", " ").replace("Z", "").split(".")[0],
              to: requestDateTime(toDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
            },
            orderBy: orderBy,
            onlyWalkInCustomer,
          },
          { withCredentials: true }
        )
        .then((data) => {
          setIsLoading(false);
          setRefund(
            data.data.map((item) => {
              if (item.customer_name === "nil") {
                return { ...item, customer_name: "Walk-In Customer", customer_number: "", customer_email: "" };
              } else {
                return item;
              }
            })
          );
        })
        .catch((err) => {
          setIsLoading(false);
          const tempErr = err.response.data.error;
          if (tempErr.status === 404 && tempErr.message === "No Record Found") {
            setRefund([]);
          }
        });
    }, DELAY_TIME);
    return () => {
      clearTimeout(pageTimeout);
    };
  }, [currentPage, noOfPages, sizeOfPages, userId, toggle, searchInput, locationField, orderBy, fromDate, toDate, onlyWalkInCustomer]);

  // Get Refund Count
  useEffect(() => {
    let filters = {};
    if (searchInput.length > 0) {
      filters = { ...filters, search: searchInput };
    } else {
      filters = { ...filters, search: "" };
    }

    if (Object.keys(locationField).length > 0) {
      filters = { ...filters, location: locationField.value };
    }
    axios
      .post(
        process.env.REACT_APP_API_URL + `/refund/get-count/${userId}`,
        {
          ...filters,
          dates: {
            from: requestDateTime(fromDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
            to: requestDateTime(toDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
          },
          orderBy: orderBy,
          onlyWalkInCustomer,
        },
        { withCredentials: true }
      )
      .then((res) => {
        setTotalRefund(res.data.no_of_items);
      })
      .catch((err) => {
        const tempErr = err.response.data.error;
        if (tempErr.status === 404 && tempErr.message === "No Record Found") {
          setTotalRefund();
        }
      });
  }, [userId, toggle, searchInput, locationField, orderBy, fromDate, toDate, onlyWalkInCustomer]);

  function requestAllHistory() {
    let filters = {};
    if (searchInput.length > 0) {
      filters = { ...filters, search: searchInput };
    } else {
      filters = { ...filters, search: "" };
    }

    if (Object.keys(locationField).length > 0) {
      filters = { ...filters, location: locationField.value };
    }
    return axios.post(
      process.env.REACT_APP_API_URL + `/refund/get/${userId}`,
      {
        ...filters,
        dates: {
          from: requestDateTime(fromDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
          to: requestDateTime(toDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
        },
        orderBy: orderBy,
        onlyWalkInCustomer,
      },
      { withCredentials: true }
    );
  }

  const handlePrint = (id) => {
    window.open(`/sales/refunds-history/` + id, "_blank");
  };

  const resetFilterHandle = () => {
    setFromDate(moment().subtract(29, "days").startOf("day")._d);
    setToDate(moment().endOf("day")._d);
    // setLocationFields([]);
  };

  const [toogleMobileSearch, setToogleMobileSearch] = useState(false);
  const searchResult = () => {
    setToogleMobileSearch(false);
    setToogleMobileSearch(false);
  };
  const handleAcoordianCLose = () => {
    setToogleMobileSearch(false);
    setSearchInput("");
  };
  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleAcoordianOpen = (value) => {
    setToogleMobileSearch(value);
  };
  const filter = () => {
    setToogleMobileSearch(false);
  };
  // const handleChangeLocationMobile = (e) => {
  //   setLocationFields({ value: e.target.id, label: e.target.value });
  // };
  // const SearchData = [
  //   {
  //     name: "Select Location",
  //     handleChange: handleChangeLocationMobile,
  //     value: locationFields,
  //     filter: location,
  //   },
  // ];

  return (
    <>
      {toogleMobileSearch === "search" ? (
        <SearchMobile data={refund} toggleSearch={handleAcoordianCLose} value={searchInput} handleChange={handleSearchChange} searchResult={searchResult} searchTitle={"customer_name"} saleNumber={"sales_order_number"} date={"created_at"} />
      ) : toogleMobileSearch === "select" ? (
        <MobileSearchAcoordian
          // SearchData={SearchData}
          toggleSearchclose={handleAcoordianCLose}
          dateName={"Select Date"}
          fromDate={fromDate}
          toDate={toDate}
          setFromDate={setFromDate}
          setToDate={setToDate}
          filter={filter}
        />
      ) : (
        <>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
              <LoaderPage />
            </div>
          ) : !refund ? (
            <NoResult name="Refunds" />
          ) : (
            <>
              <Container fluid>
                <Row className="d-flex align-items-center">
                  <Col xs="2" lg="3" className="d-none d-lg-inline">
                    <SearchInput searchlogo handleChange={handleSearchChange} value={searchInput} />
                  </Col>
                  <Col xs lg="2" xl="3" className="d-none d-lg-inline"></Col>
                  <Col xs="5" lg="3" xl="2" sm="3" className="d-none d-lg-inline"></Col>
                  <Col xs lg="2" className="d-none d-lg-inline">
                    <DatePicker important={true} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
                  </Col>

                  <Col xs="2" lg="3" onClick={() => handleAcoordianOpen("search")} className="d-lg-none d-inline">
                    <SearchInput searchlogo bordered />
                  </Col>
                  <Col className="d-lg-none d-flex" xs="2" lg="2">
                    <Button variant="outline-primary" onClick={() => handleAcoordianOpen("select")}>
                      <img src={filterImg} alt="filter" />
                      <span className=" ms-2 d-none d-sm-inline">Filter</span>{" "}
                    </Button>
                  </Col>
                  <Col className="d-none d-sm-inline d-lg-none d-md-inline" sm={2}></Col>
                  <Col xs="5" lg="3" xl="2" sm="3">
                    <SearchableSelect
                      placeholder={`Showing ${sizeOfPages}`}
                      defaultValue={`Showing ${sizeOfPages}`}
                      value={`Showing ${sizeOfPages}`}
                      searchable={false}
                      options={[
                        { value: "10", label: "10" },
                        { value: "20", label: "20" },
                        { value: "30", label: "30" },
                      ]}
                      handleChange={handlePageSize}
                    />
                  </Col>
                </Row>
                <div className="d-flex pt-2 gap-3 align-items-center">
                  <Button size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
                    <span className="d-lg-inline">Reset Filters</span>{" "}
                  </Button>
                  <div>
                    <input
                      type="checkbox"
                      name="walk-in customer"
                      checked={onlyWalkInCustomer}
                      onChange={(e) => {
                        setOnlyWalkInCustomer(e.target.checked);
                      }}
                    />{" "}
                    <label className="text-muted" for="walk-in customer">
                      Only Walk-In Customer
                    </label>
                  </div>
                </div>
                {!refund[0] ? (
                  <NoResult name="Refunds" />
                ) : (
                  <>
                    <CustomizeTableview
                      className={"mt-5"}
                      data={refund}
                      print={handlePrint}
                      columnNotShow={["id"]}
                      dateColumn={["created_at"]}
                      sortColumn={["customer_name", "created_at", "total_refund_amount"]}
                      orderBy={orderBy}
                      setOrderBy={setOrderBy}
                      actions={user.permissionArray?.includes("Sales")}
                    />
                    <Row className="mt-5 align-items-center">
                      <Col className="d-none d-md-block" md="3" lg="3">
                        <span className="text-secondary fs-6">{totalRefund} result(s)</span>
                      </Col>
                      <Col xs="12" md="6" lg="6">
                        <PaginationBar currentPage={currentPage} noOfPages={noOfPages} changePage={changePage} />
                      </Col>
                      <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                        <span className="text-secondary fs-6">{totalRefund} result(s)</span>
                      </Col>
                      <Col xs="6" md="3" lg="3" className="text-end mt-3">
                        <Csv
                          filename={`${
                            "Refunds History " +
                            shopName +
                            " " +
                            new Date().toLocaleDateString("en-US", {
                              timeZone: "Asia/Karachi",
                            })
                          }`}
                          api={requestAllHistory}
                          format={(data) => {
                            const { id, created_at, customer_number, ...temp } = data;
                            const newObj = {
                              ...temp,
                              customer_number: customer_number.replaceAll("+", "").replaceAll("-", " "),
                              created_at: created_at.split("T")[0],
                            };
                            return newObj;
                          }}
                          error={(err) => {
                            const tempErr = err.response.data.error;
                            if (tempErr.status === 404 && tempErr.message === "No Record Found") {
                              return true;
                            } else {
                              return false;
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </>
          )}
        </>
      )}
    </>
  );
};
export default Refunds;
