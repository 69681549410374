import React from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import SearchableSelect from "../SearchableSelect";
import style from "../../styles/UploadFile.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faUpload } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useSelector } from "react-redux";

function FileUplaodModal({ onClose }) {
  const user = useSelector((state) => state.auth?.user);
  const location = useSelector((state) => state.location?.location);
  const [source, setSource] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [error, setError] = useState("");
  const [isValidate, setIsValidate] = useState(false);
  const [validated, setValidated] = useState(false);

  const acceptedFileTypes = [".csv", ".xls", ".xlsx"];

  const handleFileChange = (event) => {
    setIsValidate(false);

    const selectedFilee = event.target.files[0];
    const [fileNameTitle, fileNameExt] = selectedFilee.name.split(".");
    if (!acceptedFileTypes.includes(`.${fileNameExt}`)) {
      setIsValidate(true);
      setSelectedFile(null);
      setError("Invalid File Type");
    } else {
      setError("");
      if (selectedFilee) {
        setIsValidate(false);
        setSelectedFile(selectedFilee ? selectedFilee : null);
      } else {
        setSelectedFile(null);
        console.log("cancelled");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsValidate(true);
    setValidated(true);
    if (source && location?.value && selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("sourcePOS", source);
      formData.append("shop_id", user.shop_id);
      formData.append("location_id", location?.value);

      try {
        const res = await axios.post(process.env.REACT_APP_API_URL + `/data-import/submit-data`, formData, { withCredentials: true });
        if (res) {
          onClose();
          setIsValidate(false);
        }
      } catch {
        setIsValidate(false);
      }
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit} noValidate validated={validated}>
        {" "}
        {error && <p className="text-danger p-2 text-center my-2 rounded-1">*{error}</p>}
        <Form.Group controlId="formBasicTiming">
          <label className="mb-0">
            Source POS <span className="text-danger">*</span>
          </label>
          <div className={`w-100`}>
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder={`select Source`}
              value={source?.value}
              searchable={false}
              options={[
                { value: "Vayapar", label: "Vayapar" },
                { value: "EPOS", label: "EPOS" },
                { value: "Square", label: "Square" },
                { value: "TechBazaar", label: "TechBazaar" },
                { value: "Other", label: "Other" },
              ]}
              handleChange={(e) => {
                setIsValidate(false);
                setSource(e.value);
              }}
            />
            <Form.Control value={source} hidden name="dealerlocation" required />

            <Form.Control.Feedback type="invalid">Please Select Source.</Form.Control.Feedback>
          </div>
        </Form.Group>
        <Form.Group className="mt-3">
          <SearchableSelect important label={"Location"} placeholder="Select Location" value={location} disabled={true} required />
          <Form.Control hidden type="text" name="location" value={location ? location : ""} onChange={() => {}} required />
          <Form.Control.Feedback type="invalid">* Please Select Location</Form.Control.Feedback>
        </Form.Group>
        <div className={`${style.upload_btn_wrapper} mt-4 d-flex  w-100`}>
          <Form.Group className="w-100" controlId="formBasicTiming">
            <Button variant="outline-primary" className="w-100 border">
              {" "}
              <FontAwesomeIcon className="mx-2" icon={selectedFile ? faCheck : faUpload} /> {selectedFile ? selectedFile.name : "Upload File"}
            </Button>

            <input accept={acceptedFileTypes.join(",")} onChange={handleFileChange} type="file" name="myfile" required />
            <Form.Control.Feedback type="invalid">Please Select File.</Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="d-flex justify-content-end mt-3 gap-2 ">
          <Button variant="secondary" onClick={onClose}>
            Cancel{" "}
          </Button>
          <Button type="submit" disabled={isValidate}>
            Submit{" "}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default FileUplaodModal;
