import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./SalesPage.module.css";
import { Button, Col, Dropdown } from "react-bootstrap";
import sortAsc from "../../assets/svg/icon.svg";

function SalesPageCategoryTabs({ viewMode, selectedCategory, setSelectedCategory, status, setStatus }) {
  const [listOfCategories, setListOfCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/categories/get`, { withCredentials: true });
        const allCategory = { id: 0, category: "All Categories" };
        const categories = [allCategory, ...response.data.filter((item) => item.id < 7)];
        setListOfCategories(categories);
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <div className={`mt-1 mt-md-3`}>
      {viewMode === "tiles" ? (
        <div className="d-flex justify-content-between ">
          <div className={`d-flex ${styles.TilesCatBtn} `}>
            {listOfCategories.map((cat, index) => (
              <React.Fragment key={cat.id}>
                <Button onClick={() => setSelectedCategory(cat.id)} className={` mb-2 mx-1 rounded-5 text-nowrap d-block d-md-none`} style={{ fontSize: "12px" }} variant="outline-primary">
                  {cat.category}
                </Button>

                <div className={`p-2 cursor-pointer rounded-top text-nowrap d-none d-md-block ${styles.categoryTabsButton} ${cat.id === selectedCategory ? styles.categoryTabsButtonActive : ""}`} onClick={() => setSelectedCategory(cat.id)}>
                  {cat.category}
                </div>
              </React.Fragment>
            ))}
          </div>
          <div className={` ${styles.statusDropdownSpan} d-none d-md-flex`} style={{}}>
            <Dropdown className={`d-flex justify-content-end   ${styles.statusDropdown}  ${styles.categoryTabsButton} rounded-top text-nowrap`}>
              <Dropdown.Toggle className={`border-1 p-0 dropdown-toggle show btn btn-light ${styles.du_btn}`} style={{ background: "transparent" }} id="dropdown-basic">
                <div className="cursor-pointer rounded-top text-nowrap w-30 ">
                  Status{" "}
                  <span
                    className={`${styles.spanDiv}`}
                    // style={{ color: " " }}
                  >{`(${status})`}</span>{" "}
                  <img src={sortAsc} alt="Sort Ascending Icon" />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setStatus("Available")}>Available</Dropdown.Item>
                <Dropdown.Item onClick={() => setStatus("Sold")}>Sold</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className={` ${styles.statusDropdownSpan} mx-2 d-block d-md-none `} style={{}}>
            <Button variant="outline-primary" className="px-1">
              <Dropdown className={`d-flex justify-content-end  ${styles.statusDropdown}  ${styles.categoryTabsButton} `}>
                <Dropdown.Toggle className={` p-0 dropdown-toggle show btn btn-light ${styles.du_btn}`} id="dropdown-basic">
                  <div className="cursor-pointer  text-nowrap w-30 " style={{ fontSize: "12px" }}>
                    Status{" "}
                    <span
                      className={`${styles.spanDiv}`}
                      // style={{ color: " " }}
                    >{`(${status})`}</span>{" "}
                    <img src={sortAsc} alt="Sort Ascending Icon" />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setStatus("Available")}>Available</Dropdown.Item>
                  <Dropdown.Item onClick={() => setStatus("Sold")}>Sold</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Button>
          </div>
        </div>
      ) : (
        <div className={`d-flex justify-content-between `}>
          <div className={`d-flex flex-row ${styles.categoryTabsLinkContainer}`}>
            {listOfCategories.map((cat) => (
              <div key={cat.id} className={`p-2 cursor-pointer rounded-top text-nowrap w-70 ${styles.categoryTabsLink} ${cat.id === selectedCategory ? styles.categoryTabsLinkActive : ""}`} onClick={() => setSelectedCategory(cat.id)}>
                {cat.category}
              </div>
            ))}
          </div>
          <div
            className={` ${styles.statusDropdownSpan}`}

            // style={{ borderLeft: "4px solid", marginLeft: "10px" }}
            // className={`${  styles.categoryTabsButton} `}
          >
            <Dropdown className={`d-flex justify-content-end `}>
              <Dropdown.Toggle className={`border-0 p-0  dropdown-toggle show btn btn-light ${styles.du_btn}`} id="dropdown-basic">
                <div className="p-2 cursor-pointer rounded-top text-nowrap w-30">
                  Status <span style={{ color: " rgb(149, 55, 255)" }}>{`(${status})`}</span> <img src={sortAsc} alt="Sort Ascending Icon" />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setStatus("Available")}>Available</Dropdown.Item>
                <Dropdown.Item onClick={() => setStatus("Sold")}>Sold</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      )}
    </div>
  );
}

export default SalesPageCategoryTabs;
