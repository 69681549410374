import React from "react";
import { Col, Row, Container, Button } from "react-bootstrap";
import style from "../styles/Reports.module.css";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import styles from "../styles/Setting.module.css";
import DailyCashReport from "../Components/Reports/DailyCashReport";
import DailyTransactionalReport from "../Components/Reports/DailyTransactionalReport";
import ReactToPrint from "react-to-print";
import print from "../assets/svg/printIcon.svg";
import PrintDailyCashReport from "./../Components/Reports/PrintDailyCashReport";
import GeneralReport from "../Components/Reports/GeneralReport";
import ProfitLossReport from "../Components/Reports/ProfitLossRepor";
import InventoryReports from "../Components/Reports/InventoryReports/InventoryReports";
import SalesReports from "../Components/Reports/SalesReports/SalesReports";
import ImeiReport from "../Components/Reports/ImeiReport";
import filterStyle from "../styles/filtersButtons.module.css";
import filterr from "../assets/svg/filter.svg";
import MobileReportsTabs from "../Components/Reports/MobileReportsTabs";
import SalesSummary from "../Components/Reports/SalesReports/SalesSummary";
import Journal from "../Components/Reports/Journal";
import BankTransactionsReport from "../Components/Reports/BankTransactionsReport";
import MiscItemsHistoryReport from "../Components/Reports/MiscItemsHistoryReport";
import { Route, Router, Routes, useNavigate, useParams } from "react-router-dom";
import { reportsNameMapping } from "../utils/reportsNameMapping.js";

const Reports = () => {
  const { "*": subReport } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(undefined);
  const [openingBalance, setOpeningBalance] = useState();
  const [cashInHandProp, setCashInHandProp] = useState();
  const [grandTotalProp, setGrandTotalProp] = useState();
  const [totalSalesPaymentDataProp, setTotalSalesPaymentDataProp] = useState([]);
  const [totalVendorPaymentsDataProp, setTotalVendorPaymentsDataProp] = useState([]);
  const [totalExpensesDataProp, setTotalExpensesDataProp] = useState([]);
  const [totalRefundsDataProp, setTotalRefundsDataProp] = useState([]);
  const [totalWithdrawAmountProp, setTotalWithdrawAmountProp] = useState();
  const [totalDepositeAmountProp, setTotalDepositeAmountProp] = useState();
  const [showMobileTabs, setShowMobileTabs] = useState(false);
  const [showMobileFilter, setShowMobileFilter] = useState(false);

  const handleFilterClose = () => setShowMobileFilter(false);
  const handleFilterShow = () => setShowMobileFilter(true);
  const handleClose = () => setShowMobileTabs(false);
  const handleShow = () => setShowMobileTabs(true);

  const [noRegisterCashReportProp, setNoRegisterCashReportProp] = useState(true);

  const componentRef = useRef(null);

  const [fromDate, setFromDate] = useState(moment().subtract(29, "days").startOf("day")._d);
  const [toDate, setToDate] = useState(moment().endOf("day")._d);

  const user = useSelector((state) => state.auth.user);
  const permissions = user?.modules
    ?.find((nav) => nav.name === "Reports")
    ?.subModules?.reduce((acc, nav) => {
      return { ...acc, [nav.name]: nav.isIncluded };
    }, {});

  useEffect(() => {
    if (subReport === undefined || subReport.length === 0) {
      setActiveTab(undefined);
    } else {
      setActiveTab(subReport);
    }
  }, [subReport]);

  const handleTabChange = (tab) => {
    if (tab) {
      navigate("/reports/" + tab);
    } else {
      navigate("/reports");
    }
  };

  return (
    <>
      {showMobileTabs === true ? (
        <MobileReportsTabs handleTabChange={handleTabChange} activeTab={activeTab} show={showMobileTabs} handleClose={handleClose} />
      ) : (
        <>
          <div className="d-none">
            <PrintDailyCashReport
              ref={componentRef}
              totalSalesPaymentData={totalSalesPaymentDataProp}
              totalVendorPaymentsData={totalVendorPaymentsDataProp}
              totalExpensesData={totalExpensesDataProp}
              totalRefundsData={totalRefundsDataProp}
              totalDepositeAmount={totalDepositeAmountProp}
              totalWithdrawAmount={totalWithdrawAmountProp}
              openingBalanceData={openingBalance}
            />
          </div>
          <Container fluid>
            <Row className="py-3 d-none d-lg-flex d-md-flex d-sm-flex  ">
              <Col xs="6" sm="6" lg="6" className="mb-3">
                <span className={`${style.soldproducts}`}>Reports</span>
              </Col>
              {activeTab === "daily-cash" && !noRegisterCashReportProp ? (
                <Col md="4" lg="4" xl="1" xs="6" sm="6" className={`d-flex justify-content-end d-flex d-sm-none  `}>
                  <ReactToPrint
                    trigger={() => (
                      <Button variant="outline-primary fs-14" style={{ height: "38px" }}>
                        <img src={print} alt="removeIcon" className="me-2" style={{ marginTop: "-3px" }} />
                        Print
                      </Button>
                    )}
                    content={() => componentRef.current}
                    documentTitle="Daily Cash Report"
                  >
                    {" "}
                  </ReactToPrint>
                </Col>
              ) : (
                ""
              )}

              {/* {activeTab === "General-Report" ? (
            <>
              <hr className="d-inline d-lg-none" />
              <Col xs={12} md="6" lg="6">
                <div
                  className={`d-flex align-items-center ms-auto ${style.dateFilter}`}
                >
                  <label className="me-4">Period Type:</label>
                  <DatePicker
                    fromDate={fromDate}
                    toDate={toDate}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                  />
                </div>
              </Col>
            </>
          ) : (
            ""
          )} */}
            </Row>

            <div className="d-flex gap-3 mb-2 justify-content-center d-lg-none d-md-none d-sm-none ">
              <Button onClick={handleShow} variant="outline-primary" className={`${filterStyle.filterToggleButton} d-flex justify-content-between align-items-center w-100`}>
                <span className=" ms-2 d-lg-inline d-sm-inline d-inline ">{reportsNameMapping[activeTab]}</span>
                <img className="" src={filterr} alt="filter" />
              </Button>
              <Button onClick={() => handleFilterShow()} variant="outline-primary" className={`${filterStyle.filterToggleButton} d-flex align-items-center`}>
                <img className="" src={filterr} alt="filter" />
              </Button>
            </div>

            <div className={`d-sm-flex d-none overflow-auto text-nowrap ${style.reportsNavButtons} `}>
              {permissions?.GeneralReport ? (
                <Button onClick={() => handleTabChange(undefined)} className={` ${styles.btns}  ${activeTab === undefined ? styles.activebtn : ""} border-0   mb-2`} variant="outline-primary">
                  General Report
                </Button>
              ) : (
                ""
              )}
              {permissions?.DailyCashReport ? (
                <Button onClick={() => handleTabChange("daily-cash")} className={`${styles.btns} ${activeTab === "daily-cash" ? styles.activebtn : ""}  border-0  mb-2`} variant="outline-primary">
                  Daily Cash Report
                </Button>
              ) : (
                ""
              )}
              {permissions?.DailyTransactionalReport ? (
                <Button onClick={() => handleTabChange("daily-transactional")} className={`${styles.btns} ${activeTab === "daily-transactional" ? styles.activebtn : ""}  border-0  mb-2`} variant="outline-primary">
                  Daily Transactional Report
                </Button>
              ) : (
                ""
              )}
              {permissions?.ProfitLossReport ? (
                <Button onClick={() => handleTabChange("profit-loss")} className={` ${styles.btns} ${activeTab === "profit-loss" ? styles.activebtn : ""}  border-0  mb-2`} variant="outline-primary">
                  Profit/Loss Report
                </Button>
              ) : (
                ""
              )}
              {permissions?.InventoryReport ? (
                <Button onClick={() => handleTabChange("inventory")} className={` ${styles.btns} ${activeTab === "inventory" ? styles.activebtn : ""} border-0   mb-2`} variant="outline-primary">
                  Inventory Report
                </Button>
              ) : (
                ""
              )}
              {permissions?.SalesReport ? (
                <Button onClick={() => handleTabChange("sales")} className={`${styles.btns} ${activeTab === "sales" ? styles.activebtn : ""} border-0   mb-2`} variant="outline-primary">
                  Sales Report
                </Button>
              ) : (
                ""
              )}
              {permissions?.SalesSummaryReport ? (
                <Button onClick={() => handleTabChange("sales-summary")} className={`${styles.btns} ${activeTab === "sales-summary" ? styles.activebtn : ""}  border-0  mb-2`} variant="outline-primary">
                  Sales Summary
                </Button>
              ) : (
                ""
              )}
              {permissions?.JournalReport ? (
                <Button onClick={() => handleTabChange("journal")} className={`${styles.btns} ${activeTab === "journal" ? styles.activebtn : ""} border-0   mb-2`} variant="outline-primary">
                  Journal
                </Button>
              ) : (
                ""
              )}
              {permissions?.IMEIReport ? (
                <Button onClick={() => handleTabChange("imei")} className={`${styles.btns} ${activeTab === "imei" ? styles.activebtn : ""}  border-0  mb-2`} variant="outline-primary">
                  IMEI Report
                </Button>
              ) : (
                ""
              )}
              {permissions?.BankTransactionsReport ? (
                <Button onClick={() => handleTabChange("bank-transactions")} className={`${styles.btns} ${activeTab === "bank-transactions" ? styles.activebtn : ""}  border-0  mb-2`} variant="outline-primary">
                  Bank Transactions Report
                </Button>
              ) : (
                ""
              )}
              {permissions?.MiscItemsHistoryReport ? (
                <Button onClick={() => handleTabChange("misc-items-history")} className={`${styles.btns} ${activeTab === "misc-items-history" ? styles.activebtn : ""} border-0 mb-2`} variant="outline-primary">
                  Misc Items History Report
                </Button>
              ) : (
                ""
              )}
            </div>

            <Routes>
              <Route path="" element={<GeneralReport MainactiveTab={activeTab} show={showMobileFilter} close={handleFilterClose} setFromDate={setFromDate} setToDate={setToDate} fromDate={fromDate} toDate={toDate} />} />
              <Route
                path="daily-cash"
                element={
                  <DailyCashReport
                    setOpeningBalanceProp={setOpeningBalance}
                    show={showMobileFilter}
                    close={handleFilterClose}
                    MainactiveTab={activeTab}
                    setCashInHandProp={setCashInHandProp}
                    setTotalSalesPaymentDataProp={setTotalSalesPaymentDataProp}
                    setTotalRefundsDataProp={setTotalRefundsDataProp}
                    setTotalExpensesDataProp={setTotalExpensesDataProp}
                    setTotalVendorPaymentsDataProp={setTotalVendorPaymentsDataProp}
                    setTotalWithdrawAmountProp={setTotalWithdrawAmountProp}
                    setTotalDepositeAmountProp={setTotalDepositeAmountProp}
                    noRegisterCashReportProp={noRegisterCashReportProp}
                    setNoRegisterCashReportProp={setNoRegisterCashReportProp}
                  />
                }
              />
              <Route path="daily-transactional" element={<DailyTransactionalReport show={showMobileFilter} close={handleFilterClose} MainactiveTab={activeTab} setGrandTotalProp={setGrandTotalProp} />} />
              <Route path="profit-loss" element={<ProfitLossReport show={showMobileFilter} close={handleFilterClose} MainactiveTab={activeTab} />} />
              <Route path="inventory" element={<InventoryReports show={showMobileFilter} close={handleFilterClose} MainactiveTab={activeTab} />} />
              <Route path="sales" element={<SalesReports show={showMobileFilter} close={handleFilterClose} MainactiveTab={activeTab} />} />
              <Route path="sales-summary" element={<SalesSummary show={showMobileFilter} close={handleFilterClose} MainactiveTab={activeTab} />} />
              <Route path="journal" element={<Journal MainactiveTab={activeTab} show={showMobileFilter} close={handleFilterClose} setFromDate={setFromDate} setToDate={setToDate} fromDate={fromDate} toDate={toDate} />} />
              <Route path="imei" element={<ImeiReport />} />
              <Route path="bank-transactions" element={<BankTransactionsReport MainactiveTab={activeTab} show={showMobileFilter} close={handleFilterClose} setFromDate={setFromDate} setToDate={setToDate} fromDate={fromDate} toDate={toDate} />} />
              <Route path="misc-items-history" element={<MiscItemsHistoryReport MainactiveTab={activeTab} show={showMobileFilter} close={handleFilterClose} setFromDate={setFromDate} setToDate={setToDate} fromDate={fromDate} toDate={toDate} />} />
            </Routes>

            {/* {activeTab === "General-Report" ? (
              <GeneralReport MainactiveTab={activeTab} show={showMobileFilter} close={handleFilterClose} setFromDate={setFromDate} setToDate={setToDate} fromDate={fromDate} toDate={toDate} />
            ) : activeTab === "daily-cash" ? (
              <DailyCashReport
                setOpeningBalanceProp={setOpeningBalance}
                show={showMobileFilter}
                close={handleFilterClose}
                MainactiveTab={activeTab}
                setCashInHandProp={setCashInHandProp}
                setTotalSalesPaymentDataProp={setTotalSalesPaymentDataProp}
                setTotalRefundsDataProp={setTotalRefundsDataProp}
                setTotalExpensesDataProp={setTotalExpensesDataProp}
                setTotalVendorPaymentsDataProp={setTotalVendorPaymentsDataProp}
                setTotalWithdrawAmountProp={setTotalWithdrawAmountProp}
                setTotalDepositeAmountProp={setTotalDepositeAmountProp}
                noRegisterCashReportProp={noRegisterCashReportProp}
                setNoRegisterCashReportProp={setNoRegisterCashReportProp}
              />
            ) : activeTab === "daily-transactional" ? (
              <DailyTransactionalReport show={showMobileFilter} close={handleFilterClose} MainactiveTab={activeTab} setGrandTotalProp={setGrandTotalProp} />
            ) : activeTab === "profit-loss" ? (
              <ProfitLossReport show={showMobileFilter} close={handleFilterClose} MainactiveTab={activeTab} />
            ) : activeTab === "inventory" ? (
              <InventoryReports show={showMobileFilter} close={handleFilterClose} MainactiveTab={activeTab} />
            ) : activeTab === "sales" ? (
              <SalesReports show={showMobileFilter} close={handleFilterClose} MainactiveTab={activeTab} />
            ) : activeTab === "sales-summary" ? (
              <SalesSummary show={showMobileFilter} close={handleFilterClose} MainactiveTab={activeTab} />
            ) : activeTab === "journal" ? (
              <Journal MainactiveTab={activeTab} show={showMobileFilter} close={handleFilterClose} setFromDate={setFromDate} setToDate={setToDate} fromDate={fromDate} toDate={toDate} />
            ) : activeTab === "imei" ? (
              <ImeiReport />
            ) : activeTab === "bank-transactions" ? (
              <BankTransactionsReport MainactiveTab={activeTab} show={showMobileFilter} close={handleFilterClose} setFromDate={setFromDate} setToDate={setToDate} fromDate={fromDate} toDate={toDate} />
            ) : activeTab === "misc-items-history" ? (
              <MiscItemsHistoryReport MainactiveTab={activeTab} show={showMobileFilter} close={handleFilterClose} setFromDate={setFromDate} setToDate={setToDate} fromDate={fromDate} toDate={toDate} />
            ) : (
              ""
            )} */}
          </Container>
        </>
      )}
    </>
  );
};

export default Reports;
