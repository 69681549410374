import React from "react";
import NoResult from "../../NoResult";
import ItemCard from "./Card/ItemCard";
import { CardGroup } from "react-bootstrap";
import style from "./view.module.css";
import LoaderPage from "../../loaders/loaderPage";

function GridView({ data, isLoading, viewMode }) {
  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <LoaderPage />
        </div>
      ) : (
        <>
          {data?.length > 0 ? (
            <CardGroup className={`${style.gridContainer} ${viewMode === "smallGrid" ? "smallGrid" : ""} mt-2`}>
              {data?.map((p, index) => (
                <ItemCard key={index} p={p} reserved={p.reserved_quantity} />
              ))}
            </CardGroup>
          ) : (
            <NoResult path={"/inventory/add"} name="Inventory" />
          )}
        </>
      )}
    </>
  );
}

export default GridView;
