import axios from "axios";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Accordion, Button, Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CustomizeTableview from "../Components/TableViews/CustomizeTableview";
import RequestModal from "../Components/plans/RequestModal";
import PopUpModal from "../Components/Modal";
import styles from "../styles/Subscription.module.css";
import LoaderPage from "../Components/loaders/loaderPage";
import PlanExtensionRequest from "../Components/Addons/PlanExtensionRequest";
import AddonsRequestModal from "../Components/Addons/AddonsRequestModal";
import PaginationBar from "../Components/PaginationBar";
import { currentUser } from "../Features/authSlice";
const Subscriptions = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const shop_id = user.shop_id;
  const refreshToken = localStorage.getItem("refreshToken");

  const [onTrial, setOnTrial] = useState([]);
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [onPayment, setOnPayment] = useState([]);
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [addonsRequestModal, setAddonsRequestModal] = useState(false);
  const [planExtensionRequestModal, setPlanExtensionRequestModal] = useState(false);
  const [refresh, toggle] = useState(false);
  const [addonsDetails, setAddonsDetails] = useState([]);
  const [caretData, setCaretData] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (shop_id) {
      getTransactionDetails();
      getSubscriptionDetails();
      getSubscriptionStatus();
    }
  }, [shop_id]);

  const getSubscriptionStatus = async () => {
    try {
      const res = await axios.get(process.env.REACT_APP_API_URL + `/subscriptions/status/${shop_id}`, {
        withCredentials: true,
      });
      if (res) {
        setOnTrial(res.data[0].on_trial);
        setOnPayment(res.data[0].on_payment);
        setIsLoading(false);
      }
    } catch (err) {
      throw new Error(err);
    }
  };
  const getTransactionDetails = async () => {
    axios
      .get(process.env.REACT_APP_API_URL + `/subscriptions/transaction-details/${shop_id}`, { withCredentials: true })
      .then((res) => {
        setTransactionDetails(res.data);
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
  const getSubscriptionDetails = async () => {
    axios
      .get(process.env.REACT_APP_API_URL + `/subscriptions/subscription-details/${shop_id}`, { withCredentials: true })
      .then((res) => {
        setSubscriptionDetails(res.data?.subscription);
        if (res.data?.subscription[0].current_plan_id !== user.plan_id) {
          dispatch(currentUser(refreshToken));
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
  useEffect(() => {
    getAddonsDetails();
  }, [refresh]);
  const plansCard = () => {
    navigate("/settings/plans", {
      state: subscriptionDetails[subscriptionDetails.length - 1]?.next_plan_start_date,
    });
  };
  const getAddonsDetails = async () => {
    axios
      .get(process.env.REACT_APP_API_URL + `/addons/requests?size=5&page=1`, { withCredentials: true })
      .then((res) => {
        setAddonsDetails(
          res?.data?.data?.requests.map((item) => ({
            ...item,
            addons_type: item.is_for_extension === 1 ? "Plan Extension" : "Consumable Item",
          }))
        );
      })
      .catch((err) => {
        // throw new Error(err);
        setAddonsDetails([]);
      });
  };
  const caret = async (id) => {
    const data = addonsDetails.filter((item) => item.id === id);
    if (data[0].is_for_extension === 1) {
      const value = {
        requested_duration: data[0].values.requested_duration,
        current_end_date: data[0].values.current_end_date.split("T")[0].split("-").reverse().join("-"),
        previous_end_date: data[0].values.previous_end_date.split("T")[0].split("-").reverse().join("-"),
        banner_showing_date: data[0].values.banner_showing_date.split("T")[0].split("-").reverse().join("-"),
        grace_period_end_date: data[0].values.grace_period_end_date.split("T")[0].split("-").reverse().join("-"),
      };
      setCaretData(value);
    } else {
      const value = Object.entries(data[0].values).reduce((acc, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      }, {});
      setCaretData(value);
    }
  };

  return isLoading ? (
    <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
      <LoaderPage />
    </div>
  ) : (
    <>
      <Row className="justify-content-center w-100">
        <Col lg={9} xl={7} md={8} sm="12" xs="12">
          <Card className={`${styles.card}`}>
            <div className={`py-1 ${styles.title}`}>Subscription Details</div>
            <Card.Body className="p-0 w-100 me-0 border-bottom">
              <Row className="align-items-center w-100 m-0">
                <Col className="p-0" lg={4}>
                  <div className="d-flex fw-semibold justify-content-between px-3">
                    <span>Current Plan:</span>
                    <span className={` fs-14 ${styles.w_30}`} style={{ fontStyle: "italic" }}>
                      {subscriptionDetails[0]?.current_plan_name}
                    </span>
                  </div>
                  <div className="d-flex  justify-content-between px-3">
                    <span className=" text-center    fw-semibold">Price: </span>
                    <div className={`${styles.w_30}`}>
                      {" "}
                      <span className="text-left fs-14 ">
                        {" "}
                        Rs.{" "}
                        {subscriptionDetails[0]?.current_plan_billing_frequency === "yearly"
                          ? subscriptionDetails[0]?.yearly_grand_total
                          : subscriptionDetails[0]?.current_plan_billing_frequency === "quarterly"
                          ? subscriptionDetails[0]?.quarterly_grand_total
                          : subscriptionDetails[0]?.monthly_grand_total}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex  justify-content-between px-3">
                    <span className="fw-semibold">Expires On:</span>
                    <span className={`${styles.w_30} fs-14`}>{subscriptionDetails[0]?.current_plan_end_date.split("T")[0]}</span>
                  </div>
                  <div className="d-flex  justify-content-between px-3">
                    <span className="fw-semibold">Next Plan:</span>
                    <span className={`${styles.w_30} fs-14`}>
                      {subscriptionDetails[0]?.next_plan_name === null ? (
                        <span>
                          <hr style={{ border: "black solid 1px", width: "5rem" }} />
                        </span>
                      ) : (
                        subscriptionDetails[0]?.next_plan_name
                      )}
                    </span>
                  </div>
                  {subscriptionDetails[0]?.next_plan_start_date ? (
                    <div className="d-flex  justify-content-between px-3">
                      <span className="fw-semibold">Next Plan Start Date:</span>
                      <span className={`${styles.w_30} fs-14`}>{subscriptionDetails[0]?.next_plan_start_date.split("T")[0]}</span>
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
                <Col lg={8} className="pe-0">
                  <table className="table table-borderless border-start mb-0">
                    <thead className="">
                      <tr className="border-bottom">
                        <th scope="col">#</th>
                        <th scope="col" className="   text-center">
                          Limit
                        </th>
                        <th className="text-center" scope="col">
                          Spent
                        </th>
                        <th scope="col" className="strippedColumn text-center">
                          Available
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border-bottom ">
                        <th scope="row">Marketplace Items</th>
                        <td className="strippedColumn text-center">{subscriptionDetails[0]?.marketplace_items_limit}</td>
                        <td className="text-center">{subscriptionDetails[0]?.consumed_marketplace_items}</td>
                        <td className="strippedColumn text-center">{Number(subscriptionDetails[0]?.marketplace_items_limit) - Number(subscriptionDetails[0]?.consumed_marketplace_items)}</td>
                      </tr>
                      <tr className="border-bottom ">
                        <th scope="row">SMS</th>
                        <td className="strippedColumn text-center">{subscriptionDetails[0]?.sms_limit}</td>
                        <td className="text-center">{subscriptionDetails[0]?.consumed_sms}</td>
                        <td className="strippedColumn text-center">{Number(subscriptionDetails[0]?.sms_limit) - Number(subscriptionDetails[0]?.consumed_sms)}</td>
                      </tr>
                      <tr className="border-bottom">
                        <th scope="row">Email</th>
                        <td className="strippedColumn text-center">{subscriptionDetails[0]?.emails_limit}</td>
                        <td className="text-center">{subscriptionDetails[0]?.consumed_emails}</td>
                        <td className="strippedColumn text-center">{Number(subscriptionDetails[0]?.emails_limit) - Number(subscriptionDetails[0]?.consumed_emails)}</td>
                      </tr>
                      <tr>
                        <th scope="row">Locations</th>
                        <td className="strippedColumn text-center">{subscriptionDetails[0]?.locations_limit}</td>
                        <td className="text-center">{subscriptionDetails[0]?.consumed_locations}</td>
                        <td className="strippedColumn text-center">{Number(subscriptionDetails[0]?.locations_limit) - Number(subscriptionDetails[0]?.consumed_locations)}</td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Card.Body>
            <div className="pb-4 px-3 d-flex flex-column flex-md-row gap-1 gap-md-3 mt-3">
              {onPayment === 1 ? (
                <>
                  <Button style={{ padding: "0.4rem" }} onClick={plansCard}>
                    Change Plan
                  </Button>

                  <Button
                    variant="outline-primary"
                    className="px-4"
                    style={{ padding: "0.4rem" }}
                    onClick={() => {
                      setModalShow(true);
                    }}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                ""
              )}
              <Button
                variant="outline-primary"
                className="px-4"
                style={{ padding: "0.4rem" }}
                onClick={() => {
                  setPlanExtensionRequestModal(true);
                }}
              >
                Plan Extension Request
              </Button>

              <Button
                variant="outline-primary"
                className="px-4"
                style={{ padding: "0.4rem" }}
                onClick={() => {
                  setAddonsRequestModal(true);
                }}
              >
                Consumable Items Request
              </Button>
            </div>
          </Card>
        </Col>
      </Row>

      {onTrial === 1 ? (
        <h4 className="text-center pt-3" style={{ color: "gray", lineHeight: "3rem" }}>
          You are using Trial Version. Subscribe for <Button onClick={plansCard}>Paid Plan</Button> for awesome features
        </h4>
      ) : onPayment === 1 ? (
        <>
          <Accordion defaultActiveKey="0" style={{ width: "75%", margin: "auto" }} className="mt-5">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Transaction Details</Accordion.Header>
              <Accordion.Body className="p-0">
                {transactionDetails.length > 0 ? (
                  <CustomizeTableview className="" data={transactionDetails} actions={false} dateColumn={["transaction_date"]} />
                ) : (
                  <div className="my-2 text-center">
                    <strong>No Transaction Found</strong>
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Addons Request Details</Accordion.Header>
              <Accordion.Body className="p-0">
                {addonsDetails.length > 0 ? (
                  <CustomizeTableview data={addonsDetails} actions={false} columnNotShow={["id", "shop_id", "values", "is_paid", "is_for_extension"]} caret={caret} caretData={caretData} />
                ) : (
                  <div className="my-2 text-center">
                    <strong>No Addons Request Found</strong>
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </>
      ) : (
        <></>
      )}

      <PopUpModal title="Request" show={modalShow} onHide={() => setModalShow(false)}>
        <RequestModal
          shop_id={shop_id}
          request_status="pending"
          title="Plan-Cancellation-Request"
          priority="high"
          request_type="plan-cancellation-request"
          frequency={user?.current_plan_billing_frequency}
          describe="Please Describe cancellation reason"
          placeholder="Why do you want to cancel your plan?"
          planId={user?.plan_id}
          close={() => setModalShow(false)}
        />
      </PopUpModal>

      <PopUpModal title="Plan Extension Request" show={planExtensionRequestModal} onHide={() => setPlanExtensionRequestModal(false)}>
        <PlanExtensionRequest toggle={toggle} close={() => setPlanExtensionRequestModal(false)} data={user} />
      </PopUpModal>
      <PopUpModal title="Addons Request" show={addonsRequestModal} onHide={() => setAddonsRequestModal(false)}>
        <AddonsRequestModal toggle={toggle} close={() => setAddonsRequestModal(false)} data={user} />
      </PopUpModal>
    </>
  );
};

export default Subscriptions;
