import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row, Toast } from "react-bootstrap";
import removeIcon from "../../assets/svg/remove.svg";
import AddIMEIS from "./AddIMEIS";
import style from "./../../styles/Sales.module.css";
import axios from "axios";
import { useSelector } from "react-redux";
import SearchableSelect from "../../Components/SearchableSelect";
import PromptMoal from "./PromptMoal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEdit, faPen, faXmark } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

function AddProduct({ setTotalAmount, setPaidBy, refresh, setPaidAmount, reSetLocation, setselectedItems, items = [], dealerLocation, promptModal, purchaseId, isClicked, setValidated, setPromptModal, edit, id }) {
  const [Total, setTotal] = useState();
  const [imeiModal, setImeiModal] = useState(false);
  // const [editIMEI, setEditIMEI] = useState();
  const [procuct, setProcuct] = useState([]);
  const [procuctFields, setProcuctFields] = useState([]);
  const [selectedProducts, setselectedProducts] = useState([]);
  const [itemId, setItemId] = useState();
  const [isPageVisible, setPageVisibility] = useState(true);
  const [editSItem, seteditSItem] = useState(-1);
  const { user } = useSelector((state) => state.auth);
  const [theProduct, settheProduct] = useState([]);
  const [IMEIS, setIMEIS] = useState();
  const [show, setShow] = useState(false);
  const handleImeiModal = (i) => {
    setItemId(i);
    setImeiModal(true);
  };

  const getImeis = (e, i, name) => {
    const list = [...selectedProducts];
    list[i].imeis = e;
    setselectedProducts(list);
  };

  // get data for edit
  function getRandomColor() {
    const tagColors = ["rgba(149, 55, 255, 0.15)", "rgba(217, 87, 249, 0.15)", "rgba(255, 209, 102, 0.15)", "rgba(6, 214, 160, 0.15)", "rgba(17, 138, 178, 0.15)", "rgba(7, 59, 76, 0.15)"];

    const randomIndex = Math.floor(Math.random() * tagColors.length);
    return tagColors[randomIndex];
  }
  useEffect(() => {
    if (purchaseId) {
      setselectedProducts(
        items?.map((item) => {
          return {
            label: item.title,
            in_stock: item.in_stock_quantity,
            costPrice: item.cost_price,
            Quantity: item.purchase_quantity,
            id: item.item_id,
            img0: item.img0,
            batch_id: item.batch_id,
            imeis: item.imei_no.map((im) => {
              return {
                color: getRandomColor(),
                imeiNumber: im,
              };
            }),
            // value:
          };
        }),
      );
    }
  }, [items]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const res = await axios.post(process.env.REACT_APP_API_URL + `/inventory/get-inventory/${user.user_id}`, { location_nick: dealerLocation.label, call_for_po: true }, { withCredentials: true });

        const data = res?.data?.data;
        setProcuct(
          data.map((item) => {
            if (item.category_id === 2 || item.category_id === 3) {
              return {
                brand: item.brand,
                category_id: item.category_id,
                condition_item: item.condition_item,
                created_at: item.created_at,
                img0: item.img0,
                type: item.type,
                in_stock: item.in_stock,
                location: item.location,
                sale_price: item.sale_price,
                stock_no: item.stock_no,
                value: item.id,
                id: item.id,
                label: item.title,
                Quantity: "",
                costPrice: "",
                imeis: "",
              };
            } else {
              return {
                brand: item.brand,
                category_id: item.category_id,
                condition_item: item.condition_item,
                created_at: item.created_at,
                img0: item.img0,
                type: item.type,
                in_stock: item.in_stock,
                location: item.location,
                sale_price: item.sale_price,
                stock_no: item.stock_no,
                value: item.id,
                id: item.id,
                label: item.title,
                Quantity: "",
                costPrice: "",
              };
            }
          }),
        );
        setProcuctFields(
          data
            .map((item) => {
              if (item.category_id === 2 || item.category_id === 3) {
                return {
                  brand: item.brand,
                  category_id: item.category_id,
                  condition_item: item.condition_item,
                  created_at: item.created_at,
                  img0: item.img0,
                  type: item.type,
                  in_stock: item.in_stock,
                  location: item.location,
                  sale_price: item.sale_price,
                  stock_no: item.stock_no,
                  value: item.id,
                  id: item.id,
                  label: item.title,
                  Quantity: "",
                  costPrice: "",
                  imeis: "",
                };
              } else {
                return {
                  brand: item.brand,
                  category_id: item.category_id,
                  condition_item: item.condition_item,
                  created_at: item.created_at,
                  img0: item.img0,
                  type: item.type,
                  in_stock: item.in_stock,
                  location: item.location,
                  sale_price: item.sale_price,
                  stock_no: item.stock_no,
                  value: item.id,
                  id: item.id,
                  label: item.title,
                  Quantity: "",
                  costPrice: "",
                };
              }
            })
            .filter((item) => {
              return !selectedProducts.some((it) => it.id === item.id);
            }),
        );
      } catch (error) {
        throw new Error(error.response.data);
      }
    };
    fetchProducts();
  }, [dealerLocation, isPageVisible]);
  useEffect(() => {
    setProcuctFields(
      procuct.filter((item) => {
        return !selectedProducts.some((it) => it.id === item.id);
      }),
    );
  }, [selectedProducts]);
  const selectProduct = (e) => {
    setValidated(false);
    settheProduct(e);
    setselectedProducts([...selectedProducts, e]);
  };

  const handleInputChange = (e, i, name) => {
    const items = [...selectedProducts];
    if (name === "costPrice") {
      if (Number(e.target.value) === 0) {
        items[i].costPrice = "";
      } else {
        if (e.target.value.length <= 20) {
          items[i].costPrice = Number(e.target.value.replace(/-/g, ""));
        }
      }
    } else if ((name = "Quantity")) {
      if (Number(e.target.value) === 0) {
        items[i].Quantity = ""; 
      } else {
        if (e.target.value.length <= 20) {
          items[i].Quantity = Number(e.target.value.replace(/-/g, ""));
        }
      }
    }
    setselectedProducts(items);
  };

  useEffect(() => {
    setselectedItems(selectedProducts);
    let totalPrice = 0;
    selectedProducts?.forEach((item) => {
      const price = item.costPrice;
      const quantity = item.Quantity;
      const itemTotalPrice = price * quantity;
      totalPrice += itemTotalPrice;
    });
    setTotalAmount(totalPrice);
    setTotal(totalPrice);
    if (selectedProducts.length === 0 && !purchaseId) {
      setPaidAmount("");
      setPaidBy("");
    }
  }, [selectedProducts]);
  const removeItem = (i) => {
    const list = [...selectedProducts];
    list.splice(i, 1);
    setselectedProducts(list);
    if (list.length === 0) {
      settheProduct([]);
    }
  };
  const removeProducts = () => {
    if (selectedProducts.length > 0) {
      reSetLocation();
      setselectedProducts([]);
    }
  };
  useEffect(() => {
    settheProduct([]);
  }, [dealerLocation]);

  const addNew = () => {
    window.open(process.env.REACT_APP_SELLER_URL + "/inventory/add", "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const handleVisibilityChange = () => {
    setPageVisibility(!document.hidden);
  };
  const editItem = (id) => {
    seteditSItem(id);
  };

  const updateItem = async (item) => {
    try {
      const res = await axios.put(
        process.env.REACT_APP_API_URL + `/purchase-order/${item.batch_id}`,
        {
          purchase_quantity: item.Quantity,
          cost_price: item.costPrice,
          item_id: item.id,
          purchase_order_id: purchaseId,
        },
        { withCredentials: true },
      );
      if (res) {
        seteditSItem(-1);
        toast.success("Product updated Sucessfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1500,
          // pauseOnHover: false,
          className: "toastMsg",
        });
        refresh((prev) => !prev);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1500,
        // pauseOnHover: false,
        className: "toastMsg",
      });
    }
  };
  const deleteItem = async (id, index) => {
    try {
      const res = await axios.delete(process.env.REACT_APP_API_URL + `/batch/purchase-order/${id}`, { withCredentials: true });
      if (res) {
        seteditSItem(-1);
        removeItem(index);
        refresh((prev) => !prev);
      }
    } catch (error) {}
  };
  return (
    <div className="">
      {!purchaseId && (
        <Row className="align-items-center">
          <Col md="9" lg="10">
            {" "}
            <Form.Group className="mb-3" controlId="formBasicTiming">
              <div className={``}>
                <SearchableSelect label="Select Product" placeholder="Select " important options={procuctFields} disabled={dealerLocation.label && !purchaseId ? false : true} handleChange={selectProduct} value={Object.keys(selectedProducts).length > 0 ? "" : null} />
                <Form.Control value={theProduct} hidden name="porducts" required />
                <Form.Control.Feedback type="invalid">Please Select Product</Form.Control.Feedback>
              </div>
            </Form.Group>
          </Col>
          <Col className=" " md="3" lg="2">
            <Button variant="primary" onClick={addNew} type="button" id="submitButton" className={`w-100  ${isClicked ? "mb-3 " : ""}`} data-bs-toggle="tooltip" data-bs-placement="left" title="Add new product">
              + Add New
            </Button>
          </Col>
        </Row>
      )}
      {selectedProducts.length > 0 && (
        <Row className="border-bottom d-lg-flex d-md-flex d-sm-flex d-none mb-2 pb-1">
          <Col lg="4" md="4" sm="4">
            <label className="text-muted fs-13">Products</label>
          </Col>
          {/* {!purchaseId && ( */}
          <Col lg="2" md="2" sm="2">
            <label className="text-muted fs-13"> In Stock</label>
          </Col>
          {/* )} */}
          <Col lg="2" md="2" sm="2">
            <label className="text-muted fs-13">Cost price</label>
          </Col>
          <Col lg="2" md="2" sm="2">
            <label className="text-muted fs-13">Qty</label>
          </Col>
          {purchaseId && !edit ? (
            ""
          ) : (
            <Col lg="2" md="2" sm="2">
              <label className="text-muted fs-13">Action</label>
            </Col>
          )}
        </Row>
      )}
      <div className={`  ${!edit ? style.prodDiv : style.EditprodDiv} `}>
        {selectedProducts?.map((item, index) => (
          <Row className=" pt-3 border-bottom">
            <Col className="d-flex justify-content-between" lg="4" md="4" sm="4">
              <div className="d-flex">
                <div>
                  <img src={process.env.REACT_APP_URL_S3 + "/" + item.img0} width={37} height={37} />
                </div>
                <span className="ms-2 d-grid fs-13">
                  {item.label}
                  {!purchaseId && (
                    <span className="text-start mt-2 d-lg-none d-sm-none d-md-none">
                      {" "}
                      <span className="fw-bold "> In stock :</span> {item.in_stock}
                    </span>
                  )}
                </span>
              </div>
              <div className="cursor-pointer d-lg-none d-sm-none d-md-none  " onClick={() => removeItem(index)}>
                <img
                  className="ms-3"
                  removeEventListener={purchaseId ? true : false}
                  src={removeIcon}
                  alt="removeIcon"
                  style={{
                    pointerEvents: "none",
                  }}
                />
              </div>
            </Col>
            {/* {!purchaseId && ( */}
            <Col lg="2" md="2" sm="2">
              <span className="text-center d-none d-sm-block d-lg-block d-md-block">{item.in_stock}</span>
            </Col>
            {/* )} */}
            <Col className="ms-5 ms-md-0 ms-lg-0 ms-sm-0 mt-md-0   mt-sm-0 mt-lg-0 mt-4" lg="2" md="2" sm="2" xs="5">
              <Form.Group className="mb-3 ">
                <Form.Label className="mb-0 fs-13 text-muted d-lg-none d-md-none d-sm-none d-block ">Cost Price</Form.Label>
                <Form.Control
                  value={item.costPrice}
                  onChange={(e) => handleInputChange(e, index, "costPrice")}
                  className=""
                  type="number"
                  disabled={edit && purchaseId ? (editSItem === item.id && item.in_stock === item.Quantity ? false : true) : purchaseId ? true : false}
                  required
                  placeholder="Rs. 500"
                  //   disabled={purchase_id}
                />
              </Form.Group>
            </Col>
            <Col className="mt-md-0 mt-sm-0 mt-lg-0 mt-4" lg="2" md="2" sm="2" xs="5">
              <Form.Group className="mb-3">
                <Form.Label className="mb-0 fs-13 text-muted d-lg-none d-md-none d-sm-none d-block ">Qty</Form.Label>
                <Form.Control
                  value={item.Quantity}
                  onChange={(e) => handleInputChange(e, index, "Quantity")}
                  className=""
                  required
                  disabled={edit && purchaseId ? (editSItem === item.id ? false : true) : purchaseId ? true : false}
                  type="number"
                  placeholder="Qty.5"
                  //   disabled={purchase_id}
                />
              </Form.Group>
            </Col>
            {/* {!purchaseId && ( */}
            <Col lg="2" md="2" sm="2" className="d-flex gap-2 align-items-basline">
              {edit ? (
                <div className="d-flex w-100 justify-content-center gap-3">
                  {editSItem === item.id ? (
                    <>
                      <span onClick={() => updateItem(item)}>
                        {" "}
                        <FontAwesomeIcon className="cursor-pointer" style={{ color: "green", height: "22px" }} icon={faCheck} />{" "}
                      </span>
                      <span className="cursor-pointer" onClick={() => editItem(-1)}>
                        {" "}
                        <FontAwesomeIcon style={{ color: "red", height: "22px" }} icon={faXmark} />{" "}
                      </span>
                      {item.in_stock === item.Quantity && (
                        <div
                          className="cursor-pointer d-lg-block d-md-block d-sm-block d-none "
                          onClick={() => {
                            if (item.in_stock === item.Quantity) {
                              deleteItem(item.batch_id, index);
                            }
                          }}
                        >
                          <img
                            className=" pb-1"
                            src={removeIcon}
                            alt="removeIcon"
                            style={{
                              pointerEvents: "none",
                            }}
                          />
                        </div>
                      )}{" "}
                    </>
                  ) : (
                    <span className="cursor-pointer d-flex justify-content-center" onClick={() => editItem(item.id)}>
                      {" "}
                      <FontAwesomeIcon style={{ height: "15px" }} icon={faPen} />{" "}
                    </span>
                  )}
                </div>
              ) : purchaseId && !edit ? (
                ""
              ) : (
                <div
                  className="cursor-pointer d-lg-block d-md-block d-sm-block d-none "
                  onClick={() => {
                    if (!purchaseId) {
                      removeItem(index);
                    }
                  }}
                >
                  <img
                    className="ms-3 pb-1"
                    src={removeIcon}
                    alt="removeIcon"
                    style={{
                      pointerEvents: "none",
                    }}
                  />
                </div>
              )}
            </Col>
            {/* )} */}
            {(item.category_id === 2 || item.category_id === 3) && (
              <Row>
                <Col lg="2" md="2" sm="3" xs="6">
                  {item.imeis?.length > 0 ? (
                    <span
                      onClick={() => {
                        setIMEIS(item.imeis);
                        setImeiModal(true);
                        handleImeiModal(index);
                      }}
                      className=" ms-3 fs-13 link mb-2 cursor-pointer w-100 "
                    >
                      Edit IMEI(s)
                    </span>
                  ) : (
                    <span
                      onClick={() => {
                        setIMEIS(item.imeis);
                        handleImeiModal(index);
                      }}
                      className="fs-13 ms-3 link  mb-2  cursor-pointer w-100 "
                    >
                      Add IMEI(s)
                      <Form.Control value={item?.imeis} hidden name="shopTimings" required />
                      <Form.Control.Feedback type="invalid">Please add IMEI(s) </Form.Control.Feedback>
                    </span>
                  )}
                </Col>
              </Row>
            )}
            <Row className="px-3">
              {/* {(cartItem?.label === "Mobile" || cartItem?.label === "Tabs") && ( */}
              <div className=" mb-2  ">
                {!edit && item.imeis?.length > 0 && <span>IMEI(s): </span>}
                {!edit && item.imeis?.length > 0 && (
                  <Row className={`${style.imeiNumbers} bg-light border-0  px-2 pb-2 d-flex `}>
                    {item.imeis?.map((imei, index) => (
                      <>
                        <Col
                          lg="2"
                          md="3"
                          xs="5"
                          key={index}
                          style={{
                            background: imei.color,
                            borderColor: imei.color,
                          }}
                          className={`${style.tag} px-1 me-2 px-0 mt-2 py-1`}
                        >
                          {imei.imeiNumber}
                        </Col>
                      </>
                    ))}
                  </Row>
                )}
              </div>
              {/* // )} */}
            </Row>
          </Row>
        ))}
      </div>
      {/* <div className="mt-3  mb-3">
        <span className="fs-20">
          Total: <span className="fw-bold fs-20">PKR: {Total}</span>{" "}
        </span>
      </div> */}

      <AddIMEIS show={imeiModal} selectedProducts={selectedProducts} handleInputChange={handleInputChange} itemId={itemId} IMEIS={IMEIS} close={() => setImeiModal(false)} getImeis={getImeis} />
      <PromptMoal removeProducts={removeProducts} show={promptModal} close={() => setPromptModal(false)} />
      {edit && selectedProducts?.length === 0 && (
        <div className="d-flex justify-content-center">
          <h4>data not found</h4>
        </div>
      )}
    </div>
  );
}

export default AddProduct;
