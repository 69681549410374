import React, { useState } from "react";
import style from "./listingFilter.module.css";
import {
  Accordion,
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Row,
} from "react-bootstrap";

import SearchableSelect from "../../SearchableSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import ReactSelect from "../../ReactSelect";
import axios from "axios";

function MobleFilters({
  className,
  hideCategoryFilter,
  hideBrandFilter,
  hideResetButton,
  hideOrderBy,
  handleChangeCategoryFilter,
  handleChangeBrandFilter,
  handleChangeResetButton,
  handleChangeOrderByFilter,
  categoryFilter,
  brandFilter,
  orderByFilter,
}) {
  const [isOpen, setIsOpen] = useState(true);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <Row className="align-items-center mt-3  bg-white py-2 ">
      {hideCategoryFilter ? (
        ""
      ) : (
        <Col className="mb-1" md="4" sm="4" lg="3" xl="2">
          <ReactSelect
            important
            placeholder="Select Category"
            searchable={false}
            value={categoryFilter}
            options={() => {
              return new Promise((resolve, reject) => {
                axios
                  .get(process.env.REACT_APP_API_URL + "/categories/get", {
                    withCredentials: true,
                  })
                  .then((res) => {
                    const getData = res.data.map((cat) => ({
                      value: cat.id,
                      label: cat.category,
                    }));
                    getData.splice(0, 0, { value: null, label: "All" });
                    resolve(getData);
                  })
                  .catch((err) => {
                    reject("err" + err);
                  });
              });
            }}
            handleChange={handleChangeCategoryFilter}
          />
        </Col>
      )}
      {hideBrandFilter ? (
        ""
      ) : (
        <Col className="mb-1" md="4" sm="4" lg="3" xl="2">
          <ReactSelect
            important
            placeholder="Select Brand"
            // searchable={false}
            value={brandFilter}
            options={(e) => {
              return new Promise((resolve, reject) => {
                axios
                  .get(process.env.REACT_APP_API_URL + "/component/brand", {
                    withCredentials: true,
                  })
                  .then((res) => {
                    const getData = res.data.success.data
                      .map((cat) => ({
                        value: cat.id,
                        label: cat.label,
                      }))
                      .filter(
                        (cat) =>
                          !(
                            cat.label === null ||
                            cat.label === undefined ||
                            cat.label === ""
                          ),
                      );
                    getData.splice(0, 0, { value: null, label: "All" });
                    resolve(
                      getData.filter((cat) =>
                        cat.label.toLowerCase().includes(e.toLowerCase()),
                      ),
                    );
                  })
                  .catch((err) => {
                    reject("err" + err);
                  });
              });
            }}
            handleChange={handleChangeBrandFilter}
          />
        </Col>
      )}
      {hideOrderBy ? (
        ""
      ) : (
        <Col className="mb-1" md="4" sm="4" lg="3" xl="2">
          <SearchableSelect
            important
            placeholder="Select Order By"
            // searchable={false}
            value={orderByFilter}
            options={[
              { value: { created_at: "DESC" }, label: "Newest to Oldest" },
              { value: { created_at: "ASC" }, label: "Oldest to Newest" },
            ]}
            handleChange={handleChangeOrderByFilter}
          />
        </Col>
      )}
      {hideResetButton ? (
        ""
      ) : (
        <Col className="mb-2" md="4" sm="4" lg="3" xl="2">
          <div className=" d-lg-flex justify-content-between">
            <Button
              type="submit"
              style={{
                fontSize: "1rem",
                height: "100%",
              }}
              className={style.addButtonLink}
              onClick={handleChangeResetButton}
            >
              Reset Filter
            </Button>
          </div>
        </Col>
      )}
    </Row>
  );
}

function ListingFilter({
  hideCategoryFilter = false,
  handleCategoryFilter = (category) => {
    return category;
  },
  hideBrandFilter = false,
  handleBrandFilter = (brand) => {
    return brand;
  },
  hideResetButton = false,
  handleResetButton = () => {},
  hideOrderBy = false,
  handleOrderBy = () => {},
}) {
  const [categoryFilter, setCategoryFilter] = useState(null);
  const handleChangeCategoryFilter = (e) => {
    if (e.value) {
      setCategoryFilter(e);
      handleCategoryFilter(e);
    } else {
      setCategoryFilter(null);
      handleCategoryFilter(null);
    }
  };
  const [brandFilter, setBrandFilter] = useState(null);
  const handleChangeBrandFilter = (e) => {
    if (e.value) {
      handleBrandFilter(e);
      setBrandFilter(e);
    } else {
      handleBrandFilter(null);
      setBrandFilter(null);
    }
  };
  const [orderByFilter, setOrderByFilter] = useState({
    label: "Newest to Oldest",
    value: { created_at: "DESC" },
  });
  const handleChangeOrderByFilter = (e) => {
    handleOrderBy(e);
    setOrderByFilter(e);
  };

  const handleChangeResetButton = () => {
    handleResetButton();
    setBrandFilter(null);
    setCategoryFilter(null);
    setOrderByFilter({
      label: "Newest to Oldest",
      value: { created_at: "DESC" },
    });
  };

  return (
    <div className="w-100 px-3">
      <div
        className={`d-none d-sm-grid d-xl-flex w-100 align-items-baseline gap-1 gap-xl-3 pb-3 p-xl-3  ${style.filtersContainer}`}
      >
        <div className="d-flex bd-highlight gap-2 w-100">
          {hideCategoryFilter ? (
            ""
          ) : (
            <div className="bd-highlight" style={{ width: "170px" }}>
              <ReactSelect
                important
                placeholder="Select Category"
                searchable={false}
                value={categoryFilter}
                options={() => {
                  return new Promise((resolve, reject) => {
                    axios
                      .get(process.env.REACT_APP_API_URL + "/categories/get", {
                        withCredentials: true,
                      })
                      .then((res) => {
                        const getData = res.data.map((cat) => ({
                          value: cat.id,
                          label: cat.category,
                        }));
                        getData.splice(0, 0, { value: null, label: "All" });
                        resolve(getData);
                      })
                      .catch((err) => {
                        reject("err" + err);
                      });
                  });
                }}
                handleChange={handleChangeCategoryFilter}
              />
            </div>
          )}
          {hideBrandFilter ? (
            ""
          ) : (
            <div className="bd-highlight" style={{ width: "170px" }}>
              <ReactSelect
                important
                placeholder="Select Brand"
                // searchable={false}
                value={brandFilter}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    axios
                      .get(process.env.REACT_APP_API_URL + "/component/brand", {
                        withCredentials: true,
                      })
                      .then((res) => {
                        const getData = res.data.success.data
                          .map((cat) => ({
                            value: cat.id,
                            label: cat.label,
                          }))
                          .filter(
                            (cat) =>
                              !(
                                cat.label === null ||
                                cat.label === undefined ||
                                cat.label === ""
                              ),
                          );
                        getData.splice(0, 0, { value: null, label: "All" });
                        resolve(
                          getData.filter((cat) =>
                            cat.label.toLowerCase().includes(e.toLowerCase()),
                          ),
                        );
                      })
                      .catch((err) => {
                        reject("err" + err);
                      });
                  });
                }}
                handleChange={handleChangeBrandFilter}
              />
            </div>
          )}
          {hideOrderBy ? (
            ""
          ) : (
            <div className="bd-highlight" style={{ width: "170px" }}>
              <SearchableSelect
                important
                placeholder="Select Order By"
                // searchable={false}
                value={orderByFilter}
                options={[
                  { value: { created_at: "DESC" }, label: "Newest to Oldest" },
                  { value: { created_at: "ASC" }, label: "Oldest to Newest" },
                ]}
                handleChange={handleChangeOrderByFilter}
              />
            </div>
          )}

          {hideResetButton ? (
            ""
          ) : (
            <div className="bd-highlight">
              <Button
                type="submit"
                style={{
                  fontSize: "1rem",
                  height: "100%",
                }}
                className={style.addButtonLink}
                onClick={handleChangeResetButton}
              >
                Reset Filter
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="d-grid d-sm-none w-100 gap-1 pb-3 ">
        <MobleFilters
          hideCategoryFilter={hideCategoryFilter}
          hideBrandFilter={hideBrandFilter}
          hideResetButton={hideResetButton}
          hideOrderBy={hideOrderBy}
          handleChangeCategoryFilter={handleChangeCategoryFilter}
          handleChangeBrandFilter={handleChangeBrandFilter}
          handleChangeResetButton={handleChangeResetButton}
          handleChangeOrderByFilter={handleChangeOrderByFilter}
          categoryFilter={categoryFilter}
          brandFilter={brandFilter}
          orderByFilter={orderByFilter}
        />
      </div>
    </div>
  );
}

export default ListingFilter;
