import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Components/Loader";
import customloader from "./../../assets/images/RollingLoader.gif";
import styleModal from "../../styles/Modal.module.css";

import ReactSelect from "../../Components/ReactSelect";

import SearchableSelect from "../../Components/SearchableSelect";
import { dateTime, dateTimeForInvoice, requestDateTime } from "../../Features/dateTime";
import AddProduct from "./AddProduct";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import PopUpModal from "../../Components/Modal";
import OpeningRegister from "../../Components/Register/OpeningRegister";
import { getRegisterId } from "../../Features/authSlice";
import { setLocation } from "../../Features/locationSlice";

function AddPurchaseOrder({ id, refresh, openingBalances, purchaseId, edit, close }) {
  const register = useSelector((state) => state.location?.register);
  const registerID = register?.registerId;
  const location = useSelector((state) => state.location?.location);
  const dispatch = useDispatch();
  const [order, setOrder] = useState();
  const [purchaseDate, setPurchaseDate] = useState(new Date().toISOString().split("T")[0]);
  const [status, setStatus] = useState({});
  const [vendor, setVendor] = useState({});
  const [items, setitems] = useState();
  const [paidBy, setPaidBy] = useState("");
  const [invoice, setInvoice] = useState("");
  const [memo, setMemo] = useState("");
  const [dealerLocation, setDealerLocation] = useState({});
  const [dealerLocationFields, setDealerLocationFields] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [paidAmount, setPaidAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  const userId = user.user_id;
  const user_type = user.user_type;
  const [selectedItems, setselectedItems] = useState([]);
  const [errorPaidAmount, setPaidTotalAmount] = useState(false);
  const [dueAmount, setDueAmount] = useState("");
  const [vendorFields, setvendorFields] = useState([]);
  const [promptModal, setPromptModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [temLocation, setTemLocation] = useState({});
  const [refreshPO, setRefreshPO] = useState(false);
  const [error, setError] = useState("");
  const [bank, setBank] = useState(null);
  const [cheque_number, setCheque_number] = useState(null);
  const [registerClosedAlert, setRegisterClosedAlert] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [openingBalance, setOpeningBalance] = useState();
  const [isValidate, setIsValidated] = useState(false);
  const [cashInHand, setCashInHand] = useState();

  useEffect(() => {
    // setLoading(true);
    const cashInHand = () => {
      axios
        .post(
          process.env.REACT_APP_API_URL + `/reports/cash-in-hand/${user?.user_id}`,
          {
            dates: {
              from: register?.openingBalance?.opening_balance_time.split(".")[0].replace("T", " "),
              to: register?.openingBalance?.closing_balance_time ? register?.openingBalance?.closing_balance_time.split(".")[0].replace("T", " ") : null,
            },
            locationFields: location,
            openingBalance: register?.openingBalance?.opening_balance_id,
          },
          { withCredentials: true }
        )
        .then((res) => {
          let n = res?.data?.length;
          setCashInHand(res.data[n - 1]);
        })
        .catch((err) => {
          throw new Error(err.response.data);
        });
    };
    if (Object.keys(location).length > 0 && register?.openingBalance) cashInHand();
  }, [location, register?.openingBalance]);
  // Check Opening Balance Set or Not
  const isOpeningBalanceSet = (ob) => {
    if (ob) {
      return ob.isRegisterOpen;
    } else {
      return false;
    }
  };

  const loadBanksOptions = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + `/shops/banks/${shop_id}`, {
          withCredentials: true,
        })
        .then((banksRes) => {
          const banks = banksRes.data.map((bank) => ({
            value: bank,
            label: (
              <>
                <span style={{ fontSize: "12px" }}>
                  Bank Name: <strong className="fs-12">{bank?.bank_name}</strong>
                </span>
                <br />
                <span style={{ fontSize: "12px" }}>Account Title: {bank?.account_title}</span>
                <br />
                <span className="d-block" style={{ fontSize: "12px" }}>
                  Account Number:{bank?.account_number}
                </span>
              </>
            ),
            name: bank.bank_name,
          }));
          const defaultBank = banks.filter((bank) => bank.value.default_business_account === 1);
          if (defaultBank[0]) {
            setBank({ value: defaultBank[0].value, label: defaultBank[0].name });
          }
          resolve(banks);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const handleChangeBank = (e) => {
    setBank({ value: e.value, label: e.name });
  };

  const fetchPOData = async () => {
    try {
      setisLoading(true);

      const res = await axios.post(process.env.REACT_APP_API_URL + "/purchase-order/purchase-details/" + purchaseId, {}, { withCredentials: true });
      let products = res?.data[0]?.itemsArray;
      setitems(products);
      let purchaseData = res.data[0].purchaseOrder;
      setOrder(purchaseData.purchase_number);

      setPurchaseDate(new Date(purchaseData.purchase_date).toISOString().split("T")[0]);
      setMemo(purchaseData.purchase_detail === "nil" ? "" : purchaseData.purchase_detail);
      setVendor({
        label: purchaseData.vendor_name,
        id: purchaseData.vendor_id,
      });
      setDueAmount(purchaseData.due_amount);
      setInvoice(purchaseData.invoice_number === "nil" ? "" : purchaseData.invoice_number);
      setDealerLocation({
        label: purchaseData.location_nick,
        value: purchaseData.location,
      });
      let paymentsData = res.data[0].payments;

      setPaidAmount(paymentsData.payment_amount);
      setPaidBy({
        label: paymentsData.payment_type,
        value: paymentsData.payment_type,
      });
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
    }
  };

  useEffect(() => {
    // setPurchaseDate(
    // );
    if (purchaseId) {
      fetchPOData();
    }
    axios
      .post(process.env.REACT_APP_API_URL + `/location/get-location-by-user/${userId}`, { shop_id: shop_id, user_type: user_type }, { withCredentials: true })
      .then((res) => {
        setDealerLocationFields(
          res.data.map((loc) => ({
            value: loc.location_id,
            label: loc.location,
            isDisabled: new Date().toISOString() >= loc.active_till ? true : false,
          }))
        );
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, [userId, refreshPO]);
  const getVendors = async () => {
    try {
      const res = await axios.get(process.env.REACT_APP_API_URL + "/vendors/get-vendor-by-shop/" + shop_id, { withCredentials: true });
      const data = res?.data?.map((item) => {
        return {
          label: item.vendor_name,
          value: item.vendor_id,
          // email: item.email,
          // contact: item.contact_number,
        };
      });
      setvendorFields(data);
    } catch (error) {}
  };
  useEffect(() => {
    if (!purchaseId) {
      if (totalAmount && paidAmount) {
        if (Number(dueAmount) >= 0) {
          setDueAmount(totalAmount - paidAmount);
        } else {
          setPaidAmount(0);
        }
      } else {
        setDueAmount(totalAmount);
      }
    }
  }, [totalAmount, paidAmount, dueAmount]);
  useEffect(() => {
    getVendors();
    const [date, time] = dateTimeForInvoice().toISOString().split("T");
    const curDate = date.split("-");
    const curTime = time.split(".")[0].replaceAll(":", "");
    const vendorB32 = Number(3).toString(32).toUpperCase();
    const dateB32 = Number(curDate[2] + curDate[1] + curDate[0])
      .toString(32)
      .toUpperCase();
    const timeB32 = Number(curTime).toString(32).toUpperCase();
    setOrder(`${timeB32}${dateB32}${vendorB32}`);
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);
    // const ob = openingBalances.filter(
    //   (ob) => ob.location_id === dealerLocation.value
    // )[0];

    if (paidBy?.label === "Cash" ? location.value && register?.isCashRegisterOpen : true) {
      setError("");
      if (paidBy?.label === "Cash" ? cashInHand?.cash_in_hand >= Number(paidAmount) : true) {
        const items = selectedItems.map((item, index) => {
          if (item.category_id === 2 || item.category_id === 3) {
            return {
              item_id: item.id,
              cost_price: item.costPrice,
              purchase_quantity: item.Quantity,
              imei: item?.imeis === "" ? [] : item?.imeis?.map((item) => item.imeiNumber),
              // itemBatch: item.batch_id,
            };
          } else {
            return {
              item_id: item.id,
              cost_price: item.costPrice,
              purchase_quantity: item.Quantity,
              // itemBatch: item.batch_id,
            };
          }
        });
        const purchaseDetails = {
          order: order,
          payment_date: purchaseDate ? requestDateTime(purchaseDate + "T" + new Date().toISOString().split("T")[1]) : "",

          vendor: vendor.label ? vendor.label : "",
          vendorId: vendor.value ? vendor.value : "",
          // payment_type: paidBy.label,
          // payment_amount: paidAmount,
          amountDue: dueAmount,
          // note: memo,
          dealerLocation: location.label ? location.label : "",
          locationId: location.value ? location.value : "",
        };
        const hasEmptyValueObject = items.some((item) => {
          return Object.values(item).some((value) => {
            if (Array.isArray(value)) {
              return value.length === 0;
            }
            return value === "" || value === null || value === undefined;
          });
        });
        const hasEmptyValue = Object.values(purchaseDetails).includes("");
        const purchase = {
          vendor_id: vendor.value ? vendor.value : "",
          purchase_amount: totalAmount,
          purchase_date: requestDateTime(purchaseDate + "T" + new Date().toISOString().split("T")[1]),
          purchase_number: order,
          invoice_number: invoice ? invoice : "",
          location: location.value ? location.value : "",
          purchase_detail: memo ? memo : "",
        };

        const payment = {
          payment_amount: paidAmount ? paidAmount : "",
          payment_type: paidBy?.label ? paidBy?.label : "",
          bank_id: paidBy?.label === "Cash" ? -1 : bank?.value?.bank_id,
          cheque_number: cheque_number,
          payment_date: requestDateTime(purchaseDate + "T" + new Date().toISOString().split("T")[1]),
          note: "Down payment",
        };

        if (!hasEmptyValueObject && items.length > 0 && !hasEmptyValue && (paidBy?.label ? Number(paidAmount) > 0 : true)) {
          setIsClicked(true);
          axios
            .post(
              process.env.REACT_APP_API_URL + "/purchase-order/add-with-payment",
              {
                purchase: purchase,
                payment: payment,
                items: items,
              },
              { withCredentials: true }
            )
            .then(() => {
              setSuccessModal(true);
              setTimeout(() => {
                setSuccessModal(false);
                close();
              }, 2000);
              refresh((prev) => !prev);
              setIsClicked(false);
            })
            .catch((error) => {
              setisLoading(false);
              setIsClicked(false);
            });
        }
      } else {
        setRegisterClosedAlert(false);
        setError("Amount is more then the Cash in Hand!");
      }
    } else {
      setRegisterClosedAlert(true);
      setError("Register is Not Set!");
    }
  };

  const handleExpenseDateChange = (e) => {
    setPurchaseDate(e.target.value);
  };

  const handlePaidAmountChange = (e) => {
    // const ob = openingBalances.filter(
    //   (ob) => ob.location_id === dealerLocation.value
    // )[0];
    if (Number(e.target.value) <= Number(totalAmount) && Number(e.target.value) > 0) {
      if (paidBy?.label === "Cash" ? register.isCashRegisterOpen : true) {
        if (paidBy?.label === "Cash" ? cashInHand?.cash_in_hand < Number(e.target.value) : false) {
          setRegisterClosedAlert(false);
          setError("Amount is more then the Cash in Hand!");
        } else {
          setError("");
        }
      } else {
        setRegisterClosedAlert(true);
        setError("Register is not Open yet!");
      }
      let num = parseInt(e.target.value, 10);
      setPaidAmount(num);
      setPaidTotalAmount(false);
    } else {
      setPaidTotalAmount(true);
      setPaidAmount("");
    }
  };
  const handlePaymentTypeChange = (e) => {
    if (e === null) {
      setPaidAmount("");
      setPaidBy();
    } else {
      setPaidBy({ label: e.value, value: e.value });
    }
  };

  const reSetLocation = () => {
    setDealerLocation(temLocation);
  };

  const handleChequeNumberChange = (e) => {
    if (e.target.value.length < 20) {
      setCheque_number(e.target.value);
    }
  };
  // useEffect(() => {
  //   if (user?.shop_id && dealerLocation?.value) {
  //     dispatch(
  //       getRegisterId({
  //         shop: user.shop_id,
  //         location: dealerLocation.value,
  //       })
  //     );
  //   }
  // }, [dealerLocation, user.shop_id]);
  const handleCashOpeningBalance = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setIsValidated(true);
    try {
      if (registerID === -1) {
        setError("Register is not Set!");
      } else {
        if (openingBalance >= 0) {
          const res = await axios.post(process.env.REACT_APP_API_URL + `/cash-registers/opening-balance`, { register_id: registerID, opening_balance: openingBalance }, { withCredentials: true });
          if (res) {
            dispatch(setLocation({ shop_id: user?.shop_id, location: location }));
            setIsValidated(false);
            setRegisterClosedAlert(false);
            setError(false);
            refresh();
            setOpeningBalance();
            setTimeout(() => setOpenRegisterModal(false), 1000);
          }
        }
      }
    } catch (error) {
      setError(error);
    }
  };

  const currentMonth = new Date().toDateString().split(" ")[1];
  const currentDate = new Date().getDate() + " " + currentMonth + ", " + new Date().getFullYear();
  const today_sh = new Date();
  const currentTime = today_sh.getHours();
  let showtime;
  if (currentTime < 12) {
    showtime = "Good Morning";
  } else if (currentTime < 18) {
    showtime = "Good Afternoon";
  } else {
    showtime = "Good Evening";
  }
  return (
    <div>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "40vh" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : (
        <div className=" mx-3 mt-1">
          <Form onSubmit={handleSubmit} noValidate validated={validated}>
            <Row>
              {registerClosedAlert ? (
                <div className="alert alert-warning border-dotted" style={{ border: "2px dotted #8834ff" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    *{error}
                    <button
                      onClick={() => {
                        setOpenRegisterModal(true);
                      }}
                      className="btn btn-primary"
                    >
                      Open Register
                    </button>
                  </div>
                </div>
              ) : error ? (
                <div className="alert alert-warning border-dotted" style={{ border: "2px dotted #8834ff" }}>
                  *{error}
                </div>
              ) : (
                ""
              )}
              {!edit ? (
                <>
                  {" "}
                  <Col className="py-2" md={6}>
                    <Form.Group className="" controlId="price">
                      <Form.Label className="">
                        Order #<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Write address"
                        disabled={true}
                        value={order}
                        onChange={(e) => {
                          setOrder(e.target.value);
                        }}
                        name="order"
                        pattern="^([A-Za-z ]|[0-9])+$"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col className="py-2" md={6}>
                    <Form.Group className="">
                      <Form.Label className="mb-1">
                        Date<span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Form.Control type="date" disabled={purchaseId ? true : false} value={purchaseDate} onChange={handleExpenseDateChange} placeholder="Select date" required />
                      <Form.Control value={purchaseDate} hidden name="date" required />
                      <Form.Control.Feedback type="invalid">Please Select Date.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col className="py-3" sm="6" md={12}>
                    <SearchableSelect
                      label="Supplier"
                      important
                      disabled={purchaseId ? true : false}
                      placeholder="Select Supplier"
                      searchable={true}
                      options={vendorFields}
                      handleChange={(e) => {
                        setVendor(e);
                      }}
                      value={Object.keys(vendor).length > 0 ? vendor : null}
                      // noOptionsMessage={noOptionsMessage}
                    />
                    <Form.Control value={JSON.stringify(vendor.label)} hidden name="vendor" required />

                    <Form.Control.Feedback type="invalid">Please Select Supplier.</Form.Control.Feedback>
                  </Col>
                  <Col className="py-2" md={6}>
                    <Form.Group className="mb-3" controlId="price">
                      <Form.Label className="mb-0">Invoice </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Write Invoice"
                        value={invoice}
                        disabled={purchaseId ? true : false}
                        onChange={(e) => {
                          setInvoice(e.target.value.replace(/[^.a-zA-Z0-9]+/g, ""));
                        }}
                        name="invoice"
                        // pattern="^([A-Za-z ]|[0-9])+$"
                      />

                      <Form.Control.Feedback type="invalid">Please fill Invoice </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col className="py-1 " md={6}>
                    <Form.Group controlId="formBasicTiming">
                      <label className="mb-0">
                        Location<span className="text-danger">*</span>
                      </label>
                      <div className={`w-100`}>
                        <SearchableSelect
                          important
                          placeholder="Select location"
                          disabled={true}
                          value={Object.keys(location).length > 0 ? location : null}
                          // searchable={true}
                          // options={dealerLocationFields}
                          // disabled={purchaseId ? true : false}
                          // handleChange={(e) => {
                          //   if (selectedItems.length > 0) {
                          //     setPromptModal(true);
                          //     setTemLocation(e);
                          //   } else {
                          //     setDealerLocation(e);
                          //     setPromptModal(false);
                          //   }
                          // }}
                          // value={
                          //   Object.keys(dealerLocation).length > 0
                          //     ? dealerLocation
                          //     : null
                          // }
                          // noOptionsMessage={noOptionsMessage}
                        />
                        <Form.Control value={JSON.stringify(location.label)} hidden name="dealerlocation" required />

                        <Form.Control.Feedback type="invalid">Please Select location.</Form.Control.Feedback>
                      </div>
                      <Form.Control value={location} hidden name="shopTimings" required />

                      <Form.Control.Feedback type="invalid">Please Select Location </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col className="mt-3" md={12}>
                    <Form.Group className="mb-3" controlId="price">
                      <Form.Label className="mb-0">Memo</Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        rows={3}
                        placeholder="Write Memo"
                        value={memo}
                        disabled={purchaseId ? true : false}
                        onChange={(e) => {
                          setMemo(e.target.value);
                        }}
                        name="locationName"
                        pattern="^([A-Za-z ]|[0-9])+$"
                        // required
                      />

                      {/* <Form.Control.Feedback type="invalid">
                    Please fill Memo{" "}
                  </Form.Control.Feedback> */}
                    </Form.Group>
                  </Col>
                </>
              ) : (
                ""
              )}

              {/* <div className={`  border-top border-bottom mb-3 py-2 `}>
              <span className="fs-13 ms-2 text-muted">
                Select product of purchase order
              </span>
            </div> */}
              <div>
                <AddProduct
                  setValidated={setValidated}
                  isClicked={validated}
                  items={items}
                  edit={edit}
                  refresh={setRefreshPO}
                  dealerLocation={location}
                  id={id}
                  purchaseId={purchaseId}
                  reSetLocation={reSetLocation}
                  setselectedItems={setselectedItems}
                  setTotalAmount={setTotalAmount}
                  setPaidAmount={setPaidAmount}
                  setPaidBy={setPaidBy}
                  setPromptModal={setPromptModal}
                  promptModal={promptModal}
                />
              </div>
              {!purchaseId && (
                <div className={`  border-top border-bottom mb-3 py-2 `}>
                  <span className="fs-20 ms-2 align-items-baseline d-flex text-muted">
                    Payment
                    <p className="fs-16 mb-0 ms-1 opacity-75 ">(If any down payment)</p>
                  </span>
                </div>
              )}

              {!purchaseId && (
                <Col className="py-2" md={4}>
                  <Form.Group className="mb-3">
                    <SearchableSelect
                      placeholder="Select"
                      label="Payment Type"
                      // important
                      disabled={totalAmount && !purchaseId ? false : true}
                      value={paidBy}
                      isClearable={true}
                      searchable={false}
                      options={[
                        { value: "Transfer", label: "Transfer" },
                        { value: "Cash", label: "Cash" },
                        { value: "Cheque", label: "Cheque" },
                      ]}
                      handleChange={handlePaymentTypeChange}
                    />
                    <Form.Control
                      hidden
                      value={paidBy}
                      onChange={() => {}}
                      // required
                    />
                  </Form.Group>
                </Col>
              )}

              {!purchaseId && (
                <>
                  <Col className="py-2" md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-0">
                        Paid Amount
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        value={paidAmount}
                        onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                        onChange={handlePaidAmountChange}
                        disabled={totalAmount && paidBy?.label && !purchaseId ? false : true}
                        // disabled
                        className="p-2"
                        type="number"
                        required
                        placeholder="Rs. 500"
                      />
                      <Form.Control.Feedback type="invalid">Please fill paid amount</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col className="py-2" md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-0">Amount Due</Form.Label>
                      <Form.Control value={dueAmount} onChange={() => {}} className="p-2" type="text" placeholder="Rs. 500" disabled />
                    </Form.Group>
                  </Col>
                  <Col className="text-center  align-items-center d-flex" md={4}>
                    <label className="mb-0 fs-20">Status: </label>

                    {dueAmount ? <span className="text-danger  fs-20 ms-1 ">Pending</span> : <span className="text-success fs-20 ms-1 ">Paid</span>}
                  </Col>
                </>
              )}

              {!purchaseId && (
                <>
                  {paidBy?.label === "Cash" || paidBy?.label === undefined || paidBy?.label === "" ? (
                    ""
                  ) : (
                    <>
                      {paidBy?.label === "Cheque" ? (
                        <>
                          <Col className={`py-1 ${styleModal.bankDropdown}`} md={4}>
                            <Form.Group className="mb-3">
                              <Form.Label className="mb-1">
                                Select Bank <span className="text-danger">*</span>
                              </Form.Label>
                              <ReactSelect placeholder="Select" value={bank} options={loadBanksOptions} handleChange={handleChangeBank} searchable={false} />
                              <Form.Control value={bank} onChange={() => {}} hidden required={paidBy?.label === "Transfer" || paidBy?.label === "Cheque"} />
                              <Form.Control.Feedback type="invalid">Please Select Bank.</Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col className="py-2" md={4}>
                            <Form.Group className="mb-3">
                              <Form.Label className="mb-0">Cheque Number</Form.Label>
                              <Form.Control type="text" className="p-2" value={cheque_number} onChange={handleChequeNumberChange} placeholder="Cheque Number" />
                            </Form.Group>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col className={`py-1 ${styleModal.bankDropdown}`} md={4}>
                            <Form.Group className="mb-3">
                              <Form.Label className="mb-1">
                                Select Bank <span className="text-danger">*</span>
                              </Form.Label>
                              <ReactSelect placeholder="Select" value={bank} options={loadBanksOptions} handleChange={handleChangeBank} searchable={false} />
                              <Form.Control value={bank} onChange={() => {}} hidden required={paidBy.label === "Card" || paidBy.label === "Transfer"} />
                              <Form.Control.Feedback type="invalid">Please Select Bank.</Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              <div className="mt-3  mb-3">
                <span className="fs-20">
                  Total: <span className="fw-bold fs-20">PKR {totalAmount.toLocaleString("en-IN")}</span>{" "}
                </span>
              </div>

              {!purchaseId && (
                <div className="d-flex row mb-2 ">
                  <Button disabled={isClicked} type="submit" className=" col-lg-2 col-6 me-3">
                    {isLoading ? <Loader /> : "Add Order"}
                  </Button>
                  <Button variant="outline-secondary border " disabled={isClicked} onClick={close} type="button" className="col-lg-2 col-5 ">
                    Cancel
                  </Button>
                </div>
              )}
            </Row>
          </Form>
        </div>
      )}
      <PopUpModal title="Success" icon={<FontAwesomeIcon icon={faCircleCheck} />} text="PO Added Succesfully" type={styleModal.successBg} iconModal="success" show={successModal} onHide={() => setSuccessModal(false)} />
      <OpeningRegister
        cashRegisterModal={openRegisterModal}
        setCashRegisterModal={setOpenRegisterModal}
        validated={isValidate}
        setIsValidate={setIsValidated}
        handleCashOpeningBalance={handleCashOpeningBalance}
        showtime={showtime}
        error={error}
        openingBalance={openingBalance}
        setOpeningBalance={setOpeningBalance}
      />
    </div>
  );
}

export default AddPurchaseOrder;
