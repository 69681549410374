import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import NoResult from "../NoResult";
import axios from "axios";
import Csv from "../csv/Csv";
import CustomizeTableview from "../TableViews/CustomizeTableview";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PaginationBar from "../PaginationBar";
import customloader from "../../assets/images/RollingLoader.gif";
import ItemBatchesModal from "../ItemBatches/ItemBatchesModal";
import PopUpModal from "../Modal";
import style from "../../styles/InventoryList.module.css";
import dangerline from "../../assets/svg/dangerline.svg";
import triangle from "../../assets/svg/triangle.svg";
import BarcodeModal from "../Barcode/BarcodeModal";
import LoaderPage from "../loaders/loaderPage";
import DuplicateInventoryLocationModal from "../InventoryForms/DuplicateInventoryLocationModal";

const DELAY_TIME = 100;

function Inventory({ isActive, search, category, accessoryType, location, condition, setSettingsModal, columnNotToShow, sizeOfPages, submitted, setArchiveActionTrigger, refresh, toggle }) {
  let [items, setItems] = useState([]);
  let [isLoading, setIsLoading] = useState(true);
  const [id, setId] = useState(-1);
  const [noOfPages, setNoOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const { user, allLocations } = useSelector((state) => state.auth);
  const [itemTitle, setItemTitle] = useState();
  const [categoryId, setCategoryId] = useState();
  const navigate = useNavigate();

  // const [orderBy, setOrderBy] = useState({ "items.created_at": "DESC" });
  const [orderBy, setOrderBy] = useState({ "items.stock_no": "DESC" });

  // ItemBachesStates
  const [isItemBachesModalShow, setIsItemBachesModalShow] = useState(false);
  const [itemIdForBatch, setItemIdForBatch] = useState(-1);
  const closeItemBatchesModalHandle = () => {
    setIsItemBachesModalShow(false);
    setItemIdForBatch(-1);
  };

  // BarcodeStates
  const [isBarcodeModalShow, setIsBarcodeModalShow] = useState(false);
  const [isDuplicateLocationModalShow, setIsDuplicateLocationModalShow] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [item, setItem] = useState(null);
  const closeDuplicateLocationModal = () => {
    setIsDuplicateLocationModalShow(false);
    // setItem(null);
  };
  const closeBarcodeModal = () => {
    setIsBarcodeModalShow(false);
    setItem(null);
  };

  const shopName = user.shop_name;

  const changePage = (page) => {
    setCurrentPage(page);
    navigate("/inventory?page=" + page);
  };

  function requestAllHistory() {
    let searchobj = {};

    if (category && Object.keys(category).length > 0) {
      searchobj = {
        ...searchobj,
        category_name: category?.value ? category?.label : null,
      };
    }
    if (accessoryType && Object.keys(accessoryType).length > 0) {
      searchobj = {
        ...searchobj,
        accessory_type: accessoryType?.value ? accessoryType?.label : null,
      };
    }

    if (location && Object.keys(location).length > 0) {
      searchobj = {
        ...searchobj,
        location_nick: location?.value ? location?.label : null,
      };
    }

    if (condition && Object.keys(condition).length > 0) {
      searchobj = {
        ...searchobj,
        condition_item: condition?.value ? condition?.label : null,
      };
    }

    if (Object.keys(search).length > 0) {
      searchobj = { ...searchobj, search: search };
    }

    searchobj = { ...searchobj, orderBy };

    searchobj = { ...searchobj, isArchive: false };
    return axios.post(process.env.REACT_APP_API_URL + `/inventory/get-inventory/${user.user_id}`, searchobj, { withCredentials: true });
  }

  useEffect(() => {
    let searchobj = {};

    if (category && Object.keys(category).length > 0) {
      searchobj = {
        ...searchobj,
        category_name: category?.value ? category?.label : null,
      };
    }
    if (accessoryType && Object.keys(accessoryType).length > 0) {
      searchobj = {
        ...searchobj,
        accessory_type: accessoryType?.value ? accessoryType?.label : null,
      };
    }

    if (location && Object.keys(location).length > 0) {
      searchobj = {
        ...searchobj,
        location_nick: location?.value ? location?.label : null,
      };
    }

    if (condition && Object.keys(condition).length > 0) {
      searchobj = {
        ...searchobj,
        condition_item: condition?.value ? condition?.label : null,
      };
    }
    if (Object.keys(search).length > 0) {
      searchobj = { ...searchobj, search: search };
    }
    searchobj = { ...searchobj, orderBy, isArchive: false };

    const pageTimeout = setTimeout(async () => {
      setIsLoading(true);
      try {
        const res = await axios.post(process.env.REACT_APP_API_URL + `/inventory/get-inventory/${user.user_id}?page=${currentPage}&size=${sizeOfPages}`, searchobj, { withCredentials: true });
        const data = res.data;
        setCount(data?.count?.no_of_items);
        setItems(data?.data);
        setNoOfPages(data?.count?.no_of_pages);
        if (data?.count?.no_of_pages !== 0) {
          if (currentPage === 0) {
            navigate("/inventory?page=1");
            setCurrentPage(1);
          }
        }
        if (currentPage > data?.count?.no_of_pages) {
          setCurrentPage(data?.count?.no_of_pages);
          navigate("/inventory?page=" + data?.count?.no_of_pages);
        }
        // setSearchDataa(data);
        setIsLoading(false);
      } catch (error) {
        setItems([]);
        setIsLoading(false);
        throw new Error(error.response.data);
      }
    }, DELAY_TIME);

    return () => {
      clearTimeout(pageTimeout);
    };
  }, [search, category, submitted, location, condition, sizeOfPages, currentPage, refresh, orderBy, accessoryType]);

  const inventoryEditHandle = (id, isMiscellaneous) => {
    const temp = items.filter((obj) => obj.id === id);
    navigate("/inventory/edit/" + id, {
      state: { id: temp[0].category_id, isMiscellaneous: isMiscellaneous },
    });
  };

  const deleteInventory = async (id) => {
    if (id !== -1) {
      try {
        const res = await axios.post(process.env.REACT_APP_API_URL + `/inventory/delete/${id}`, {}, { withCredentials: true });
        if (res) {
          toggle((prev) => !prev);
        }
      } catch (error) {
        throw new Error(error.response.data);
      }
    }
  };
  const [duplicate_id, setDuplicateId] = useState();
  const [miscellaneous, setMiscellaneous] = useState();
  const inventoryDuplicateHandle = (id, is_miscellaneous) => {
    if (allLocations.length > 1) {
      setIsDuplicateLocationModalShow(true);
      setDuplicateId(id);
      setMiscellaneous(is_miscellaneous);
    } else {
      onDuplicateAction(id, is_miscellaneous);
    }
  };
  useEffect(() => {
    if (selectedLocation && Object.keys(selectedLocation).length > 0) {
      setIsDuplicateLocationModalShow(false);
      onDuplicateAction(duplicate_id, miscellaneous);
    }
  }, [Object.keys(selectedLocation).length > 0, duplicate_id, miscellaneous]);
  const onDuplicateAction = (id, is_miscellaneous) => {
    const temp = items.filter((obj) => obj.id === id);
    navigate("/inventory/duplicate/" + id, {
      state: { id: temp[0].category_id, is_miscellaneous: is_miscellaneous },
    });
  };

  const archiveInventory = async (id) => {
    try {
      const res = await axios.post(process.env.REACT_APP_API_URL + `/inventory/archive/${id}`, {}, { withCredentials: true });
      if (res) {
        toggle((prev) => !prev);
        setArchiveActionTrigger((prev) => !prev);
      }
    } catch (error) {
      throw new Error(error.response.data);
    }
  };
  const inventoryBatchesHandle = async (id, data) => {
    setItemIdForBatch(id);
    setItemTitle(data["title"]);
    setCategoryId(data["category_id"]);
    setIsItemBachesModalShow(true);
  };

  const barcodeHandle = (item) => {
    setItem(item);
    setIsBarcodeModalShow(true);
  };
  const settingsModal = () => {
    setSettingsModal(true);
  };
  const refreshHandle = () => {
    toggle((prev) => !prev);
  };

  return (
    <div>
      {isLoading ? (
        <div style={{ height: "50vh", display: "flex", justifyContent: "center" }}>
          <LoaderPage />
        </div>
      ) : items?.length === 0 ? (
        <NoResult name="Inventory" />
      ) : (
        <>
          <CustomizeTableview
            settingsModal={settingsModal}
            batches={!columnNotToShow.includes("batches") ? inventoryBatchesHandle : ""}
            barcode={!columnNotToShow.includes("barcode") ? barcodeHandle : ""}
            thumbnail={{
              title: "Title",
              img: "img0",
              description: "title",
            }}
            data={items}
            columnNotShow={["id", "category_id", "img0", "title", "is_miscellaneous"].concat(columnNotToShow)}
            inventoryActions={{
              remove: deleteInventory,
              duplicate: inventoryDuplicateHandle,
              archive: archiveInventory,
              edit: inventoryEditHandle,
            }}
            dateColumn={columnNotToShow.includes("updated_at") ? (columnNotToShow.includes("created_at") ? "" : ["created_at"]) : columnNotToShow.includes("created_at") ? ["updated_at"] : ["created_at", "updated_at"]}
            status={
              !columnNotToShow.includes("status")
                ? {
                    column: "in_stock",
                    style: (value) => {
                      if (value > 0) {
                        return "text-success";
                      } else {
                        return "text-danger fst-italic";
                      }
                    },
                    format: (value) => {
                      if (value > 0) {
                        return "Available";
                      } else {
                        return "Sold Out *";
                      }
                    },
                  }
                : ""
            }
            sortColumn={["title", "created_at", "price", "sale_price", "updated_at", "in_stock"]}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          />
          <Row className="mt-5 align-items-center">
            <Col className="d-none d-sm-block" sm="2" md="3" lg="3">
              <span className="text-secondary fs-6">{count} result(s)</span>
            </Col>
            <Col xs="12" sm="7" md="6" lg="6">
              <PaginationBar currentPage={currentPage} noOfPages={noOfPages} changePage={changePage} />
            </Col>
            <Col className="d-sm-none d-flex align-items-center mt-3" xs="6" lg="3">
              <span className="text-secondary fs-6">{count} result(s)</span>
            </Col>
            <Col xs="6" sm="3" md="3" lg="3" className="text-end pe-2 px-0">
              <Csv
                filename={`${
                  "Inventory " +
                  shopName +
                  " " +
                  new Date().toLocaleDateString("en-US", {
                    timeZone: "Asia/Karachi",
                  })
                }`}
                api={requestAllHistory}
                format={(data) => {
                  const { id, img0, category_id, created_at, updated_at, ...temp } = data;
                  const newObj = {
                    ...temp,
                  };
                  return newObj;
                }}
                error={(err) => {
                  if (err.response) {
                    const tempErr = err.response.data.error;
                    if (tempErr.status === 404 && tempErr.message === "No Record Found") {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return false;
                  }
                }}
                responseObject={(data) => {
                  return data?.data?.data;
                }}
              />
            </Col>
          </Row>
          <ItemBatchesModal show={isItemBachesModalShow} close={closeItemBatchesModalHandle} id={itemIdForBatch} categoryId={categoryId} itemTitle={itemTitle} refreshing={refreshHandle} />
          <BarcodeModal show={isBarcodeModalShow} close={closeBarcodeModal} item={item} />
          <DuplicateInventoryLocationModal show={isDuplicateLocationModalShow} close={closeDuplicateLocationModal} selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation} />
        </>
      )}
    </div>
  );
}

export default Inventory;
