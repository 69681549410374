import axios from "axios";

export const getcomponent = async (
  component,
  filters = {},
  dropdown = false,
  page,
  size,
  search = "",
) => {
  try {
    const obj = JSON.stringify(filters);
    const data = axios.get(
      process.env.REACT_APP_API_URL +
        `/component/${component}${
          page && size ? `?page=${page}&size=${size}` : ""
        }`,
      {
        withCredentials: true,
        headers: { search, filters: obj, dropdown },
      },
    );
    return data;
  } catch (error) {
    return error;
  }
};
