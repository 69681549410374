import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import style from "./CardPaymentPart.module.css";
import editpencil from "../../../assets/svg/editpencil.svg";
import ReactSelect from "../../ReactSelect";

function CartPaymentPart(
  // { close, is_remaining, received_amount, updatePaymentId, emailClose, emailStatus, paymentCheck }
  { paymentType, setPyamentType, amountReceived, setAmountReceived, bank, setBank, comment, setComment, isInvoice, setIsInvoice, isSms, setIsSms, isEmail, setIsEmail }
) {
  const cart = useSelector((state) => state.cart);
  const [is_disabled, setDisabled] = useState(true);
  const [validated, setValidated] = useState(false);
  const [errorPaidAmount, setPaidTotalAmount] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [success, setSuccess] = useState("");
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [error, setError] = useState("");

  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  const logo = user.logo_path;
  const handlePayment = (e) => {
    const baseGrandTotal = cart.totalCartAmount + cart.shippingCost - cart.totalCartDiscount;
    if (Number(e.target.value) <= Number(baseGrandTotal) && +e.target.value > 0) {
      setAmountReceived(e.target.value);
    } else {
      setAmountReceived("");
    }
  };
  const loadBanksOptions = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + `/shops/banks/${shop_id}`, {
          withCredentials: true,
        })
        .then((banksRes) => {
          const banks = banksRes.data.map((bank) => ({
            value: bank,
            label: (
              <>
                <span style={{ fontSize: "12px" }}>
                  Bank Name: <strong className="fs-12">{bank?.bank_name}</strong>
                </span>
                <br />
                <span style={{ fontSize: "12px" }}>Account Title: {bank?.account_title}</span>
                <br />
                <span className="d-block" style={{ fontSize: "12px" }}>
                  Account Number:{bank?.account_number}
                </span>
              </>
            ),
            name: bank.bank_name,
          }));
          resolve(banks);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const handleChangeBank = (e) => {
    setBank({ value: e.value, label: e.name });
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + `/subscriptions/subscription-details/${shop_id}`, { withCredentials: true })
      .then((res) => {
        setSubscriptionDetails(res.data?.subscription[0]);
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, []);

  const handlePaymentType = (e) => {
    setPyamentType(e.target.value);
  };

  return (
    <div className={`p-1`} style={{ fontSize: "14px" }}>
      <Form noValidate validated={validated}>
        {success && <p className="text-success text-center">{success}</p>}
        <div className="text-center">{error && <span className="text-danger p-2 text-center my-2 rounded-1">*{error}</span>}</div>
        <Form.Group className="mb-1">
          <Form.Label className="mb-1" style={{ fontSize: "14px" }}>
            Payment Type *
          </Form.Label>
          <div className="d-flex justify-content-between flex-wrap">
            <Form.Check className="me-2" name="paymentType" type="radio" label="Cash" value="Cash" checked={paymentType === "Cash"} onChange={handlePaymentType} />

            <Form.Check className="me-2" name="paymentType" type="radio" label="Debit/Credit Card" value="Card" checked={paymentType === "Card"} onChange={handlePaymentType} />

            <Form.Check className="me-2" name="paymentType" type="radio" label="Transfer" value="Transfer" checked={paymentType === "Transfer"} onChange={handlePaymentType} />
          </div>
        </Form.Group>
        <hr className={`${style.borderedHr}`} />

        {paymentType === "Cash" ? (
          ""
        ) : (
          <>
            <Form.Group className="w-100" controlId="formBasicShopName">
              <Form.Label className="mb-1">
                Select Bank <span className="text-danger">*</span>
              </Form.Label>
              <ReactSelect placeholder="Select" value={bank} options={loadBanksOptions} handleChange={handleChangeBank} searchable={false} />
              <Form.Control value={bank} onChange={() => {}} hidden required />
              <Form.Control.Feedback type="invalid">Please Select Bank.</Form.Control.Feedback>
            </Form.Group>
            <div>
              <Card className="mb-4 border-0 mt-2 bg-white">
                <Row style={{ background: "#F5F6FA" }} className="">
                  <Card.Body>
                    {bank ? (
                      <Row>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex gap-2" lg="10" xs="11">
                            <div className="d-flex flex-column">
                              <span style={{ fontSize: "12px" }}>Bank Name: {bank?.value?.bank_name}</span>
                              <span style={{ fontSize: "12px" }}>Account Title: {bank?.value?.account_title}</span>
                              <span className="d-block" style={{ fontSize: "12px" }}>
                                Account Number: {bank?.value?.account_number}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Row>
                    ) : (
                      <Row className="text-center text-secondary p-1">
                        <span className="ms-1">No Bank Added.</span>
                      </Row>
                    )}
                  </Card.Body>
                </Row>
              </Card>
            </div>
          </>
        )}
        <Form.Group className="mb-1" controlId="exampleForm.ControlTextarea1">
          <Form.Label className="mb-1">Comments</Form.Label>
          <Form.Control style={{ fontSize: "12px" }} placeholder="e.g. 10 days warranty" as="textarea" maxLength={"200"} rows={1} value={comment} onChange={(e) => setComment(e.target.value)} />
        </Form.Group>
        <div className="">
          <Form.Group className="d-flex justify-content-between flex-wrap mb-3">
            <Form.Check className="py-1 me-2" name="paymentType" type="checkbox" label="Generate Invoice" checked={isInvoice} onChange={(e) => setIsInvoice(e.target.checked)} />
            {!cart?.isCustomerWalkIn && (
              <>
                <Form.Check className="py-1  me-2" name="paymentType" type="checkbox" label="Send SMS" checked={isSms} onChange={(e) => setIsSms(e.target.checked)} disabled={subscriptionDetails.consumed_sms >= subscriptionDetails.sms_limit ? true : false} />
                {cart?.customer?.customer_email && (
                  <Form.Check className="py-1 me-2" name="paymentType" type="checkbox" label="Send Email" checked={isEmail} disabled={subscriptionDetails.consumed_emails < subscriptionDetails.emails_limit ? false : true} onChange={(e) => setIsEmail(e.target.checked)} />
                )}
              </>
            )}
          </Form.Group>
          {subscriptionDetails.consumed_sms >= subscriptionDetails.sms_limit ? <span className="ms-4 text-danger">Sms limit exceeded</span> : ""}
          {subscriptionDetails.consumed_emails >= subscriptionDetails.emails_limit ? <span className="ms-4 text-danger">Emails limit exceeded</span> : ""}
        </div>
        <Form.Group className="mb-3 mt-3">
          <Form.Label className="mb-1" style={{ fontSize: "12px" }}>
            Total Amount
          </Form.Label>
          <Form.Control className="p-2" min={1} required size="sm" style={{ fontSize: "12px" }} type="number" placeholder="Total Amount" disabled={true} value={Number(cart.totalCartAmount + cart.shippingCost - cart.totalCartDiscount)} />
        </Form.Group>
        <Form.Group className="mb-3 mt-3">
          <Form.Label className="mb-1" style={{ fontSize: "12px" }}>
            Received Amount
          </Form.Label>

          <div className="position-relative">
            <Form.Control
              className="p-2"
              min={1}
              required
              size="sm"
              style={{ fontSize: "12px" }}
              type="number"
              placeholder="Enter Amount"
              onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
              disabled={is_disabled}
              value={amountReceived}
              isInvalid={validated && !(parseInt(amountReceived) > 0) && amountReceived?.length}
              onChange={(e) => handlePayment(e)}
              onFocus={(e) => {
                if (+e.target.value === 0) {
                  setAmountReceived("");
                }
              }}
              onBlur={(e) => {
                if (e.target.value === "") {
                  setAmountReceived(0);
                }
              }}
            />
            <div className="position-absolute w-100 d-flex justify-flex-end">
              <img src={editpencil} alt="edit" className={`position-absolute`} style={{ bottom: "13px", right: "0.75rem", cursor: "pointer" }} onClick={() => setDisabled(false)} />
            </div>
            {errorPaidAmount && (
              <div className="alert alert-warning" role="alert">
                Receive Amount should be less then or equal to Remaining Amount.
              </div>
            )}
          </div>
        </Form.Group>
      </Form>
    </div>
  );
}

export default CartPaymentPart;
