import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import PhoneInput from "../../Components/PhoneInput/PhoneInput";
import Select from "../../Components/Select";
import { useSelector } from "react-redux";

const EditCustomer = ({ id, close }) => {
  const [customer_name, setCustomerName] = useState("");
  const [customer_email, setCustomerEmail] = useState("");
  const [customer_number, setCustomerNumber] = useState("");
  const [customer_type, setCustomerType] = useState("");
  const [customer_comment, setCustomerComments] = useState("");
  const [validated, setValidated] = useState(false);
  const [success, setSucces] = useState("");
  const [error, setError] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  // Edit Customer
  useEffect(() => {
    if (id !== -1) {
      axios
        .get(process.env.REACT_APP_API_URL + "/customer/get/" + id, {
          withCredentials: true,
        })
        .then((res) => {
          setCustomerName(res.data[0].customer_name);
          setCustomerEmail(res.data[0].customer_email);
          setCustomerNumber(res.data[0].customer_number);
          setCustomerType(res.data[0].customer_type);
          setCustomerComments(res.data[0].customer_comment);
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
  }, [id]);

  const handleCustomerEdit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setValidated(true);
    try {
      if (
        customer_name !== "" &&
        customer_type !== "" &&
        customer_number !== ""
      ) {
        setIsClicked(true);
        const res = await axios.patch(
          process.env.REACT_APP_API_URL + "/customer/update",
          {
            customer_id: id,
            customer_name,
            customer_email,
            customer_type,
            customer_number,
            customer_comment,
            shop_id: shop_id,
          },
          { withCredentials: true }
        );

        if (res) {
          setSucces("Customer Updated Successfully");
          setError("");
          setTimeout(() => close(), 1000);
        }
      }
    } catch (error) {
      setIsClicked(true);
      setError(error.response.data.error.message);
      setIsClicked(false);
    }
  };
  return (
    <Form noValidate validated={validated} onSubmit={handleCustomerEdit}>
      {success && (
        <p className="text-success p-2 text-center my-2 rounded-1">
          *{success}
        </p>
      )}
      {error && (
        <p className="text-danger p-2 text-center my-2 rounded-1">*{error}</p>
      )}
      <Form.Group className="mb-3">
        <Form.Label className="mb-1">
          Customer Full Name <span style={{ color: "red" }}>*</span>
        </Form.Label>
        <Form.Control
          className="p-2"
          type="text"
          name="customer_name"
          placeholder="Full Name"
          value={customer_name}
          onChange={(e) => setCustomerName(e.target.value)}
          required
        />
        <Form.Control.Feedback className="invalid-text" type="invalid">
          * Invalid Username
        </Form.Control.Feedback>
      </Form.Group>
      <div className="mb-3">
        <Select
          label={"Customer Type"}
          placeholder="Select Customer Type"
          value={customer_type}
          handleChange={(e) => setCustomerType(e.target.value)}
          required={true}
        >
          <option value="Individual">Individual</option>
          <option value="Business">Business</option>
        </Select>
      </div>
      <Form.Group className="mb-3">
        <Form.Label className="mb-1">Customer Email</Form.Label>
        <Form.Control
          className="p-2"
          type="email"
          name="customer_email"
          placeholder="example@mail.com"
          pattern="[a-z0-9._+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
          value={customer_email}
          onChange={(e) => setCustomerEmail(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          * Invalid Email
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="mb-1">
          Customer Contact <span style={{ color: "red" }}>*</span>
        </Form.Label>
        <PhoneInput
          className="p-2"
          name="customer_number"
          placeholder="+92-3XX-XXXXXXX"
          value={customer_number}
          onChange={(e) => setCustomerNumber(e.target.value)}
          required
        />
        <span className="invalid-text" type="invalid">
          * Invalid Phone Number
        </span>
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label className="mb-1">Comments</Form.Label>
        <Form.Control
          placeholder="Comments..."
          as="textarea"
          rows={3}
          value={customer_comment}
          onChange={(e) => setCustomerComments(e.target.value)}
        />
      </Form.Group>
      <Button disabled={isClicked} type="submit" className="w-100">
        Save
      </Button>
    </Form>
  );
};

export default EditCustomer;
