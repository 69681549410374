import { useState, useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import { Form, Row, Col, Table } from "react-bootstrap";
import ImageUploader from "../../ImageUploader/ImageUploader";
import ReactSelect from "../../ReactSelect";
import axios from "axios";
import SearchableSelect from "../../SearchableSelect";
import { convert } from "html-to-text";
import Markdown from "../../MarkdownEditor/Markdown";
import VideoUploader from "../../VideoUploader/VideoUploader";
import style from "./validationModal.module.css";
import { generateShortDescription } from "./generateDescription";

const SearchForm = forwardRef(({ state, setState, category, condition }, ref) => {
  const { imageRef, videoRef } = ref;
  const [colorOptions, setColorOptions] = useState([]);
  const [imageMode, setImageMode] = useState(false);
  const [isImagesLoading, setIsImagesLoading] = useState(false);
  const [isShowMarkdown, setIsShowMarkdown] = useState(false);
  const [titlePlaceholder, setTitlePlaceholder] = useState("e.g. Lenove Ideapad 3");
  const [isTitleCustomize, setIsTitleCustomize] = useState(true);
  const [customizeTitle, setCustomizeTitle] = useState({
    fixedPart: "",
    dynamicPart: "",
  });

  const [isErrorInGeneratingDescription, setIsErrorInGeneratingDescription] = useState(false);
  const handleChangeColor = (e) => {
    setState((prev) => {
      return {
        ...prev,
        listing_color: {
          color: e.label,
          fk_color_id: e.value,
        },
      };
    });
  };

  const handleChangeAdditionalNotes = (e) => {
    setState((prev) => {
      return {
        ...prev,
        listing_optional: {
          ...prev.listing_optional,
          notes: e.target.value,
        },
      };
    });
  };
  const handleChangePtaApproved = (e) => {
    if (category === "Mobile") {
      setState((prev) => ({
        ...prev,
        listing: {
          ...prev.listing,
          is_pta_approved: e.value,
        },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        listing_optional: {
          ...prev.listing_optional,
          is_pta_approved: e.value,
        },
      }));
    }
  };
  useEffect(() => {
    try {
      const getImages = async (model_id, color_id) => {
        const res = await axios.get(process.env.REACT_APP_API_URL + `/images/get/${model_id}/${color_id}`, { withCredentials: true });

        const imagesArray = res.data[0]
          ?.map((image) => {
            if (image === "nil") {
              return null;
            }
            const obj = {
              preview: process.env.REACT_APP_URL_S3 + "/" + image,
              data: image,
              type: "url",
            };
            return obj;
          })
          .filter((file) => file !== null);

        if (imagesArray) {
          setState((prev) => ({ ...prev, images: imagesArray }));
        }
        setIsImagesLoading(false);
      };
      if (imageMode) {
        if (state?.listing_color?.fk_color_id && state?.listing?.fk_model_id) {
          setIsImagesLoading(true);
          getImages(state?.listing?.fk_model_id, state?.listing_color?.fk_color_id);
        } else {
          setIsImagesLoading(true);
          setState((prev) => ({ ...prev, images: [] }));
          setIsImagesLoading(false);
        }
      } else {
        if (state?.oldImages && state?.oldImages?.length > 0) {
          setIsImagesLoading(true);
          setState((prev) => ({ ...prev, images: state?.oldImages }));
          setIsImagesLoading(false);
        }
      }
    } catch (err) {
      setIsImagesLoading(false);
    }
  }, [state?.listing_color?.fk_color_id, state?.listing?.fk_model_id, imageMode]);
  const handleChangeModel = (e) => {
    if (e) {
      if (e.value.fk_model_id !== state?.listing?.fk_model_id) {
        setState((prev) => {
          delete prev?.listing_color;
          delete prev?.listing_optional;
          delete prev?.listing_port;
          delete prev?.isValueFilled;
          return {
            ...prev,
            listing: {
              model: e.value.model,
              fk_model_id: e.value.fk_model_id,
            },
            model_title: e.value.model_title,
          };
        });
      }
    } else {
      setState((prev) => {
        delete prev?.listing_color;
        delete prev?.listing_optional;
        delete prev?.listing_port;
        delete prev?.isValueFilled;
        return {
          ...prev,
          listing: {
            model: null,
            fk_model_id: null,
          },
          title: null,
          model_title: null,
        };
      });
    }
  };

  useEffect(() => {
    if (category === "TV / Monitor") {
      if (state?.listing?.type && state?.model_title) {
        setState((prev) => ({
          ...prev,
          title: prev?.model_title + " [" + prev?.listing?.type + "]",
        }));
      }
    } else {
      setState((prev) => ({
        ...prev,
        title: prev?.model_title,
      }));
    }
  }, [state?.listing?.type, state?.model_title]);
  const handleImageMode = (e) => {
    setImageMode(Boolean(e.target.value === "yes"));
  };

  useEffect(() => {
    const getModel = async (id) => {
      const res = await axios.get(process.env.REACT_APP_API_URL + `/model/${id}`, { withCredentials: true });
      setModel(res.data.success.data);

      setColorOptions(
        res.data.success.data.colors.map((c) => ({
          value: c.id,
          label: c.label,
        })),
      );
    };
    if (state?.listing?.fk_model_id && state?.listing?.fk_model_id !== -1) {
      getModel(state?.listing?.fk_model_id);
    }
  }, [state?.listing?.fk_model_id]);

  const setModel = (model) => {
    setIsShowMarkdown(convert(model.description)?.trim().length < 50);
    if (state?.isValueFilled) {
      setState((prev) => ({
        ...prev,
        model_title: model.title,
      }));
    } else {
      if (category === "Accessories") {
        setState((prev) => ({
          ...prev,
          listing: {
            ...prev.listing,
            accessory_type: model.accessory_type,
            fk_accessory_type_id: model.accessory_type_id,
            brand: model.brand,
            fk_brand_id: model.brand_id,
            description: model.description,
          },
          model_title: model.title,
        }));
      } else if (category === "TV / Monitor") {
        setState((prev) => ({
          ...prev,
          listing: {
            ...prev.listing,
            brand: model.brand,
            fk_brand_id: model.brand_id,
            description: model.description,
            display_type: model.display_type,
            fk_display_type_id: model.display_type_id,
            operating_system: model.operating_system,
            fk_operating_system_id: model.operating_system_id,
            refresh_rate: model.refresh_rate,
            fk_refresh_rate_id: model.refresh_rate_id,
            resolution: model.resolution,
            fk_resolution_id: model.resolution_id,
            screen_size: model.screen_size,
            fk_screen_size_id: model.screen_size_id,
            screen_type: model.screen_type,
            fk_screen_type_id: model.screen_type_id,
            speaker: model.speaker,
            fk_speaker_id: model.speaker_id,
            is_smart_tv: model.smart_tv,
            is_tv_certificated: model.tv_certification,
            is_webcam: model.webcam,
            type: model.type,
          },
          model_title: model.title,

          listing_port: model.ports.map((port) => ({
            port: port.label,
            fk_port_id: port.id,
          })),
        }));
      } else if (category === "Mobile") {
        setState((prev) => ({
          ...prev,
          listing: {
            ...prev.listing,
            brand: model.brand,
            fk_brand_id: model.brand_id,
            description: model.description,
            ram: model.ram,
            fk_ram_id: model.ram_id,
            storage_mobile: model.storage,
            fk_storage_mobile_id: model.storage_id,
          },
          model_title: model.title,

          listing_optional: {
            ...prev.listing_optional,
            battery_capacity: model.battery_capacity,
            fk_battery_capacity_id: model.battery_capacity_id,
            body_type: model.body_type,
            fk_body_type_id: model.body_type_id,
            camera_specs: model.camera_specs,
            fk_camera_specs_id: model.camera_specs_id,
            finger_print: model.finger_print,
            fk_finger_print_id: model.finger_print_id,
            network_band: model.network_bands,
            fk_network_band_id: model.network_bands_id,
            port: model.port,
            fk_port_id: model.port_id,
            processor: model.processor,
            fk_processor_id: model.processor_id,
            screen_protection: model.screen_protection,
            fk_screen_protection_id: model.screen_protection_id,
            screen_size: model.screen_size,
            fk_screen_size_id: model.screen_size_id,
            screen_type: model.screen_type,
            fk_screen_type_id: model.screen_type_id,
            speaker: model.speaker,
            fk_speaker_id: model.speaker_id,
            is_e_sim: model.e_sim,
            resolution: model.resolution,
            fk_resolution_id: model.resolution_id,
            refresh_rate: model.refresh_rate,
            fk_refresh_rate_id: model.refresh_rate_id,
            sim_type: model.sim_type,
            fk_sim_type_id: model.sim_type_id,
          },
        }));
      } else if (category === "Tablet") {
        setState((prev) => ({
          ...prev,
          listing: {
            ...prev.listing,
            brand: model.brand,
            fk_brand_id: model.brand_id,
            description: model.description,
            ram: model.ram,
            fk_ram_id: model.ram_id,
            storage_mobile: model.storage,
            fk_storage_mobile_id: model.storage_id,
          },
          model_title: model.title,

          listing_optional: {
            ...prev.listing_optional,
            battery_capacity: model.battery_capacity,
            fk_battery_capacity_id: model.battery_capacity_id,
            body_type: model.body_type,
            fk_body_type_id: model.body_type_id,
            camera_specs: model.camera_specs,
            fk_camera_specs_id: model.camera_specs_id,
            finger_print: model.finger_print,
            fk_finger_print_id: model.finger_print_id,
            network_band: model.network_bands,
            fk_network_band_id: model.network_bands_id,
            port: model.port,
            fk_port_id: model.port_id,
            processor: model.processor,
            fk_processor_id: model.processor_id,
            screen_protection: model.screen_protection,
            fk_screen_protection_id: model.screen_protection_id,
            screen_size: model.screen_size,
            fk_screen_size_id: model.screen_size_id,
            screen_type: model.screen_type,
            fk_screen_type_id: model.screen_type_id,
            speaker: model.speaker,
            fk_speaker_id: model.speaker_id,
            is_e_sim: model.e_sim,
            is_sim_support: model.sim_support,
            resolution: model.resolution,
            fk_resolution_id: model.resolution_id,
            refresh_rate: model.refresh_rate,
            fk_refresh_rate_id: model.refresh_rate_id,
            sim_type: model.sim_type,
            fk_sim_type_id: model.sim_type_id,
            notes: model.notes,
          },
        }));
      } else if (category === "Desktop Computer") {
        setState((prev) => ({
          ...prev,
          listing: {
            ...prev.listing,
            brand: model.brand,
            fk_brand_id: model.brand_id,
            description: model.description,
            desktop_type: model.desktop_type,
            fk_desktop_type_id: model.desktop_type_id,
            generation: model.generation,
            fk_generation_id: model.generation_id,
            processor: model.processor,
            fk_processor_id: model.processor_id,
            ram: model.ram,
            fk_ram_id: model.ram_id,
            ram_type: model.ram_type,
            fk_ram_type_id: model.ram_type_id,
            storage_hdd: model.storage,
            fk_storage_hdd_id: model.storage_id,
            storage_ssd: model.storage_ssd,
            fk_storage_ssd_id: model.storage_ssd_id,
          },
          model_title: model.title,

          listing_port: model.ports.map((port) => ({
            port: port.label,
            fk_port_id: port.id,
          })),
          listing_optional: {
            ...prev.listing_optional,
            graphic_card_memory: model.graphic_card_memory,
            fk_graphic_card_memory_id: model.graphic_card_memory_id,
            graphic_card_name: model.graphic_card_name,
            fk_graphic_card_name_id: model.graphic_card_name_id,
            graphic_card_type: model.graphic_card_type,
            fk_graphic_card_type_id: model.graphic_card_type_id,
            resolution: model.resolution,
            fk_resolution_id: model.resolution_id,
            screen_size: model.screen_size,
            fk_screen_size_id: model.screen_size_id,
            speaker: model.speaker,
            fk_speaker_id: model.speaker_id,
            refresh_rate: model.refresh_rate,
            fk_refresh_rate_id: model.refresh_rate_id,
            notes: model.notes,
          },
        }));
      } else if (category === "Laptop") {
        setState((prev) => ({
          ...prev,
          listing: {
            ...prev.listing,
            brand: model.brand,
            fk_brand_id: model.brand_id,
            description: model.description,
            laptop_type: model.laptop_type,
            fk_laptop_type_id: model.laptop_type_id,
            generation: model.generation,
            fk_generation_id: model.generation_id,
            processor: model.processor,
            fk_processor_id: model.processor_id,
            ram: model.ram,
            fk_ram_id: model.ram_id,
            ram_type: model.ram_type,
            fk_ram_type_id: model.ram_type_id,
            storage_hdd: model.storage,
            fk_storage_hdd_id: model.storage_id,
            storage_ssd: model.storage_ssd,
            fk_storage_ssd_id: model.storage_ssd_id,
            screen_size: model.screen_size,
            fk_screen_size_id: model.screen_size_id,
          },
          model_title: model.title,

          listing_port: model.ports.map((port) => ({
            port: port.label,
            fk_port_id: port.id,
          })),
          listing_optional: {
            ...prev.listing_optional,
            battery: model.battery,
            fk_battery_id: model.battery_id,
            camera_type: model.camera_type,
            fk_camera_type_id: model.camera_type_id,
            finger_print: model.finger_print,
            fk_finger_print_id: model.finger_print_id,
            graphic_card_memory: model.graphic_card_memory,
            fk_graphic_card_memory_id: model.graphic_card_memory_id,
            graphic_card_name: model.graphic_card_name,
            fk_graphic_card_name_id: model.graphic_card_name_id,
            graphic_card_type: model.graphic_card_type,
            fk_graphic_card_type_id: model.graphic_card_type_id,
            keyboard: model.keyboard,
            fk_keyboard_id: model.keyboard_id,
            resolution: model.resolution,
            fk_resolution_id: model.resolution_id,
            screen_type: model.screen_type,
            fk_screen_type_id: model.screen_type_id,
            speaker: model.speaker,
            fk_speaker_id: model.speaker_id,
            is_backlit: model.backlit,
            is_touch_screen: model.touch_screen,
            notes: model.notes,
          },
        }));
      }
    }
  };
  const transform = (text) => {
    if (text === "pta_approved") {
      return "PTA Aprroved";
    } else if (text === "e_sim") {
      return "E-Sim";
    } else if (text === "storage_mobile") {
      return "Mobile storage";
    } else {
      const temp = text.split("_");
      let ret = "";
      temp.forEach((txt) => {
        if (txt.toLowerCase() === "no") {
          ret += " " + txt.charAt(0).toUpperCase() + txt.slice(1) + ".";
        } else if (txt.toLowerCase() === "incl") {
          ret += " " + txt.charAt(0).toUpperCase() + txt.slice(1) + ".";
        } else {
          ret += " " + txt.charAt(0).toUpperCase() + txt.slice(1);
        }
      });
      return ret;
    }
  };
  const [listingInTable, setListingInTable] = useState([]);
  const [listingInSmallTable, setListingInSmallTable] = useState([]);
  useEffect(() => {
    const arrayOfItems = [];
    if (state?.listing) {
      Object.entries(state?.listing).forEach(([key, value]) => {
        if (!(key.startsWith("fk") || key === "model" || key === "color" || key === "description")) {
          if (key.startsWith("is")) {
            arrayOfItems.push([transform(key.replace("is_", "")), value ? "Yes" : "No"]);
          } else {
            arrayOfItems.push([transform(key), value]);
          }
        }
      });
    }
    if (state?.listing_optional) {
      Object.entries(state?.listing_optional).forEach(([key, value]) => {
        if (!key.startsWith("fk") && key !== "notes") {
          if (key.startsWith("is")) {
            arrayOfItems.push([transform(key.replace("is_", "")), value ? "Yes" : "No"]);
          } else {
            arrayOfItems.push([transform(key), value]);
          }
        }
      });
    }
    if (state?.listing_port) {
      const portsArray = state?.listing_port?.reduce((array, port) => {
        return [...array, port.port];
      }, []);
      arrayOfItems.push(["Ports", portsArray]);
    }
    setListingInTable(
      arrayOfItems.reduce((oldArray, item) => {
        if (oldArray.length > 0) {
          if (oldArray[oldArray.length - 1]?.length === 2) {
            oldArray.push([item]);
            return oldArray;
          } else {
            oldArray[oldArray.length - 1].push(item);
            return oldArray;
          }
        } else {
          oldArray.push([item]);
          return oldArray;
        }
      }, []),
    );
    setListingInSmallTable(arrayOfItems);
  }, [state]);

  const handleChangeImages = (event) => {
    if (typeof event === "function") {
      setState((prev) => ({
        ...prev,
        images: event(prev.images),
      }));
    } else {
      setState((prev) => ({
        ...prev,
        images: event,
      }));
    }
  };
  const handleChangeVideo = (event) => {
    if (typeof event === "function") {
      setState((prev) => ({
        ...prev,
        video: event(prev.video),
      }));
    } else {
      if (event === null) {
        setState((prev) => ({
          ...prev,
          video: undefined,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          video: event,
        }));
      }
    }
  };

  const formatModelSpecs = (model) => {
    return `${model.ram ? `${model.ram} GB RAM ` : ""}${model.mobile_storage ? `/${model.mobile_storage} GB` : ""}${model.storage ? `${model.storage} GB HDD ` : ""}${model.storage_ssd ? `${model.storage_ssd} GB SSD ` : ""}${model.processor ? `${model.processor} ` : ""}${
      model.generation ? `${model.generation}` : ""
    }`;
  };

  useEffect(() => {
    if (isTitleCustomize) {
      if (state?.listing?.brand && state?.listing?.model) {
        const autoFillPart = `${state?.listing?.brand ? (state?.listing?.brand === "Other" ? "" : state?.listing?.brand) : ""} ${state?.listing?.model?.trim()}`;
        setCustomizeTitle((prev) => ({
          ...prev,
          fixedPart: autoFillPart,
        }));
        setState((prev) => ({
          ...prev,
          title: autoFillPart + `${customizeTitle?.dynamicPart?.length > 0 ? " " + customizeTitle.dynamicPart : ""}`,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          title: "",
        }));
        setCustomizeTitle({ fixedPart: "", dynamicPart: "" });
        setTitlePlaceholder("Fill all the fields to Add the title....");
      }
    } else {
      if (state?.listing?.brand && state?.listing?.model) {
        if (category === "Accessories") {
          setState((prev) => ({
            ...prev,
            title: `${state?.listing?.accessory_type && state?.listing?.accessory_type !== "Other" ? state?.listing?.accessory_type + " " : ""}${state?.listing?.brand && state?.listing?.brand !== "Other" ? state?.listing?.brand + " " : ""}${
              state?.listing_color?.color && state?.listing_color?.color !== "Other" ? state?.listing_color?.color + " " : ""
            }${state?.listing?.model?.trim()}`,
          }));
        } else if (category === "TV / Monitor") {
          setState((prev) => ({
            ...prev,
            title: `${state?.listing?.brand ? (state?.listing?.brand === "Other" ? "" : state?.listing?.brand + " ") : ""}${state?.listing?.model?.trim()}${state?.listing?.screen_size && state?.listing?.screen_size !== "0.00" ? " " + state?.listing?.screen_size + " Inches" : ""}${
              state?.listing?.refresh_rate ? " " + state?.listing?.refresh_rate : ""
            } Hz${state?.listing?.screen_type && state?.listing?.screen_type !== "Other" ? " " + state?.listing?.screen_type : ""} ${state?.listing?.type ? "[" + state?.listing?.type + "]" : ""}`,
          }));
        } else if (category === "Mobile") {
          setState((prev) => ({
            ...prev,
            title: `${state?.listing?.brand ? (state?.listing?.brand === "Other" ? "" : state?.listing?.brand + " ") : ""}${state?.listing?.model?.trim()}${
              state?.listing?.ram && state?.listing?.ram !== 0
                ? state?.listing?.storage_mobile && state?.listing?.storage_mobile !== 0
                  ? ` ${state?.listing?.ram}/${state?.listing?.storage_mobile}`
                  : ` ${state?.listing?.ram}`
                : state?.listing?.storage_mobile && state?.listing?.storage_mobile !== 0
                ? ` ${state?.listing?.storage_mobile}`
                : ""
            }${state?.listing_color?.color && state?.listing_color?.color !== "Other" ? " " + state?.listing_color?.color : ""}`,
          }));
        } else if (category === "Tablet") {
          setState((prev) => ({
            ...prev,
            title: `${state?.listing?.brand ? (state?.listing?.brand === "Other" ? "" : state?.listing?.brand + " ") : ""}${state?.listing?.model?.trim()}${
              state?.listing?.ram && state?.listing?.ram !== 0
                ? state?.listing?.storage_mobile && state?.listing?.storage_mobile !== 0
                  ? ` ${state?.listing?.ram}/${state?.listing?.storage_mobile}`
                  : ` ${state?.listing?.ram}`
                : state?.listing?.storage_mobile && state?.listing?.storage_mobile !== 0
                ? ` ${state?.listing?.storage_mobile}`
                : ""
            }${state?.listing_color?.color && state?.listing_color?.color !== "Other" ? " " + state?.listing_color?.color : ""}`,
          }));
        } else if (category === "Desktop Computer") {
          setState((prev) => ({
            ...prev,
            title: `${state?.listing?.brand ? (state?.listing?.brand === "Other" ? "" : state?.listing?.brand + " ") : ""}${state?.listing?.model?.trim()}${state?.listing?.ram && state?.listing?.ram !== 0 ? +" " + state.listing.ram + "GB" : ""}${
              state?.listing?.storage_ssd && state?.listing?.storage_ssd !== 0 ? " " + state.listing.storage_ssd + "GB SSD" : ""
            }${state?.listing?.storage_hdd && state?.listing?.storage_hdd !== 0 ? " " + state.listing.storage_hdd + "GB HDD" : ""}${state?.listing_color?.color && state?.listing_color?.color !== "Other" ? " " + state.listing_color.color : ""}${
              state?.listing?.generation && state?.listing?.generation !== "Other" ? " " + state.listing.generation : ""
            }${state?.listing?.processor && state?.listing?.processor !== "Other" ? " " + state.listing.processor : ""}`,
          }));
        } else if (category === "Laptop") {
          setState((prev) => ({
            ...prev,
            title: `${state?.listing?.brand ? (state?.listing?.brand === "Other" ? "" : state?.listing?.brand + " ") : ""}${state?.listing?.model?.trim()}${state?.listing?.ram && state?.listing?.ram !== 0 ? " " + state.listing.ram + "GB" : ""}${
              state?.listing?.storage_ssd && state?.listing?.storage_ssd !== 0 ? " " + state.listing.storage_ssd + "GB SSD" : ""
            }${state?.listing?.storage_hdd && state?.listing?.storage_hdd !== 0 ? " " + state.listing.storage_hdd + "GB HDD" : ""}${state?.listing_color?.color && state?.listing_color?.color !== "Other" ? " " + state.listing_color.color : ""}${
              state?.listing?.generation && state?.listing?.generation !== "Other" ? " " + state.listing.generation : ""
            }${state?.listing?.processor && state?.listing?.processor !== "Other" ? " " + state.listing.processor : ""}${state?.listing?.screen_size && state?.listing?.screen_size !== "0.00" ? " " + state?.listing?.screen_size + " Inches" : ""}`,
          }));
        }
      } else {
        if (state?.listing?.brand || state?.listing?.model) {
          setTitlePlaceholder("Fill all the fields to generate the title....");
          setState((prev) => {
            delete prev.title;
            return prev;
          });
        }
        setState((prev) => {
          return {
            ...prev,
            title: "",
          };
        });
      }
    }
  }, [state?.listing?.brand, state?.listing?.model, isTitleCustomize, customizeTitle.dynamicPart]);
  const validateInput = (event) => {
    const regex = /^[a-zA-Z0-9 \-\/']*$/;

    const input = event.target.value + event.key;
    if (!regex.test(input)) {
      event.preventDefault();
    }
  };

  return (
    <>
      <Form noValidate validated={state?.validated} className="m-3">
        <Row className="gx-0 gx-sm-3 gx-lg-5">
          <Col className="" md={12}>
            <Form.Group>
              <Form.Control
                as={ReactSelect}
                label={"Model"}
                important
                placeholder={"Search by Model"}
                searchable={true}
                cacheOptions={false}
                isClearable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    const searchParams = new URLSearchParams({
                      category: category,
                      search: e.toLowerCase(),
                    }).toString();
                    axios
                      .get(process.env.REACT_APP_API_URL + `/model${searchParams && searchParams.length > 0 ? `?${searchParams}` : ""}`, {
                        withCredentials: true,
                      })
                      .then((res) => {
                        const filteredValue = res.data.filter((loc) => loc.model_title.toLowerCase().includes(e.toLowerCase()));
                        const value = filteredValue.map((model) => ({
                          value: {
                            model_title: model.model_title,
                            fk_model_id: model.model_id,
                            model: model.model_name,
                          },
                          label: (
                            <div className="border-bottom pb-1">
                              <h6 className="mb-0">{model.model_title}</h6>
                              <div className="text-muted opacity-75" style={{ fontSize: "12px" }}>
                                {formatModelSpecs(model)}
                              </div>
                            </div>
                          ),
                        }));
                        resolve(value);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeModel}
                value={
                  state?.model_title
                    ? {
                        label: state?.model_title,
                        value: state?.model_title,
                      }
                    : null
                }
                isInvalid={state?.validated && !state?.title}
                name="Accessory Type"
                required
              />
              <Form.Control.Feedback type="invalid"> * Please Select Type </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-2" md={12}>
            <Form.Group>
              <Form.Label>
                Title<span style={{ color: "red" }}>*</span>
                <Form.Check
                  type="checkbox"
                  style={{ display: "inline", paddingLeft: "5px" }}
                  checked={!isTitleCustomize}
                  onChange={(e) => {
                    setIsTitleCustomize(!e.target.checked);
                  }}
                />
                <span className={`ps-1 text-muted`}>Auto-Generate Title</span>
              </Form.Label>
              {!isTitleCustomize ? (
                <Form.Control className="py-3 pe-5" type="text" placeholder={titlePlaceholder} value={state?.title} disabled={true} onChange={() => {}} name="model" required />
              ) : (
                <>
                  <div className={`p-3 form-control ${style.customizeTitleField} ${state.title ? "" : style.disabledDiv}`}>
                    {state?.title ? (
                      <>
                        <span className={style.customizeTitleFieldSpan}>{customizeTitle.fixedPart}&nbsp;</span>
                        <input
                          value={customizeTitle.dynamicPart}
                          className={`${style.customizeTitleFieldInput}`}
                          onChange={(e) => {
                            const value = e.target.value;
                            const cleanedValue = value.replace(/[^a-zA-Z0-9 \-\/'+]/g, "").replace(/\s+/g, " ");

                            setCustomizeTitle((prev) => ({
                              ...prev,
                              dynamicPart: cleanedValue,
                            }));
                          }}
                          onKeyDown={validateInput}
                          type="text"
                          disabled={state?.listing?.brand && state?.listing?.model ? false : true}
                          name="model"
                          required
                        />
                      </>
                    ) : (
                      <span className="text-muted">{titlePlaceholder}</span>
                    )}
                  </div>
                </>
              )}
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={SearchableSelect}
                label={"Color"}
                important
                placeholder="Select Color"
                searchable={true}
                options={colorOptions}
                handleChange={handleChangeColor}
                value={
                  state?.listing_color?.color
                    ? {
                        label: state?.listing_color?.color,
                        value: state?.listing_color?.fk_color_id,
                      }
                    : null
                }
                isInvalid={state?.validated && !state?.listing_color?.color}
                name="Color"
                disabled={!state?.listing?.model}
                required
              />
              <Form.Control.Feedback type="invalid">Please Select Color</Form.Control.Feedback>
            </Form.Group>
          </Col>
          {category === "Mobile" || category === "Tablet" ? (
            <Col className="py-3" sm="6" md={6}>
              <Form.Group>
                <Form.Control
                  as={SearchableSelect}
                  value={
                    category === "Mobile"
                      ? typeof state?.listing?.is_pta_approved === "boolean"
                        ? state?.listing?.is_pta_approved
                          ? { value: true, label: "Yes" }
                          : { value: false, label: "No" }
                        : null
                      : typeof state?.listing_optional?.is_pta_approved === "boolean"
                      ? state?.listing_optional?.is_pta_approved
                        ? { value: true, label: "Yes" }
                        : { value: false, label: "No" }
                      : null
                  }
                  label={"PTA approved"}
                  important={category === "Mobile"}
                  placeholder="Select PTA Approved"
                  searchable={false}
                  options={[
                    { value: true, label: "Yes" },
                    { value: false, label: "No" },
                  ]}
                  handleChange={handleChangePtaApproved}
                  name="pta_approved"
                  required
                  isInvalid={state?.validated && category === "Mobile" ? state?.validated && !(typeof state?.listing?.is_pta_approved === "boolean") : state?.validated && !(typeof state?.listing_optional?.is_pta_approved === "boolean")}
                  disabled={!state?.listing?.model}
                />
                <Form.Control.Feedback type="invalid">* Please Select PTA approved</Form.Control.Feedback>
              </Form.Group>
            </Col>
          ) : (
            <></>
          )}
          <div className="d-none d-sm-block">
            {listingInTable?.length > 0 ? (
              <Table className={`ms-3 mb-3 border table-striped`} style={{ width: "95%" }}>
                <tbody>
                  {listingInTable?.map((valueRow) => (
                    <>
                      <tr>
                        {valueRow?.map((value, index) => (
                          <>
                            <td>
                              <strong>{value[0]}</strong>
                            </td>
                            <td className={`${index === 0 ? "border-end" : ""}`}>{Array.isArray(value[1]) ? value[1].map((val) => <div className={`me-1 mb-1 border px-1 rounded d-inline-block bg-secondary bg-gradient text-white`}>{val}</div>) : value[1]}</td>
                          </>
                        ))}
                        {valueRow.length === 1 ? (
                          <>
                            <td></td>
                            <td></td>
                          </>
                        ) : (
                          <></>
                        )}
                      </tr>
                    </>
                  ))}
                </tbody>
              </Table>
            ) : (
              <></>
            )}
          </div>
          <div className="d-block d-sm-none">
            {listingInSmallTable?.length > 0 ? (
              <Table className={`ms-3 mb-3 border table-striped`} style={{ width: "95%" }}>
                <tbody>
                  {listingInSmallTable?.map((valueRow, index) => (
                    <>
                      <tr>
                        <td>
                          <strong>{valueRow[0]}</strong>
                        </td>
                        <td className={`${index === 0 ? "border-end" : ""}`}>{Array.isArray(valueRow[1]) ? valueRow[1].map((val) => <div className={`me-1 mb-1 border px-1 rounded d-inline-block bg-secondary bg-gradient text-white`}>{val}</div>) : valueRow[1]}</td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </Table>
            ) : (
              <></>
            )}
          </div>

          <Col className="py-2" md={12}>
            <Form.Group>
              <Form.Label>
                Additional Notes <span className="text-muted">(Optional)</span>
              </Form.Label>
              <Form.Control className="py-3 pe-5" type="text" as="textarea" rows={3} placeholder="Enter Additional Information" value={state?.listing_optional?.notes} onChange={handleChangeAdditionalNotes} name="notes" isInvalid={state?.listing_optional?.notes?.length > 500} />
              <Form.Control.Feedback type="invalid">Character Limit Exceeded: Please limit your input to a maximum of 500 characters.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          {isShowMarkdown ? (
            <Col className="pb-3" sm={12} md={12}>
              <Form.Group>
                <Markdown
                  important
                  value={state?.listing?.description ? state?.listing?.description : ""}
                  autoGenerated={
                    category === "Accessories"
                      ? undefined
                      : state?.listing
                      ? () => {
                          try {
                            const description = generateShortDescription(category, {
                              ...state?.listing_color,
                              ...state?.listing,
                              ...(state?.listing_optional ? state?.listing_optional : {}),
                              ...(state?.listing_port
                                ? {
                                    ports: state?.listing_port.map((port) => port.port),
                                  }
                                : {}),
                            });
                            setState((prev) => ({
                              ...prev,
                              listing: {
                                ...prev.listing,
                                description: description,
                              },
                            }));
                          } catch (error) {
                            setIsErrorInGeneratingDescription(true);
                          }
                        }
                      : undefined
                  }
                  onEditorChange={(content, editor) => {
                    setState((prev) => ({
                      ...prev,
                      listing: {
                        ...prev.listing,
                        description: content,
                      },
                    }));
                  }}
                  isInvalid={(state?.validated && !state?.listing?.description) || isErrorInGeneratingDescription}
                />
                <Form.Control value={state?.listing?.description} name="Description" onChange={() => {}} hidden required isInvalid={state.validated && convert(state?.listing?.description)?.length < 50} />

                <Form.Control.Feedback type="invalid">{isErrorInGeneratingDescription ? "Please Select All Required Fields" : convert(state?.listing?.description)?.length < 50 ? "Description Must be more than 50 characters." : "Please Enter Description"}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          ) : (
            <></>
          )}
          {condition === 2 ? (
            <Col sm={12} md={12}>
              <Form.Group>
                <Form.Label className="mb-0 mt-3">
                  Use Official or Vendor Images?
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Check value="yes" type="radio" label="Yes" onChange={handleImageMode} checked={imageMode === true} />
                <Form.Check value="no" type="radio" label="No" onChange={handleImageMode} checked={imageMode === false} />
              </Form.Group>
            </Col>
          ) : (
            <></>
          )}
          <Col sm={12} md={12}>
            <Form.Group>
              <Form.Label className="mb-0 mt-3">
                Upload Image<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <ImageUploader ref={imageRef} selectedFiles={state?.images} setSelectedFiles={handleChangeImages} isInvalid={state?.validated && !state?.images?.length > 0} disabled={!(state?.listing?.model && state?.listing_color?.color && !isImagesLoading)} />
              <Form.Control value={state?.images?.length > 0 ? state?.images : ""} name="Image" onChange={() => {}} hidden required />
              <Form.Control.Feedback type="invalid">Please Enter Atleast One Image</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} md={12}>
            <Form.Group>
              <Form.Label className="mb-0 mt-3">Upload Video</Form.Label>
              <VideoUploader ref={videoRef} selectedFile={state?.video} setSelectedFile={handleChangeVideo} isInvalid={state?.validated && !state?.images?.length > 0} disabled={!state?.listing?.model} />
              <Form.Control value={state?.images?.length > 0 ? state?.images : ""} name="Image" onChange={() => {}} hidden />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
});

export default SearchForm;
