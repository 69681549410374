import CustomizeTableview from "../TableViews/CustomizeTableview";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { Button, Col, Container, Row } from "react-bootstrap";
import PrintDailyCashReport from "./PrintDailyCashReport";
import ReactToPrint from "react-to-print";
import SearchableSelect from "../SearchableSelect";
import customloader from "../../assets/images/RollingLoader.gif";
import style from "../../styles/Reports.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import lineIcon from "../../assets/svg/line.svg";
import dateIcon from "../../assets/svg/dateIcon.svg";
import print from "../../assets/svg/printIcon.svg";
import PopUpModal from "../Modal";
import WithdrawAmount from "../WithdrawAmount";
import { getRegisterId } from "../../Features/authSlice";
import DepositeAmount from "../DepositeAmount";
import ReactSelect from "../ReactSelect";
import { dateTime, requestDateTime } from "../../Features/dateTime";
import styles from "../../styles/Setting.module.css";
import { moneyToMillions } from "./InventoryReports/numberToWords";
import filterStyle from "../../styles/filtersButtons.module.css";
import filterr from "../../assets/svg/filter.svg";
import MobileSubReportsTabs from "./MobileSubReportsTab";
import MobilrReportsFilter from "./MobileReportsFilter";
import LoaderPage from "../loaders/loaderPage";

const DailyCashReport = ({
  setOpeningBalanceProp,
  setCashInHandProp,
  setTotalSalesPaymentDataProp,
  setTotalVendorPaymentsDataProp,
  setTotalExpensesDataProp,
  setTotalRefundsDataProp,
  setTotalWithdrawAmountProp,
  setTotalDepositeAmountProp,
  noRegisterCashReportProp,
  setNoRegisterCashReportProp,
  show,
  close,
  MainactiveTab,
}) => {
  const [salesPaymentData, setSalesPaymentData] = useState([]);
  const [totalSalesPaymentData, setTotalSalesPaymentData] = useState([]);
  const [expensesData, setExpensesData] = useState([]);
  const [refundsData, setRefundsData] = useState([]);
  const [totalRefundsData, setTotalRefundsData] = useState([]);
  const [totalExpensesData, setTotalExpensesData] = useState([]);
  const [vendorPaymentsData, setVendorPaymentsData] = useState([]);
  const [totalVendorPaymentsData, setTotalVendorPaymentsData] = useState([]);
  const [tableData, settableData] = useState([]);
  const [openingBalance, setOpeningBalance] = useState();
  const [cashInHand, setCashInHand] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [location, setLocation] = useState({});
  let [locationFields, setLocationFields] = useState({});

  const [register, setRegister] = useState({});
  const [registers, setRegisters] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isToggledSales, setIsToggledSales] = useState(true);
  const [isToggledExpenses, setIsToggledExpenses] = useState(true);
  const [isToggledVendorPayments, setIsToggledVendorPayments] = useState(true);
  const [isToggledRefund, setIsToggledRefund] = useState(true);
  const [isToggledWithdraw, setIsToggledWithdraw] = useState(true);
  const [isToggledDeposite, setisToggledDeposite] = useState(true);

  const [orderBySales, setOrderBySales] = useState({});
  const [orderByExpenses, setOrderByExpenses] = useState({});
  const [orderByVendorPayments, setOrderByVendorPayments] = useState({});
  const [orderByRefund, setOrderByRefund] = useState({});
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [showDepositeModal, setShowDepositeModal] = useState(false);

  const [openingBalanceData, setopeningBalanceData] = useState();
  const [allWithdraData, setAllWithDrawData] = useState();
  const [totalWithdrawAmount, setTotalWithdrawAmount] = useState();
  const [withdrawSubmitted, setWithdrawSubmitted] = useState(false);
  const [allDepositeData, setAllDepositeData] = useState();
  const [totalDepositeAmount, setTotalDepositeAmount] = useState();
  const [depositeSubmitted, setDepositeSubmitted] = useState(false);
  const [activeTAb, setActiveTAb] = useState("Sale-payment");
  const [showMobileTabs, setShowMobileTabs] = useState(false);

  const handleClose = () => setShowMobileTabs(false);
  const handleShow = () => setShowMobileTabs(true);

  const componentRef = useRef(null);
  const dispatch = useDispatch();

  const handleClickSales = () => {
    setIsToggledSales(!isToggledSales);
  };
  const handleClickExpenses = () => {
    setIsToggledExpenses(!isToggledExpenses);
  };
  const handleClickVendorPayments = () => {
    setIsToggledVendorPayments(!isToggledVendorPayments);
  };
  const handleClickRefund = () => {
    setIsToggledRefund(!isToggledRefund);
  };
  const handleWithdrawCLick = () => {
    setIsToggledWithdraw(!isToggledWithdraw);
  };

  const handleDepositeCLick = () => {
    setisToggledDeposite(!isToggledDeposite);
  };
  let fromcurrentDate = new Date(startDate);
  let fromyear = fromcurrentDate.getFullYear();
  let frommonth = ("0" + (fromcurrentDate.getMonth() + 1)).slice(-2);
  let fromday = ("0" + fromcurrentDate.getDate()).slice(-2);
  let fromdateresult = fromyear + "-" + frommonth + "-" + fromday;

  let tocurrentDate = new Date(startDate);
  tocurrentDate.setDate(tocurrentDate.getDate() - 1);
  let toyear = tocurrentDate.getFullYear();
  let tomonth = ("0" + (tocurrentDate.getMonth() + 1)).slice(-2);
  let today = ("0" + tocurrentDate.getDate()).slice(-2);
  let todateresult = toyear + "-" + tomonth + "-" + today;

  const { user } = useSelector((state) => state.auth);
  const userId = user.user_id;
  const user_type = user.user_type;
  const shop_id = user.shop_id;
  const registerID = useSelector((state) => state.auth?.registerId);

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_API_URL + `/location/get-location-by-user/${userId}`, { shop_id: shop_id, user_type: user_type }, { withCredentials: true })
      .then((res) => {
        const locationsArr = res.data.map((loc) => ({
          value: loc.location_id,
          label: loc.location,
          isDisabled: new Date().toISOString() >= loc.active_till ? true : false,
        }));

        setLocation(locationsArr);
        setLocationFields({
          value: locationsArr[0]?.value,
          label: locationsArr[0]?.label,
          isDisabled: locationsArr[0]?.isDisabled,
        });
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, [userId, shop_id, user_type]);
  useEffect(() => {
    const SalesPayment = (locationFields) => {
      axios
        .post(
          process.env.REACT_APP_API_URL + `/reports/daily-cash/sales-payment/${userId}`,
          {
            dates: {
              from: register?.value?.opening_balance_time.split(".")[0].replace("T", " "),
              to: register?.value?.closing_balance_time ? register?.value?.closing_balance_time.split(".")[0].replace("T", " ") : null,
            },
            orderBySales,
            locationFields,
          },
          { withCredentials: true },
        )
        .then((res) => {
          setSalesPaymentData(
            res.data.map((row) => ({
              ...row,
              customer_name: row.customer_number === "nil" ? "Walk-in Customer" : row.customer_name,
              customer_number: row.customer_number === "nil" ? "" : row.customer_number,
              customer_email: row.customer_number === "nil" ? "" : row.customer_email,
            })),
          );
          setIsLoading(false);
        })
        .catch((err) => {
          throw new Error(err.response.data);
        });
    };
    if (Object.keys(locationFields).length > 0 && Object.keys(register).length > 0) {
      SalesPayment(locationFields);
    }
  }, [userId, locationFields, orderBySales, register]);
  useEffect(() => {
    const TotalSalesPayment = (locationFields) => {
      axios
        .post(
          process.env.REACT_APP_API_URL + `/reports/daily-cash/total/sales-payment/${userId}`,
          {
            dates: {
              from: register?.value?.opening_balance_time.split(".")[0].replace("T", " "),
              to: register?.value?.closing_balance_time ? register?.value?.closing_balance_time.split(".")[0].replace("T", " ") : null,
            },
            locationFields,
          },
          { withCredentials: true },
        )
        .then((res) => {
          const data = {
            a: null,
            b: null,
            c: null,
            d: null,
            ...res.data[0],
            e: null,
            f: null,
          };
          setTotalSalesPaymentData(data);
          setTotalSalesPaymentDataProp(data);
          setIsLoading(false);
        })
        .catch((err) => {
          throw new Error(err.response.data);
        });
    };
    if (Object.keys(locationFields).length > 0 && Object.keys(register).length > 0) {
      TotalSalesPayment(locationFields);
    }
  }, [userId, locationFields, register]);
  useEffect(() => {
    const Expenses = (locationFields) => {
      axios
        .post(
          process.env.REACT_APP_API_URL + `/reports/daily-cash/expenses/${userId}`,
          {
            dates: {
              from: register?.value?.opening_balance_time.split(".")[0].replace("T", " "),
              to: register?.value?.closing_balance_time ? register?.value?.closing_balance_time.split(".")[0].replace("T", " ") : null,
            },
            orderByExpenses,
            locationFields,
          },
          { withCredentials: true },
        )
        .then((res) => {
          setExpensesData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          throw new Error(err.response.data);
        });
    };
    if (Object.keys(locationFields).length > 0 && Object.keys(register).length > 0) {
      Expenses(locationFields);
    }
  }, [userId, startDate, locationFields, orderByExpenses, register]);
  useEffect(() => {
    const TotalExpenses = (locationFields) => {
      axios
        .post(
          process.env.REACT_APP_API_URL + `/reports/daily-cash/total/expenses/${userId}`,
          {
            dates: {
              from: register?.value?.opening_balance_time.split(".")[0].replace("T", " "),
              to: register?.value?.closing_balance_time ? register?.value?.closing_balance_time.split(".")[0].replace("T", " ") : null,
            },
            locationFields,
          },
          { withCredentials: true },
        )
        .then((res) => {
          setTotalExpensesData(res.data[0]);
          setTotalExpensesDataProp(res.data[0]);
          setIsLoading(false);
        })
        .catch((err) => {
          throw new Error(err.response.data);
        });
    };
    if (Object.keys(locationFields).length > 0 && Object.keys(register).length > 0) {
      TotalExpenses(locationFields);
    }
  }, [userId, startDate, locationFields, register]);

  useEffect(() => {
    const VendorPayment = (locationFields) => {
      axios
        .post(
          process.env.REACT_APP_API_URL + `/reports/daily-cash/vendors-payment/${userId}`,
          {
            dates: {
              from: register?.value?.opening_balance_time.split(".")[0].replace("T", " "),
              to: register?.value?.closing_balance_time ? register?.value?.closing_balance_time.split(".")[0].replace("T", " ") : null,
            },
            orderByVendorPayments,
            locationFields,
          },
          { withCredentials: true },
        )
        .then((res) => {
          setVendorPaymentsData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          throw new Error(err.response.data);
        });
    };
    if (Object.keys(locationFields).length > 0 && Object.keys(register).length > 0) {
      VendorPayment(locationFields);
    }
  }, [userId, startDate, locationFields, orderByVendorPayments, register]);
  useEffect(() => {
    const TotalVendorsPayment = (locationFields) => {
      axios
        .post(
          process.env.REACT_APP_API_URL + `/reports/daily-cash/total/vendors-payment/${userId}`,
          {
            dates: {
              from: register?.value?.opening_balance_time.split(".")[0].replace("T", " "),
              to: register?.value?.closing_balance_time ? register?.value?.closing_balance_time.split(".")[0].replace("T", " ") : null,
            },
            locationFields,
          },
          { withCredentials: true },
        )
        .then((res) => {
          const data = {
            ...res.data[0],
            a: null,
            b: null,
          };
          setTotalVendorPaymentsData(data);
          setTotalVendorPaymentsDataProp(data);
          setIsLoading(false);
        })
        .catch((err) => {
          throw new Error(err.response.data);
        });
    };
    if (Object.keys(locationFields).length > 0 && Object.keys(register).length > 0) {
      TotalVendorsPayment(locationFields);
    }
  }, [userId, startDate, locationFields, register]);
  useEffect(() => {
    const Refunds = (locationFields) => {
      axios
        .post(
          process.env.REACT_APP_API_URL + `/reports/daily-cash/refunds/${userId}`,
          {
            dates: {
              from: register?.value?.opening_balance_time.split(".")[0].replace("T", " "),
              to: register?.value?.closing_balance_time ? register?.value?.closing_balance_time.split(".")[0].replace("T", " ") : null,
            },
            orderByRefund,
            locationFields,
          },
          { withCredentials: true },
        )
        .then((res) => {
          setRefundsData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          throw new Error(err.response.data);
        });
    };
    if (Object.keys(locationFields).length > 0 && Object.keys(register).length > 0) {
      Refunds(locationFields);
    }
  }, [userId, startDate, locationFields, orderByRefund, register]);
  useEffect(() => {
    const TotalRefunds = (locationFields) => {
      axios
        .post(
          process.env.REACT_APP_API_URL + `/reports/daily-cash/total/refunds/${userId}`,
          {
            dates: {
              from: register?.value?.opening_balance_time.split(".")[0].replace("T", " "),
              to: register?.value?.closing_balance_time ? register?.value?.closing_balance_time.split(".")[0].replace("T", " ") : null,
            },
            locationFields,
          },
          { withCredentials: true },
        )
        .then((res) => {
          setTotalRefundsData(res.data[0]);
          setTotalRefundsDataProp(res.data[0]);
          setIsLoading(false);
        })
        .catch((err) => {
          throw new Error(err.response.data);
        });
    };
    if (Object.keys(locationFields).length > 0 && Object.keys(register).length > 0) {
      TotalRefunds(locationFields);
    }
  }, [userId, startDate, locationFields, register]);
  useEffect(() => {
    const cashInHand = () => {
      axios
        .post(
          process.env.REACT_APP_API_URL + `/reports/cash-in-hand/${userId}`,
          {
            dates: {
              from: register?.value?.opening_balance_time.split(".")[0].replace("T", " "),
              to: register?.value?.closing_balance_time ? register?.value?.closing_balance_time.split(".")[0].replace("T", " ") : null,
            },
            openingBalance: register?.value?.opening_balance_id,
            locationFields,
          },
          { withCredentials: true },
        )
        .then((res) => {
          setCashInHand(res.data[0]?.cash_in_hand);
          setCashInHandProp(res.data[0]?.cash_in_hand);
          setIsLoading(false);
        })
        .catch((err) => {
          throw new Error(err.response.data);
        });
    };
    if (Object.keys(locationFields).length > 0 && Object.keys(register).length > 0 && openingBalanceData) {
      cashInHand();
    }
  }, [userId, locationFields, register, openingBalanceData, withdrawSubmitted, depositeSubmitted]);

  const getWithDrawReports = async () => {
    try {
      if (openingBalanceData?.opening_balance_id) {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/transaction/total-withdraw-amount/${openingBalanceData?.opening_balance_id}`, { withCredentials: true });
        if (res) {
          setAllWithDrawData(
            res?.data?.map((item) => {
              return {
                withdrawer: item.withdrawer,
                amount: item.amount,
                note: item.note,
                withdraw_at: item.created_at,
              };
            }),
          );
          // if(activeTAb==="Withdraw-payments"){
          // settableData(res?.data)
          // }
        }
      }
    } catch {}
  };
  const getToatalWithDrawAmount = async () => {
    try {
      if (openingBalanceData?.opening_balance_id) {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/transaction/total-amount/${openingBalanceData?.opening_balance_id}`, { withCredentials: true });
        if (res) {
          setTotalWithdrawAmount(res?.data[0]?.total);
          setTotalWithdrawAmountProp(res?.data[0]?.total);
        }
      }
    } catch {}
  };
  const getDepositeReports = async () => {
    try {
      if (openingBalanceData?.opening_balance_id) {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/transaction/all-deposit-amounts/${openingBalanceData?.opening_balance_id}`, { withCredentials: true });
        if (res) {
          //  if(activeTAb==="Deposite-payments"){
          //     settableData(res?.data)
          //   }

          setAllDepositeData(
            res?.data?.map((item) => {
              return {
                depositor: item.depositor,
                amount: item.amount,
                note: item.note,
                deposit_at: item.created_at,
              };
            }),
          );
        }
      }
    } catch {}
  };
  const getToatalDepositeAmount = async () => {
    try {
      if (openingBalanceData?.opening_balance_id) {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/transaction/total-depositamount/${openingBalanceData?.opening_balance_id}`, { withCredentials: true });
        if (res) {
          setTotalDepositeAmount(res?.data[0]?.total);
          setTotalDepositeAmountProp(res?.data[0]?.total);
        }
      }
    } catch {}
  };
  useEffect(() => {
    getDepositeReports();
    getToatalDepositeAmount();
  }, [openingBalanceData, startDate, fromdateresult, locationFields, depositeSubmitted]);
  useEffect(() => {
    getWithDrawReports();
    getToatalWithDrawAmount();
  }, [openingBalanceData, startDate, fromdateresult, locationFields, withdrawSubmitted]);

  const formatTimeForDropdown = (date) => {
    return (((date.getHours() - 1) % 12) + 1).toString().padStart(2, "0") + ":" + date.getMinutes().toString().padStart(2, "0") + (parseInt(date.getHours() / 12) > 0 ? "PM" : "AM");
  };
  useEffect(() => {
    if (locationFields?.value) {
      let dates = {
        from: todateresult,
        to: fromdateresult,
      };
      axios.post(`${process.env.REACT_APP_API_URL}/cash-registers/all-register-of-day/${userId}`, { locationFields, dates }, { withCredentials: true }).then((res) => {
        const currentDate = new Date();
        const registerOptions = res.data.map((reg) => {
          const start = dateTime(reg.opening_balance_time);
          if (reg.closing_balance_time) {
            const end = dateTime(reg.closing_balance_time);
            return {
              value: reg,
              label: formatTimeForDropdown(start) + " - " + formatTimeForDropdown(end),
            };
          } else {
            if (start?.toDateString() === currentDate?.toDateString()) {
              return {
                value: reg,
                label: formatTimeForDropdown(start) + " - " + "Till Now",
              };
            } else {
              return {
                value: reg,
                label: formatTimeForDropdown(start) + " - " + "11:59PM",
              };
            }
          }
        });
        setRegisters(registerOptions);
        setNoRegisterCashReportProp(registerOptions?.length === 0);
        setRegister(registerOptions[0] ? registerOptions[0] : {});
        setOpeningBalance(registerOptions[0]?.value?.opening_balance ? registerOptions[0]?.value?.opening_balance : 0);
        setOpeningBalanceProp(registerOptions[0]?.value);
        setopeningBalanceData(registerOptions[0]?.value);
      });
    }
  }, [userId, locationFields, todateresult, fromdateresult]);

  const handleTabChange = (tab) => {
    setActiveTAb(tab);
  };

  useEffect(() => {
    if (activeTAb === "Sale-payment") {
      settableData(salesPaymentData);
    } else if (activeTAb === "Vendor-Payments") {
      settableData(vendorPaymentsData);
    } else if (activeTAb === "Expense") {
      settableData(expensesData);
    } else if (activeTAb === "Refunded-Payments") {
      settableData(refundsData);
    } else if (activeTAb === "Deposite-Payments") {
      settableData(allDepositeData);
    } else if (activeTAb === "Withdraw-Payments") {
      settableData(allWithdraData);
    }
  }, [salesPaymentData, activeTAb, allDepositeData, allWithdraData]);
  const handleLocation = (e) => {
    if (e.value) {
      setLocationFields(e);
    } else {
      setLocationFields({});
    }
    dispatch(
      getRegisterId({
        shop: shop_id,
        location: e?.value,
      }),
    );
  };
  return (
    <>
      <div className="d-none">
        <PrintDailyCashReport
          ref={componentRef}
          totalSalesPaymentData={totalSalesPaymentData}
          totalVendorPaymentsData={totalVendorPaymentsData}
          totalExpensesData={totalExpensesData}
          totalRefundsData={totalRefundsData}
          totalDepositeAmount={totalDepositeAmount}
          totalWithdrawAmount={totalWithdrawAmount}
          openingBalanceData={openingBalanceData}
        />
      </div>
      <Row className="w-100 row px-2 bg-white mb-3  py-md-3 align-items-center">
        <div className="w-100 d-flex  ">
          <div className="w-100 d-xl-flex d-lg-grid d-md-grid d-sm-grid d-none  align-items-baseline">
            <div className=" d-flex mb-3 ">
              <div style={{ minWidth: "174px" }} className="position-relative me-2">
                <DatePicker className={style.datpicker} selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="MMM dd, yyyy" />

                <div
                  style={{
                    position: "absolute",
                    right: "25px",
                    top: "10px",
                  }}
                  className="d-flex align-items-center"
                >
                  <img src={dateIcon} height="18"></img>
                </div>
              </div>
              <div style={{ minWidth: "174px" }} className="me-2">
                <SearchableSelect placeholder="Select Location" searchable={false} options={location} handleChange={handleLocation} value={Object.keys(locationFields).length > 0 ? locationFields : null} disabled={!location.length} />
              </div>
              <div style={{ minWidth: "174px" }} className="me-2">
                <SearchableSelect
                  placeholder="Select Register"
                  searchable={false}
                  options={registers}
                  disabled={Object.keys(locationFields).length === 0}
                  handleChange={(e) => {
                    if (e.value) {
                      setRegister(e);
                      setOpeningBalance(e.value.opening_balance);
                      setOpeningBalanceProp(e.value.opening_balance);
                      setopeningBalanceData(e.value);
                    } else {
                      setRegister({});
                    }
                  }}
                  value={Object.keys(register).length > 0 ? register : null}
                  style={{ width: "100px" }}
                  noOptionsMessage={() => {
                    const currentDate = new Date();
                    if (startDate.toDateString() === currentDate.toDateString()) {
                      return <div>No Register Today</div>;
                    } else {
                      const dateToShow = startDate.toDateString().split(" ");
                      dateToShow.shift();
                      const stringToShow = dateToShow[0] + " " + dateToShow[1] + "," + dateToShow[2];
                      return <div>No Register for {stringToShow}</div>;
                    }
                  }}
                />
              </div>
            </div>
            <div className=" d-flex w-100 mb-1">
              {noRegisterCashReportProp ? (
                <></>
              ) : (
                <>
                  {new Date().toDateString() === dateTime(register?.value?.opening_balance_time).toDateString() && !register?.value?.closing_balance_time ? (
                    <>
                      <Button onClick={() => setShowWithdrawModal(true)} size="sm" className="me-2   " style={{ fontSize: "14px" }} variant="outline-primary">
                        Withdraw
                      </Button>

                      <Button style={{ fontSize: "14px" }} size="sm" className="me-2" onClick={() => setShowDepositeModal(true)}>
                        Deposit
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}

                  <ReactToPrint
                    trigger={() => (
                      <Button variant="outline-primary fs-14 " style={{ height: "42px" }}>
                        <img src={print} alt="removeIcon" className="me-2 d-none d-lg-inline d-md-none " style={{ marginTop: "-3px" }} />
                        Print
                      </Button>
                    )}
                    content={() => componentRef.current}
                    documentTitle="Daily Cash Report"
                  >
                    {" "}
                  </ReactToPrint>
                  {new Date().toDateString() === dateTime(register?.value?.opening_balance_time).toDateString() && !register?.value?.closing_balance_time ? <></> : <Col className="pt-lg-0 pt-md-2 pt-2" sm="4" md="4" lg="3" xl="2"></Col>}
                </>
              )}
            </div>
          </div>
        </div>

        <Col xs="12" lg="6" md="6" xl="6" xxl="3" className={`py-2  d-flex`}>
          <div className="d-grid bg-light p-2 ">
            <span className={`${style.boldTxt} fs-20`}>Rs {openingBalance >= 0 ? moneyToMillions(openingBalance) : "--"}</span>
            <span>Opening balance</span>
          </div>

          <img className="mx-3  " src={lineIcon}></img>
          <div className="d-grid bg-light  p-2 ">
            <span className={`${style.boldTxt} fs-20`}>Rs {cashInHand}</span>
            <span>Cash in hand</span>
          </div>
        </Col>
      </Row>
      <Row></Row>
      <Row className=" w-100 bg-white p-2 mb-3 overflow-auto text-nowrap ob ">
        <Button onClick={handleShow} variant="outline-primary" className={`${filterStyle.filterToggleButton} d-flex d-md-none d-lg-none d-sm-none justify-content-between align-items-center w-100`}>
          <span className=" ms-2 d-lg-inline d-sm-inline d-inline ">{activeTAb.replaceAll("-", " ")}</span>
          <img className="" src={filterr} alt="filter" />
        </Button>
        <Col xl="11" lg="11" md="12" className="d-none d-lg-flex d-md-flex d-sm-flex">
          <Button
            onClick={() => {
              handleTabChange("Sale-payment");
            }}
            className={`${styles.btn} ${activeTAb === "Sale-payment" ? styles.activebtn : ""}  mb-2 mx-2 rounded-5 `}
            variant="outline-primary"
          >
            Sale Payments
          </Button>
          <Button
            // disabled={true}
            onClick={() => {
              handleTabChange("Vendor-Payments");
            }}
            className={`${styles.btns} ${activeTAb === "Vendor-Payments" ? styles.activebtn : ""}  mb-2 mx-2 rounded-5`}
            variant="outline-primary"
          >
            Vendor Payments
          </Button>
          <Button
            onClick={() => {
              handleTabChange("Expense");
            }}
            className={`${styles.btns} ${activeTAb === "Expense" ? styles.activebtn : ""}  mb-2 mx-2 rounded-5`}
            variant="outline-primary"
          >
            Expense
          </Button>
          <Button
            onClick={() => {
              handleTabChange("Refunded-Payments");
            }}
            className={`${styles.btns} ${activeTAb === "Refunded-Payments" ? styles.activebtn : ""} mb-2 mx-2 rounded-5`}
            variant="outline-primary"
          >
            Refunded Payments
          </Button>
          <Button
            onClick={() => {
              handleTabChange("Withdraw-Payments");
            }}
            className={`${styles.btne} ${activeTAb === "Withdraw-Payments" ? styles.activebtn : ""}  mb-2 mx-2 rounded-5`}
            variant="outline-primary"
          >
            Withdraw Payments
          </Button>
          <Button
            onClick={() => {
              handleTabChange("Deposite-Payments");
            }}
            className={`${styles.btne} ${activeTAb === "Deposite-Payments" ? styles.activebtn : ""}  mb-2 mx-2 rounded-5`}
            variant="outline-primary"
          >
            Deposit Payments
          </Button>
        </Col>
      </Row>

      {noRegisterCashReportProp ? (
        <Container fluid className="d-flex justify-content-center align-items-center" style={{ height: "75vh" }}>
          <div className="text-center">
            <h5 className="text-dark">No Register for {startDate.toDateString()}</h5>
          </div>
        </Container>
      ) : (
        <>
          {isLoading ? (
            <div style={{ height: "50vh" }} className="">
              <LoaderPage />
            </div>
          ) : tableData?.length > 0 ? (
            <>
              <div className="d-flex bg-white  justify-content-between">
                <div className="p-3 w-100 fs-20 fw-bold">{activeTAb.replace("-", " ")}</div>
                {activeTAb === "Deposite-Payments" && (
                  <Button style={{ fontSize: "14px" }} size="sm" className="me-2 my-2 d-lg-none d-md-none d-sm-none d-block " onClick={() => setShowDepositeModal(true)}>
                    Deposit
                  </Button>
                )}
                {activeTAb === "Withdraw-Payments" && (
                  <Button onClick={() => setShowWithdrawModal(true)} size="sm" className="me-2 d-lg-none d-md-none d-sm-none d-block  my-2 " style={{ fontSize: "14px" }} variant="primary">
                    Withdraw
                  </Button>
                )}
              </div>
              <div className={style.brdrd}>
                <CustomizeTableview
                  className={"mt-1"}
                  data={tableData}
                  columnNotShow={["id", "user_id", "opening_balance_id"]}
                  actions={false}
                  dateColumn={["customer_payments_date", "created_at", "deposit_at", "withdraw_at"]}
                  sortColumn={["customer_name", "received_amount", "base_grand_total", "total_amount", "cash_received", "Time"]}
                  orderBy={orderBySales}
                  setOrderBy={setOrderBySales}
                  isToggled={isToggledSales}
                />
              </div>
            </>
          ) : (
            <Row className={`${style.noDataAccordian} fw-bold fs-16 d-flex justify-content-center py-3`} style={{ marginBottom: "2rem" }}>
              no data found
            </Row>
          )}
        </>
      )}
      <PopUpModal size="md" title=" Withdraw Amount" show={showWithdrawModal} onHide={() => setShowWithdrawModal(false)}>
        {cashInHand > 0 ? (
          <WithdrawAmount
            cashInHand={cashInHand}
            close={() => {
              setShowWithdrawModal(false);
            }}
            toggle={setWithdrawSubmitted}
            openingBalanceData={openingBalanceData}
          />
        ) : (
          <h5 className="">Cannot Withdraw because cash in hand is 0</h5>
        )}
      </PopUpModal>
      <PopUpModal size="md" title=" Deposit Amount" show={showDepositeModal} onHide={() => setShowDepositeModal(false)}>
        <DepositeAmount
          close={() => {
            setShowDepositeModal(false);
          }}
          toggle={setDepositeSubmitted}
          openingBalanceData={openingBalanceData}
        />
      </PopUpModal>
      <MobileSubReportsTabs tabs={["Sale-payment", "Vendor-Payments", "Expense", "Refunded-Payments", "Withdraw-Payments", "Deposite-Payments"]} activeTAb={activeTAb} handleTabChange={handleTabChange} show={showMobileTabs} handleClose={handleClose} />
      <MobilrReportsFilter handleLocation={handleLocation} location={location} locationFields={locationFields} setLocationFields={setLocationFields} activeTab={MainactiveTab} show={show} handleClose={close} startDate={startDate} setStartDate={setStartDate} />
    </>
  );
};

export default DailyCashReport;
