import React, { useState, useEffect, useRef } from "react";
import { Col, Form } from "react-bootstrap";
import style from "./../styles/AddLocation.module.css";
import { GoogleMap, MarkerF, useJsApiLoader, StandaloneSearchBox } from "@react-google-maps/api";

const Map = ({ coords, setCoords, isLocationSelected = false, setIsLocationSelected }) => {
  const searchBoxRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [libraries] = useState(["places"]);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const [geocoder, setGeocoder] = useState(null);

  useEffect(() => {
    if (isLoaded && !geocoder) {
      setGeocoder(new window.google.maps.Geocoder());
    }
  }, [isLoaded, geocoder]);

  //get current location's longitude and latitude
  useEffect(() => {
    if (coords?.lat === 0 && coords?.lng === 0) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCoords({
          lat: position?.coords?.latitude,
          lng: position?.coords?.longitude,
        });
      });
    } else {
      getFormattedAddress(coords?.lat, coords?.lng);
    }
  }, [navigator, coords, geocoder]);
  const getFormattedAddress = (lat, lng) => {
    if (geocoder) {
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK" && results[0]) {
          setInputValue(results[0].formatted_address);
        } else {
          setInputValue("Address not found");
        }
      });
    }
  };
  const handleMapDragEnd = (e) => {
    setIsLocationSelected(true);
    const settingCoords = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    setCoords(settingCoords);
    getFormattedAddress(settingCoords?.lat, settingCoords?.lng);
  };
  const mapOptions = {
    mapTypeControl: false, // Remove the map type control
    streetViewControl: false, // Remove the street view control
  };
  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places.length > 0) {
      const place = places[0];
      if (place.geometry && place.geometry.location) {
        setCoords({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
        getFormattedAddress(place.geometry.location.lat(), place.geometry.location.lng());
      }
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const clearInput = () => {
    setInputValue("");
    if (searchBoxRef.current) {
      if (searchBoxRef.current.searchBox) {
        searchBoxRef.current.searchBox.value = "";
        searchBoxRef.current.searchBox.focus();
      }
    }
  };
  return (
    <>
      <Form.Group className={`${style.fullWidth} mb-3`} as={Col} lg="12" md="12" sm="12">
        <label className="mb-1">
          Choose Location <span className="text-danger">*</span>
        </label>
        {isLoaded ? (
          <>
            <GoogleMap options={mapOptions} center={coords} zoom={15} mapContainerStyle={{ height: "290px", width: "100%" }}>
              <StandaloneSearchBox onLoad={(ref) => (searchBoxRef.current = ref)} onPlacesChanged={onPlacesChanged}>
                <div className="input-group mb-3 position-relative" style={{ top: "10px", left: "20px", width: "240px" }}>
                  <input type="text" className="form-control" placeholder="Search for location" value={inputValue} onChange={handleInputChange} ref={searchBoxRef} />
                  <span className="input-group-text" style={{ cursor: "pointer" }} onClick={clearInput}>
                    x
                  </span>
                </div>
              </StandaloneSearchBox>
              <MarkerF position={coords} draggable={true} onDragEnd={handleMapDragEnd}></MarkerF>
            </GoogleMap>
          </>
        ) : (
          <></>
        )}
        <Form.Control hidden value={isLocationSelected === true ? isLocationSelected : null} required />
        <Form.Control.Feedback type="invalid">* Adjust Marker On Map To Select Your Location!</Form.Control.Feedback>
      </Form.Group>
    </>
  );
};
export default Map;
