import { useState } from "react";
import Topbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar/Sidebar";
import style from "./../styles/Sidebar.module.css";
import { Outlet, useLocation } from "react-router-dom";
import BreadCrumb from "../Components/BreadCrumb/BreadCrumb";
import { ToastContainer } from "react-toastify";
import SwipeableCart from "../Components/SalesPage/Cart/SwipeableCart";
import { memo } from "react";

const DashboardLayout = ({ onClick }) => {
  const [isSandwichOpen, setIsSandwichOpen] = useState(window.innerWidth > 820 ? false : true);
  const [isCartOpen, setIsCartOpen] = useState(false);

  const toggle = (e) => {
    setIsSandwichOpen((prev) => {
      if (window.innerWidth <= 820) {
        if (prev) {
          window.tidioChatApi?.hide();
        } else {
          window.tidioChatApi?.show();
        }
      }
      return !prev;
    });
  };
  const location = useLocation();
  return (
    <div className={`${style.dashboardLayout}`}>
      <Topbar toggle={toggle} isSandwichOpen={isSandwichOpen} isCartOpen={isCartOpen} setIsCartOpen={setIsCartOpen} />
      <div className={`${isSandwichOpen ? style.sidebarLayoutClose : style.sidebarLayout}`} style={{ zIndex: "2", position: "relative" }}>
        <Sidebar
          setIsSandwichOpen={setIsSandwichOpen}
          isSandwichOpen={isSandwichOpen}
          // toggle={toggle}
        />
      </div>
      <div
        className={`
         ${isSandwichOpen ? style.contentClose : style.content}`}
      >
        <div className={`${style.contentDiv} ${style.scroll}  `}>
          <div className="mt-1">
            <BreadCrumb />
          </div>
          <div className={`mt-2 px-2`}>
            <Outlet />
          </div>
        </div>
      </div>
      <SwipeableCart isOnSalesPage={location.pathname === "/sales"} isOpen={isCartOpen} setIsOpen={setIsCartOpen} />
      <ToastContainer />
    </div>
  );
};

export default memo(DashboardLayout);
