import React from "react";
import { CSVLink } from "react-csv";
import { useState, useEffect, useRef } from "react";

function Csv({ api, format, error, filename, responseObject = null }) {
  const [downloadData, setDownloadData] = useState([]);
  const excelRef = useRef();

  useEffect(() => {
    if (downloadData.length > 0) {
      excelRef.current?.link.click();
      setDownloadData([]);
    }
  }, [downloadData]);

  function requestAllHistory() {
    api()
      .then((data) => {
        if (responseObject) {
          const dataForDownload = responseObject(data)?.map((list) => {
            return format(list);
          });
          setDownloadData(dataForDownload);
        } else {
          const dataForDownload = data.data.map((list) => {
            // const { id, created_at, ...temp } = list;
            // const newObj = { ...temp, created_at: created_at.split("T")[0] };
            return format(list);
          });
          setDownloadData(dataForDownload);
        }
      })
      .catch((err) => {
        if (error(err)) {
          setDownloadData([]);
        }
      });
  }
  return (
    <>
      <button type="button" className="btn-outline-primary btn" onClick={() => requestAllHistory()}>
        <span> Download as CSV </span>
        <span className="download"></span>
      </button>
      <CSVLink
        //   style={{ display: "none" }}
        data={downloadData}
        ref={excelRef}
        filename={filename + ".csv"}
      />
    </>
  );
}

export default Csv;
