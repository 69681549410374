import { useEffect, useRef, useState } from "react";
import { Form, Button, Row, Image } from "react-bootstrap";
import axios from "axios";
import Select from "../../Components/Select";
import { useSelector } from "react-redux";
import PhoneInput from "../../Components/PhoneInput/PhoneInput";
import PopUpModal from "../../Components/Modal";
import styleModal from "./../../styles/Modal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faUpload } from "@fortawesome/free-solid-svg-icons";

import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import CnicInput from "../../Components/CnicInput/CnicInput";
import style from "../../styles/UploadFile.module.css";

const AddTradeInCustomer = ({ close, getCustomer }) => {
  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  const [customer_name, setCustomerName] = useState("");
  const [customer_email, setCustomerEmail] = useState("");
  const [customer_number, setCustomerNumber] = useState("");
  const [customer_cnic, setCustomercnic] = useState("");
  const [cnic_front, setCnic_front] = useState("");
  const [cnic_back, setCnic_back] = useState();
  const [success, setSucces] = useState("");
  const [error, setError] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const nameFormat = /^[a-zA-Z\s]+$/;
  const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  let emailValidate = customer_email.length > 0 && !emailFormat.test(customer_email) ? false : true;
  // Add Customer
  const handleCustomerSubmit = async (e) => {
    e.preventDefault();
    try {
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropagation();
      }
      setValidated(true);
      if (nameFormat.test(customer_name) && customer_number.replace(/\D/g, "").length === 12 && emailValidate && customer_cnic.replace(/\D/g, "").length === 13 && Object.values(cnic_back).length > 0 && Object.values(cnic_front).length > 0) {
        setIsClicked(true);
        const cnicFrontURLs = await handleUpload(cnic_front);
        const cnicBackURLs = await handleUpload(cnic_back);
        if (cnicFrontURLs.length > 0 && cnicBackURLs.length > 0) {
          const customer = {
            customer_name,
            customer_email,
            customer_number,
            customer_cnic,
            shop_id,
          };
          const images = [
            {
              type: "cnic_front",
              url: cnicFrontURLs[0],
            },
            {
              type: "cnic_back",
              url: cnicBackURLs[0],
            },
          ];
          const res = await axios.post(process.env.REACT_APP_API_URL + "/trade-in/add-customer", { customer, images }, { withCredentials: true });
          if (res) {
            setSucces("Customer Added Successfully");
            setTimeout(() => close(), 0);
            setModalShow(true);
            setError("");
            getCustomer(res.data);
          }
        }
      }
    } catch (error) {
      setIsClicked(true);
      if (error?.response?.data?.error) {
        setError(error.response.data.message);
      }
      setIsClicked(false);
    }
  };
  const handleCNICFrontChange = (e) => {
    const file = e?.target?.files[0];
    if (file) {
      setCnic_front({ preview: URL.createObjectURL(file), file, name: file.name });
    }
  };
  const handleCNICBackChange = (e) => {
    const file = e?.target?.files[0];
    if (file) {
      setCnic_back({ preview: URL.createObjectURL(file), file, name: file.name });
    }
  };
  const handleUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("files", file.file, file.file.name);
      const resData = await axios.post(
        `${process.env.REACT_APP_URL_IMAGE_CDN}/trade-in`,
        formData,
        { withCredentials: true },
        {
          headers: {
            Accept: "application/json",
            "content-type": "multipart/form-data",
          },
        }
      );

      return resData.data.files;
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <PopUpModal
        title={success ? success : error}
        icon={<FontAwesomeIcon icon={success ? faCircleCheck : faCircleXmark} />}
        text={success ? success : error}
        type={success ? styleModal.successBg : styleModal.errorBg}
        iconModal={success ? styleModal.successIcon : styleModal.errorIcon}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <Form noValidate validated={validated} onSubmit={handleCustomerSubmit}>
        {/* {error ? <p className="text-danger p-2 text-center my-2 rounded-1">*{error}</p> : success ? <p className="text-success p-2 text-center my-2 rounded-1">*{success}</p> : ""} */}
        {error && <p className="text-danger p-2 text-center my-2 rounded-1">*{error}</p>}
        {/* {success && <p className="text-success p-2 text-center my-2 rounded-1">*{success}</p>} */}
        <Form.Group className="mb-3">
          <Form.Label className="mb-1">
            Customer Full Name <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control
            className="p-2"
            type="text"
            name="customer_name"
            placeholder="Full Name"
            pattern="^^[A-Za-z ]+$"
            value={customer_name}
            onChange={(e) => {
              if (e.target.value.length < 30) {
                setCustomerName(e.target.value);
              }
            }}
            required
          />
          <Form.Control.Feedback type="invalid">* Enter username</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="mb-1">
            Customer Contact <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <PhoneInput className="p-2" name="customer_number" placeholder="+92-3XX-XXXXXXX" value={customer_number} isInvalid={validated && customer_number.replace(/\D/g, "").length !== 12} onChange={(e) => setCustomerNumber(e.target.value)} required />
          <Form.Control.Feedback type="invalid">* Enter phone number</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="mb-1">Customer Email</Form.Label>
          <Form.Control className="p-2" type="email" name="customer_email" placeholder="example@mail.com" value={customer_email} onChange={(e) => setCustomerEmail(e.target.value)} isInvalid={validated && !emailValidate} />
          <Form.Control.Feedback type="invalid">* Invalid Email</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="mb-1">
            Customer CNIC <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <CnicInput className="p-2" name="customer_number" value={customer_cnic} isInvalid={validated && customer_cnic.replace(/\D/g, "").length !== 13} onChange={(e) => setCustomercnic(e.target.value)} placeholder="XXXXX-XXXXXXX-X" required />
          <Form.Control.Feedback type="invalid">* Enter CNIC number</Form.Control.Feedback>
        </Form.Group>
        <div className={`${style.upload_btn_wrapper} mt-4 d-flex  w-100`}>
          <Form.Group className="w-100" controlId="formBasicTiming">
            <Form.Label className="mb-1">
              CNIC Front <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Button variant="outline-primary" className="w-100 border">
              {" "}
              <FontAwesomeIcon className="mx-2" icon={cnic_front?.file ? faCheck : faUpload} /> {cnic_front?.file ? cnic_front.name : "Upload File"}
            </Button>
            <input
              className="p-2"
              type="file"
              name="cnic_front"
              style={{ height: "42px" }}
              onChange={handleCNICFrontChange}
              accept="image/*" // Restrict to image files
              required
            />
            <Form.Control.Feedback type="invalid">* Select CNIC Front Image</Form.Control.Feedback>
            <div style={{ width: "150px" }}>
              <img src={cnic_front?.preview} alt="" width="100%" height="auto" />
            </div>
          </Form.Group>
        </div>
        <div className={`${style.upload_btn_wrapper} mt-4 d-flex  w-100 mb-3`}>
          <Form.Group className="w-100" controlId="formBasicTiming">
            <Form.Label className="mb-1">
              CNIC Back <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Button variant="outline-primary" className="w-100 border">
              {" "}
              <FontAwesomeIcon className="mx-2" icon={cnic_back?.file ? faCheck : faUpload} /> {cnic_back?.file ? cnic_back?.name : "Upload File"}
            </Button>
            <input
              className="p-2"
              type="file"
              name="cnic_back"
              style={{ height: "42px" }}
              onChange={handleCNICBackChange}
              accept="image/*" // Restrict to image files
              required
            />
            <Form.Control.Feedback type="invalid">* Select CNIC Back Image</Form.Control.Feedback>
            <div style={{ width: "150px" }}>
              <img src={cnic_back?.preview} alt="" width="100%" height="auto" />
            </div>
          </Form.Group>
        </div>
        <Button disabled={isClicked} type="submit" className="w-100">
          Save
        </Button>
      </Form>
    </>
  );
};

export default AddTradeInCustomer;
